import { useEffect, useState } from "react";

import {
    Avatar, Button, Card, Divider, Grid, Link, List,
    ListItem, ListItemIcon, Tooltip, Typography
} from "@material-ui/core";
import { AttachFileOutlined, Close, GetApp, ListAlt, Visibility } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import ProgressDeterminate from "../ProgressDeterminate";
import DocumentPreview from '../solicitations/documents/document.preview.component';

import clientDocumentsService from "../../domain/services/client.documents.service";

function PetitionModelDocuments({ customerId, isEdit, emptyComponent = null, setAlert, hideHeader }) {

    const [models, setModels] = useState([]);
    const [preview, setPreview] = useState({ show: false });

    useEffect(() => getAll(), [customerId]);

    const getAll = async () => {

        if (!customerId)
            return;

        clientDocumentsService
            .find(customerId)
            .then(res => setModels(res.data || []))
    }

    const visibility = async (file, show) =>
        setPreview({ ...file, show });

    const save = async (files) => {

        await clientDocumentsService.saveAll(customerId, files)
            .then((res) => {
                let fileErrors = res.map((item) => item.document.name);

                (fileErrors.length) ?
                    setAlert(`Erro no upload dos arquivos: ${fileErrors.join(', ')}`, "warning") :
                    setAlert("Upload Concluído", "success");

            }).finally(getAll);

    }

    const removeDocument = async (item, index) => {

        if (item.documentId)
            await clientDocumentsService.remove(item);

        cancel(index);
    }

    const cancel = (index) => {
        let item = [...models];
        item.splice(index, 1);
        setModels(item);
    }

    const handleSave = (files) => {

        const formatFiles = [...Array.from(files)];

        save(formatFiles);
        setModels([...models, ...formatFiles]);
    }

    if (!isEdit && !models.length)
        return emptyComponent;

    return (
        <>

            <Grid item>

                {!hideHeader && (
                    <>
                        <Grid style={{ display: 'flex' }}>

                            <ListAlt style={{ fontSize: 18, marginRight: 5, alignSelf: 'center' }} />

                            <Typography
                                style={{ fontSize: 15, width: "100%", alignSelf: 'center' }}
                                variant="h6"
                            >
                                Timbrados do cliente
                            </Typography>

                            {isEdit && (
                                <Grid container justifyContent="flex-end">

                                    <input
                                        style={{ 'display': 'none' }}
                                        id="customerFiles" type="file" multiple
                                        onChange={(e) => handleSave(e.target.files)}
                                        accept=".docx,.doc"
                                    />

                                    <label htmlFor="customerFiles">
                                        <Button
                                            size="small" variant="contained"
                                            color="primary" component="span"
                                            style={{ height: 28, minHeight: 0, fontSize: 12, marginTop: 6 }}
                                        >
                                            Anexar
                                        </Button>
                                    </label>

                                </Grid>
                            )}

                        </Grid>

                        <Divider style={{ color: '#d8d8d8', marginTop: 20 }} />
                    </>
                )}

                {
                    (models || []).length ? (
                        <List
                            component="nav" dense={true}
                            style={{
                                width: "100%", height: "auto",
                                overflow: "auto", borderBottom: "1px solid #e0e0e0"
                            }}
                        >
                            {models.map((item, i) => (
                                <ListItem button style={{ paddingLeft: "0" }} key={i}>

                                    <ListItemIcon style={{ minWidth: "24px" }}>
                                        <Avatar
                                            alt={item.name}
                                            style={{
                                                color: "white", width: "24px", height: "24px",
                                                fontSize: "10px", fontWeight: 600, marginRight: 10
                                            }}
                                        >
                                            <AttachFileOutlined style={{ fontSize: "12px" }} />
                                        </Avatar>
                                    </ListItemIcon>

                                    <Grid container justifyContent="flex-start">
                                        <Tooltip title={item.name}><Typography>{item.name}</Typography></Tooltip>
                                    </Grid>

                                    <Grid container justifyContent="flex-end">

                                        <Tooltip title="Visualizar">
                                            <Link style={{ marginRight: "10px" }} onClick={() => { visibility(item, true) }}>
                                                <Visibility style={{ fontSize: "16px" }} />
                                            </Link>
                                        </Tooltip>

                                        <Tooltip title="Download">
                                            <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                                <GetApp style={{ fontSize: "16px" }} />
                                            </Link>
                                        </Tooltip>

                                        {isEdit && (
                                            <Tooltip title="Remover">
                                                <Link
                                                    style={{ marginLeft: "10px", color: "red" }}
                                                    onClick={() => removeDocument(item, i)}
                                                >
                                                    <Close style={{ fontSize: "16px" }} />
                                                </Link>
                                            </Tooltip>
                                        )}

                                    </Grid>

                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Card style={{ margin: "10px 20px 20px", borderRadius: "0", backgroundColor: "unset" }}>

                            <p>
                                <span style={{
                                    paddingTop: "10px", marginBottom: "10px",
                                    color: "rgb(0 0 0 / 34%)", fontWeight: "600"
                                }}>
                                    * Anexe aqui documentos, para ser utilizado como modelo na criação das petições
                                </span>
                            </p>

                            <Alert mt={2} mb={1} severity="info">
                                Não há anexos
                            </Alert>

                        </Card>
                    )
                }

                {isEdit && <ProgressDeterminate style={{ display: "block" }} />}

            </Grid>

            {preview.show && <DocumentPreview file={preview} onSelect={visibility} />}

        </>
    );
}

export default PetitionModelDocuments;
