import repository from '../../repositories/faciliter/faciliter.assure.income.repository';
import faciliterAvailabilityService from '../faciliter.availability.service';
import loginService from '../login.service';

const functionByAction = {
    add: repository.add,
    remove: repository.remove
}

const updateAssureIncome = async (action, faciliterId, data) =>
    functionByAction[action](faciliterId, data);

const findFaciliterAvailability = async (userId) =>
    faciliterAvailabilityService
        .findByUserId(userId)
        .then(getDevelopmentAvailability);

const getDevelopmentAvailability = (res) =>
    (res.data || []).find(item => item.type == 'development') || {};

const findInfoById = (id) =>
    repository
        .findInfoById(id)
        .then(res => res.data || {});

const canAddOrIsAssureIncome = () => {
    const auth = loginService.getUserAuthentication();
    if (!auth || !auth.faciliter) return false;

    const isAllowedForAssureIncome = auth.faciliter?.isAssureIncome || auth.faciliter?.canAddAssureIncome;
    return isAllowedForAssureIncome;
}

export default {
    updateAssureIncome,
    findFaciliterAvailability,
    findInfoById,
    canAddOrIsAssureIncome,
}