import { get, post, put, remove } from './api';
import moment from 'moment';

import requestRegistryService from './solicitation/request.registry.service';
import loginService from './login.service';

import analysisPdfProvider from '../../providers/analysis.pdf.provider';
import requestValidator from '../validators/request.validator';

const findById = async (id) =>
    get({ type: 'user', service: `/requests/analysis/${id}` });

const getAnalysis = async (requestId) => {
    const response = await get({ type: 'user', service: `/requests/${requestId}/analysis` })
    return response.data;
}

const getFilters = async () => {
    const { data } = await get({ type: 'user', service: '/requests/analysis/filters' })
    return data
}

const getCount = async ({ queryString = {}, filters = {} }) => {
    const { data: [{ count }] } = await post({ type: 'user', service: '/requests/analysis/filters/count', queryString, data: filters })
    return +count
}

const filter = async ({ page, perPage } = {}, data = {}) => {

    const response = await post({
        type: 'user',
        service: `/requests/analysis/filters?page=${page}&perPage=${perPage}&getWithPagination=true`,
        data
    })

    return response.data;
}

const getParts = async (analysisId) =>
    get({ type: 'user', service: `/requests/analysis/parts/${analysisId}` });

const editRequestAnalysis = async (data) => {

    if (data.id)
        return await put({ type: 'user', service: `/requests/analysis/${data.id}`, data: mapAnalysis(data) });

    return await post({ type: 'user', service: `/requests/${data.requestId}/analysis`, data: mapAnalysis(data) });
}

const editField = (requestId, field) => {

    let key = "field";

    if (Array.isArray(field.value))
        key = "multiselectField";

    return {
        field: () => requestRegistryService.updateField(requestId, field.id, { value: field.value, isAnalysis: true }),
        multiselectField: () => requestRegistryService.updateMultiSelectField(requestId, field.id, field.value, true)
    }[key];
}

const forwardAsRequest = async (requestId) =>
    post({ type: 'user', service: `/requests/analysis/${requestId}/forward`, data: {} });

const createPart = async (requestId, data) =>
    post({ type: 'user', service: `/requests/analysis/parts/${requestId}`, data });

const editPart = async (data) =>
    put({ type: 'user', service: `/requests/analysis/part/${data.id}`, data });

const removePart = async (data) =>
    remove({ type: 'user', service: `/requests/analysis/part/${data.id}`, data });

const downloadPdf = async (requestId) => {

    const analysisContent = await getAnalysisContent(requestId);

    return analysisPdfProvider.downloadPdf(analysisContent);
}

const getAnalysisContent = async (requestId) => {
    const [analysis, fields] = await Promise
        .all([
            getAnalysis(requestId).catch(() => ({})),
            requestRegistryService.getDynamicFieldsAnalysis(requestId).catch(() => ({}))
        ]);

    const parts = await getParts(analysis.id)
        .then(res => res.data)
        .catch((err) => ({ data: [] }));

    return mapAnalysisContent(analysis, parts, fields)
}

const mapAnalysisContent = (analysis, parts, fieldValues) => ({
    ...analysis,
    parts,
    ...requestRegistryService.integrateFields(analysis, fieldValues)
})

const mapAnalysis = (values) => {

    const fields = [
        'id',
        'created',
        'updated',
        'deleted',
        'analystUserId',
        'requestId',
        'duration',
        'type',
        'state',
        'city',
        'timing',
        'factsAndProceduralPhase',
        'mainPoints',
        'mainOrders',
        'attorney',
        'needCalculation',
        'isFaciliterPremium',
        'modalityId',
        'calculationServiceId',
        'petitionTopics',
        'facts'
    ]

    Object.keys(values).forEach(key => (fields.indexOf(key) < 0) && delete values[key]);

    if (values.duration)
        return { ...values, duration: moment.duration(values.duration, 'HH:mm').asMinutes() }

    return { ...values };
}

const getTimeByTotalMin = (totalMin) => {

    const hh = `${Math.floor(totalMin / 60)}`.padStart(2, '0');
    const mm = `${(totalMin % 60)}`.padStart(2, '0');

    return `${hh}:${mm}`;
}

const getSolicitationTypeName = (analyse) => {
    const dict = {
        petition: "Petição",
        diligence: "Diligência"
    }
    return dict[analyse.requestType]
}

const objectToArray = (obj) => {
    const keys = Object.keys(obj)
    return keys.reduce((previous, current) => {
        const value = obj[current]
        if (value.length > 0)
            return [...previous, { name: current, value }]
        return [...previous]
    }, [])
}

const canViewFormAnalysis = (request) =>
    (loginService.isInternal() || loginService.isFaciliter()) && requestValidator.isPetitionIndicationService(request.serviceKey);

const getRegistrationRecord = async (id) =>
    get({ type: 'user', service: `/requests/registration/${id}/find-analysis-customer` });

export default {
    findById,
    getAnalysis,
    getFilters,
    getCount,
    filter,
    getParts,
    editRequestAnalysis,
    editField,
    forwardAsRequest,
    createPart,
    editPart,
    removePart,
    downloadPdf,
    getTimeByTotalMin,
    getSolicitationTypeName,
    objectToArray,
    canViewFormAnalysis,
    getRegistrationRecord
}