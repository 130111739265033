import { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import moment from "moment";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Snackbar,
  Checkbox,
  Collapse,
  styled,
  IconButton,
  Divider,
} from "@material-ui/core";

import { ArrowDropDown } from "@material-ui/icons";

import { Alert } from "@material-ui/lab";

import solicitationService from "../../../domain/services/solicitation/solicitation.service";

import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";

const _defaultChecklist = [
  {
    id: 1,
    name: "caseFit",
    date: "",
  },
  {
    id: 2,
    name: "structureAndWriting",
    date: "",
  },
  {
    id: 3,
    name: "facts",
    date: "",
  },
  {
    id: 4,
    name: "rationaleAndArgumentation",
    date: "",
  },
  {
    id: 5,
    name: "sourcesOfLaw",
    date: "",
  },
];

const _ChecklistLabels = [
  {
    id: 1,
    title: "ADEQUAÇÃO AO CASO",
    label:
      "Qualificação, endereçamento, tipo de petição e correlação com o processo? Foi observada ou rediscutida a análise para sanar eventuais dúvidas? Não houve cópia da Indicação de Petição?",
    expanded: true,
  },
  {
    id: 2,
    title: "ESTRUTURA E ESCRITA",
    label:
      "Norma culta, gramática correta, sem abreviações, formatação do cliente e, caso o cliente não tenha, a padrão da facilita, com exposição de ideias dotadas de coesão e coerência?",
    expanded: true,
  },
  {
    id: 3,
    title: "FATOS",
    label:
      "Fatos corretos, cronológicos e com remissão aos movimentos/Ids/folhas? Com escorreito tom (de defesa ou acusação, por exemplo?)",
    expanded: true,
  },
  {
    id: 4,
    title: "FUNDAMENTAÇÃO E ARGUMENTAÇÃO ",
    label:
      "Concatenação com o ordenamento jurídico? Utilizando-se de decisões em feitos análogos? Com conectivos explicando o porquê de se dever decidir da maneira solicitada? O tema foi esgotado?",
    expanded: true,
  },
  {
    id: 5,
    title: "FONTES DO DIREITO",
    label: "Jurisprudência, legislação e doutrina utilizadas eram as pertinentes e usadas a contento? Inseriu jurisprudência do Tribunal pertinente ao processo, a partir do ano de 2019? Conferiu se a legislação está em vigência? Inseriu a referência da doutrina?",
    expanded: true,
  },
];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ChecklistRemember = (props) => {
  const [open, setOpen] = useState(true);
  const [statusMsg, setStatusMsg] = useState({});

  const { promiseInProgress } = usePromiseTracker();

  const [checklist, setChecklist] = useState(_defaultChecklist);
  const [checklistLabels, setChecklistLabels] = useState(_ChecklistLabels);

  useEffect(() => {
    getChecklist(props.request.id);
  }, []);

  const getChecklist = async (requestId) => {
    if (!requestId) return;

    const { data } = await solicitationService.getChecklist(requestId);

    if (data) {
      checklist.splice(0, checklist.length);
      checklist.push(...data);

      setChecklist([...checklist]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.onCloseDialog();
  };

  const handleChange = async (index, event) => {
    event ? (checklist[index].date = moment()) : (checklist[index].date = "");

    try {
      await solicitationService.editChecklist(props.request.id, {
        checklist: [...checklist],
      });
    } catch (error) {
      setAlertStatus("Não foi possível salvar a sua conferência", "error");

      return;
    }

    setChecklist([...checklist]);
    setAlertStatus("Salvo com sucesso", "success");
  };

  const handleExpanded = async (index, event) => {
    checklistLabels[index].expanded = !event;

    setChecklistLabels([...checklistLabels]);
  };

  const handleNextStep = () => {
    trackPromise(
      props
        .onSave({ stepName: stepsSolicitation.REVIEW, requestId: props.request.id })
        .then(() => props.onRefreshRequest())
        .then(() => setAlertStatus("Fase alterada com sucesso", "success"))
        .catch(() => setAlertStatus("Não foi possível avançar o status", "error"))
        .finally(() => handleClose())
    );
  };

  const isAllChecked = () => {
    const hasDate = checklist.find((check) => !check.date);

    if (hasDate) {
      return true;
    }

    return false;
  };

  const setAlertStatus = (msg, severity) => {
    setStatusMsg({ text: msg, date: new Date(), open: true, severity });
  };

  const handleCloseAlert = () => {
    setStatusMsg({ text: "", date: new Date(), open: false });
  };

  const loading = () => {
    return (
      promiseInProgress && (
        <Card
          mb={12}
          xs={12}
          style={{
            marginLeft: "-10px",
            marginTop: "10px",
            width: "100%",
            boxShadow: "unset",
          }}
        >
          <CardContent
            style={{
              display: "-webkit-inline-box",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
            <Grid item style={{ marginLeft: "20px", width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Aguarde
              </Typography>
              <Typography variant="label2" gutterBottom>
                Carregando as informações...
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      )
    );
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" disableBackdropClick disableEscapeKeyDown>
        <DialogTitle style={{ padding: "32px 32px 10px" }}>CHECKLIST DE ENVIO PARA REVISÃO</DialogTitle>
        <DialogContent style={{ padding: "12px 32px 10px" }}>
          {loading()}

          {!promiseInProgress && (
            <>
              <Typography style={{ width: "100%", whiteSpace: "normal", marginBottom: "10px" }}>
                Prezado Faciliter, em todas as petições entregues são avaliadas os pontos abaixo. Valide o seu serviço e garanta que todos os requisitos foram desenvolvidos. Marque todos os pontos para que possa prosseguir com a revisão da sua petição.{" "}:
              </Typography>
              <Divider />
              {checklist.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    display: "grid",
                    marginTop: "16px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "self-start",
                      width: "100%",
                    }}
                  >
                    <Checkbox
                      name={item.name}
                      checked={!!item.date}
                      onChange={(e) => handleChange(index, e.target.checked)}
                      color="primary"
                      style={{ padding: 0, marginRight: "10px", marginTop: "-1px" }}
                    />
                    {checklistLabels.map((element, index) => {
                      if (element.id === item.id) {
                        return (
                          <div key={element.id} style={{ display: "grid", width: "100%" }}>
                            <div onClick={() => handleExpanded(index, element.expanded)} style={{ display: "flex" }}>
                              <Typography style={{ width: "100%", whiteSpace: "normal", cursor: "pointer" }}>
                                <strong>{element.title}</strong>
                              </Typography>

                              <ExpandMore
                                expand={element.expanded}
                                aria-expanded={element.expanded}
                                aria-label="show more"
                                style={{ padding: 0 }}
                              >
                                <ArrowDropDown />
                              </ExpandMore>
                            </div>

                            <Collapse in={element.expanded} timeout="auto" unmountOnExit>
                              <Typography style={{ width: "100%", whiteSpace: "normal", marginTop: "10px" }}>
                                {element.label}
                              </Typography>
                            </Collapse>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <Divider style={{ marginTop: "12px" }} />
                </div>
              ))}
              <Typography style={{ width: "100%", whiteSpace: "normal", marginTop: "16px" }}>
                Confira sempre se sua petição atende a todos os critérios que serão valorados!
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="primary" disabled={promiseInProgress}>
            Fechar
          </Button>

          <Button
            onClick={handleNextStep}
            variant="text"
            color="primary"
            disabled={promiseInProgress || isAllChecked()}
          >
            Prosseguir
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        onClose={handleCloseAlert}
        open={statusMsg.open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key={statusMsg.date}
      >
        <Alert severity={statusMsg.severity}>{statusMsg.text}</Alert>
      </Snackbar>
    </>
  );
};

export default ChecklistRemember;
