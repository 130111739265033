import utilsProvider from '../../../components/providers/utils.provider';
import repository from '../../repositories/request/request.advance.repository';

const findAvailableByMainId = async (mainId) =>
    repository.findAvailableByMainId(mainId)
        .then(res => res.data);

const advance = async (mainId, advanceId, skipValidation) =>
    repository.advance(mainId, advanceId, skipValidation)
        .then(res => res.data);

const cancelAdvance = async (mainId, skipValidation = false) =>
    repository.cancelAdvance(mainId, skipValidation)
        .then(res => res.data);

const getAdvanceLabel = (option) => {

    const advanceName = option.label || `Entrega em ${parseInt(option.hoursToDueDate / 24)} dia(s) ${option.isWorkHours ? 'úteis' : ''}`;
    const advancePrice = option.billing ? getAdvanceOptionPriceByBilling(option) : `Você não possui saldo para essa opção`;

    return (
        <div className='flex flex-col'>
            {`${advanceName} - ${advancePrice} `}
            {option.validationErrorMessage && (
                <>
                    <span className='text-[10px]'>{option.validationErrorMessage} </span>
                </>
            )}
        </div>
    )
}

const getAdvanceOptionPriceByBilling = (option) =>
    option.billing.amount == 0 ? `Grátis` : `Preço: ${utilsProvider.formatNumberCurrency(option.billing.amount)}`;

export default {
    findAvailableByMainId,
    advance,
    cancelAdvance,
    getAdvanceLabel
}