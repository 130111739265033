import { get, put, post, remove } from '../api';

import utilsProvider, { isNullOrEmpty } from '../../../components/providers/utils.provider';

import loginService from '../login.service';

import repository from '../../repositories/request/request.registration.repository';
import requestRegistrationInterface from '../../interfaces/request.registration.interface';
import requestService from '../request/request.service';

const findSettingsFields = async (areaId, modalityId) =>
    get({ type: 'user', service: `/requests/registration/settings-fields/modality/${modalityId}/area/${areaId}` });

const findDynamicFields = async (areaId, modalityId) => {

    if (!areaId || !modalityId)
        return [];

    return await get({ type: 'user', service: `requests/registration/field/modality/${modalityId}/area/${areaId}` });
}

const findFieldsToAnalysis = async (areaId, modalityId) =>
    get({ type: 'user', service: `requests/registration/field-analysis/modality/${modalityId}/area/${areaId}` });

const loadOptions = async (path) =>
    get({ type: 'user', service: `requests/registration/field/${path}` });

const getFieldOptions = async (fieldId, fieldKey) =>
    get({ type: 'user', service: `requests/registration/field/${fieldId}/option?fieldKey=${fieldKey}` });

const getDetailsFields = async (requestId) =>
    get({ type: 'user', service: `requests/fields/${requestId}/fields` });

const getDetailsFieldsToForwardPetition = async (requestId) =>
    get({ type: 'user', service: `requests/fields/${requestId}/fields/forward-petition` });

const getDynamicFieldsAnalysis = async (requestId) => {
    const response = await get({ type: 'user', service: `requests/fields/${requestId}/dynamic-fields/analysis` });
    return response.data;
}

const getParts = async (requestId) =>
    get({ type: 'user', service: `requests/registration/parts/${requestId}` });

const editValue = async (data, cacheValue) => {

    if (cacheValue && utilsProvider.checkIfArraysIsEquals(data, cacheValue))
        return {};

    const updateData = await requestRegistrationInterface.update(data);

    if (!updateData)
        return {};

    return await repository.update(data.id, updateData).then(res => res.data);

}

const saveDynamicFields = async (requestId, data) =>
    await put({ type: 'user', service: `requests/registration/${requestId}/dynamic-fields`, data });

const editPart = async (data) =>
    put({ type: 'user', service: `requests/registration/part/${data.id}`, data });

const indicationPetition = async (requestId, data) =>
    put({ type: 'user', service: `requests/registration/${requestId}/indication-petition`, data });

const initRegistry = async (data) => {

    const customerId = data.customerId || loginService.getUserAuthentication().customer?.id;

    return repository
        .initRegistry({ ...data, customerId })
        .then(res => res.data);
}

const validateFields = async (requestId, data) =>
    post({ type: 'user', service: `requests/registration/${requestId}/validate-fields`, data });

const updateField = async (requestId, fieldId, data) =>
    post({ type: 'user', service: `requests/registration/${requestId}/field/${fieldId}`, data });

const updateMultiSelectField = async (requestId, fieldId, data, isAnalysis) =>
    post({ type: 'user', service: `requests/registration/${requestId}/field/${fieldId}/multiselect?isAnalysis=${isAnalysis}`, data });

const createPart = async (requestId, data) =>
    post({ type: 'user', service: `requests/registration/${requestId}/parts`, data });

const finishRequestRegistry = async (requestId, data) =>
    post({ type: 'user', service: `requests/registration/${requestId}/finish`, data });

const checkRequiredFieldsToRegistration = async (requestId) =>
    post({ type: 'user', service: `requests/registration/${requestId}/check-fields-registration`, data: {} });

const removePart = async (data) =>
    remove({ type: 'user', service: `requests/registration/part/${data.id}`, data });

const integrateFields = (requestValue, fields = []) => {

    const exceptionFields = ['linkPremiumFaciliter'];

    return fields.reduce((results, item) => {

        if (item.key === "freeJustice") {

            results[item.key] = { ...item, ...getValueByFieldType(item)};

            return results;
        }


        if (utilsProvider.isNullOrUndefined(requestValue[item.key]) || exceptionFields.includes(item.key))
            results[item.key] = {
                ...item,
                ...getValueByFieldType(item)
            };

        return results;

    }, {});
};

const getValueByFieldType = (item) => {

    const extraDataFunctionByFieldType = {
        'multiselect': (item) => ({ value: (item?.values || []).map(value => ({ ...value, name: value.optionName })) }),
        'select': (item) => ({ initialOptionName: (item?.values || [])[0]?.optionName }),
        'boolean': (item) => ({ value: (item?.values || [])[0]?.value == 'true' }),
    }

    const getExtraData = extraDataFunctionByFieldType[item.type];
    const extraData = getExtraData ? getExtraData(item) : {};

    return {
        value: (item?.values || [])[0]?.value,
        ...extraData
    }
}

const getRequestToContinueRegistration = async () =>
    repository
        .findRequestToContinueRegistration(loginService.getUserAuthentication()?.customer?.id)
        .then(res => res.data || {})
        .catch(() => ({}));

const discardRegistrationDraft = async (requestId) =>
    editValue({ id: requestId, additionalInformation: { isDraftDiscarded: true } });

const getMissingFieldsToStartRegistration = (request) => {

    const requiredFields = [
        { key: 'mainServiceId' },
        { key: 'serviceId' },
        { key: 'areaId' },
        { key: 'modalityId' },
        {
            key: 'forwardCalculationServiceId',
            checkToValidate: (data) => data.needCalculation && data.serviceKey !== 'calculation',
            validator: (data) => data.forwardCalculationServiceId
        },
        {
            key: 'calculationServiceId',
            checkToValidate: (data) => data.needCalculation && data.serviceKey === 'calculation',
            validator: (data) => data.calculationServiceId
        },
        {
            key: 'processNumber',
            checkToValidate: (data) => requestService.hasRequiredProcessNumber(data.modalityKey),
            validator: (data) => data.processNumber
        }
    ];

    if (loginService.isInternal())
        requiredFields.push({ key: 'customerId' })

    const allFields = utilsProvider.flattenObject(request);

    const missingFields = [];

    for (const field of requiredFields) {
        const isInvalid = !field.validator && !allFields[field.key];
        const isInvalidWithValidator = field.checkToValidate && field.checkToValidate(allFields) && !field.validator(allFields);

        if (isInvalid || isInvalidWithValidator)
            missingFields.push(field.key)
    }

    return missingFields;
}

const finishRegistry = async (request) =>
    requestService
        .editRequestAndFields(request, true)
        .then(() => finishRequestRegistry(request.id));

const loadFieldsByProcessNumber = async (requestId) =>
    repository.loadFieldsByProcessNumber(requestId);

const mapValuesToEdit = (values) => {

    const { dueDate, operationalDueDate, servicePublishedAt, citationDate, processDocumentAttachment } = values;

    return {
        ...values,
        dueDate: isNullOrEmpty(dueDate) ? null : dueDate,
        operationalDueDate: isNullOrEmpty(operationalDueDate) ? null : operationalDueDate,
        servicePublishedAt: isNullOrEmpty(servicePublishedAt) ? null : servicePublishedAt,
        citationDate: isNullOrEmpty(citationDate) ? null : citationDate,
        processDocumentAttachment: isNullOrEmpty(processDocumentAttachment) ? null : processDocumentAttachment
    }
}

export default {
    findSettingsFields,
    findDynamicFields,
    findFieldsToAnalysis,
    loadOptions,
    getFieldOptions,
    getDetailsFields,
    getDetailsFieldsToForwardPetition,
    getDynamicFieldsAnalysis,
    getParts,
    editValue,
    editPart,
    indicationPetition,
    saveDynamicFields,
    initRegistry,
    validateFields,
    updateField,
    updateMultiSelectField,
    createPart,
    finishRegistry,
    checkRequiredFieldsToRegistration,
    removePart,
    integrateFields,
    getRequestToContinueRegistration,
    discardRegistrationDraft,
    getMissingFieldsToStartRegistration,
    loadFieldsByProcessNumber,
    mapValuesToEdit
}