import { Button, Dialog, Typography, CircularProgress } from "@material-ui/core";
import { useState, useEffect } from "react";
import documentsService from "../../domain/services/solicitation/documents.service";
import RequestDocumentList from "../requests/documents/request.document.list.component";
import SimpleLoading from "../SimpleLoading";

export default function RequestAdditionalChargeByProcessDocument({
    onConfirm,
    onClose,
    request,
    open,
    setAlert,
    documents,
    getDocuments,
    loading,
    setLoading,
    setRequest,
}) {

    useEffect(() => getDocuments(), [])


    const hasProcessMainDocumentAttached = (docs) => docs.find(doc => doc.isProcessMainDocument);

    const updateProcessMainDocument = (doc, isProcessMainDocument) => documentsService.update({ id: doc.id, isProcessMainDocument})

    const unsetProcessMainDocument = async (document) => {
        await updateProcessMainDocument(document, false);
        setAlert('Documento do processo desmarcado com sucesso', 'success');
        setRequest({ ...request, hasProcessDocument: false });
        getDocuments();
    }

    const setProcessMainDocument = async (document) => {

        const mainProcessDocument = hasProcessMainDocumentAttached(documents);

        if (mainProcessDocument) await updateProcessMainDocument(mainProcessDocument, false);

        await updateProcessMainDocument(document, true);
        setAlert('Documento do processo selecionado com sucesso', 'success');
        setRequest({ ...request, hasProcessDocument: true });
        getDocuments();
    }

    const handleConfirm = async () => {
        try {
            setLoading(true);
            return onConfirm();
        } catch (error) {
            setAlert(error, 'error');
        } finally {
            setLoading(false);
            onClose();
        }
    }

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            onClose={onClose}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 32,
                gap: 24,
                textAlign: 'left',
            }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: 24 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h3" >
                            O documento do seu processo não foi anexado
                        </Typography>

                        {loading ? <CircularProgress size={40} color="inherit" /> : 
                        (
                            documents.length ? (
                                
                                <>
                                    <Typography variant="subtitle1" style={{ marginTop: 20, marginBottom: 20 }}>
                                        Caso você ja tenha adicionado, marque entre os documentos abaixo qual representa o documento de seu processo:
                                    </Typography>
                                    <RequestDocumentList
                                        request={request}
                                        documents={documents}
                                        isProcessDocumentSelection={true}
                                        setProcessMainDocument={setProcessMainDocument}
                                        unsetProcessMainDocument={unsetProcessMainDocument}
            
                                    />                                
                                </>

                            ) : null
                        )
                        }

                        {
                            !hasProcessMainDocumentAttached(documents) && 
                            (
                                    <>
                                        <Typography variant="subtitle1" style={{ marginTop: 30, fontWeight: 600 }}>
                                            Se você não possui o documento do seu processo, não se preocupe!
                                       </Typography>
                                       <Typography variant="subtitle1">
                                            Entendemos o quanto o seu tempo é valioso e por isso buscamos o processo por você.
                                            <b> Para isso, cobramos um valor de R$20 do seu pacote.</b>
                                            <br/><br/>
                                       </Typography>
                                    </>
                            )
                        }
                        
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: 24 }}>
                    {
                        !hasProcessMainDocumentAttached(documents) &&

                        <Button size="large" variant="outlined" onClick={() => onClose(!hasProcessMainDocumentAttached(documents))}>Voltar e adicionar processo</Button>
                    }
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleConfirm}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : hasProcessMainDocumentAttached(documents) ? 'Finalizar Cadastro' :'Busquem o processo por mim'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
