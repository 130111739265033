import { put } from '../api';

import { getDocsByCondition, trackRealTimeChanges } from "../api/firebase";

const getNewsByUser = async (id) =>
    await getDocsByCondition('notifications', { field: 'userId', comparison: '==', value: id })

const trackRealTimeChangesByUser = (collection, id, onChange) =>
    trackRealTimeChanges(collection, { field: 'userId', comparison: '==', value: id }, onChange)

const read = async (id) =>
    put({ type: 'user', service: `notifications/${id}` })

export default {
    getNewsByUser,
    trackRealTimeChangesByUser,
    read
}