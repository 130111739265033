import repository from '../../repositories/request.hearing.repository';

import loginService from '../login.service';

import requestValidator from '../../validators/request.validator';

const canViewForm = (request) =>
    requestValidator.isHearingModality(request.modalityId) && (loginService.isInternal() || loginService.isFaciliter())

const save = async (requestId, values) =>
    repository.save(requestId, values)
        .then(res => res.data);

const findByRequestId = async (requestId) =>
    repository.findByRequestId(requestId)
        .then(res => res.data)
        .then(data => Object.keys(data).reduce((acc, fieldKey) => ({ ...acc, [fieldKey]: data[fieldKey] || typeof data[fieldKey] == 'boolean' ? data[fieldKey].toString() : null }), {}));

export default {
    canViewForm,
    save,
    findByRequestId
}