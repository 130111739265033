import { useRef } from 'react';

export default function useForm() {
    const formInstance = useRef();

    const form = {
        form: formInstance,
        getFieldValue: function(...params) {
            return formInstance.current?.getFieldValue(...params);
        },
        getFieldsValue: function() {
            return formInstance.current?.getFieldsValue();
        },
        resetFields: function() {
            return formInstance.current?.resetFields();
        },
        setFieldValue: function(...params) {
            return formInstance.current?.setFieldValue(...params);
        },
        submit: function() {
            return formInstance.current?.submit();
        },
        validateFields: function() {
            return formInstance.current?.validateFields();
        },
    };

    return form;
};