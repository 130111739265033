import Async from "../components/Async";

const Redirect = Async(() => import('../pages/redirect/redirect'));

const externalRoutes = {
    redirectPage: {
        id: 'redirect',
        path: '/redirect',
        component: Redirect,
    },
}

const externalPages = Object.values(externalRoutes);

export default {
    externalRoutes,
    externalPages
}