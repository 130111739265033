import React from "react";

import { Typography, Grid, Divider } from "@material-ui/core";

import { Helmet } from "react-helmet";

import FaciliterRatingList from '../../../components/requests/rating/faciliter.rating.list.component';

import loginService from "../../../domain/services/login.service";

function FaciliterFeedbacks() {

    const userId = loginService.getUserAuthentication().id;

    return (
        <React.Fragment>

            <Grid container spacing={0} justify="center">
                <Grid item md={11} xs={12}>
                    <Helmet title="Meus Feedbacks" />

                    <Typography variant="h4" gutterBottom display="inline"> Feedbacks </Typography>

                    <Divider my={6} style={{ marginTop: "10px", marginBottom: "10px" }} />

                    <Grid style={{ margin: "15px 0" }}>
                        <FaciliterRatingList userId={userId} />
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default FaciliterFeedbacks;
