import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';

import {
    AccordionDetails,
    Card,
    CardContent,
    Snackbar,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Avatar,
    Tooltip,
    Link,
    Grid,
    CircularProgress,
    Chip,
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import { GetApp, Visibility, Subject, DescriptionOutlined, Close } from '@material-ui/icons';

import { AccordionStyled, LoadingCard } from '../solicitations/documents/finalDocuments.form.styles';

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import { DocsContext } from '../../context/docs.context';

import DocumentPreview from '../solicitations/documents/document.preview.component';

import documentsService from '../../domain/services/solicitation/documents.service';
import finalDocumentsService from '../../domain/services/solicitation/final.documents.service';
import loginService from '../../domain/services/login.service';

function FinalDocumentsForm(props) {

    const [documents, setDocuments] = useState([]);
    const [statusMsg, setStatusMsg] = useState({});
    const [preview, setPreview] = useState({ show: false });
    const { promiseInProgress } = usePromiseTracker();

    const { showFinalDocuments, setShowFinalDocuments } = useContext(DocsContext);

    useEffect(() => getAll(props.request.id), [props.request.id]);

    const getAll = async (requestId) => {

        if (!requestId) return;

        trackPromise(finalDocumentsService.findCustomerFinalDocuments(requestId).then((res) => setDocuments(res.data || [])));
    };

    const selectFile = (index, checked) => {
        let item = [...documents];
        item[index].checked = checked;
        setDocuments(item);
    };

    const visibility = async (file, show) => {
        setPreview({ ...file, show });
    };

    const handleCloseAlert = () => {
        setStatusMsg({ text: '', date: new Date(), open: false });
    };

    const getItemDocument = (item) => {
        const docStatus = {
            REVIEW: { title: 'Versão de Revisão', color: '#FF8033' },
            DEVELOPMENT: { title: 'Versão de Desenvolvimento', color: '#0B7FF7' },
            CORRECTION: { title: 'Versão de Correção', color: '#AE1B12' },
            FINAL: { title: 'Versão Final', color: '#12AC4E' },
        };
        const documentValue = {
            name: item.name,
            step: docStatus[item.step],
            version: item.version,
            created: item.created || moment(),
            updated: item.updated || moment(),
            senderUserName: loginService.isCustomer()
                ? ''
                : `${item.senderUserName || loginService.getUserAuthentication().name} - `,
            error:
                item.verificationInfo && !item.verificationInfo.success
                    ? ' - Arquivo Corrompido'
                    : '',
        };

        const dateTime = convertDateTimeToLocaleString(documentValue.updated);

        return (
            <ListItemText
                style={{ flex: '5 1 auto' }}
                size="small"
                primary={
                    <Fragment>
                        {documentValue.name}<br />
                        <Chip
                            variant="outlined"
                            style={{ marginTop: '5px', marginBottom: '5px', border: `1px solid ${documentValue?.step?.color}` }}
                            label={`${documentValue?.step?.title} - Versão ${documentValue.version}`}
                            size='small'
                        />
                    </Fragment>}
                secondary={`${documentValue.senderUserName}${dateTime}`}
            />
        );
    };

    const convertDateTimeToLocaleString = (date) => {
        let convertDate = date ? new Date(date) : '';

        if (convertDate)
            convertDate = `${convertDate.toLocaleDateString(
                'pt-BR',
            )} - ${convertDate.toLocaleTimeString('pt-BR')} `;

        return convertDate;
    };

    const checkColorFile = (item) => {
        let color = 'gray';

        if (item.verificationInfo)
            color = !item.verificationInfo.success ? '#ff0000a6' : '#008000b8';

        return color;
    };

    const checkColorRow = (item) => (item.checked ? '#f0f8ffdb' : 'white');

    const loading = () => {
        return (
            promiseInProgress &&
            !props.isModalOpen && (
                <LoadingCard mb={12} xs={12}>
                    <CardContent>
                        <CircularProgress m={2} color="secondary" />
                        <Grid>
                            <Typography variant="h6" gutterBottom>
                                Aguarde
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                carregando lista...
                            </Typography>
                        </Grid>
                    </CardContent>
                </LoadingCard>
            )
        );
    };

    return (
        <React.Fragment>
            {(documents || []).length ? (
                <Dialog open={showFinalDocuments} onClose={() => setShowFinalDocuments(!showFinalDocuments)} maxWidth='sm' fullWidth>
                    <DialogTitle style={{ display: 'flex' }}>
                        <Typography style={{ marginTop: '8px', fontSize: '1rem', width: '100%' }} variant='h4'>
                            <DescriptionOutlined
                                style={{
                                    color: '#376fd0',
                                    marginBottom: '-5px',
                                    marginRight: '7px',
                                }}
                            />
                            Petições
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={() => setShowFinalDocuments(!showFinalDocuments)}
                            style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Card mb={6} style={{ marginBottom: '10px' }}>
                        <CardContent style={{ padding: '20px' }}>
                            <AccordionStyled expanded={true}>
                                <AccordionDetails>
                                    {loading()}
                                    {!promiseInProgress &&
                                        ((documents || []).length ? (
                                            <section>
                                                <List component="nav" dense={true}>
                                                    {documents.map((doc, i) => (
                                                        <ListItem
                                                            button
                                                            style={{
                                                                background: checkColorRow(doc),
                                                                paddingLeft: '0'
                                                            }}
                                                            key={i}
                                                        >
                                                            <section
                                                                className="item-section"
                                                                onClick={() => {
                                                                    selectFile(i, !doc.checked);
                                                                }}
                                                            >
                                                                <ListItemIcon
                                                                    style={{
                                                                        minWidth: '30px',
                                                                        marginRight: '15px'
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        alt={doc.name}
                                                                        style={{
                                                                            background: checkColorFile(doc),
                                                                        }}
                                                                    >
                                                                        <Subject
                                                                            style={{ fontSize: '16px' }}
                                                                        />
                                                                    </Avatar>
                                                                </ListItemIcon>
                                                                {getItemDocument(doc)}
                                                            </section>

                                                            {doc.id && (
                                                                <>
                                                                    <Tooltip title="Visualizar">
                                                                        <Link
                                                                            style={{ marginLeft: '10px' }}
                                                                            onClick={() => {
                                                                                visibility(doc, true);
                                                                            }}
                                                                        >
                                                                            <Visibility
                                                                                style={{ fontSize: '16px' }}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                    {loginService.isCustomer() && <Tooltip title="Download">
                                                                        <Link
                                                                            onClick={() => documentsService.downloadFile(doc.url, doc.name)}
                                                                            style={{ marginLeft: '10px' }}
                                                                        >
                                                                            <GetApp
                                                                                style={{ fontSize: '16px' }}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>}
                                                                </>
                                                            )}
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </section>
                                        ) : null)}
                                </AccordionDetails>
                            </AccordionStyled>
                        </CardContent>
                    </Card>
                </Dialog>
            ) : <React.Fragment />}

            <Snackbar
                onClose={handleCloseAlert}
                open={statusMsg.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                key={statusMsg.date}
            >
                <Alert severity={statusMsg.severity}>{statusMsg.text}</Alert>
            </Snackbar>

            {preview.show && <DocumentPreview file={preview} onSelect={visibility} />}
        </React.Fragment>
    );
}

export default FinalDocumentsForm;
