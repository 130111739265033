import RequestConclusion from '../request.conclusion.step';
import RequestProcessInformation from '../request.process.information.component';
import PartsForm from './parts.form.component';
import FactsFundamentalsForm from '../facts.fundamentals.form.component';
import RequestDocumentForm from '../documents/request.document.form.component';
import RequestForm from '../request.form.component';
import RequestFinancial from '../financial/request.financial.component';
import CorrectionsList from '../../solicitations/corrections.list.component';
import CommentsForm from '../../solicitations/comments.form.component';
import CustomerRating from '../rating/customer.rating.component';
import HistoryList from '../../solicitations/history.list.component';
import FaciliterRating from '../rating/faciliter.rating.component';
import RequestServiceDocuments from '../services/request.service.documents.component';
import ReferencesPetition from '../references.petition.component';
import SelectStateCity from './select.state.city';
import ProcessMainDocument from './process.main.document';
import RequestMainInformation from '../request.main.information.component';
import RequestServiceInformation from '../request.service.information.component';
import CustomerRequestServiceInformation from '../customer.request.service.information.component';
import InputTextField from "./input.text.field";
import MultilineTextField from "./multiline.text.field";
import MultiSelectField from "./multiselect.field";
import SelectAutocompleteField from "./select.autocomplete.field";
import RadioButtonField from "./radio.button.field";
import DateTimePickerField from './date.time.picker.field';
import DatePickerField from './date.picker.field';

import { Clipboard, Bookmark, Clock, DollarSign, Edit, MessageSquare, ThumbsUp, Star } from 'react-feather';

import { copyWriter } from './step.legend.config';

import solicitationService from '../../../domain/services/solicitation/solicitation.service';
import requestCalculationService from '../../../domain/services/solicitation/request.calculation.service';

import loginService from '../../../domain/services/login.service';
import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';
import { InsertDriveFile } from '@material-ui/icons';
import LabelViewField from './label.view.field';
import CustomerRequestPetitionIndicationList from '../notion/customer.request.petition.indication.list';
import PremiumField from './premium.field';
import ToggleButtonField from './toggle.button.fields';
import RequestField from './request.field';
import stepsSolicitation from '../../../domain/constant/steps.solicitation.constant';
import PremiumRequestIncentiveButton from '../PremiumRequestIncentiveButton';
import modalities from '../../../domain/constant/modalities.constant';
import { segmentations } from '../../../domain/constant/marketing/segmentation.constant';
import { packages } from '../../../domain/constant/marketing/product.constant';

const requestHeaderSteps = [
    {
        name: "serviceDocument",
        validator: (req) => !req.isLegacyServiceDocument && !!req.concludedAt,
        Component: RequestServiceDocuments,
        source: ['customerUser', 'customer']
    },
    {
        name: "customerRequestInformation",
        Component: CustomerRequestServiceInformation,
        source: ['customerUser', 'customer']
    },
    {
        name: 'requestServiceInformation',
        getLabel: () => 'Informações do Serviço' + (loginService.isInternal() ? ' Atual' : ''),
        Component: RequestServiceInformation,
        source: ['internal', 'faciliter'],
    },
]

const getRequestHeaderSteps = (req) =>
    requestHeaderSteps.filter(step => checkSource(step) && (!step.validator || step.validator(req)))

const isExcludedActionType = (req, excludedActionTypes) => {

    if (!excludedActionTypes) return false;

    const resourceType = req.fields?.typeAction || null;
    if (!resourceType) return true;

    const isExcluded = excludedActionTypes.some(resource => {
        const excludedResource = resource.toLowerCase();
        const selectedResource = (resourceType.optionName || "").toLowerCase();
        return selectedResource.includes(excludedResource);
    });

    return isExcluded;
}

const checkPremiumStepDisplay = (req, isNew) => {
    const isFaciliter = loginService.isFaciliter();
    const isInfinity = packages.infinity.id === req.billedMarketingPackageId 

    if(isFaciliter && isInfinity) return false

    const user = loginService.getUserAuthentication();
   
    if(!user?.customer?.segmentationKey)
        return true;

    const hide = isNew && user.customer.segmentationKey === segmentations.INFINITY.key;

    return !hide;
}

const requestFieldsSteps = [
    {
        name: 'petitionIndications',
        label: () => 'Petições identificadas para o seu caso',
        htmlDescription: () => 'Para prosseguir com a elaboração da minuta, pressione "Solicitar serviço". Observe: 1 crédito será descontado do seu pacote por petição solicitada.',
        Component: CustomerRequestPetitionIndicationList,
        source: ['customer', 'customerUser'],
        hideStep: (req) => req.concludedAt,
        order: () => 1
    },
    {
        name: 'premiumServices',
        label: () => 'Gostaria de solicitar um Faciliter Premium?',
        description: () => (
            'Para garantir qualidade na entrega da sua solicitação, é possível fornecer créditos adicionais para solicitar um Faciliter especializado no serviço escolhido.'
        ),
        Component: PremiumField,
        extra: () => <PremiumRequestIncentiveButton />,
        needFieldsToRender: true,
        hideStep: checkPremiumStepDisplay,
        order: (req, isNew) => (req.status == stepsSolicitation.NEW || isNew) ? 8 : 2
    },
    {
        name: 'mainInformation',
        label: (req) => 'Dados da Solicitação',
        Component: RequestMainInformation,
        order: () => 3
    },
    {
        name: 'processInformation',
        label: (req) => copyWriter.processInformation[req.modalityKey]?.title || copyWriter.processInformation.default.title,
        description: (req) => copyWriter.processInformation[req.modalityKey]?.legend || copyWriter.processInformation.default.legend,
        Component: RequestProcessInformation,
        validator: req => !!req.modalityId && !!req.areaId,
        needFieldsToRender: true,
        order: () => 4
    },
    {
        name: 'processDocument',
        label: () => 'Processo',
        description: () => 'O documento do seu processo é necessário para a realização do serviço',
        Component: ProcessMainDocument,
        hideStep: (req, isNew) => !!isNew && (modalities[req.modalityKey]?.processDocumentRequired || !!req.processNumber),
        order: () => 5
    },
    {
        name: 'parts',
        label: (req) => copyWriter.parts[req.modalityKey]?.legend || copyWriter.parts.default.legend,
        description: () => 'Clique no botão "Adicionar" para incluir as partes do processo. Você incluirá uma parte por vez.',
        Component: PartsForm,
        validator: req => !!req.modalityId,
        order: () => 6
    },
    {
        name: 'factsAndFundamentals',
        label: (req) => copyWriter.factsAndFundamentals[req.modalityKey]?.title || copyWriter.factsAndFundamentals.default.title,
        description: req => copyWriter.factsAndFundamentals[req.modalityKey]?.legend || '',
        Component: FactsFundamentalsForm,
        validator: req => !!req.modalityId && !!req.areaId,
        order: () => 7
    },
    {
        name: 'requestDocuments',
        label: (req) => 'Documentos da solicitação',
        htmlDescription: req => copyWriter.attachaments[req.modalityKey]?.legend || copyWriter.attachaments.default.legend,
        Component: RequestDocumentForm,
        hideStep: (req, isNew) => !!isNew,
        order: () => 9
    },
    {
        name: 'reqeustRegistrationConclusion',
        label: (req) => 'Cadastro Concluído',
        Component: RequestConclusion,
        fullWidth: true,
        hideStep: (req, isNew, isRegistrationCompleted) => !!isNew && !!isRegistrationCompleted,
        order: () => 10
    }
];

const checkSource = (step) => {

    if (!step.source)
        return true;

    const userSource = loginService.getUserAuthentication().source;

    return step.source.includes(userSource);
}

const getRequestFieldsSteps = (request, isNewRequest, isRegistrationCompleted) => {
    return requestFieldsSteps
        .filter(item => checkSource(item))
        .filter(item => !item.hideStep || item.hideStep(request, isNewRequest, isRegistrationCompleted))
        .sort((itemA, itemB) => itemA.order(request) - itemB.order(request));
};

const getElementByFieldType = (type) => ({
    'boolean': {
        component: RadioButtonField
    },
    'date': {
        component: DatePickerField
    },
    'datetime': {
        component: DateTimePickerField
    },
    'text': {
        component: InputTextField
    },
    'multilineText': {
        component: MultilineTextField
    },
    'select': {
        component: SelectAutocompleteField
    },
    'multiselect': {
        component: MultiSelectField
    },
    'selectStateCity': {
        component: SelectStateCity
    },
    'labelView': {
        component: LabelViewField
    },
    'toggleButton': {
        component: ToggleButtonField
    },
}[type]);

const editField = (request, field, isForCreatedRequest = false) => {

    let key = "request";

    if (field.id)
        key = Array.isArray(field.value) ? "multiselectField" : "field";

    const requestEditMethod = isForCreatedRequest ?
        () => solicitationService.editValue(request.id, { [field.name]: field.value })
        : () => requestRegistryService.editValue({ id: request.id, [field.name]: field.value });

    return {
        request: requestEditMethod,
        field: () => requestRegistryService.updateField(request.id, field.id, { value: field.value }),
        multiselectField: () => requestRegistryService.updateMultiSelectField(request.id, field.id, field.value)
    }[key];
}

const getFieldOptions = async (fieldId, fieldName, areaId, fieldKey) => {

    if ((fieldName.indexOf("forwardCalculationService") != -1) || fieldName === "selectCalculationType")
        return await requestCalculationService.findByAreaId(areaId);

    return await requestRegistryService.getFieldOptions(fieldId, fieldKey);
}

const getFieldsToElaborateService = async (areaId, modalityId) => {

    if (!areaId || !modalityId)
        return {};

    const { 0: settingFields, 1: dynamicFields } = await Promise.all([
        requestRegistryService.findSettingsFields(areaId, modalityId).catch(() => ({})),
        requestRegistryService.findFieldsToAnalysis(areaId, modalityId).catch(() => ({}))
    ])


    const fields = [...(settingFields.data || []), ...(dynamicFields.data || [])];

    return fields.filter(field => field.key.indexOf("forward") == -1);
}

const getSetFieldValue = (field, setValue, request) => {

    if (!field.id)
        return setValue

    return (name, value, optionName) => {

        if (name == 'freeJustice')
            setValue(name, !!value)

        setValue("fields", { ...request.fields, [name]: { ...field, value, optionName } }, value);
    }
}

const getFieldValue = (field, fieldName, request) => {

    if (!field.id)
        return request[fieldName]

    return (request?.fields || {})[fieldName]?.value
}

const getFields = async (areaId, modalityId, request, isEdit) => {

    if (!areaId || !modalityId)
        return {
            processInformation: [],
            factsAndFundamentals: []
        }

    const res = await Promise.all([
        requestRegistryService.findSettingsFields(areaId, modalityId).catch(() => ({})),
        requestRegistryService.findDynamicFields(areaId, modalityId).catch(() => ({}))
    ])

    const modalityFields = (res[0].data || []).filter(item => !['forwardToPetition', 'forwardToAnalysis', 'forwardCalculationServiceId'].includes(item.key));
    const dynamicFields = (res[1].data || []);

    const processInformationFields = [
        ...dynamicFields.filter(item => item.tab == 'serviceInformation'),
        ...modalityFields
    ];

    const premiumServices = [...dynamicFields.filter(item => item.tab === 'premiumServices')];

    return {
        processInformation: processInformationFields.sort((a, b) => a.order < b.order ? -1 : 1),
        factsAndFundamentals: [...dynamicFields.filter(item => item.tab == 'factsAndFundamentals')],
        premiumServices,
    };
}

const checkToDisableFields = (sectorFields, request) => {

    const disableFieldsWithoutValue = [{ id: 73, key: 'purpose' }];

    const hasValue = (itemField = {}) => itemField.value || itemField.values?.length;

    const fieldEntries = Object.entries(sectorFields);

    const availableFieldEntries = fieldEntries.map(item => {

        const { 0: key, 1: fields } = item;

        const availableFields = fields.filter(field => {

            const isUnavailableField = disableFieldsWithoutValue.some(item => item.id == field.id);

            if (isUnavailableField && hasValue(request?.fields[field.key]))
                return field;

            return !isUnavailableField;
        })

        return { 0: key, 1: availableFields };
    })

    return Object.fromEntries(availableFieldEntries);
}

const mapRequestFields = (requestValue, fields) => {

    let serviceInformationFields = [...fields.filter(item => item.tab == 'serviceInformation')];

    if (requestValue.referenceAnalysisId)
        serviceInformationFields = serviceInformationFields.filter(item => item.key != 'competences');

    return {
        serviceInformation: serviceInformationFields,
        factsAndFundamentals: [...fields.filter(item => item.tab == 'factsAndFundamentals')]
    }
}

const getComponentField = (props, isReadOnly) => (
    <RequestField
        {...props}
        isReadOnly={isReadOnly}
        getFieldValue={getFieldValue}
        getSetFieldValue={getSetFieldValue}
        getFieldOptions={getFieldOptions}
        getElementByFieldType={getElementByFieldType}
    />
)

const componentByMenuOption = {
    requestForm: {
        name: 'requestForm',
        icon: Clipboard,
        component: RequestForm,
    },
    documents: {
        name: 'documents',
        icon: InsertDriveFile,
        component: RequestDocumentForm,
    },
    comments: {
        name: 'comments',
        icon: MessageSquare,
        component: CommentsForm,
        maxWidth: 800
    },
    historyList: {
        name: 'historyList',
        icon: Clock,
        component: HistoryList,
        maxWidth: 700
    },
    corrections: {
        name: 'corrections',
        icon: Edit,
        component: CorrectionsList,
        maxWidth: 700
    },
    financial: {
        name: 'financial',
        icon: DollarSign,
        component: RequestFinancial,
    },
    ratingFaciliter: {
        name: 'ratingFaciliter',
        icon: Star,
        component: FaciliterRating,
        maxWidth: 700
    },
    requestCustomerRating: {
        name: 'requestCustomerRating',
        icon: ThumbsUp,
        component: CustomerRating,
        maxWidth: 800
    },
    references: {
        name: 'references',
        icon: Bookmark,
        component: ReferencesPetition
    }
}

export default {
    editField,
    isExcludedActionType,
    getFields,
    checkToDisableFields,
    mapRequestFields,
    getComponentField,
    getRequestFieldsSteps,
    getRequestHeaderSteps,
    getFieldsToElaborateService,
    componentByMenuOption
}