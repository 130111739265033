import React, { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { FixedSizeList } from 'react-window';

const PADDING_SIZE = 20;
const ITEM_SIZE = 50;

function Row({ id, title, item, onSelect, style }) {
  const handleSelect = () => onSelect({ id, item });

  return (
    <ListItem onClick={handleSelect} key={id} button disablePadding style={{ ...style }}>
      {title}
    </ListItem>
  );
}

// List must follow type: [{ id: number | string, title: string, item: Object }]
function VirtualizedList({ list, color, onSelect }) {
  return (
    <FixedSizeList
      height={250}
      width={300}
      itemSize={ITEM_SIZE}
      itemCount={list.length}
      overscanCount={20}
      innerElementType={innerElementType}
    >
      {({ index, style }) => (
        <Row style={style} color={color} onSelect={onSelect} {...list[index]} />
      )}
    </FixedSizeList>
  );
}

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + PADDING_SIZE * 2}px`,
    }}
    {...rest}
  />
));

export default VirtualizedList;
