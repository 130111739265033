import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import { Card, CardContent, Grid, Typography, Chip, CircularProgress, Fab } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
    TodayOutlined, EventAvailableOutlined, AccessTimeOutlined, CheckBoxOutlined, MonetizationOn, InsertInvitationOutlined, PersonOutline
} from "@material-ui/icons";

import { Alert } from "@material-ui/lab";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import loginService from "../../domain/services/login.service";
import userService from "../../domain/services/user.service";
import correspondentService from "../../domain/services/correspondent.service";
import packageService from "../../domain/services/financial/package.service";
import utilsProvider from "../providers/utils.provider";

const CardWrapper = styled(Card)`
  border: 1px solid rgb(0 0 0 / 3%);
  margin-bottom: 10px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 2px !important;
  height: fit-content;
`;

const InvoicesList = (props) => {

    const [openFilter, setOpenFilter] = useState(false);
    const [periodValue, setPeriodValue] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [faciliter, setFaciliter] = useState({});

    const { promiseInProgress } = usePromiseTracker();

    const userId = (
        new URL(window.location.href).searchParams.get("userId") ||
        loginService.getUserAuthentication().id
    );

    useEffect(() => {
        trackPromise(list());
        getFaciliter();
    }, []);

    moment.locale('pt');

    moment.updateLocale('pt', {
        months: [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
            "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        ]
    });

    const list = async () => findByPeriod(moment());

    const getFaciliter = async () => {
        if (!loginService.hasPermissionRole('financial', loginService.getUserAuthentication()))
            return;

        const { data } = await userService.find(userId).catch(error => ({ data: [] }));
        setFaciliter({ ...data });
    }

    const findByPeriod = async (period) => {
        setPeriodValue(moment(period).format('MMMM [de] YYYY'));

        const { data } = await correspondentService.getInvoicesByPeriod(userId, moment(period).format("YYYY/MM"))
            .catch(error => ({ data: [] }));

        setInvoices(data);
    }

    const closeFilter = () => {
        trackPromise(list());
        setPeriodValue(null);
    }

    const loading = () => {
        return (
            promiseInProgress &&
            <Card mb={12} xs={12} style={{ marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                <CardContent style={{ display: "-webkit-inline-box", paddingTop: "20px", paddingBottom: "20px" }}>
                    <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                    <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                        <Typography variant="h6" gutterBottom> Aguarde </Typography>
                        <Typography variant="body2" gutterBottom> carregando os faturamentos... </Typography>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    const getFilterPeriod = () => {
        return (
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <Fab size="small" variant="round" color="primary"
                    style={{ width: "28px", height: "28px", minHeight: "0", marginTop: "3px" }}
                    onClick={() => { setOpenFilter(isOpen => !isOpen) }}>
                    <InsertInvitationOutlined fontSize="small" />
                </Fab>
                <DatePicker name="filter" label="Período" style={{ display: "none" }}
                    okLabel="Confirmar" cancelLabel="Cancelar"
                    openTo="month" views={["year", "month"]}
                    open={openFilter} format="yyyy/MM"
                    onClose={() => setOpenFilter(isOpen => !isOpen)}
                    onChange={(value) => findByPeriod(value)}
                />
            </MuiPickersUtilsProvider>
        )
    }

    return (
        <React.Fragment>
            {loading()}

            <Grid container justify="flex-end" style={{ display: 'inline-flex', marginBottom: "10px", width: "98%" }}>

                {!!periodValue &&
                    <Chip label={`Período: ${periodValue}`}
                        color="primary" variant="outlined"
                        style={{ margin: '5px', height: '24px', fontWeight: "600" }} onDelete={closeFilter}
                    />
                }

                {getFilterPeriod()}

                <Chip label={`Total: ${invoices.length}`} color="primary"
                    style={{ margin: '5px', height: '24px', fontWeight: "600" }}
                />
            </Grid>

            {!promiseInProgress && (invoices.length ? invoices.map((item, i) => (
                <CardWrapper mb={4} key={i} onClick={() => props.onSelect(item)}>
                    <CardContent>
                        <Chip label={`ID: #${item.id} `}
                            style={{
                                float: 'left', color: 'white', fontWeight: '600', marginRight: "10px",
                                fontSize: '12px', height: '17px', backgroundColor: "#616161a6"
                            }}
                        />

                        <Chip label={item.closed ? 'Fechada' : 'Aberta'}
                            style={{
                                float: 'right', color: 'white', fontWeight: '600',
                                fontSize: '12px', height: '17px',
                                background: !item.closed ? "#376fd0" : "green"
                            }}
                        />

                        <Grid container direction="column" alignItems="flex-start" mb={1}
                            style={{ marginTop: "20px" }}>
                            <Typography variant="h4" style={{ fontSize: "14px", fontWeight: "600", width: "100%", marginTop: "10px" }}>
                                {!item.closed ?
                                    <AccessTimeOutlined fontSize="small" style={{ marginBottom: "-3px", color: "#376fd0" }} /> :
                                    <CheckBoxOutlined fontSize="small" style={{ marginBottom: "-3px", color: "green" }} />
                                }
                                {" "}{moment(item.created).format('MMMM [de] YYYY')}
                            </Typography>
                        </Grid>

                        {!!faciliter.id &&
                            <Grid style={{ marginTop: "10px", fontSize: "14px", whiteSpace: "nowrap", width: "22em", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <PersonOutline fontSize="small"
                                    style={{ color: "#616161", marginBottom: "-3px", paddingTop: "3px", marginRight: "2px" }} />

                                <span>Faciliter: </span>
                                <span style={{ marginLeft: "2px" }}>{faciliter.name}</span>
                            </Grid>
                        }

                        <Grid style={{ marginTop: "10px", fontSize: "14px", whiteSpace: "nowrap", width: "22em", overflow: "hidden", textOverflow: "ellipsis" }}>
                            <TodayOutlined fontSize="small"
                                style={{ color: "#616161", marginBottom: "-3px", paddingTop: "3px", marginRight: "2px" }} />

                            <span>Data de abertura: </span>
                            <span style={{ marginLeft: "2px" }}>{moment(item.created).format("DD/MM/YYYY")}</span>
                        </Grid>

                        <Grid style={{ marginTop: "10px", fontSize: "14px", whiteSpace: "nowrap", width: "22em", overflow: "hidden", textOverflow: "ellipsis" }}>
                            <EventAvailableOutlined fontSize="small"
                                style={{ color: "#616161", marginBottom: "-3px", paddingTop: "3px", marginRight: "2px" }} />

                            <span>Data de fechamento: </span>
                            <span style={{ marginLeft: "2px" }}>
                                {!!item.closed ?
                                    moment(item.closed).format("DD/MM/YYYY") : "--"
                                }
                            </span>
                        </Grid>

                        <Grid container direction="column" alignItems="flex-end" mb={1}>
                            <Typography variant="h4" style={{ fontSize: "14px", marginTop: "10px" }}>
                                <MonetizationOn style={{ marginBottom: "-6px", color: "gold" }} />
                                {" Valor Total:"}{utilsProvider.formatNumberCurrency(item.priceToPay)}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardWrapper>

            )) :
                <Card style={{ marginTop: "10px", borderRadius: "0", backgroundColor: "unset" }}>
                    <Alert mt={2} mb={1} severity="info">
                        Não há Faturamentos
                    </Alert>
                </Card>
            )}
        </React.Fragment>
    );
}

export default InvoicesList;
