import { get, post, remove, events } from '../api';

const find = async (invoiceId, userId) =>
    get({ type: 'user', service: `correspondents/${userId}/invoice/${invoiceId}/document/` });

const saveAll = async (invoiceId, userId, files) => {

    if (!files || files.length == 0)
        return;

    const res = await post({
        type: 'user', service: `correspondents/${userId}/invoice/${invoiceId}/document/`,
        data: getDocument(invoiceId, files[0])
    })

    return await requestFiles(files, (res.data || []));
}

const removeDocument = async (userId, invoiceId, documentId) =>
    remove({ type: 'user', service: `correspondents/${userId}/invoice/${invoiceId}/document/${documentId}` });

const getDocument = (invoiceId, item) => ({
    invoiceId,
    name: item.name,
    fileSize: item.size,
    fileType: item.type
})

const uploadFile = (file, signedRequest, item) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);

    xhr.upload.onprogress = (e) =>
        setProgress({ total: e.total, loaded: e.loaded, filename: item.document.name, type: "Upload" });

    xhr.onloadend = () => checkFileError(item, item.document.url, resolve);

    xhr.send(file);
})

const checkFileError = (file, url, resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            resolve({ ...file, success: (xhr.status === 200) });
        }

    };
    xhr.send();
}

const setProgress = (progressData) => {
    if (!progressData.loaded) return;

    let progress = Math.round((progressData.loaded / progressData.total) * 100);
    events.emmit('onProgressDeterminate',
        { info: `Fazendo ${progressData.type} do arquivo: ${progressData.filename}`, progress, show: true }
    );
}

const requestFiles = async (files, data) => {
    events.emmit('onProgressDeterminate', { info: 'Verificando o arquivo', progress: 0, show: true });

    if (!data || !data.document) return;

    const file = files.find((x) => x.name == data.document.name);
    const response = await uploadFile(file, data.signedRequest, data);

    events.emmit('onProgressDeterminate', { info: 'Upload concluído com sucesso', progress: 100, show: false });

    return await removeFileErrors(!response.success ? [response] : []);
}

const removeFileErrors = async (files) =>
    Promise.all(files.map(async (item) => {
        await remove(item.document);
        return item;
    }));


export default {
    find,
    saveAll,
    removeDocument
};