import api from '../services/api';

const baseUrl = 'requests/analysis/hearing'

const findFields = async () =>
    api.get({ type: 'user', service: `${baseUrl}/fields` });

const findValuesByRequestId = async (requestId) =>
    api.get({ type: 'user', service: `${baseUrl}/${requestId}` });

const saveAnalysis = async (requestId, data) =>
    api.post({ type: 'user', service: `${baseUrl}/${requestId}`, data });

export default {
    findFields,
    findValuesByRequestId,
    saveAnalysis
}