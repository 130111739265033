import { useState } from "react";

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@material-ui/core";

import memberService from "../../../domain/services/solicitation/member.service";

export default function RequestFinancialRemoveDiscountModal(
    { isOpen, faciliterServiceInfo, setAlert, handleClose, refresh }
) {

    const [isSaving, setIsSaving] = useState(false);

    const removeDiscount = async () => {

        setIsSaving(true);

        await memberService
            .removeRatingDiscount(faciliterServiceInfo.requestId, faciliterServiceInfo.responsibleId)
            .then(() => {
                refresh();
                setAlert('Desconto do faciliter removido!', 'success');
                handleClose();
            })
            .catch(() => setAlert("Não foi possível remover o desconto.", 'error'))
            .finally(() => setIsSaving(false));
    }

    return (
        <Dialog maxWidth='xs' fullWidth open={isOpen} onClose={handleClose}>

            <DialogTitle>
                <strong>Remover Desconto</strong> - {faciliterServiceInfo.serviceName}
            </DialogTitle>

            <DialogContent style={{ marginBottom: 10 }}>

                <DialogContentText style={{ marginBottom: 20 }}>

                    <Typography color="textSecondary" style={{ marginBottom: 20 }}>
                        Faciliter: {faciliterServiceInfo.responsibleName}
                    </Typography>

                    <Typography color="textPrimary">
                        Ao clicar no botão "Confirmar", você está prestes
                        a remover a avaliação negativa do serviço
                        e restaurar o valor total a ser recebido pelo faciliter.
                        Isso significa que a avaliação ruim não afetará mais o
                        pagamento correspondente a esse serviço ou o score do
                        responsável.
                        <br />
                        <br />
                        Certifique-se de que o desconto tenha sido aplicado
                        incorretamente ou que existam motivos válidos para remover
                        o desconto. Esta ação não poderá ser desfeita e o valor
                        original do serviço será considerado para o pagamento
                        do faciliter.
                    </Typography>

                </DialogContentText>

                <Grid container justifyContent="space-between">

                    <Button
                        variant="text" color="textSecondary"
                        onClick={handleClose} disabled={isSaving}
                    >
                        Cancelar
                    </Button>

                    <Button
                        variant="contained" color="primary"
                        disabled={isSaving} onClick={removeDiscount}
                    >
                        Confirmar
                    </Button>

                </Grid>

            </DialogContent>

        </Dialog >
    )
}