import { Skeleton } from "@material-ui/lab";

const SkeletonLoading = ({ children, isDataLoading, size = {}, variant }) => {

    if (!isDataLoading)
        return children || null;

    return (
        <Skeleton height={size.height} width={size.width} variant={variant} />
    )
}

export default SkeletonLoading;
