import api from './api';
import axios from 'axios';

const findStates = async () =>
    api.get({ type: 'user', service: `states/` });

const findCities = async (uf) =>
    api.get({ type: 'user', service: `states/${uf}/cities` });

const findAllAvailableStatesByArea = async (areaId) =>
    api.get({ type: 'user', service: `states/available-states/${areaId}` });


const getCityByGeoLocation = async (setAlert) => {
    return new Promise((resolve, reject) => {   
        navigator.geolocation.getCurrentPosition(
            async position => {
                try {
                    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`);
                    resolve({ success: true, address: response.data.address });
                } catch (error) {
                    reject({ success: false, askLocation: true });
                }
            },
            error => {
                setAlert('Por favor, habilite a localização no seu navegador e tente novamente.', 'warning');
                reject({ success: false, askLocation: true });
            },
            { maximumAge:60000, timeout:5000, enableHighAccuracy:true }
        );
    });
}

export default {
    findStates,
    findCities,
    getCityByGeoLocation,
    findAllAvailableStatesByArea
}