import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography, CircularProgress, Tooltip, TextField } from '@material-ui/core';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import DateTimeTooltip from './DateTimeTooltip';

export default function OperationalDueDateModal({
  open,
  operationalDueDate,
  clientDueDate,
  subtitle,
  setOpenDueDateModal,
  onSave,
  isLoading
}) {

  const [value, setValue] = useState(operationalDueDate);

  const handleChange = (value) => {
    setValue(value);
  };

  const handleSave = () => {
    try {
      if (value === operationalDueDate) {
        onSave(operationalDueDate);
        return;
      }

      const dateString = new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(value);

      !!value && onSave(dateString);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogContent>
        <Typography variant="h6" style={{ marginBottom: '10px' }}>
          Alterar prazo de entrega do operacional
        </Typography>
        <Typography variant="subtitle2" style={{ marginBottom: '20px' }}>
          {subtitle}
        </Typography>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateTimeTooltip>
                <DatePicker
                  format="dd/MM/yyyy HH:mm"
                  label="Prazo do Cliente"
                  value={clientDueDate}
                  readOnly
                  disabled
                />
              </DateTimeTooltip>
            </Grid>
            <Grid item xs={6}>
                <DateTimeTooltip>
                  <DateTimePicker
                    label="Prazo do Operacional"
                    okLabel="Confirmar"
                    cancelLabel="Cancelar"
                    required
                    fullWidth
                    format="dd/MM/yyyy HH:mm"
                    minutesStep={5}
                    value={value}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                  />
                </DateTimeTooltip>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDueDateModal(false)}>Fechar</Button>
        <Button color="primary" onClick={handleSave} disabled={isLoading}>
          {
          isLoading ? 
          <CircularProgress size={15} /> : 
          "Salvar"
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
