import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import swal from 'sweetalert2';

import {
    Button, IconButton, Typography, Dialog, DialogTitle as MuiDialogTitle,
    DialogContent, DialogActions, Grid, CircularProgress, FormControl, Select,
    MenuItem, TextField
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import requestServicesService from '../../../domain/services/solicitation/request.services.service';

const DialogTitle = styled(MuiDialogTitle)`
    h2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;

const CorrectionRequestService = ({ mainRequestId, setAlert }) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [messageToFaciliter, setMessageToFaciliter] = useState('');

    const [correctionService, setCorrectionService] = useState({});

    const [services, setServices] = useState([]);

    useEffect(() => {
        getServices();
    }, [])

    const getServices = async () => {

        setLoading(true);
        setCorrectionService({});

        await requestServicesService.getDoneServicesForCorrection(mainRequestId)
            .then((res) => {
                const newServices = (res.data || []);
                setServices(newServices);

                if (newServices.length == 1)
                    setCorrectionService(newServices[0])
            })
            .catch((error) => setAlert(error.response.data.message || "Não há serviços disponíveis para correção", 'error'))

        setLoading(false);
    }

    const handleSave = () => {
        swal.fire({
            title: `Deseja enviar o serviço para ajuste?`,
            text: `Após o ajuste o serviço atual também deverá ser ajustado!`,
            icon: "warning",
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        })
            .then(result => result.isConfirmed && save())
            .catch(err => setAlert((err?.response?.data?.message || 'Não foi possível enviar o serviço para ajuste!'), "error"));
    }

    const save = async () => {

        setSubmitting(true);

        await requestServicesService.correction(mainRequestId, { ...correctionService, message: messageToFaciliter })
            .then((res) => onSaveSuccess(res.data))
            .catch((error) => setAlert(error.response.data.message || "Erro ao abrir correção do serviço, contate o suporte", 'error'))

        setSubmitting(false);
        handleModal(false);
    }

    const onSaveSuccess = ({ message }) => {

        swal.fire({
            title: "Serviço aberto para correção",
            text: `${message}`,
            icon: "success",
            confirmButtonText: 'Abrir solicitação',
            cancelButtonText: 'Voltar',
            showCancelButton: true
        }).then(result => {

            if (!result.isConfirmed)
                return;

            window.location.href = `/solicitations/${mainRequestId}`;
        });
    }

    const onChange = (field, fieldValue) =>
        setCorrectionService((values) => ({ ...values, [field]: fieldValue }));

    const handleModal = (isOpen) =>
        setOpen(isOpen)

    const getButtonLabel = () =>
        services.length <= 2 ?
            `Enviar ${services.map(s => s.name).join(' ou ')} para ajustes`
            : `Enviar serviços para ajustes`

    return services.length > 0 && (
        <>

            <Button
                variant="outlined" color="primary"
                onClick={() => handleModal(true)}
                style={{ fontWeight: 600 }}
            >
                {!loading && !isSubmitting ? getButtonLabel() :
                    <span>
                        Carregando...
                        <CircularProgress style={{
                            marginLeft: 8, width: 16,
                            height: 16, color: 'greenyellow'
                        }} />
                    </span>
                }
            </Button>

            <Dialog open={open} onClose={() => handleModal(false)} maxWidth="xs" fullWidth>

                <DialogTitle style={{
                    display: 'flex', background: "rgb(238 238 238 / 50%)",
                    borderBottom: "3px solid rgb(25, 118, 210)"
                }}>

                    <Typography variant="h4" style={{ display: 'flex', alignItems: 'center' }}>
                        {getButtonLabel()}
                    </Typography>

                    <IconButton
                        aria-label="close"
                        onClick={() => handleModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>

                </DialogTitle>

                <DialogContent>

                    {services.length > 1 && (
                        <>
                            <Typography
                                variant='subtitle2'
                                style={{ margin: '10px 0', fontSize: 12, fontWeight: 'bold' }}
                            >
                                Selecione o serviço que será enviado para ser corrigido
                            </Typography>

                            <Grid container spacing={6} style={{ marginBottom: "15px" }}>

                                <Grid item xs={12}>
                                    <FormControl m={2} style={{ width: "100%" }}>

                                        <Select
                                            value={correctionService.serviceId || 0}
                                            required displayEmpty labelId='services'
                                            renderValue={!correctionService.serviceId ?
                                                () => <div style={{ color: "#aaa" }}>Selecione um serviço</div>
                                                : null
                                            }
                                            onChange={(e) => onChange("serviceId", e.target.value)}
                                        >
                                            {services.map((item, index) =>
                                                (<MenuItem key={index} value={item.serviceId}>{item.name}</MenuItem>)
                                            )}
                                        </Select>

                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <Typography variant='subtitle2' style={{ margin: '10px 0', fontWeight: 'bold' }}>
                        Envie uma mensagem para nortear o faciliter que irá ajustar o serviço
                    </Typography>

                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                multiline={true}
                                value={messageToFaciliter}
                                required={true}
                                onChange={(e) => setMessageToFaciliter(e.target.value)}
                                size="medium"
                            />
                        </FormControl>
                    </Grid>

                </DialogContent>

                <DialogActions style={{
                    padding: "15px", background: "rgb(238 238 238 / 50%)",
                    borderTop: "3px solid rgb(25, 118, 210)"
                }}>

                    <Button
                        onClick={() => handleModal(false)} variant="contained"
                        style={{ width: '100px', height: '32px' }}
                    >
                        Fechar
                    </Button>

                    <Button
                        variant="contained" color="primary"
                        style={{ height: '32px' }} onClick={handleSave}
                    >
                        {!isSubmitting ? "Salvar" :
                            <span>
                                Abrindo correção...
                                <CircularProgress style={{
                                    marginLeft: '8px', width: '16px',
                                    height: '16px', color: 'greenyellow'
                                }} />
                            </span>
                        }
                    </Button>

                </DialogActions>

            </Dialog>

        </>
    );
}

export default CorrectionRequestService;
