import { events, get, post } from "../api";

export const removeOptionFilter = (filters, removeOption, description) => {
    return filters.reduce((results, item) => {
        if (item.key === removeOption.key) {
            if (item.descriptions.length > 1) {
                item.value.splice(item.descriptions.indexOf(description), 1);

                item.descriptions.splice(item.descriptions.indexOf(description), 1);

                results.push(item);
            }

            return results;
        }

        results.push(item);
        return results;
    }, []);
};

export const getAvailableFilters = async () =>
    await get({ type: "user", service: "/customers/available-filters" });

export const filter = async (body, params) => {
    events.emmit("onLoadingList", true);

    return await post({
        type: "user",
        service: "customers/filter",
        data: body,
        params,
    }).finally(() => events.emmit("onLoadingList", false));
};

export const getDashboardData = async (filters, id) =>
    post({ type: "user", service: `/customers/${id}/dashboard`, data: filters });

export const getAcquisitionChannel = () => [
    { name: "Instagram", value: "Instagram" },
    { name: "Linkedin", value: "Linkedin" },
    { name: "Facebook", value: "Facebook" },
    { name: "Youtube", value: "Youtube" },
    { name: "Google", value: "Google" },
    { name: "Email", value: "Email" },
    { name: "TikTok", value: "TikTok" },
    { name: "Indicação", value: "Indicação" },
    { name: "Outros", value: "Outros" },
];

export const getStatus = () => [
    { id: "isActive", name: "Ativo", value: "isActive" },
    { id: "isInactive", name: "Inativo", value: "isInactive" },
];
