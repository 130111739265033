import { useEffect, useState } from "react";

import { Grid, Dialog, DialogTitle, DialogContent, Typography, Divider, Button } from "@material-ui/core"

import CustomerAddressForm from "../customer/customer.address.form.component";
import ExternalRegisterProfile from "./external/external.register.profile.component";
import LoadingProgress from "../LoadingProgress";

import loginService from "../../domain/services/login.service";
import customerService from "../../domain/services/customer/customer.service";

import utilsProvider from "../providers/utils.provider";

import { checkFinishRegisterFields } from "../../providers/client.register.provider";

const CustomerFinishRegistrationModal = ({ onClose = () => {} }) => {

    const [isOpen, setIsOpen] = useState(false);

    const [customer, setCustomer] = useState({});
    const [withAddress, setWithAddress] = useState(true);

    const [fieldsWithError, setFieldsWithError] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => { checkInitializeModal() }, []);

    const checkInitializeModal = () => {

        const user = loginService.getUserAuthentication();

        const showModal = user.customer.isRegisterIncomplete;

        if (!showModal) {
            onClose();
            return;
        }

        getCustomerInformation(user.customer.id);
        setIsOpen(true);
    }

    const getCustomerInformation = (customerId) =>
        customerService
            .findById(customerId)
            .then((res) => checkCustomerRegister(res.data))
            .catch(handleClose)
            .finally(() => setIsLoading(false));

    const checkCustomerRegister = (customerData) => {

        const addressInfoMissing = utilsProvider.isNullOrEmpty(customerData.address);

        if (addressInfoMissing)
            return;

        setWithAddress(false);
    }

    const handleSave = (customer) => {

        const missingFields = checkFinishRegisterFields(customer, isCorporation(), withAddress);

        if (missingFields.length > 0) {
            setFieldsWithError(missingFields);
            return;
        }

        const customerId = loginService.getUserAuthentication().customer.id;

        return customerService
            .edit(customerId, { ...customer, isRegisterIncomplete: false })
            .then(checkModalShowed)
            .then(handleClose)
            .catch((err) => err.response.data.message
                || 'Não foi possível salvar informações')
    }

    const handleChangeCustomer = (newChanges) =>
        setCustomer({ ...customer, ...newChanges });

    const checkModalShowed = () => {
        const user = loginService.getUserAuthentication();
        const newCustomer = { ...user.customer, oab: customer.oab, isRegisterIncomplete: false }
        loginService.refreshUserAuthentication({ ...user, customer: newCustomer });
    }

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    }

    const isCorporation = () =>
        loginService.getUserAuthentication()?.customer?.personType == 'corporation'

    const getAddressFields = () =>
        withAddress && (
            <>
                <Divider style={{ margin: '15px 0' }} />

                <Typography variant="h5" color="primary" style={{ marginBottom: 10 }}>
                    Endereço
                </Typography>

                <CustomerAddressForm
                    customer={customer} afterRegister
                    setCustomer={handleChangeCustomer}
                    fieldsWithError={fieldsWithError}
                    titleSize={14} titleColor='grey'
                />
            </>
        );

    const getMainContent = () => {

        if (isLoading)
            return (
                <Grid style={{ marginBottom: 20 }}>
                    <LoadingProgress />
                </Grid>
            )

        return (
            <>
                <ExternalRegisterProfile
                    customer={customer} afterRegister
                    isCorporation={isCorporation()}
                    setCustomer={handleChangeCustomer}
                    fieldsWithError={fieldsWithError}
                />

                {getAddressFields()}

                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <Button
                        variant="contained" color="primary" size="large"
                        onClick={() => handleSave(customer)}
                    >
                        Salvar
                    </Button>
                </Grid>
            </>
        )
    }

    return (
        <Dialog
            onClose={handleClose}
            maxWidth='sm' fullWidth open={isOpen} disableEscapeKeyDown
        >
            <DialogTitle className="!p-6 !pb-0">
                <Grid
                    container justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h3">
                        <strong>Finalize seu cadastro</strong>
                    </Typography>
                </Grid>
            </DialogTitle>

            <DialogContent className="!p-6">
                <div className="mb-4 text-base">Notamos que no seu perfil ainda não consta alguns dados essenciais. Por favor, os informe para continuar.</div>

                {getMainContent()}

            </DialogContent>
        </Dialog>
    )
}

export default CustomerFinishRegistrationModal;
