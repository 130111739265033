import { useState, useEffect } from 'react';

import swal from 'sweetalert';

import FormField from '../../FormField';

import utilsProvider from '../../providers/utils.provider';

import requestCalculationService from '../../../domain/services/solicitation/request.calculation.service';

export default function RequestCalculationServices(props) {

    const {
        requestId, request, calculationServiceId, areaId, isEdit, onError,
        setFieldValue, onSave, fieldProps, label = 'Selecione o tipo de cálculo para elaboração'
    } = props;

    const [services, setServices] = useState([]);

    useEffect(() => getServices(areaId), [areaId]);

    useEffect(() => {
        if (!areaId)
            return
        getServices(areaId)
    }, [calculationServiceId])

    const getServices = (areaId) =>
        requestCalculationService
            .findByAreaId(areaId)
            .then((res) => setServices(res.data));

    const onChange = async (name, value, item) => {

        if (isEdit) {

            const confirm = await showAlertDialog("Alterar serviço de cálculo", "info", "Realmente deseja mudar o tipo de cálculo?");
            if (!confirm)
                return;
        }

        await update(requestId, item);
    }

    const update = async (requestId, value) => {

        const calculationServiceFlag = (request.serviceKey != 'calculation') ? 'forwardCalculationServiceId' : 'calculationServiceId';

        if (!onSave) {
            setFieldValue(calculationServiceFlag, value.id);
            return;
        }

        await Promise
            .resolve(onSave({ requestId, [calculationServiceFlag]: value.id }))
            .then(() => onSuccessUpdate(value, calculationServiceFlag))
            .catch((error) =>
                showAlertDialog("Erro ao salvar o cálculo", "error", error.response.data.message || "Erro ao salvar, contate o suporte")
            );
    }

    const onSuccessUpdate = (value, calculationServiceFlag) => {

        if (isEdit)
            showAlertDialog("Serviço de cálculo atualizado", "success", "Tipo de cálculo, atualizado com sucesso")

        setFieldValue(calculationServiceFlag, value.id);
    }

    const showAlertDialog = async (title, icon, text) =>
        swal({
            title, text, icon,
            buttons: {
                cancel: 'Voltar', confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' }
            }
        });

    const filterOptions = (options, { inputValue }) =>
        !inputValue ? options : options.filter(item =>
            item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        );

    return (
        <FormField
            type='autocomplete'
            name='calculationService'
            currentValue={calculationServiceId}
            field={label}
            placeholder='Selecione o tipo de cálculo'
            options={services.map(s => ({ ...s, value: s.id }))}
            onChange={onChange}
            filterOptions={filterOptions}
            disableClearable
            getCustomRenderProps={(params) => ({
                InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {params.InputProps.endAdornment}
                        </>
                    ),
                }
            })}
            onError={onError}
            {...fieldProps}
        />
    );
}
