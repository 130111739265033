import React, { useState } from 'react';

import styled from 'styled-components/macro';

import { Button as MuiButton } from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';

import RequestAnalysisModal from './request.analysis.modal';

const Button = styled(MuiButton)`
    span {
        justify-content: flex-start;
        svg {
            margin-right: 10px;
        }
    }
`;

const RequestAnalysisCustomerView = ({ requestId, buttonWidth, buttonVariant, buttonTitle }) => {

    const [showAnalysis, setShowAnalysis] = useState(false);

    const handleClose = () => setShowAnalysis(false);

    return (
        <>
            <Button
                size="small" color="primary" variant="outlined"
                onClick={() => setShowAnalysis(true)}
            >
                {buttonTitle}
            </Button>

            {(showAnalysis && requestId) &&
                <RequestAnalysisModal
                    requestId={requestId}
                    onClose={handleClose}
                />
            }
        </>
    );
};

export default RequestAnalysisCustomerView;
