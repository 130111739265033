import moment from "moment";

import utilsProvider from "../../components/providers/utils.provider";

import loginService from "../services/login.service";
import requestRegistryService from "../services/solicitation/request.registry.service";

import { stepsSolicitation } from "../../domain/constant/steps.solicitation.constant";
import { requestServices } from "../constant/request.services.constant";
import modalities from "../constant/modalities.constant";

import { servicesByTypeKey } from "../../domain/constant/request.services.constant";

const isAnalysisModality = (request) => request.modalityId == 1;

const isHearingAnalysisModality = (modalityId) => modalityId == 12;

const getRequiredInitialFields = () => ({
    serviceInformation: {
        customerId: "Selecione o cliente",
        modalityId: "Selecione a modalidade",
        areaId: "Selecione a área",
        newFormatParts: "Indique as partes do processo"
    }
})

const getRequiredFields = (fields = []) => fields.reduce((result, field) => {

    const fieldName = (field.key || field.name);

    if (field.required)
        return { ...result, [fieldName]: `Preencha o campo: ${field.label}` }

    return { ...result };

}, {})

const fieldValidation = (fields, fieldNames, input) => {

    for (const fieldName of fieldNames) {

        if (!input[fieldName] || (Array.isArray(input[fieldName]) && !input[fieldName].length))
            return { success: false, fieldName, message: 'Campo obrigatório' };

        const field = fields.find(item => item.key == fieldName);

        if ((field && field.minLength) && input[fieldName]) {

            const fieldValue = Array.isArray(input[fieldName]) ? input[fieldName][0].value : input[fieldName];

            if (fieldValue.length < field.minLength)
                return { success: false, fieldName, message: `O campo deve ter no mínimo ${field.minLength} caracteres` };
        }
    }

    return { success: true };
}

const canNextStep = async (requestId, stepFields) => {

    const fieldsKey = stepFields.reduce((values, item) => ([...values, item.key]), []);

    return await requestRegistryService.validateFields(requestId, fieldsKey)
        .then(() => true)
        .catch((error) => false);
}

const getCityValidator = (modalityId) => {

    if (isInitialModality(modalityId))
        return { city: `Selecione a cidade` };

    return {};
}

const getCalculationServiceValidator = (modalityId) => {

    if (isCalculationModality(modalityId))
        return { calculationServiceId: `Selecione uma opção de cálculo` };

    return {};
}

const hearingModalities = [11, 15];

const isInitialModality = (modalityId) => (modalityId == 4);

const isCalculationModality = (modalityId) => (modalityId == 10);

const isPetitionService = (serviceKey) =>
    requestServices.PETITION == serviceKey;

const isPetitionIndicationService = (serviceKey) =>
    requestServices.ANALYSIS == serviceKey;

const isCalculationService = (serviceKey) =>
    requestServices.CALCULATION == serviceKey;

const isHearingModality = (modalityId) => (hearingModalities.includes(modalityId));

const convertDate = (date) => (date ? moment.utc(date).format('YYYY-MM-DDTHH:mm') : '');

const checkResolvePending = (files, observation, missingField, requestChanges) => {

    if (!missingField)
        return checkStandardPendingResolution(files, observation);

    const missingFieldValue = (requestChanges?.fields?.[missingField.key] || {})?.value;
    const canResolvePending = !!missingFieldValue;
    const errorMessage = `Preencha o campo: ${missingField.label}`;

    return { canResolvePending, errorMessage };
}

const checkStandardPendingResolution = (files, observation) => {

    const canResolvePending = observation.length > 0 || files.length > 0;
    const errorMessage = 'Anexe um documento ou faça uma observação para resolver a pendência';

    return { canResolvePending, errorMessage };
}

const canCreateService = (request) =>
    loginService.isInternal()
    && [
        stepsSolicitation.ANALYSE, stepsSolicitation.NOT_STARTED,
        stepsSolicitation.IN_PROGRESS, stepsSolicitation.REVIEW
    ].includes(request.status)
    && !request?.additionalInformation?.isLegacyCascade
    && !request.isFirstCascadeService;

const canConvertAnalysisToNotion = (request) =>
    loginService.isInternal() &&
    request.status == stepsSolicitation.ANALYSE &&
    !request?.additionalInformation?.isLegacyCascade &&
    isAnalysisModality(request);

const canCorrectionService = (request) =>
    (loginService.isFaciliter() || loginService.isInternal()) &&
    [stepsSolicitation.CORRECTION, stepsSolicitation.ANALYSE, stepsSolicitation.IN_PROGRESS, stepsSolicitation.NOT_STARTED, stepsSolicitation.REVIEW].includes(request.status) &&
    !request?.additionalInformation?.isLegacyCascade;


const isForwardingValid = (request, showAlertDialog) => {

    if (!isCalculationModality(request.status))
        return true

    if (!request.forwardToAnalysis && request.forwardToPetition) {
        showAlertDialog("Adequar elaboração dos serviços", 'warning', "Não é possível selecionar a elaboração da petição, sem marcar a elaboração da Indicação de Petição.");
        return false;
    }

    return true;
}

const isCalculationValid = ({ modalityId, needCalculation, calculationServiceId }, showAlertDialog) => {

    const isEmptyOrNoSelection = (!calculationServiceId || calculationServiceId == "111");

    const isNeedCalculation = (needCalculation == "true" || needCalculation == true);

    if (!isCalculationModality(modalityId) && !isNeedCalculation)
        return true;

    if (isEmptyOrNoSelection) {
        showAlertDialog("Indique o tipo de cálculo para a elaboração do serviço", 'warning', "Campo obrigatório");
        return false;
    }

    return true;
}

const isCalculationToPrepareOnlyPetition = (request) => {

    if (request.mainId && request.mainId != request.id && request.modality != 'Cálculo')
        return false;

    return request.modality == 'Cálculo' &&
        !request.referenceAnalysisId &&
        (request.forwardToPetition == "true" || request.forwardToPetition == true) &&
        (request.forwardToAnalysis == "false" || !request.forwardToAnalysis);
}

const isIndicationSelectionRequired = (request, serviceTypeKey) =>
    utilsProvider.isNullOrUndefined(request.isPetitionIndication) &&
    serviceTypeKey == requestServices.PETITION &&
    request.modalityId != modalities.notion.id &&
    request.modalityId != modalities.contract.id

const canAdvanceRequest = (request) =>
    !request.isDueDateAdvanced && ![requestServices.HEARING, requestServices.HEARINGANALYSIS].includes(request.serviceKey || request.serviceTypeKey) && ![servicesByTypeKey.hearing.id].includes(request.serviceTypeId)

const canEditAdvance = (request) =>
    (loginService.isInternal() || loginService.isCustomer()) && request.isDueDateAdvanced && (loginService.isInternal() || ![servicesByTypeKey.hearing.id].includes(request.serviceTypeId))

const hasAnalysisService = (request) => request.forwardToAnalysis;

export default {
    isPetitionService,
    isPetitionIndicationService,
    isInitialModality,
    isAnalysisModality,
    isHearingAnalysisModality,
    isCalculationModality,
    isCalculationService,
    isHearingModality,
    getRequiredInitialFields,
    getRequiredFields,
    fieldValidation,
    canNextStep,
    getCityValidator,
    getCalculationServiceValidator,
    convertDate,
    checkResolvePending,
    canCreateService,
    canConvertAnalysisToNotion,
    canCorrectionService,
    isForwardingValid,
    isCalculationValid,
    isCalculationToPrepareOnlyPetition,
    isIndicationSelectionRequired,
    canAdvanceRequest,
    canEditAdvance,
    hasAnalysisService
}