import { IconButton, Popover } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";

export default function ScrollPopup({
    anchorRef,
    show,
    onClose,
    scrollPercentage = 0.5,
    children,
    checkOnScroll = () => true,
}) {

    const [isShowingPopup, setIsShowingPopup] = useState(false);

    useEffect(() => {
        if (!show) return;

        window.addEventListener('scroll', handleCheckScroll);
        return () => { window.removeEventListener('scroll', handleCheckScroll) };
    }, [show, isShowingPopup]);

    function handleCheckScroll() {

        const currentElement = anchorRef.current;
        if (!currentElement)
            return

        const { top } = currentElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const minimumElementScrollTop = windowHeight * scrollPercentage;
        const respectsCustomCondition = checkOnScroll();

        if (isShowingPopup || top > minimumElementScrollTop | !respectsCustomCondition)
            return;

        setIsShowingPopup(true);
    }

    const handleClose = () => {
        window.removeEventListener('scroll', handleCheckScroll)
        setIsShowingPopup(false)
        onClose();
    }

    return (
        <Popover
            open={isShowingPopup}
            anchorEl={anchorRef.current}
            transitionDuration={300}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <div className="p-3">
                <div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'end',
                    }}>
                        <IconButton aria-label="close" onClick={handleClose} size='small'>
                            <Close />
                        </IconButton>
                    </div>
                    <div className="-mt-6">
                        {children}
                    </div>
                </div>
            </div>

        </Popover>
    )
}