import { Grid, Typography } from "@material-ui/core";

import RequestDocumentForm from "../documents/request.document.form.component";

function ProcessMainDocument({ request, setRequest, setAlert, isEdit, refreshDocuments }) {



    return (
        <Grid item xs={12}>

        {
            !isEdit && (
                <>
                    <Typography
                    variant="subtitle1" gutterBottom
                    style={{
                        color: '#000000DE',
                        fontFamily: 'satoshi, sans-serif',
                        fontSize: 15,
                        fontWeight: 500
                    }}
                >
                    Processo
                </Typography>
                <Typography
                    gutterBottom
                    style={{
                        fontFamily: 'satoshi, sans-serif',
                        fontSize: 12,
                        fontWeight: 400,
                        marginBottom: 25
                    }}>
                    <span className="font-semibold">Não possui o processo de forma fácil?</span> Não se preocupe! Entendemos o quanto o seu tempo é valioso e por isso <span className="font-semibold">buscamos o processo por você.</span> Para isso, cobramos um valor de <span className="font-semibold">R$20</span> do seu pacote. <span style={{ fontStyle: 'italic' }}>* Buscamos apenas processos virtuais e não sigilosos</span>
                </Typography>
            </>
            )
        }

                <RequestDocumentForm
                    request={request}
                    setRequest={setRequest}
                    setAlert={setAlert}
                    isEdit={isEdit}
                    refreshDocuments={refreshDocuments}
                    isMainDocumentForm={true}
                />

        </Grid>
    );
}

export default ProcessMainDocument;
