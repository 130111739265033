import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Grid, Typography
} from "@material-ui/core";
import { ExpandMore, FormatIndentDecrease, FormatLineSpacing, FormatSize, TextFormat } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import customerService from "../../domain/services/customer/customer.service";
import loginService from "../../domain/services/login.service";
import LoadingProgress from "../LoadingProgress";

const TextFormatRequirement = ({ customerId, isOpen = false, emptyComponent = null }) => {

    const [expanded, setExpanded] = useState(isOpen);
    const [textFormatRequirement, setTextFormatRequirement] = useState(null);

    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        if (!customerId)
            return

        trackPromise(customerService.getTextFormatRequirement(customerId))
            .then((res) => setTextFormatRequirement(res.data[0].textFormatRequirement));
    }, [customerId]);

    const loading = () => (
        promiseInProgress && (
            <LoadingProgress />
        )
    );

    if (!textFormatRequirement || loginService.isCustomer())
        return !promiseInProgress && emptyComponent;

    return (
        <>
            {loading()}
            {!promiseInProgress && textFormatRequirement && (
                <Grid
                    container direction="row" justifyContent="flex-start"
                    alignItems="flex-start" spacing={2}
                    style={{ padding: '8px 0px 1px 0px' }}
                >
                    {textFormatRequirement.fontFamily && (
                        <Grid item>
                            <Chip
                                icon={<TextFormat />}
                                label={`Fonte: ${textFormatRequirement.fontFamily}`}
                            />
                        </Grid>
                    )}
                    {textFormatRequirement.fontSize && (
                        <Grid item>
                            <Chip
                                icon={<FormatSize />}
                                label={`Tamanho da fonte: ${textFormatRequirement.fontSize}`}
                            />
                        </Grid>
                    )}
                    {textFormatRequirement.retreatParagraph && (
                        <Grid item>
                            <Chip
                                icon={<FormatIndentDecrease />}
                                label={`Recuo de parágrafo: ${textFormatRequirement.retreatParagraph}`}
                            />
                        </Grid>
                    )}
                    {textFormatRequirement.lineSpacing && (
                        <Grid item>
                            <Chip
                                icon={<FormatLineSpacing />}
                                label={`Espaçamento entre linhas: ${textFormatRequirement.lineSpacing}`}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default TextFormatRequirement;
