import { useRef } from 'react';

import { Button, Tooltip, CircularProgress } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';

import AudioRecorderAnnouncementFlyout from '../popups/request.new.recording.tool.flyout';

const AudioRecorderButton = ({ handleStartRecording, isNewRequest, disabled, isLoading }) => {

    const anchorRef = useRef(null);

    if (isLoading)
        return <CircularProgress size={20} />

    return (
        <>

            <Tooltip title='Clique para começar a gravar'>
                <Button
                    ref={anchorRef}
                    color='primary' variant='outlined' size='small'
                    style={{ borderRadius: 20, minWidth: 0, padding: 2 }}
                    onClick={handleStartRecording} disabled={disabled}
                >
                    <MicIcon />
                </Button>
            </Tooltip>

            <AudioRecorderAnnouncementFlyout
                isNewRequest={isNewRequest}
                anchorRef={anchorRef}
            />

        </>
    );
}

export default AudioRecorderButton;
