import Async from "../../components/Async";

const AuthGuard = Async(() => import("../auth.guard"));

const SolicitationDevelopment = Async(() => import('../../components/solicitations/development/solicitation.new.component'));
const SolicitationReview = Async(() => import('../../components/solicitations/development/solicitation.review.component'));
const SolicitationCorrection = Async(() => import('../../components/solicitations/development/solicitation.correction.component'));

const routes = {
    requestDevelopment: {
        id: 'requestDevelopment',
        name: 'Desenvolvimento da Solicitação',
        path: '/solicitations/:id/development',
        component: SolicitationDevelopment,
        guard: AuthGuard,
    },
    requestReview: {
        id: 'requestReview',
        name: 'Revisão da Solicitação',
        path: '/solicitations/:id/review',
        component: SolicitationReview,
        guard: AuthGuard,
    },
    requestCorrection: {
        id: 'requestCorrection',
        name: 'Correção da Solicitação',
        path: '/solicitations/:id/correction',
        component: SolicitationCorrection,
        guard: AuthGuard,
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}