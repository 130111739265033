import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import { Grid, Collapse, TableContainer, Table, TableCell, TableRow, TableBody, Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import useSnackbar from '../../../hooks/useSnackbar';
import FacilitaSnackbar from '../../FacilitaSnackbar';
import requestNotionService from '../../../domain/services/request/notion/request.notion.service';
import requestService from '../../../domain/services/request/request.service';
import stepsSolicitation from '../../../domain/constant/steps.solicitation.constant';
import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';
import communicationProvider from '../../../providers/communication.provider';
import LoadingProgress from '../../LoadingProgress';
import requestStatusService from '../../../domain/services/solicitation/request.status.service';
import RequestConclusion from '../request.conclusion.step';
import ModalDetailRequestPetitionIndication from './modal.detail.request.petition.indication';
import PetitionIndicationTour from '../../tours/petitionIndicationTour';
import { useTour } from '../../../context/tourContext';
import announcementService from '../../../domain/services/marketing/announcement.service';
import { announcements } from '../../../domain/constant/announcements.constant';

import { requestServices } from '../../../domain/constant/request.services.constant'
import VisibilityIcon from '@material-ui/icons/Visibility';

const TableWrapper = styled.div`
    overflow-y: auto;
    max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

const CustomerRequestPetitionIndicationList = ({ request }) => {
    const [petitionIndications, setPetitionIndications] = useState([]);
    const [newRequest, setNewRequest] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { snackbarAlert, setAlert, closeAlert } = useSnackbar();

    const [openTour, setOpenTour] = useState(false);
    const [tourFinished, setTourFinished] = useState(false);

    const [selectedPetition, setSelectedPetition] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [allowNextOnTour, setAllowNextOnTour] = useState(false);

    useEffect(() => {
        load(true);
    }, []);

    const load = (showAlert) =>
        findPetitionIndications(request.id, showAlert);

    const findPetitionIndications = async (requestId, showAlert) => {
        setLoading(true);

        const values = await requestNotionService
            .findPetitionIndications(requestId)
            .catch(() => ([]))
            .finally(() => setLoading(false));

        setPetitionIndications(values);

        const checkTourViewed = announcementService.isShowed(announcements.tourPetitionIndication)

        if (!tourFinished && values.length > 0 && !checkTourViewed)
            setOpenTour(true);

        if (!values.some(item => item.status == stepsSolicitation.NEW) || !showAlert)
            return;
    }

    const { registerRef } = useTour();
    const indicationOfPetitions = registerRef(announcements.tourPetitionIndication, 'indicationOfPetitions');
    const previewEye = registerRef(announcements.tourPetitionIndication, 'previewEye');

    const openModal = (petitionIndication) => {
        setSelectedPetition(petitionIndication);
        setShowModal(true);
        setAllowNextOnTour(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const finishRegistration = async (petitionIndication, isConfirmed) => {
        if (!isConfirmed) return;

        setLoading(true);

        const requestValues = await requestService.getRequestDetails(petitionIndication.id).catch(() => ({}));

        await requestRegistryService
            .finishRegistry(requestRegistryService.mapValuesToEdit(requestValues))
            .then((res) => onFinishedRegistration(res.data))
            .catch((err) => communicationProvider.getErrorMessage(err, setAlert, 'Erro ao encaminhar serviço'))
            .finally(() => setLoading(false));
    };

    const onFinishedRegistration = async (newRequest) => {
        await load();
        setNewRequest(newRequest);
    };

    const details = (petitionIndication) =>
        window.location.href = `/solicitations/${petitionIndication.id}`;

    const getStepByKeyAndUserSource = (request, key) =>
        requestStatusService.getStepByKeyAndUserSource(request.status)[key];

    const getStatusIndication = (petitionIndication) => {
        if (petitionIndication.status === stepsSolicitation.NEW)
            return null;

        return (
            <Typography
                style={{
                    color: getStepByKeyAndUserSource(petitionIndication, 'background'),
                    fontWeight: 500,
                    fontSize: 13,
                    display: 'inline'
                }}
            >
                {getStepByKeyAndUserSource(petitionIndication, 'value')}
            </Typography>
        );
    };

    const changeNewRequestValues = (changes) => {
        const requestChanges = { ...newRequest, ...changes };
        setNewRequest(requestChanges);
    };

    const handleTourClose = () => setOpenTour(false);
    const handleTourFinish = () => {
        setOpenTour(false);
        setTourFinished(true);
        markViewed();
    };

    const markViewed = () =>
        announcementService.markViewed(announcements.tourPetitionIndication);

    return (
        <Grid container direction='column'>
            {isLoading && <LoadingProgress transparentBackground={true} />}

            <Collapse in={!isLoading && !!petitionIndications?.length} style={{ width: '100%' }}>
                <TableContainer style={{ width: '100%' }} ref={indicationOfPetitions}>
                    <TableWrapper>
                        <Table size="small">
                            <TableBody ref={previewEye}>
                                {(petitionIndications || []).map((petitionIndication, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Tooltip title="Ver detalhes da indicação">
                                                <IconButton>
                                                    <VisibilityIcon color="primary"
                                                        onClick={() => openModal(petitionIndication)} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>

                                        <TableCell component="td" scope="row" style={{ fontSize: 14 }}>
                                            <p style={{ padding: 'none', margin: '5px 0 5px 0' }}>
                                                {petitionIndication.serviceName} - {petitionIndication.areaName} {petitionIndication.serviceKey !== requestServices.PETITION ? `- ${petitionIndication.modalityName}` : ''}
                                            </p>

                                            <p style={{ padding: 'none', marginBottom: 10, display: 'inline' }}>
                                                {petitionIndication.status !== stepsSolicitation.NEW &&
                                                    <Typography
                                                        style={{ fontSize: 13, marginRight: 5, display: 'inline' }}
                                                    >
                                                        ID: <strong>{petitionIndication.id} -</strong>
                                                    </Typography>
                                                }
                                                {getStatusIndication(petitionIndication)}
                                            </p>
                                        </TableCell>

                                        {petitionIndication.status === stepsSolicitation.NEW &&
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => openModal(petitionIndication)}
                                                >
                                                    Solicitar Serviço
                                                </Button>
                                            </TableCell>
                                        }

                                        {petitionIndication.status !== stepsSolicitation.NEW &&
                                            <TableCell>
                                                <Button
                                                    color='primary'
                                                    variant='text'
                                                    size='small'
                                                    style={{ fontWeight: 500, textDecoration: 'underline' }}
                                                    onClick={() => details(petitionIndication)}
                                                >
                                                    Acessar serviço
                                                </Button>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableWrapper>
                </TableContainer>
            </Collapse>

            <FacilitaSnackbar alert={snackbarAlert} onClose={closeAlert} />

            {!!newRequest.id &&
                <RequestConclusion
                    request={newRequest}
                    setMultipleValues={changeNewRequestValues}
                />
            }

            {showModal && (
                <ModalDetailRequestPetitionIndication
                    showModal={showModal}
                    isStarted={selectedPetition?.status !== stepsSolicitation.NEW}
                    petitionIndication={selectedPetition}
                    finishRegistration={finishRegistration}
                    closeModal={closeModal}
                />
            )}

            <PetitionIndicationTour
                open={openTour}
                onClose={handleTourClose}
                onFinish={handleTourFinish}
                allowNext={allowNextOnTour}
            />

        </Grid>
    );
};

export default CustomerRequestPetitionIndicationList;