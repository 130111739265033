import { get, post, put } from '../api';

import moment from 'moment';
import swal from 'sweetalert';

import loginService from '../login.service';

import { customerStepSolicitation, stepsSolicitation } from "../../constant/steps.solicitation.constant";
import { stepsFromInProduction } from '../../constant/request.step.production.constant';
import repository from '../../repositories/request/request.status.repository';

const getAvailableSteps = async (requestId) =>
    get({ type: 'user', service: `requests/${requestId}/available-steps` })

const getCorrectionCategories = async () =>
    get({ type: 'user', service: 'requests/correction-categories' });

const changeStep = async (step) =>
    put({ type: 'user', service: `requests/${step.requestId}/process`, data: step })

const concludeBySampling = async (data) =>
    put({ type: 'user', service: `requests/${data.requestId}/sampling-process`, data });

const resolvePending = async (requestId) =>
    post({ type: 'user', service: `requests/${requestId}/resolve-pending` });

const getStepByKey = (statusKey) =>
    (stepsSolicitation.stepTypes[statusKey] || {});

const mapPendingReason = (reason, senderUserId = 9) => ({
    reason,
    date: moment().format("YYYY-MM-DDTHH:mm"),
    senderUserId
})

const checkFaciliterAccessLimit = (stepName, faciliterReviewers) => {

    const faciliterReviewer = (faciliterReviewers || [])[0];
    const user = loginService.getUserAuthentication();

    if ((!loginService.isFaciliter() || faciliterReviewer?.userId == user.id) || stepName != stepsSolicitation.REVIEW)
        return;

    swal({
        title: "Agora é com a gente!",
        text: "A petição foi encaminhada com sucesso, você será redirecionado para a tela principal.",
        icon: "success",
        buttons: { confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' } }
    }).then(() => window.location.href = '/solicitations');
}

const getStepByKeyAndUserSource = (statusKey) => {

    if (loginService.isCustomer() && stepsFromInProduction.includes(statusKey))
        return customerStepSolicitation.IN_PRODUCTION;

    if (loginService.isFaciliter() && statusKey == stepsSolicitation.WAITING_DELIVER)
        return getStepByKey(stepsSolicitation.CONCLUDED);

    return getStepByKey(statusKey);
}

const getStepLabelBySource = (statusItem) => {

    if (loginService.isFaciliter() && statusItem.name == stepsSolicitation.SAMPLING)
        return "Concluir";

    return statusItem.label;
}

const unrevoke = (requestId, service) =>
    post({ type: 'user', service: `requests/${requestId}/unrevoke`, data: { service } });

const getRequestsToRevoke = (requestId, isAllServices) =>
    get({ type: 'user', service: `requests/revoke/${requestId}/find?isAllServices=${isAllServices}` })
        .then(res => res.data);

const customerRevoke = (requestId) =>
    changeStep({ requestId, stepName: stepsSolicitation.REVOKED });

const revokeRequest = async (requestId) =>
    repository.revokeRequest(requestId)
        .then(res => res.data);

const findAllRevokedServices = async (requestId) =>
    repository.findAllRevokedServices(requestId)
        .then(res => res.data);

export default {
    getAvailableSteps,
    getCorrectionCategories,
    getStepByKey,
    getStepByKeyAndUserSource,
    getStepLabelBySource,
    changeStep,
    resolvePending,
    concludeBySampling,
    mapPendingReason,
    checkFaciliterAccessLimit,
    getRequestsToRevoke,
    unrevoke,
    customerRevoke,
    revokeRequest,
    findAllRevokedServices
}