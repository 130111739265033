import { get, put } from '../api';
import loginService from '../login.service';

const findOptions = async () =>
    get({ type: 'user', service: `/marketing/segmentation/subscription` });

const findSubscriptionToCheckout = async (id) =>
    get({ type: 'user', service: `/marketing/segmentation/${id}` });

const findByCustomer = async (customerId) =>
    get({ type: 'user', service: `/financial/subscription/${customerId}` });

const findBillings = async (customerId) =>
    get({ type: 'user', service: `/financial/subscription/${customerId}/billings` });

const handleAutomaticRenovation = async (customerId, data) =>
    await put({ type: 'user', service: `/financial/subscription/${customerId}/renovation`, data });

const updatePaymentMethod = async (customerId, data) =>
    await put({ type: 'user', service: `/financial/subscription/${customerId}/payment-method`, data });

const isSubscribed = () => {
    const auth = loginService.getUserAuthentication();
    if (!auth || !auth.customer) return false;

    const isSubscribed = !!auth.customer?.isSubscriber;
    return isSubscribed;
}

export default {
    findOptions,
    findSubscriptionToCheckout,
    findByCustomer,
    findBillings,
    handleAutomaticRenovation,
    updatePaymentMethod,
    isSubscribed,
}