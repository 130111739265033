import Inline from 'quill/blots/inline';
import ReactDOMServer from 'react-dom/server';

export class ButtonBlot extends Inline {

    static blotName = 'button'
    static tagName = ['a', 'button']
    static className = ''

    static create(value) {

        const classes = {
            primary: '!no-underline inline-flex px-4 py-2 rounded-full !text-white caret-white bg-blue-600 hover:bg-blue-500',
            secondary: '!no-underline inline-flex px-4 py-2 rounded-full border border-blue-600 text-blue-600 hover:border-blue-500 hover:text-blue-500'
        }

        const buttonContent = ReactDOMServer.renderToStaticMarkup(
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
                className={classes[value.type]}
                href={value.url}
                data-name={value.name}
            />
        );

        const parser = new DOMParser();
        const doc = parser.parseFromString(buttonContent, 'text/html');
        const node = doc.body.children[0];

        return node;
    }

    format(name, value) {
        super.format(name, value);
    }
}