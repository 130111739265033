
import React from "react";

import Async from "../../components/Async";

import { Share } from "@material-ui/icons";

const AuthGuard = Async(() => import("../auth.guard"));

const ReferralPage = Async(() => import('../../pages/marketing/referral.index'));
const ReferralLinkPage = Async(() => import('../../pages/marketing/external.referral'));

const routes = {
    referrals: {
        id: 'referrals',
        name: 'Minhas Indicações',
        path: '/referrals',
        component: ReferralPage,
        icon: <Share />,
        guard: AuthGuard,
        roles: ['customer', 'customer-user-admin'],
    }
}

const externalRoutes = {
    referralLinkPage: {
        id: 'referralLink',
        path: '/referrals/new/:uuid',
        component: ReferralLinkPage,
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}