import React, { createContext, useContext, useState } from 'react';

const CustomerRequestListContext = createContext();

export const useCustomerRequestList = () => useContext(CustomerRequestListContext);

export const CustomerRequestListProvider = ({ children }) => {

    const [isPendingResolveModalOpen, setIsPendingResolveModalOpen] = useState(false);
    const [showingPendingResolveTour, setShowingPendingResolveTour] = useState(false);

    const contextValue = {
        isPendingResolveModalOpen,
        setIsPendingResolveModalOpen,
        showingPendingResolveTour,
        setShowingPendingResolveTour,
    };

    return (
        <CustomerRequestListContext.Provider value={contextValue}>
            {children}
        </CustomerRequestListContext.Provider>
    );
};