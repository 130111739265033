import { useState } from "react";
import swal from "sweetalert";

import {
    Button, Chip, Dialog, DialogActions,
    DialogContent, DialogTitle, TextField
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import LoadingProgress from "../LoadingProgress";

import solicitationService from "../../domain/services/solicitation/solicitation.service";
import faciliterAssureIncomeService from "../../domain/services/correspondent/faciliter.assure.income.service";
import loginService from "../../domain/services/login.service";

function FaciliterDropout({ request, setAlert }) {

    const [description, setDescription] = useState("");

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleConfirm = async () => {

        if (!description) {
            setAlert("Insira o motivo para continuar com a desistência!", "error");
            return;
        }

        const faciliterWithAssureIncome = loginService.getUserAuthentication()?.faciliter?.isAssureIncome;

        if (!faciliterWithAssureIncome)
            return onConfirm();

        return confirmFaciliterAssureIncome();
    }

    const onConfirm = async () => {

        setLoading(true)

        return solicitationService
            .faciliterDropout(request.id, description)
            .then(() => window.location.href = "/solicitations")
            .catch(err => setAlert((err.response.data.message || "Erro ao solicitar a desistência"), "error"))
            .finally(() => setLoading(false));
    }


    const confirmFaciliterAssureIncome = async () => {

        const { faciliter } = loginService.getUserAuthentication();
        const programRequirements = await faciliterAssureIncomeService.findInfoById(faciliter.id)

        setOpen(false);

        swal({
            title: "Tem certeza?",
            text: `Se você desistir de ${programRequirements.maxDropout} solicitações em um período de ${programRequirements.dropoutRange} dias será desligado do programa Renda Garantida!`,
            icon: "warning",
            buttons: { cancel: 'Cancelar', confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' } }
        })
            .then(confirm => confirm && onConfirm())
            .catch(err => setAlert((err?.response?.data?.message || 'Não foi possível remover as solicitações da pauta.'), "error"));
    }

    return (
        <>

            <Chip
                label="Desistir da Solicitação"
                icon={<Cancel />}
                onClick={() => setOpen(true)}
                variant="outlined"
                style={{ height: '100%' }}
            />

            <Dialog
                open={open} disableEscapeKeyDown={loading}
                onClose={() => !loading && setOpen(false)}
            >

                <DialogTitle style={{ backgroundColor: '#eee' }}>
                    Informe o motivo da Desistência
                </DialogTitle>

                <DialogContent>

                    {!loading &&
                        <div style={{ padding: "10px" }}>
                            <TextField id="description" autoFocus margin="dense" label="Motivo" fullWidth
                                multiline={true} rows={2} variant="outlined"
                                onChange={(e) => setDescription(e.target.value)} />
                        </div>
                    }

                    {loading && LoadingProgress("Desvinculando...")}

                </DialogContent>

                <DialogActions>

                    <Button
                        onClick={() => setOpen(false)}
                        style={{ color: "#FF5959" }} disabled={loading}
                    >
                        Cancelar
                    </Button>

                    <Button
                        onClick={() => handleConfirm()}
                        color="primary" disabled={loading}
                    >
                        Confirmar
                    </Button>

                </DialogActions>

            </Dialog>

        </>
    )
}

export default FaciliterDropout;