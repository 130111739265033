import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import swal from "sweetalert";
import { Button, CircularProgress } from "@material-ui/core";

import urlQueryProvider from "../../providers/url.query.provider";
import communicationProvider from "../../providers/communication.provider";

import requestStatusService from "../../domain/services/solicitation/request.status.service";
import loginService from "../../domain/services/login.service";

const UnrevokeButton = ({ request, setAlert, refresh, isList = false }) => {

    let { search } = useLocation();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        urlQueryProvider.checkRedirectionInUrl(
            search, 'unrevokeRequest',
            () => handleRevokeRequest(request.mainId || request.id)
        )
    }, []);

    const handleRevokeRequest = (mainId) =>
        swal({
            title: "Desarquivar solicitação",
            text: "Ao confirmar esta ação, a solicitação será cobrada novamente e voltará para produção com todos os serviços adicionados em sua criação. Deseja continuar?",
            icon: "warning",
            buttons: {
                cancel: 'Cancelar',
                confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' },
            }
        }).then(res => res && onConfirmAction(mainId));

    const onConfirmAction = (mainId) =>
        Promise
            .resolve(setIsLoading(true))
            .then(() => requestStatusService.unrevoke(mainId))
            .then(onFinish)
            .catch(sendErrorMessage);

    const sendErrorMessage = (err) => {

        if (loginService.isCustomer())
            return setAlert(
                'Não foi possível desarquivar a solicitação, entre em contado com o suporte para mais informações!',
                'error'
            );

        return communicationProvider.getErrorMessage(err, setAlert, 'Não foi possível desarquivar a solicitação');
    }

    const onFinish = () => {

        setAlert('Solicitação desarquivada com sucesso!', 'success');

        if (!request.justLeftPending || !loginService.isCustomer()) {
            refresh();
            window.location.href = `/solicitations/${request.mainId || request.id}`;
            return;
        }

        window.location.href = `/solicitations/${request.id}?solvePending=1`
    }

    const getButton = (isList) => ({
        true: (
            <Button
                variant='text'
                color='primary'
                className="w-full"
                onClick={() => handleRevokeRequest(request.mainId || request.id)}
            >
                <span className='text-sm'>Desarquivar</span>
            </Button>
        ),
        false: (
            <Button
                color='primary' variant='outlined' disabled={isLoading} fullWidth
                onClick={() => handleRevokeRequest(request.mainId || request.id)}
                style={{ fontWeight: 600 }}
            >
                Desarquivar

                {isLoading && <CircularProgress size={15} style={{ marginLeft: 8 }} />}
            </Button>
        )
    })[isList];

    return getButton(isList);
}

export default UnrevokeButton;