import { useState, useEffect } from "react";
import moment from "moment";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import { Avatar, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Hearing, InsertDriveFile, MonetizationOn, Search,  Note} from "@material-ui/icons";

import LoadingProgress from "../LoadingProgress";

import communicationProvider from "../../providers/communication.provider";

import requestHistoryService from "../../domain/services/request/request.history.service";

function HistoryList({ mainRequestId, setAlert }) {

    const [history, setHistory] = useState([]);

    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => { getAll(mainRequestId) }, []);

    const getAll = async (requestId) => {

        if (!requestId)
            return;

        trackPromise(
            requestHistoryService
                .findByRequestId(requestId)
                .then(setHistory)
                .catch(err =>
                    communicationProvider.getErrorMessage(err, setAlert)
                )
        );
    }

    const convertDateTimeToLocaleString = (date) =>
        date ? moment(date).format('DD/MM/YYYY - HH:mm:ss') : '';

    const iconByServiceType = {
        calculation: MonetizationOn,
        petition: InsertDriveFile,
        analysis: Search,
        hearing: Hearing,
        hearingInPerson: Hearing,
        notion: Note,
        contract: InsertDriveFile,
        hearingAnalysis: Search
    }

    const getHistoryItem = (item, i) => {

        const Icon = iconByServiceType[item.serviceKey];

        return (
            <ListItem
                key={i} button
                style={{
                    borderBottom: "1px solid #f6f6f6"
                }}
            >

                <ListItemIcon style={{ minWidth: 35 }}>
                    <Avatar style={{
                        color: "white", width: 24, height: 24,
                        fontSize: 10, fontWeight: 600
                    }}>
                        <Icon style={{ fontSize: 12 }} />
                    </Avatar>
                </ListItemIcon>

                <ListItemText
                    primary={item.title}
                    secondary={item.description || ""}
                    style={{ width: '65%' }}
                />

                <span style={{
                    color: 'gray', flex: '1 1 auto',
                    fontSize: 10, display: 'flex',
                    justifyContent: 'end'
                }}>
                    {convertDateTimeToLocaleString(item.created)}
                </span>

            </ListItem>
        )
    }

    if (promiseInProgress)
        return (
            <LoadingProgress />
        );

    if (!history.length)
        return (
            <Alert mt={2} mb={1} severity="info">
                Não há Históricos
            </Alert>
        )

    return (
        <List>
            {history.map(getHistoryItem)}
        </List>
    )
}

export default HistoryList;
