import React, { useState, useEffect } from "react";

import {
    List, ListItem, ListItemText, ListItemIcon, TextField, Button, Avatar, Tooltip, Chip, Fab,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";

import { Add, PersonAdd, CheckBox } from "@material-ui/icons";

import EmptyList from "../EmptyList";

import memberService from "../../domain/services/solicitation/member.service";
import userService from "../../domain/services/user.service";
import loginService from "../../domain/services/login.service";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingProgress from "../LoadingProgress";

function SelectAnalyst(props) {

    const [filterText, setFilterText] = useState("");

    const [list, setList] = useState([]);
    const [linkeds, setLinkeds] = useState([]);
    const [analyst, setAnalyst] = useState({});

    const [open, setOpen] = useState(false);
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        if (!props.isTableView)
            find();

        recoveryAnalystsRequest(props.analysts);
    }, []);

    const find = async () => {
        const { data } = await userService.findByRoles(["analyst"]).catch(err => ({ data: [] }));
        setList(data);
        refreshList(data);
    }

    const onChange = (field, value) => {
        analyst[field] = value;
        setAnalyst({ ...analyst });
    }

    const findLinkeds = async () => {
        const { data } = await memberService.findAnalyst(props.requestId).catch(err => ({ data: [] }));
        setLinkeds(data);
        return data;
    }

    const recoveryAnalystsRequest = (analystsRequest) => {
        let item = (analystsRequest || []).map(item => {
            item.id = item.responsibleId;
            return item;
        });
        setLinkeds(item);
        return item;
    }

    const checkAnalyst = async (item) => {
        if (!!item.checked) {
            setAnalyst({ ...item, isConfirmRemove: true });
            return;
        }
        await save(item);
    }

    const save = async (item) => {
        await memberService.saveAnalyst({ userId: item.id, requestId: props.requestId });
        refreshList(list);
        onRefreshRequest();
    }

    const handleDelete = (item) =>
        setAnalyst({ ...item, analystId: item.id, isConfirmRemove: true });

    const remove = async (item) => {
        await memberService.removeAnalyst({
            id: item.analystId,
            requestId: props.requestId,
            addtionalInformation: { reason: item.reason },
            userId: item.userId,
            isAnalyst: true
        });
        refreshList(list);
        setAnalyst({ isConfirmRemove: false });
        onRefreshRequest();
    }

    const refreshList = async (list) =>
        Promise.resolve(findLinkeds()).then(data => mapAnalyst(data, list));

    const onRefreshRequest = async () =>
        props.onRefreshRequest && props.onRefreshRequest();

    const mapAnalyst = (linkedItems, listItems) => {
        const result = memberService.mergeAnalysts(listItems, linkedItems);
        setList(result);
        return result;
    }

    const listSearch = (text) => {
        let filterList = [...list];

        if (text)
            filterList = filterList.filter((item) => item.name.toLowerCase().indexOf(text) != -1);

        return filterList;
    }

    const changeFilter = (value) => {
        setFilterText(value);
    }

    const openAnalysts = () => {
        setOpen(true);

        if (!list.length)
            trackPromise(find());
    }

    const closeModal = () => {
        setOpen(false);
    }

    const addAnalysts = () => {
        return (
            (linkeds || []).length ?
                <Tooltip title="Adicionar Analista">
                    <Fab mr={2} color="secondary" size="small" onClick={openAnalysts}
                        style={{
                            width: "22px", minHeight: "22px",
                            height: "23px", marginLeft: "2px", marginRight: "10px"
                        }}>
                        <Add style={{ fontSize: "14px" }} />
                    </Fab>
                </Tooltip>
                :
                <Tooltip title="Adicionar Analista">
                    <Chip size="small" label="Analista" variant="outlined"
                        avatar={<PersonAdd style={{ color: "#49ae5e", width: "16px", height: "16px" }} />}
                        style={{ fontWeight: "500", marginRight: "10px", marginBottom: "8px", color: '#49ae5e', border: '1px solid #49ae5e' }}
                        onClick={openAnalysts}
                    />
                </Tooltip>
        );
    }

    const addAnalystsInTable = () => {
        if (!(linkeds || []).length)
            return (
                <Tooltip title="Adicionar Analista">
                    <Chip size="small" label="Analista" variant="outlined"
                        avatar={<PersonAdd style={{ color: "rgba(73, 174, 94, 0.7)", width: "16px", height: "16px" }} />}
                        style={{ fontWeight: "500", marginRight: "5px", marginBottom: "8px" }}
                        onClick={openAnalysts}
                    />
                </Tooltip>
            );
    }

    const getListLinkeds = (analysts) => {
        return (
            <div style={{ display: "inline" }}>
                {analysts.map((item, index) => (
                    <Tooltip title={item.name} key={index} style={{ zIndex: "999999", cursor: "pointer" }}>
                        <Chip size="small" label="Analista" variant="outlined"
                            avatar={
                                <Avatar alt={item.name} src="/static/img/avatars/avatar-1.jpg"
                                    style={{
                                        fontWeight: 600, background: "rgba(73, 174, 94, 0.7)",
                                        color: "#fff", width: "20px", height: "20px"
                                    }}>{loginService.getNameInitials(item.name)}</Avatar>
                            }
                            style={{ fontWeight: "500", color: "#49ae5e", border: '1px solid #49ae5e', marginRight: "5px", marginBottom: "8px" }}
                            onDelete={() => { handleDelete(item); }}
                        />
                    </Tooltip>
                ))}
            </div>
        );
    }

    const analystsForm = () =>
        (listSearch(filterText) || []).map((item, i) => (
            <ListItem button style={{ paddingLeft: "0" }} onClick={() => { checkAnalyst(item) }} key={i}>
                <ListItemIcon>
                    <Avatar alt={item.name} src="/static/img/avatars/avatar-2.jpg"
                        style={{
                            width: "28px", height: "28px", fontSize: "11px", fontWeight: 600,
                            background: "#376fd0", color: "#fff"
                        }}>{loginService.getNameInitials(item.name)}</Avatar>
                </ListItemIcon>
                <ListItemText primary={item.name} style={{ whiteSpace: "nowrap", width: "18em", overflow: "hidden", textOverflow: "ellipsis" }} />
                {item.checked ? <CheckBox style={{ fontSize: "20px", color: "#a4dd00" }} /> : null}
            </ListItem>
        ))

    const confirmRemoveDialog = () => {

        const close = () =>
            setAnalyst({ ...analyst, isConfirmRemove: false, checked: true });

        return (
            <Dialog open={(analyst.isConfirmRemove || false)} onClose={close} maxWidth="sm">
                <DialogTitle>Remover analista</DialogTitle>
                <DialogContent>
                    <section style={{ width: "200px" }}>
                        <TextField id="reason" autoFocus margin="dense" label="Motivo da inativação" fullWidth
                            onChange={(e) => { onChange('reason', e.target.value); }} />
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">Cancelar</Button>
                    <Button onClick={() => { remove(analyst) }} color="primary">Excluir</Button>
                </DialogActions>
            </Dialog>
        );

    }

    const loading = () => {
        return (promiseInProgress && <LoadingProgress />);
    }

    return (
        <React.Fragment>

            {(linkeds || []).length ? getListLinkeds(linkeds) : null}

            {!props.isTableView ? addAnalysts() : addAnalystsInTable()}

            <Dialog open={open} onClose={closeModal} disableEscapeKeyDown>
                <DialogTitle>Analistas</DialogTitle>

                <TextField name="search" label="Buscar analistas" value={filterText}
                    onChange={(e) => { changeFilter(e.target.value.toLowerCase()) }}
                    style={{ margin: "0 20px 10px" }} my={2} />

                <DialogContent>
                    <List component="nav" dense={true} style={{ padding: "0", height: "120px", overflow: "auto" }}>
                        {loading()}
                        {(!promiseInProgress && list.length) ? analystsForm() : <EmptyList />}
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeModal} color="primary"> Fechar </Button>
                </DialogActions>
            </Dialog>

            {confirmRemoveDialog()}

        </React.Fragment>
    );
}

export default SelectAnalyst;


