import repository from '../../repositories/request/request.deadline.repository'

const checkExtensionDeadlinePossibility = async (requestId, userId, intendedDate) =>
    repository.checkExtensionDeadlinePossibility(requestId, userId, intendedDate);

const updateOperationalDueDateByExtension = async (requestId, userId, newOperationalDueDate) =>
    repository.updateOperationalDueDateByExtension(requestId, userId, newOperationalDueDate);

const confirmConclusionExpectation = async (requestId, userId) =>
    repository.confirmConclusionExpectation(requestId, userId);

const confirmOneHourOperationalDeadlineExtension = async (requestId, userId) =>
    repository.confirmOneHourOperationalDeadlineExtension(requestId, userId);

export default {
    checkExtensionDeadlinePossibility,
    updateOperationalDueDateByExtension,
    confirmConclusionExpectation,
    confirmOneHourOperationalDeadlineExtension
}