import React, { useEffect, useState } from 'react';

import {
    Avatar, Button, Card, Checkbox, CircularProgress, Divider, Fab, FormControlLabel,
    Grid, List, ListItem, ListItemText, TextField, Typography
} from '@material-ui/core';

import { Delete, MoreHoriz, Person } from '@material-ui/icons';

import swal from 'sweetalert';

import FacilitaSnackbar from '../../FacilitaSnackbar';
import LoadingProgress from '../../LoadingProgress';

import useSnackbar from '../../../hooks/useSnackbar';

import solicitationService from '../../../domain/services/solicitation/solicitation.service';
import requestAnalysisService from '../../../domain/services/request.analysis.service';

import utilsProvider from '../../providers/utils.provider';
import { Alert } from '@material-ui/lab';
import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';

function AnalysisPartsForm({ analysisId, requestId, modalityId }) {

    const [partTypes, setPartTypes] = useState([]);
    const [registrationRecordParts, setRegistrationRecordParts] = useState([]);
    const [parts, setParts] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const { snackbarAlert, setAlert, closeAlert } = useSnackbar();

    useEffect(() => getParts(), []);

    useEffect(() => {

        if (!modalityId)
            return

        getPartTypes();

    }, [modalityId]);

    useEffect(() => {
        loadPartsByTypes()
    }, [registrationRecordParts, partTypes]);

    const getParts = async () => {

        const { data: analysisParts } = await requestAnalysisService.getParts(analysisId).catch(() => ({ data: [] }));
        if (analysisParts.length > 0)
            return setRegistrationRecordParts(analysisParts || []);

        const { data: requestParts } = await requestRegistryService.getParts(requestId).catch(() => ({ data: [] }));

        setRegistrationRecordParts(requestParts.map(p => ({ ...p, id: null })));
    }

    const getPartTypes = async () => {

        setLoading(true);

        const { data } = await solicitationService.getPartTypes(modalityId).catch(() => ({ data: [] }));
        const types = (data || []);

        setPartTypes(types);
        setLoading(false);
    }

    const loadPartsByTypes = () => {

        if (registrationRecordParts.length == 0 || partTypes.length == 0)
            return;

        let partsByTypes = registrationRecordParts.filter(part => partTypes.some(type => type.id == part.partTypeId));

        if (partsByTypes.length == 0 && registrationRecordParts.length > 0) {
            partsByTypes = registrationRecordParts.map(part => ({ ...part, partTypeId: partTypes.find(pt => pt.order == part.order)?.id }))
            partsByTypes.map(part => save({ ...part, analysisId, requestId, deleted: null }, false));
        }

        setParts(partsByTypes || []);
    }

    const getPartsByType = (parts, partTypeId) =>
        parts.filter(value => value.partTypeId == partTypeId);

    const addParts = (partTypeId) =>
        setParts([...parts, newPart(partTypeId)]);

    const newPart = (partTypeId) => ({
        value: '',
        partTypeId,
        analysisId,
        requestId,
        isRepresented: false,
        showForm: true
    })

    const save = async (partValue, isToNotify = true) => {

        if (partValue.id)
            return edit(partValue, isToNotify);

        setSubmitting(true);

        await requestAnalysisService.createPart(partValue.requestId, partValue)
            .then((res) => {
                const items = [...utilsProvider.removeItemInList(parts, partValue)];

                setParts([...items, { ...res.data, showForm: false }]);

                if (isToNotify)
                    setAlert("Parte adicionada com sucesso", "success");
            })
            .catch(error => setAlert(error.response.data.message, "error"));

        setSubmitting(false);
    }

    const edit = async (partValue, isToNotify = true) => {

        setSubmitting(true);

        const { id, partTypeId, isRepresented, value, deleted } = partValue;

        await requestAnalysisService
            .editPart({ id, partTypeId, isRepresented, value, deleted })
            .then(() => setParts([...utilsProvider.changeItemInList(parts, { ...partValue, showForm: false })]));

        if (isToNotify)
            setAlert("Parte alterada com sucesso", "success");

        setSubmitting(false);
    }

    const details = (partValue) =>
        setParts([...utilsProvider.changeItemInList(parts, { ...partValue, showForm: true })]);

    const cancelForm = (partValue) => {

        if (!partValue.id) {
            setParts([...utilsProvider.removeItemInList(parts, partValue)]);
            return;
        }

        setParts([...utilsProvider.changeItemInList(parts, { ...partValue, showForm: false })]);
    }

    const remove = async (partValue) => {

        setParts([...utilsProvider.removeItemInList(parts, partValue)]);

        if (partValue.id)
            await requestAnalysisService.removePart(partValue);

        setAlert("Parte excluída", "success");
    }

    const handleChange = (key, value, part, partTypeId) =>
        setParts([...utilsProvider.changeItemInList(parts, { ...part, partTypeId, [key]: value })]);

    const isAllowedRepresent = (checked, part, partTypeId, listParts) => {

        const existRepresentedPart = listParts.some(part => (part.partTypeId != partTypeId && part.isRepresented));

        if (!existRepresentedPart) {
            handleChange("isRepresented", checked, part, partTypeId);
            return;
        }

        swal({
            title: "Atenção", text: "Não é permitido representar mais de um tipo de parte", icon: "warning"
        });
    }

    const canRemove = (partValue) => {

        swal({
            title: "Excluir Parte",
            text: "Realmente deseja excluir a parte selecionada",
            icon: "warning",
            buttons: { cancel: 'Cancelar', confirm: { text: 'Sim', className: 'MuiButton-containedPrimary' } }
        }).then(confirm => confirm && remove(partValue));
    }

    const getPartsContent = (partType) => {

        const partList = getPartsByType(parts, partType.id);

        if (!partList.length)
            return (
                <List style={{ width: '100%', paddingTop: 0 }}>
                    <ListItem>
                        <ListItemText fontSize="16px" primary={`Não há partes adicionada como: ${partType.name}`} />
                    </ListItem>
                </List>
            );

        return (
            <List style={{ width: '100%', paddingTop: 0 }}>
                {partList.map((part) => (
                    <React.Fragment>
                        {part.showForm ? getPartForm(part, partType) :
                            <ListItem key={part.id}>
                                <ListItemText
                                    primary={
                                        <Grid container spacing={2}>
                                            <Grid item xs={2} md={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Avatar style={{ width: "30px", height: "30px" }}><Person fontSize='small' /></Avatar>
                                            </Grid>
                                            <Grid item xs={10} md={11}>
                                                <Typography variant="h4" className="MuiInputLabel-shrink" style={{ fontSize: "13px" }}>
                                                    {partType.name}
                                                    {part.isRepresented &&
                                                        <span style={{ marginLeft: "5px" }}>
                                                            - Parte representada
                                                        </span>
                                                    }
                                                </Typography>
                                                <Typography variant="body1" className="MuiInputLabel-shrink">
                                                    {(part.value || part.name)}
                                                </Typography>

                                                <Fab
                                                    size="small" color="secondary"
                                                    onClick={() => { details(part) }}
                                                    style={{
                                                        minWidth: "30px", minHeight: "30px", width: "30px",
                                                        height: "30px", float: "right", marginTop: "-30px"
                                                    }}
                                                >
                                                    <MoreHoriz fontSize='small' />
                                                </Fab>

                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        }
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        );
    }

    const getPartForm = (part, partType) => {

        return (
            <ListItem key={part.id}>
                <ListItemText
                    primary={
                        <Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="A parte é representada por seu escritório?"
                                    control={
                                        <Checkbox
                                            checked={part.isRepresented}
                                            name="isRepresented"
                                            onChange={(e) => {
                                                isAllowedRepresent(e.target.checked, part, partType.id, parts)
                                            }}
                                        />
                                    }

                                />

                                <Button variant="text" style={{ float: "right" }}
                                    onClick={() => { canRemove(part) }}
                                >
                                    <Delete style={{ marginRight: "5px", fontSize: "15px" }} /> Excluir
                                </Button>

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="value"
                                    value={(part.partName || part.value)}
                                    label="Nome da parte"
                                    placeholder="Preencha o nome da parte"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ marginTop: "10px" }} fullWidth
                                    onChange={(e) => {
                                        handleChange("value", e.target.value, part, partType.id)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "5px" }}>
                                <Button
                                    variant="text"
                                    style={{ float: "right" }}
                                    onClick={() => { cancelForm(part) }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    variant="text" color="primary"
                                    onClick={() => { save(part) }}
                                    style={{ margin: "0 10px", float: "right" }}
                                >
                                    {isSubmitting ?
                                        <span>
                                            Salvando
                                            <CircularProgress m={2} style={{ marginLeft: '5px', width: '16px', height: '16px' }} />
                                        </span> : "Salvar"
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
            </ListItem >
        );
    }

    return (
        <React.Fragment>

            <Grid container spacing={6}>

                {loading &&
                    <div style={{ margin: '20px', width: "98%" }}>
                        <LoadingProgress />
                    </div>
                }

                {(!loading && !partTypes.length) &&
                    <Alert variant="filled" severity="info" style={{ margin: '20px', width: "98%" }}>
                        Para informar as partes, selecione a modalidade na aba de informações.
                    </Alert>
                }

                {!loading && partTypes.map(partType => (
                    <List
                        key={partType.id}
                        style={{ width: '94%', margin: '20px', padding: "0", background: "#fff" }}>

                        <Card>
                            <ListItem style={{ padding: "0" }}>
                                <ListItemText
                                    primary={
                                        <Grid item xs={12}
                                            style={{ display: "flex", borderBottom: "1px dashed #d4d4d4", justifyContent: "space-between" }}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    fontSize: "16px", fontWeight: "500", color: "rgba(0, 0, 0, 0.8)", margin: "10px"
                                                }}
                                            >
                                                {partType.name}
                                            </Typography>
                                            <Button type="button" color="primary" mt={3}
                                                style={{
                                                    background: "aliceblue",
                                                    margin: "5px 10px", height: "38px"
                                                }}
                                                onClick={() => { addParts(partType.id) }}>
                                                Adicionar
                                            </Button>
                                        </Grid>
                                    }
                                />
                            </ListItem>

                            {getPartsContent(partType)}

                        </Card>
                    </List>
                ))}
            </Grid>

            <FacilitaSnackbar alert={snackbarAlert} onClose={closeAlert} />

        </React.Fragment>
    );
}

export default AnalysisPartsForm;