import { useState } from "react";
import CustomerFinishRegistrationModal from "../../clients/customer.finish.registration.modal.component";
import CustomerOfficeModal from "../../customer/office/customerOfficeModal";
import loginService from "../../../domain/services/login.service";

export default function FirstRequestDownloadModals() {

    const [currentModalIndex, setCurrentModalIndex] = useState(0);
    
    const user = loginService.getUserAuthentication();
    const customer = { ...user.customer, name: user.name };

    const modals = ([
        () => <CustomerFinishRegistrationModal onClose={handleCloseModal} />,
        () => <CustomerOfficeModal onClose={handleCloseModal} customer={customer} />,
    ]);

    const handleCloseModal = () => {

        const nextModalIndex = currentModalIndex + 1;

        const haveNextModal = nextModalIndex < modals.length;

        if (!haveNextModal)
            return;

        setCurrentModalIndex(nextModalIndex);
    }

    return modals[currentModalIndex]();

}