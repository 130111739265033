import React, { useState } from "react";

import { Link, Grid, Divider, Breadcrumbs, Typography } from "@material-ui/core";

import { Helmet } from "react-helmet";
import InvoicesList from "../../../components/correspondents/invoices.list.component";
import InvoiceDetails from "../../../components/correspondents/invoice.details.component";
import { NavLink } from "react-router-dom";

function FaciliterInvoices() {

    const [invoiceValue, setInvoiceValue] = useState({});
    const [openDetails, setOpenDetails] = useState(false);

    const onSelect = (value) => {
        setInvoiceValue({ ...value });
        setOpenDetails(true);
    }

    const closeDetails = () => setOpenDetails(false);

    return (
        <React.Fragment>
            <Grid container spacing={0} justify="center">
                <Grid item md={6} xs={12}>
                    <Helmet title="Faturas" />
                    <Typography variant="h4" gutterBottom display="inline">
                        {!openDetails ? "Faturas" : "Detalhes da Fatura"}
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2} style={{ marginTop: "10px", width: "100%" }}>
                        <Link component={NavLink} exact to={`/invoices${window.location.search}`} onClick={closeDetails}> Lista de Faturas </Link>
                        {openDetails && <Typography variant="body1"> Detalhes da Fatura </Typography>}
                    </Breadcrumbs>

                    <Divider my={6} style={{ marginTop: "10px", marginBottom: "10px" }} />

                    {!openDetails && <InvoicesList onSelect={onSelect} />}

                    {openDetails && <InvoiceDetails value={invoiceValue} onCancel={closeDetails} />}

                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default FaciliterInvoices;
