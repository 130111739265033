import api from './api';

const findByUserId = async (userId) =>
    api.get({ type: 'user', service: `/correspondents/availability/${userId}` });

const refresh = async (data) =>
    api.post({ type: 'user', service: `/correspondents/availability`, data });

export default {
    findByUserId,
    refresh
}
