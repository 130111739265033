import {
    AccessTime, Archive, ArrowForward, Done, EditOutlined, Event, EventAvailable,
    HourglassEmpty, Inbox, Link, Loop, RateReview, Visibility,
} from '@material-ui/icons';

import loginService from '../services/login.service';

export const stepsSolicitation = {
    ANALYSE: 'ANALYSE',
    PENDING: 'PENDING',
    LINK: 'LINK',
    NOT_STARTED: 'NOT_STARTED',
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    REVIEW: 'REVIEW',
    CORRECTION: 'CORRECTION',
    WAITING_DELIVER: 'WAITING_DELIVER',
    CONCLUDED: 'CONCLUDED',
    REVOKED: 'REVOKED',
    FROZEN: 'FROZEN',
    SAMPLING: 'SAMPLING',
    stepTypes: {
        ANALYSE: {
            id: 0,
            name: 'analyse',
            key: 'ANALYSE',
            value: 'Caixa de Entrada',
            background: 'rgb(55, 111, 208)',
            color: 'white',
            icon: <Inbox fontSize='small' />,
            visible: () => ({ internal: true, faciliter: false }),
            position: 0,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        PENDING: {
            id: 1,
            name: 'pending',
            key: 'PENDING',
            value: 'Pendente',
            background: '#fcdc00',
            color: 'black',
            icon: <AccessTime fontSize='small' />,
            visible: () => ({ internal: true, faciliter: false }),
            position: 1,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        LINK: {
            id: 2,
            name: 'link',
            key: 'LINK',
            value: 'A Vincular',
            background: '#16a5a5',
            color: 'white',
            icon: <Link fontSize='small' />,
            visible: () => ({ internal: true, faciliter: false }),
            position: 2,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        NOT_STARTED: {
            id: 3,
            name: 'notStarted',
            key: 'NOT_STARTED',
            value: 'Não Iniciado',
            background: 'gray',
            color: 'black',
            icon: <Event fontSize='small' />,
            visible: () => ({ internal: true, faciliter: true }),
            disabledAction: { faciliter: true },
            position: 3,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        IN_PROGRESS: {
            id: 5,
            name: 'inProgress',
            key: 'IN_PROGRESS',
            value: 'Fazendo',
            background: 'rgb(55, 111, 208)',
            color: 'white',
            icon: <ArrowForward fontSize='small' />,
            visible: () => ({ internal: true, faciliter: true }),
            position: 5,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        REVIEW: {
            id: 6,
            name: 'review',
            key: 'REVIEW',
            value: 'Em Revisão',
            background: '#68ccca',
            color: 'white',
            icon: <RateReview fontSize='small' />,
            visible: () => ({ internal: true, faciliter: loginService.isFaciliterReviewer() }),
            position: 6,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        CORRECTION: {
            id: 8,
            name: 'correction',
            key: 'CORRECTION',
            value: 'Em Correção',
            background: 'orange',
            color: 'white',
            icon: <EditOutlined fontSize='small' />,
            visible: () => ({ internal: true, faciliter: false }),
            position: 8,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        WAITING_DELIVER: {
            id: 9,
            name: 'waitingDeliver',
            key: 'WAITING_DELIVER',
            value: 'Em Espera para Entrega',
            background: '#b31cff',
            color: 'white',
            icon: <HourglassEmpty fontSize='small' />,
            visible: () => ({ internal: true }),
            position: 9,
            expanded: false,
            isStep: true,
            isColdStep: false,
        },
        CONCLUDED: {
            id: 10,
            name: 'concluded',
            key: 'CONCLUDED',
            value: 'Concluídas',
            background: '#a4dd00',
            color: 'white',
            icon: <EventAvailable fontSize='small' />,
            visible: () => ({ internal: true, faciliter: true }),
            position: 9,
            expanded: false,
            isStep: true,
            isColdStep: true,
        },
        REVOKED: {
            id: 10,
            name: 'revoked',
            key: 'REVOKED',
            value: 'Arquivadas',
            background: 'rgb(196 219 218)',
            color: 'black',
            icon: <Archive fontSize='small' />,
            visible: () => ({ internal: true, faciliter: false }),
            position: -1,
            expanded: false,
            isStep: true,
            isColdStep: true,
        },
        FROZEN: {
            id: 11,
            name: 'frozen',
            key: 'FROZEN',
            value: 'Em produção',
            background: 'rgb(55, 111, 208)',
            color: 'white',
            icon: <Loop fontSize='small' />,
            visible: () => ({ internal: false, faciliter: false }),
            position: -1,
            expanded: false,
            isStep: false,
            isColdStep: true,
        },
        SAMPLING: {
            id: 12,
            name: 'sampling',
            key: 'SAMPLING',
            value: 'Concluir por amostragem',
            background: 'darkgreen',
            color: 'white',
            icon: <Done fontSize='small' />,
            visible: () => ({ internal: false, faciliter: false }),
            position: -1,
            expanded: false,
            isStep: false,
            isColdStep: true,
        },
        NEW: {
            id: 13,
            name: 'new',
            key: 'NEW',
            value: 'Em espera',
            background: 'orange',
            color: 'white',
            icon: <Loop fontSize='small' />,
            visible: () => ({ internal: false, faciliter: false }),
            position: -1,
            expanded: false,
            isStep: false,
            isColdStep: true,
        },
    },
};

export const customerStepSolicitation = {
    IN_PRODUCTION: {
        name: 'inProduction',
        key: 'IN_PRODUCTION',
        value: 'Em Andamento',
        background: 'rgb(55, 111, 208)',
        specificColumns: ['dueDate', 'price'],
        buttons: ['comments', 'details', 'revoke']
    },
    PENDING: {
        name: 'pending',
        key: 'PENDING',
        value: 'Pendências',
        background: '#fcdc00',
        specificColumns: ['dueDate'],
        buttons: ['comments', 'resolvePending', 'revoke']
    },
    CONCLUDED: {
        name: 'concluded',
        key: 'CONCLUDED',
        value: 'Concluídas',
        background: '#a4dd00',
        specificColumns: ['dueDate', 'price', 'rating'],
        buttons: ['comments', 'correction', 'details']
    },
    REVOKED: {
        name: 'revoked',
        key: 'REVOKED',
        value: 'Arquivadas',
        background: 'rgb(196 219 218)',
        buttons: ['comments', 'unrevoke', 'details']
    }
};

export default stepsSolicitation;