import styled from "styled-components";

import { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { Bookmark, CloudDownload, PaymentSharp } from '@material-ui/icons';

import subscriptionService from "../../domain/services/financial/subscription.service";
import loginService from "../../domain/services/login.service";

const InfoLegend = styled.span`
    margin: 10px 10px 10px 0;
    color: rgba(68, 68, 68, 0.85);
    width: auto;
    font-size: 14px;
`;

const iconStyle = {
    fontSize: 18, color: 'blue',
    marginBottom: -3, marginRight: 10,
};

export default function CustomerSubscriptionInfo({ customerId }) {

    const [subscriptionInfo, setSubscriptionInfo] = useState({});

    useEffect(() => findSubscriptionInfo(customerId), []);

    const findSubscriptionInfo = (customerId) =>
        subscriptionService
            .findByCustomer(customerId)
            .then(res => setSubscriptionInfo(res.data || {}))
            .catch(err => null);

    if (subscriptionInfo.status != "active")
        return null;

    return (
        <Grid item container spacing={2}>

            {loginService.isInternal() && (
                <Grid item>
                    <InfoLegend>

                        <Bookmark style={iconStyle} />

                        Cliente assinante do plano: <strong>{subscriptionInfo.name}</strong>

                    </InfoLegend>
                </Grid>
            )}

            {loginService.isInternal() && subscriptionInfo.segmentationDetails.freeCorrectionRequestAfterPeriod && (
                <Grid item>
                    <InfoLegend>

                        <PaymentSharp style={iconStyle} />

                        Sem taxa de correção após o período disponível

                    </InfoLegend>
                </ Grid>
            )}

        </Grid>
    );
}