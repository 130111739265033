export default function BlogEditorDesktopSidebar({ options = [], onSelect, selectedOption }) {

    const renderSidebarOption = (option) => {

        const isSelected = selectedOption === option.id;

        return (
            <div
                key={option.id}
                className={`px-3 py-3 rounded ${isSelected ? 'bg-[#0001] text-[#131313]' : 'bg-transparent text-[#434343]'} hover:bg-[#0001] hover:cursor-pointer flex items-center gap-3`}
                style={{ transition: 'background-color .3s ease' }}
                onClick={() => onSelect(option.id)}
            >
                <div className="text-[#555] translate-y-[0px]">
                    {option.icon}
                </div>
                <span className="text-sm">{option.label}</span>
            </div>
        )
    }

    return (
        <div className="shrink-0 w-72 hidden md:block pr-8 h-auto border-r border-[#dedede]">
            <div className="flex flex-col gap-2 h-full">
                {options.map(renderSidebarOption)}
            </div>
        </div>
    )
}