import api from '../../services/api';

const baseUrl = 'correspondents/assure-income'

const add = async (faciliterId, data) =>
    api.put({ type: 'user', service: `${baseUrl}/${faciliterId}/add`, data });

const remove = async (faciliterId, data) =>
    api.put({ type: 'user', service: `${baseUrl}/${faciliterId}/remove`, data });

const findInfoById = async (faciliterId) =>
    api.get({ type: 'user', service: `${baseUrl}/${faciliterId}/info` });

export default {
    add,
    remove,
    findInfoById
}