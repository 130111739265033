import { useParams } from "react-router-dom";
import { BlogProvider } from "../../../context/marketing/blogContext";
import BlogEditor from "../../../components/marketing/blog/blogEditor/blogEditor";

export default function BlogEditorPage() {

    const { id } = useParams();

    return (
        <BlogProvider blogId={id} >
            <BlogEditor />
        </BlogProvider>
    );
}