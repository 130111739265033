import { useRef, useState } from 'react';

import styled from 'styled-components/macro';
import swal from 'sweetalert';

import {
    Typography, Fab, Tooltip, Checkbox, Chip, Dialog, DialogContent,
    DialogActions, Button, Grid, Popover, CircularProgress
} from '@material-ui/core';
import {
    DeleteForever, LockOpenOutlined, LockOutlined,
    Person, VerifiedUser, Work, Warning, AlarmOn
} from '@material-ui/icons';

import LoadingProgress from './LoadingProgress';

import commentsService from '../domain/services/solicitation/comments.service';
import loginService from '../domain/services/login.service';
import { Alert } from '@material-ui/lab';

const ChatMessage = styled(Grid)`
    text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
    display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
    text-align: right;
    opacity: 0.8;
`;

const ChatMessageBubble = styled.div`
    display: inline-block;
    margin-right: auto;
    background: ${(props) => (props.highlighted ? props.theme.palette.secondary.main : props.theme.palette.action.hover)};
    color: ${(props) => (props.highlighted ? props.theme.palette.common.white : props.theme.palette.text.primary)};
    border-radius: 3px;
    padding: ${(props) => props.theme.spacing(2)}px;
    margin-bottom: ${(props) => props.theme.spacing(1)}px;
    ${(props) => props.theme.shadows[1]};
    width: 100%;
    min-width: 200px;
`;

const ChatHead = styled.div`
    font-weight: 700 !important;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

function ChatMessageComponent({ comment, refreshComments, position = 'left', showRealeaseCustomerPopover, setShowRealeaseCustomerPopover }) {

    const [showConfirmReleaseModal, setShowConfirmReleaseModal] = useState(false);
    const [showReleaseDeniedModal, setShowReleaseDeniedModal] = useState(false);

    const [isReleasingCommentToCustomer, setIsReleasingCommentToCustomer] = useState(false);

    const customerReleaseButtonAnchor = useRef(null);

    const handleCloseConfirmReleaseModal = () => {
        setShowConfirmReleaseModal(false);
    }

    const deleteComments = () => {
        if (loginService.getUserAuthentication().id == comment.senderUserId)
            return (
                <Fab
                    onClick={checkRemove} size="small" color="default"
                    style={{
                        marginLeft: '10px', height: '23px',
                        minHeight: '20px', minWidth: '20px', width: '23px'
                    }}
                >
                    <DeleteForever style={{ fontSize: '16px' }} />
                </Fab>
            );
    };

    const releaseCommentDeniedModal = () => {
        return (
            <Dialog
                open={showReleaseDeniedModal} maxWidth="xs" fullWidth
                onClose={(e, reason) => reason != 'backdropClick' && handleCloseConfirmReleaseModal}
            >
                <DialogContent style={{ justifyContent: 'center', textAlign: 'center' }}>

                    <Warning style={{ fontSize: '2.5rem' }} />

                    <Typography style={{
                        fontSize: '1rem', padding: '0.9rem',
                        textAlign: 'justify', lineHeight: '1.5'
                    }}>
                        Não é possível liberar comentários que contenham dados pessoais, como nome,
                        número de celular e email. Esta medida visa proteger a privacidade do cliente.
                    </Typography>

                    <Typography style={{
                        marginBottom: '1rem', fontSize: '1rem', padding: '0.9rem',
                        textAlign: 'justify', lineHeight: '1.5'
                    }}>
                        Caso deseje liberar uma nota para o cliente, por favor, certifique-se
                        de remover quaisquer informações pessoais antes de publicá-la.
                    </Typography>

                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained" color="primary"
                        onClick={() => setShowReleaseDeniedModal(false)}
                    >
                        Entendi
                    </Button>
                </DialogActions>
            </Dialog>
        )

    }

    const confirmEnableCommentVisibilityModal = () => {
        return (
            <Dialog
                open={showConfirmReleaseModal} maxWidth="xs" fullWidth
                onClose={(e, reason) => reason != 'backdropClick' && handleCloseConfirmReleaseModal}
            >
                <DialogContent>

                    <Typography style={{ fontSize: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
                        Você está liberando o comentário para o cliente
                    </Typography>

                    <Typography style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                        Deseja continuar?
                    </Typography>

                </DialogContent>

                <DialogActions>

                    <Button
                        variant="contained"
                        onClick={handleCloseConfirmReleaseModal}
                        disabled={isReleasingCommentToCustomer}
                    >
                        Cancelar
                    </Button>

                    <Button
                        variant="contained" color="primary"
                        onClick={handleConfirmEnableComment}
                        disabled={isReleasingCommentToCustomer}
                    >
                        <CircularProgress
                            size={20}
                            style={{ display: isReleasingCommentToCustomer ? 'block' : 'none', marginRight: 10 }}
                        />
                        Confirmar
                    </Button>

                </DialogActions>
            </Dialog>
        )

    }

    const handleClickCommentLock = async (checked) => {

        if (checked) {
            setShowConfirmReleaseModal(true);
            return;
        }

        const isVisibleToCustomer = false;
        update(isVisibleToCustomer)
    }

    const handleConfirmEnableComment = async () => {

        try {
            setIsReleasingCommentToCustomer(true);
            const isVisibleToCustomer = true
            await update(isVisibleToCustomer)
        }
        catch (error) {
            handleCloseConfirmReleaseModal();
            setShowReleaseDeniedModal(true);
        }

        setShowRealeaseCustomerPopover(false);
        setIsReleasingCommentToCustomer(false);
    }

    const messageWaitingToShowCustomer = () => {

        if (!loginService.isInternal() || !comment.isWaitingToShowCustomer || comment.isVisibleCustomer)
            return null;

        return (
            <Tooltip title={`
                Aguardando disponibilização para o cliente. O comentário ficará disponível para o cliente 
                quando a solicitação for finalizada ou enviada para Pendências.
            `}>
                <AlarmOn fontSize='14' style={{ color: 'orange', marginBottom: -6, marginRight: 10 }} />
            </Tooltip >
        );
    }

    const enabledMessageToCustomer = () => {

        if (!(loginService.isInternal() && comment.source != 'customer') && !(loginService.isFaciliter() && comment.source == 'faciliter'))
            return null;

        const isChecked = comment.isVisibleCustomer || comment.isWaitingToShowCustomer;

        const tooltipTitle = isChecked ? "Bloquear para o cliente" : "Tornar visível para o cliente";

        return (
            <Tooltip title={tooltipTitle}>
                <Checkbox
                    color="primary"
                    checked={isChecked}
                    onChange={(e) => handleClickCommentLock(e.target.checked)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    icon={<LockOutlined style={{ fontSize: '18px' }} />}
                    checkedIcon={<LockOpenOutlined style={{ fontSize: '18px' }} />}
                    style={{ width: '30px', height: '30px', marginLeft: '5px' }}
                />
            </Tooltip >
        );
    }

    const checkRemove = () =>
        swal({
            title: 'Excluir comentário',
            text: 'Será removido para todos. Realmente deseja excluir?',
            icon: 'warning',
            buttons: {
                confirm: { text: 'Excluir', className: 'MuiButton-containedPrimary' },
                cancel: 'Cancelar',
            },
        }).then((res) => {
            if (res) remove();
        });

    const remove = async () => {
        await commentsService.remove(comment);
        refreshComments();
    };

    const update = async (isVisibleCustomer) => {
        await commentsService.update({ ...comment, isVisibleCustomer });
        refreshComments();
    };

    const getSourceName = (source) => {

        const sources = {
            customer: 'Cliente',
            customerUser: 'Usuário do Cliente',
            internal: 'Interno',
            faciliter: 'Faciliter',
        };

        return sources[source];
    };

    const getSourceIcon = (source) => {

        const sources = {
            customer: <Person />,
            customerUser: <Person />,
            internal: <VerifiedUser />,
            faciliter: <Work />,
        };

        return sources[source];
    };

    return (
        <ChatMessage className='max-w-full' item position={position}>
            <ChatMessageInner className='max-w-full'>

                {
                    position == 'left' && (
                        <ChatHead>

                            {comment.source == 'faciliter' && loginService.isCustomer() ?
                                "Faciliter" :
                                comment.senderUserName}

                            {loginService.isInternal() && (
                                <Chip
                                    icon={getSourceIcon(comment.source)}
                                    label={getSourceName(comment.source)}
                                    size="small" style={{ marginLeft: 8 }}
                                />
                            )}

                        </ChatHead>
                    )
                }

                <ChatMessageBubble highlighted={position === 'right'} ref={customerReleaseButtonAnchor}>
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
                        {comment.description}
                    </Typography>
                </ChatMessageBubble>

                <ChatMessageTime variant="body2">
                    {messageWaitingToShowCustomer()}
                    {new Date(comment.created).toLocaleString()}
                    {deleteComments()}
                    {enabledMessageToCustomer()}
                    {confirmEnableCommentVisibilityModal()}
                    {releaseCommentDeniedModal()}
                </ChatMessageTime>

            </ChatMessageInner>

            <Popover
                open={showRealeaseCustomerPopover}
                anchorEl={customerReleaseButtonAnchor.current}
                transitionDuration={300}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div className="p-5 max-w-lg">

                    <p className="text-sm font-medium text-gray-900">Comentário para cliente?</p>

                    <p className="mt-1 text-sm text-gray-500">
                        Para que seu comentário fique disponível para o cliente é necessário que você libere manualmente. Deseja liberar o comentário para o cliente?
                    </p>

                    <div className="mt-4 flex space-x-2">
                        <Button
                            onClick={handleConfirmEnableComment}
                            color='primary'
                            variant='contained'
                            disabled={isReleasingCommentToCustomer}
                        >
                            <CircularProgress
                                size={20}
                                style={{ display: isReleasingCommentToCustomer ? 'block' : 'none', marginRight: 10 }}
                            />
                            Sim
                        </Button>
                        <Button
                            onClick={() => setShowRealeaseCustomerPopover(false)}
                            variant='contained'
                            disabled={isReleasingCommentToCustomer}
                        >
                            Não
                        </Button>
                    </div>

                </div>
            </Popover>
        </ChatMessage>
    );
}

function Chat({ messages, onRefresh, isLoading, showLastCommentCustomerRelasePopover, setShowLastCommentCustomerRelasePopover }) {

    const isLastComment = (index) => index === messages.length - 1;

    if (isLoading)
        return (
            <LoadingProgress />
        )

    if (!messages.length)
        return (
            <Alert severity='info'>
                Ainda não há comentários para visualizar.
            </Alert>
        )

    return (
        <Grid container direction='column' spacing={6}>
            {(messages || []).map((item, i) => (
                <ChatMessageComponent
                    key={i}
                    comment={item}
                    refreshComments={onRefresh}
                    position={loginService.getUserAuthentication().id == item.senderUserId ? 'right' : 'left'}
                    showRealeaseCustomerPopover={isLastComment(i) && showLastCommentCustomerRelasePopover}
                    setShowRealeaseCustomerPopover={setShowLastCommentCustomerRelasePopover}
                />
            ))}
        </Grid>
    );
}

export default Chat;
