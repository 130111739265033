import React, { useEffect, useState } from "react";

import moment from "moment";

import {
    Avatar, Grid, List, ListItem, ListItemText, ListItemIcon, Tooltip, Link, Button, Snackbar
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import service from "../../domain/services/correspondent/faciliter.invoice.document.service";

import { AttachFileOutlined, Close, GetApp, Visibility } from "@material-ui/icons";
import ProgressDeterminate from "../ProgressDeterminate";
import DocumentPreview from '../solicitations/documents/document.preview.component';

function InvoiceDocument({ invoiceId, userId }) {

    const [document, setDocument] = useState([]);

    const [statusMsg, setStatusMsg] = useState({});

    const [preview, setPreview] = useState({ show: false });

    useEffect(() => getInvoiceDocument(), []);

    const getInvoiceDocument = async () => {

        if (!invoiceId) return;

        service.find(invoiceId, userId).then(res => setDocument(res.data || []));
    }

    const visibility = async (file, show) => setPreview({ ...file, show });

    const save = async (files) => {

        await service.saveAll(invoiceId, userId, files).then((res) => {

            let fileErrors = res.map((item) => item.document.name);

            (fileErrors.length) ?
                setAlertStatus(`Erro no upload dos arquivos: ${fileErrors.join(', ')}`, "warning") :
                setAlertStatus("Upload Concluído", "success");

        }).finally(getInvoiceDocument);

    }

    const removeDocument = async (item) => {

        if (item.documentId)
            await service.removeDocument(userId, invoiceId, item.documentId);

        setDocument([]);
    }

    const handleSave = async (files) => {

        const format = /[!@#$&+\=\[\]{};':"\\|,<>\/?]+/;

        if (files.length && format.test(files[0].name)) {
            setAlertStatus('Arquivo com o nome com caracteres especiais não serão carregados: (& $ @ = ; : + , ?)', "warning");
            return;
        }

        const formatFiles = [...Array.from(files)];

        await save(formatFiles);
        setDocument([...document, formatFiles]);
    }

    const setAlertStatus = (msg, severity) => { setStatusMsg({ text: msg, date: new Date(), open: true, severity }) }

    const handleCloseAlert = () => { setStatusMsg({ text: "", date: new Date(), open: false }) }

    const getItemDocument = (item) => {
        let dateTime = moment.utc(item.created).format('DD/MM/YYYY');
        return (
            <ListItemText style={{ flex: "5 1 auto" }} size="small" primary="Nota Fiscal" secondary={dateTime} />
        );
    }

    return (
        <React.Fragment>


            {document.length ?
                <section style={{ marginLeft: "-15px" }}>
                    <List component="nav" dense={true} style={{ width: "230px", height: "auto" }}>
                        {document.map((item, i) => (
                            <ListItem button style={{ paddingLeft: "0" }} key={i}>
                                <ListItemIcon>
                                    <Avatar alt={item.name}
                                        style={{ marginLeft: "10px", color: "white", width: "24px", height: "24px", fontSize: "10px", fontWeight: 600 }}>
                                        <AttachFileOutlined style={{ fontSize: "12px" }} />
                                    </Avatar>
                                </ListItemIcon>
                                <Grid container justify="flex-start">
                                    {getItemDocument(item)}
                                </Grid>
                                <Grid container justify="flex-end">
                                    <Tooltip title="Visualizar">
                                        <Link style={{ marginLeft: "10px", marginRight: "10px" }} onClick={() => { visibility(item, true) }}>
                                            <Visibility style={{ fontSize: "16px" }} />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Download">
                                        <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                            <GetApp style={{ fontSize: "16px" }} />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Remover">
                                        <Link style={{ marginLeft: "10px", color: "red" }} onClick={() => removeDocument(item)}>
                                            <Close style={{ fontSize: "16px" }} />
                                        </Link>
                                    </Tooltip>
                                </Grid>
                            </ListItem>
                        ))
                        }
                    </List>
                </section>
                :
                <Grid item>
                    <input style={{ 'display': 'none' }} id="customerFiles" type="file" multiple onChange={(e) => handleSave(e.target.files)} />
                    <label htmlFor="customerFiles">
                        <Button size="small" variant="contained" color="primary" component="span"
                            style={{ height: "28px", minHeight: "0", fontSize: "12px", marginTop: "6px" }}>
                            Anexar Nota Fiscal
                        </Button>
                    </label>
                </Grid>
            }

            <ProgressDeterminate style={{ display: "block" }} />

            <Snackbar onClose={handleCloseAlert} open={statusMsg.open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000} key={statusMsg.date}>
                <Alert severity={statusMsg.severity}>
                    {statusMsg.text}
                </Alert>
            </Snackbar>

            {preview.show && <DocumentPreview file={preview} onSelect={visibility} />}

        </React.Fragment>
    );
}

export default InvoiceDocument;


