import api from '../../services/api';

const baseUrl = 'requests'

const findAvailableByMainId = async (mainId) =>
    api.get({ type: 'user', service: `${baseUrl}/deadline/${mainId}/advance` });

const advance = async (mainId, advanceId, skipValidation) =>
    api.post({ type: 'user', service: `${baseUrl}/deadline/${mainId}/advance`, data: { advanceId, skipValidation } });

const cancelAdvance = async (mainId, skipValidation) =>
    api.post({ type: 'user', service: `${baseUrl}/deadline/${mainId}/cancel-advance`, data: { skipValidation } });

export default {
    findAvailableByMainId,
    advance,
    cancelAdvance
}