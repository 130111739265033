import { useEffect, useState } from "react";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import { Grid, Button, CircularProgress, Divider, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CommentOutlined } from "@material-ui/icons";

import PendingSolveDocumentForm from "./pending.solve.document.form.component";
import SimpleModal from "../../shared/SimpleModal";

import requestValidator from "../../../domain/validators/request.validator";
import loginService from "../../../domain/services/login.service";
import pendingSolveService from "../../../domain/services/solicitation/pending.solve.service";
import requestFieldsService from "../../../domain/services/request/request.fields.service";
import communicationProvider from "../../../providers/communication.provider";
import requestFieldsConfig from "../fields/request.fields.config";

const PendingSolveModal = ({ isOpen, request, closeModal, onFinish, setAlert }) => {

    const [newFiles, setNewFiles] = useState([]);
    const [observation, setObservation] = useState('');
    const [requestChanges, setRequestChanges] = useState({});

    const [missingField, setMissingField] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {

        const missingFieldKey = request.additionalInformation?.pending?.requestFieldMissing
        if (!isOpen || missingField || !missingFieldKey)
            return;

        findMissingField();

    }, [isOpen]);

    const findMissingField = () => {

        setIsLoading(true);

        requestFieldsService
            .findMissingByRequestId(request.id)
            .then(setMissingField)
            .catch(err => communicationProvider.getErrorMessage(err, setAlert))
            .finally(() => setIsLoading(false));
    }


    const handleResolvePending = async (requestId, files, observation, setAlert) => {

        const { canResolvePending, errorMessage } = requestValidator.checkResolvePending(files, observation, missingField, requestChanges);

        if (!loginService.isInternal() && !canResolvePending) {
            setAlert(errorMessage || 'Não foi possível resolver pendência!', 'error');
            return;
        }

        trackPromise(
            pendingSolveService
                .handleResolvePending(requestId, observation, requestChanges)
                .then(() => setAlert('Pendência resolvida com sucesso!', 'success'))
                .then(onFinish)
                .then(closeModal)
                .catch((err) => setAlert(err.response.data.message
                    || 'Não foi possível resolver pendência!'))
        );
    }

    const getPendingResolutionContent = () => {

        if (missingField)
            return (
                <div className="mb-5">
                    {requestFieldsConfig.getComponentField({
                        field: missingField,
                        request: requestChanges,
                        setValue: (field, value) => setRequestChanges({ ...requestChanges, [field]: value }),
                        isEdit: true,
                        setAlert
                    })}
                </div>
            )

        if (isLoading)
            return (
                <div className="flex justify-center">
                    <CircularProgress color="secondary" size={40} />
                </div>
            )

        return (
            <>

                <Grid style={{ marginBottom: 30 }}>
                    <PendingSolveDocumentForm
                        request={request}
                        newFiles={newFiles}
                        setNewFiles={setNewFiles}
                        setAlert={setAlert}
                        isLoading={promiseInProgress}
                    />
                </Grid>

                <Divider style={{ margin: "24px 0" }} />

                <Grid style={{ marginBottom: 20 }}>
                    <Grid
                        container alignItems='center'
                        style={{ marginBottom: 10 }}
                    >
                        <CommentOutlined style={{ fontSize: 18, marginRight: 5 }} />

                        <Typography variant='h6'>
                            Adicionar observação
                        </Typography>
                    </Grid>


                    <Grid style={{ flexGrow: 1 }}>
                        <TextField
                            value={observation} id="commentText" multiline
                            onChange={(e) => setObservation(e.target.value)}
                            placeholder="Escreva sua observação aqui"
                            variant="outlined" fullWidth
                        />
                    </Grid>
                </Grid>

            </>
        )
    }

    const loading = () =>
        promiseInProgress && (
            <CircularProgress color="secondary" size={30} />
        )

    return (
        <>
            <SimpleModal
                open={isOpen}
                title={`Resolver Pendência - ID ${request.mainId || request.id}`}
                onClose={closeModal}
                maxWidth="sm"
            >
                {!!request.pendingReason && (
                    <div className="mb-6">
                        <div className="text-sm text-[#0007]">
                            Motivo da pendência:
                        </div>
                        <div className="text-sm mt-2">
                            {request.pendingReason}
                        </div>
                    </div>
                )}

                {!loginService.isInternal() && !missingField && (
                    <Alert
                        severity="warning"
                        variant="outlined"
                        className="!bg-[#faad1419]"
                        style={{
                            border: 'solid 1px #faad14',
                        }}
                    >
                        Anexe um documento e/ou adicione uma observação com a
                        informação solicitada para resolver a pendência e, assim,
                        conseguirmos prosseguir com sua solciitação!
                    </Alert>
                )}

                <Divider style={{ margin: "24px 0" }} />

                {getPendingResolutionContent()}

                <Grid container justifyContent='flex-end'>
                    <Button
                        color='primary' variant='contained'
                        disabled={promiseInProgress || isLoading}
                        onClick={() => handleResolvePending(request.id, newFiles, observation, setAlert)}
                    >
                        {promiseInProgress ? loading() : 'Resolver pendência'}
                    </Button>
                </Grid>
            </SimpleModal>
        </>
    );
}

export default PendingSolveModal;