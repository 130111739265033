import { Button, Dialog, DialogTitle, Divider, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
export default function RequestHearingAdditionalModal({
    onFinish,
    open,
    onClose
}) {
    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <div style={{
                    display: 'flex',
                    justifyContent: 'end',
                }}>
                    <IconButton aria-label="close" onClick={onClose} size='small'>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0 24px 24px',
                gap: 24,
                textAlign: 'center',
            }}>
                <Typography variant="h3">🌟Aprimore sua estratégia processual!🌟</Typography>
                <Typography variant="subtitle1">Adicione agora o serviço de análise de audiência.</Typography>
                <Typography variant="subtitle1">Com isso, nosso advogado responsável se dedicará a estudar e elaborar todos os quesitos e apontamentos relevantes da audiência.</Typography>
                <Typography variant="subtitle1">
                    Este serviço é a chave para tornar o ato mais célere e efetivo, aumentando significativamente suas chances de êxito.
                </Typography>
                <Divider style={{ width: '100%' }} />
                <Button style={{ width: '100%' }} onClick={onFinish} variant="contained" color="primary">
                    Adicionar Análise de Audiência
                </Button>
                <Button style={{ width: '100%' }} onClick={onClose} variant="outlined">
                    Não, obrigado. Eu farei os direcionamentos ao faciliter audiencista no cadastro
                </Button>
            </div>
        </Dialog>
    );
}
