import { Grid } from '@material-ui/core';

import SelectMembers from '../solicitations/select.members.component';
import SelectAnalyst from '../solicitations/select.analyst.component';
import SelectFaciliterReviewer from '../solicitations/select.faciliter.reviewer.component';
import SelectReviewers from '../solicitations/select.reviewers.component';

function RequestResponsibleComponent({ request, refresh }) {

    return (
        <Grid container>
            <SelectMembers
                requestId={request.id}
                requestInfo={{ city:request.city, serviceId: request.serviceId }}
                serviceId={request.serviceId}
                step={request.status}
                members={request.members}
                onRefreshRequest={refresh}
            />

            <SelectReviewers
                type={request.type}
                requestId={request.id}
                reviewers={request.reviewers}
            />

            <SelectFaciliterReviewer
                requestId={request.id}
                faciliterReviewer={(request.faciliterReviewers || [])[0]}
                operationalDueDate={request.operationalDueDate}
                clientDueDate={request.dueDate}
                callback={refresh}
            />

            <SelectAnalyst
                type={request.type}
                requestId={request.id}
                analysts={request.analysts}
                onRefreshRequest={refresh}
            />

        </Grid>
    );
}

export default RequestResponsibleComponent;
