import React from "react";
import styled from "styled-components/macro";

import {
    CardContent,
    Card as MuiCard,
    Typography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import loginService from "../domain/services/login.service";
const Card = styled(MuiCard)(spacing);

function EmptyList(props) {

    const getListDetails = () => {
        if (props.type == "package")
            return `Não encontramos nenhum pacote ${props.packageStatus} para o Dr(a).`;

        return "nenhum resultado encontrado...";
    }

    return (
        <>
            {!loginService.isCustomer() ?
                <Card mb={12} xs={12} style={{ marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom> Não há registros </Typography>
                        <Typography variant="body2" gutterBottom> nenhum resultado encontrado... </Typography>
                    </CardContent>
                </Card>
                :
                <Card mb={12} xs={12} style={{ marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom> Não há registros </Typography>
                        <Typography variant="body2" gutterBottom> {getListDetails()} </Typography>
                    </CardContent>
                </Card>
            }
        </>

    );
}

export default EmptyList;

