import customerService from "../customer/customer.service"
import CorrespondentService from "../correspondent.service";
import loginService from "../login.service"

import { officeCollaboratorsRange, purchaseReasons } from "../../../domain/constant/client.register.required.fields";

const save = (customerId, questions) =>
    customerService
        .edit(customerId, { profileQuestions: questions })
        .then(() => updateAuthentication(questions))
        .then(() => loginService.isInternal() && window.location.reload());

const updateAuthentication = (questions) => {

    if (!loginService.isCustomer())
        return;

    const user = loginService.getUserAuthentication();

    loginService.refreshUserAuthentication({ ...user, customer: { ...user.customer, profileQuestions: questions } })
}

const getAreas = async () =>
    CorrespondentService
        .getAreas()
        .then(res => (res.data || []).filter(s => !s.parentId))
        .catch(() => ([]));

const getFormFields = () => [
    { name: 'occupationArea', type: 'select', multiple: true, options: '' },
    { name: 'delegationAreas', type: 'select', multiple: true, options: '' },
    { name: 'totalCollaborators', type: 'select', options: officeCollaboratorsRange },
    { name: 'reason', type: 'select', options: purchaseReasons },
    { name: 'delegationAllServices', type: 'text', },
    { name: 'whyFacilita', type: 'text', },
    { name: 'socialMedia', type: 'text', },
];

const getVariableCopy = (item, socialMediaLabel) => ({
    title: getCopyByUserSource('Olá Dr(a). ', 'Perfil do escritório - '),
    occupationAreaLabel: getCopyByUserSource(
        'Quais as áreas de atuação do seu escritório?',
        'Áreas de atuação do escritório'
    ),
    occupationAreaDescription: getCopyByUserSource('Áreas de atuação do seu escritório'),
    delegationAreasLabel: getCopyByUserSource(
        'Quais áreas você pretende delegar para a Facilita Jurídico?',
        'Áreas que pretede delegar para Facilita Jurídico'
    ),
    delegationAreasDescription: getCopyByUserSource('Áreas que deseja nos delegar'),
    totalCollaboratorsLabel: getCopyByUserSource(
        'Você trabalha sozinho? Quantas pessoas trabalham em seu escritório?',
        'Quantas pessoas trabalham no escritório'),
    totalCollaboratorsDescription: getCopyByUserSource('Selecione quantas pessoas trabalham em seu escritório'),
    reasonLabel: getCopyByUserSource('Qual foi o principal motivo da sua compra?', 'Principal motivo da compra'),
    reasonDescription: getCopyByUserSource('Selecione o principal motivo da sua compra'),
    delegationAllServicesLabel: getCopyByUserSource(
        'Você pretende delegar toda a sua demanda de petições para a Facilita Jurídico?',
        'Pretende delegar todos os serviços para a FacilitaJurídico'
    ),
    delegationAllServicesDescription: getCopyByUserSource('Descreva se pretende delegar toda a sua demanda'),
    socialMediaLabel: getCopyByUserSource(socialMediaLabel, 'Redes sociais'),
    socialMediaDescription: getCopyByUserSource('Ex: Instagram - @advogado'),
    whyFacilitaLabel: getCopyByUserSource('Por que você escolheu nossa solução?', 'Por que escolheu nossa solução.'),
    whyFacilitaDescription: getCopyByUserSource('Comente o que te motivou a escolher a plataforma da Facilita Jurídico'),
})[item];

const getCopyByUserSource = (externalCopy, internalCopy = '') =>
    loginService.isInternal() ? internalCopy : externalCopy;

const getMissingAnswers = (answeredQuestions) =>
    getFormFields().filter(field => !answeredQuestions[field.name] || (Array.isArray(answeredQuestions[field.name]) && !answeredQuestions[field.name].length));

const getQuestionsToShow = (profileAnswers, filterAnswered = true) =>
    (loginService.isInternal() || !filterAnswered || !profileAnswers) ? getFormFields() : getMissingAnswers(profileAnswers);

export default {
    save,
    getAreas,
    getQuestionsToShow,
    getVariableCopy,
    getMissingAnswers
}