
import React from "react";

import Async from "../../components/Async";

import { SupervisedUserCircle } from "@material-ui/icons";

const AuthGuard = Async(() => import("../auth.guard"));

const Employee = Async(() => import('../../pages/admin/employees/employee'));

const routes = {
    employess: {
        id: 'employess',
        name: 'Colaboradores',
        path: '/employess',
        component: Employee,
        guard: AuthGuard,
        icon: <SupervisedUserCircle />,
        roles: ['admin'],
    }
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}