import repository from '../../repositories/request/request.repository';

import { getMissingFields } from '../../../components/providers/utils.provider';
import requestValidator from '../../validators/request.validator';

import solicitationService from '../solicitation/solicitation.service';
import requestRegistryService from '../solicitation/request.registry.service';

import requestFieldsConfig from '../../../components/requests/fields/request.fields.config';
import modalities from '../../constant/modalities.constant';

const findAllAvailableCitiesByState = (requestId, stateSigla) => repository.findAllAvailableCitiesByState(requestId, stateSigla);

const findNewRequest = async (mainId) =>
    repository
        .findNewRequest(mainId)
        .then(res => res.data || {});

const storageRegister = (data) =>
    localStorage.setItem('@facilita-app/request-register', JSON.stringify(data));

const getstoragedRegister = () =>
    JSON.parse(localStorage.getItem('@facilita-app/request-register'));

const clearStoragedRegister = () =>
    localStorage.removeItem('@facilita-app/request-register');

const getRequiredFieldsToSave = (dynamicFields) => {

    const basicFields = ['areaId', 'modalityId', 'serviceTypeId', 'serviceId', 'parts'];

    let allDynamicFields = [];
    Object.values(dynamicFields).forEach(fields => allDynamicFields = [...allDynamicFields, ...fields]);

    const requiredDynamicFields = allDynamicFields.filter(df => df.required).map(df => df.key);

    return [...basicFields, ...requiredDynamicFields];
}

const checkFieldsToSave = (request, dynamicFields) => {

    const requiredFields = getRequiredFieldsToSave(dynamicFields);

    const completedDynamicFields = {};
    Object.keys(request.fields || {}).forEach(key => completedDynamicFields[key] = request.fields[key].value);

    const completedFields = { ...request, ...completedDynamicFields };

    return getMissingFields(completedFields, requiredFields);
}

const changeServiceToPremium = (requestId) => repository.changeServiceToPremium(requestId);

const removePremiumServiceFromRequest = (requestId) => repository.removePremiumServiceFromRequest(requestId);

const getRequestDetails = async (id) => {

    const res = await Promise.all([
        solicitationService.find(id),
        requestRegistryService.getDetailsFields(id),
    ]);

    const requestValue = (res[0].data || {});
    const fields = (res[1].data || []);

    return {
        ...requestValue,
        operationalDueDate: requestValidator.convertDate(requestValue.operationalDueDate),
        servicePublishedAt: requestValidator.convertDate(requestValue.servicePublishedAt),
        citationDate: requestValidator.convertDate(requestValue.citationDate),
        fields: requestRegistryService.integrateFields({ ...requestValue, processNumber: null }, fields),
        parts: requestValue.newFormatParts || [],
    }
}

const findPricesByMainId = (mainId) =>
    repository
        .findPricesByMainId(mainId)
        .then(res => res.data);

const getMenuOptions = async (requestId) =>
    repository
        .findMenuOptions(requestId)
        .then(mapMenuOptions);

const getMenuOptionComponent = (option) => ({
    ...option,
    ...requestFieldsConfig.componentByMenuOption[option.name]
});

const mapMenuOptions = ({ data }) =>
    (data || []).map(itemMenu => {

        if (!itemMenu.subOptions)
            return getMenuOptionComponent(itemMenu);

        const subOptions = itemMenu.subOptions.map(getMenuOptionComponent);

        return { ...itemMenu, subOptions };
    });

const editRequestAndFields = async (request, isRegisteringRequest = false) => {

    const { id, fields = {}, parts, ...requestInfo } = request;

    const hasRequestInfoToEdit = Object.keys(requestInfo).length > 0;

    const dynamicFieldsData = Object.values(fields).map(field => ({ ...field, fieldId: field.id }));

    const editMethods = {
        requestEditMethod: {
            register: () => requestRegistryService.editValue({ id, ...requestInfo }),
            edit: () => solicitationService.editValue(id, requestInfo)
        },
        fieldEditMehotd: {
            register: () => requestRegistryService.saveDynamicFields(request.id, dynamicFieldsData),
            edit: () => requestRegistryService.saveDynamicFields(request.id, dynamicFieldsData)
        }
    }

    const actionKey = isRegisteringRequest ? 'register' : 'edit';

    const editRequest = editMethods.requestEditMethod[actionKey];
    if (hasRequestInfoToEdit)
        await editRequest();

    const editFields = editMethods.fieldEditMehotd[actionKey];
    if (dynamicFieldsData.length > 0)
        await editFields();
}

const hasRequiredProcessNumber = (modalityKey) =>
    [
        modalities.hearingAnalysis.key,
        modalities.hearing.key,
        modalities.contestation.key,
        modalities.manifestation.key,
        modalities.resources.key,
        modalities.counterReasons.key
    ].includes(modalityKey)

export default {
    findNewRequest,
    storageRegister,
    getstoragedRegister,
    clearStoragedRegister,
    checkFieldsToSave,
    getRequestDetails,
    findPricesByMainId,
    getMenuOptions,
    editRequestAndFields,
    hasRequiredProcessNumber,
    findAllAvailableCitiesByState,
    changeServiceToPremium,
    removePremiumServiceFromRequest
}