import { useEffect, useState } from "react";
import Card from "../../../shared/Card";
import Form from "../../../shared/form/Form";
import SnackbarAlert from "../../../shared/SnackbarAlert";
import Switch from "../../../shared/Switch";
import SaveChangesFooter from "./saveChangesFooter";
import { useBlog } from "../../../../context/marketing/blogContext";
import useForm from "../../../../hooks/useForm";
import Row from "../../../shared/grid/Row";
import Col from "../../../shared/grid/Col";
import FormItem from "../../../shared/form/FormItem";
import Input from "../../../shared/Input";
import Textarea from "../../../shared/Textarea";
import CustomSelect from "../../../shared/CustomSelect";
import useRequest from "../../../../hooks/useRequest";
import BlogsService from "../../../../domain/services/marketing/blog.service";
import BlogHeaderCta from "../header/blogHeaderCta";
import DragAndDropFile from "../../../shared/DragAndDropFile";

export default function BlogHeaderConfig() {

    const mainInfoForm = useForm();
    const headerForm = useForm();

    const { updateChanges, changes, saveChanges, blogData, loading, setBlogData } = useBlog();

    const [findTags, findingTags] = useRequest(BlogsService.findBlogTags);
    const [updateCover, updatingCover] = useRequest(BlogsService.updateBlogCover);

    const [includeHeaderCta, setIncludeHeaderCta] = useState(!!blogData.headerCta);
    const [tags, setTags] = useState([]);
    const [coverImage, setCoverImage] = useState(blogData.coverImage?.url);

    const initialValues = {
        title: blogData.title,
        tags: blogData.tags,
    }

    const headerCtaInitialValues = {
        title: blogData.headerCta?.title || null,
        buttonLabel: blogData.headerCta?.buttonLabel || null,
        buttonUrl: blogData.headerCta?.buttonUrl || null,
    };

    const updateHeaderCtaInfo = (changedFields, formValues) => {
        updateChanges('headerCta', formValues);
    }

    const updateMainInfo = (changedFields) => {
        const [key, value] = Object.entries(changedFields)[0];
        updateChanges(key, value);
    }

    const handleAddNewTag = (label) => {
        setTags(prev => [
            ...prev,
            {
                label,
                value: label.toLowerCase(),
            }
        ]);
    }

    const handleSave = () => {
        mainInfoForm.validateFields()
            .then(() => includeHeaderCta && headerForm.validateFields())
            .then(saveChanges)
            .then(() => SnackbarAlert.success('Alterações salvas com sucesso!'))
            .catch(() => { });
    }

    const validateImage = (file) => {
        const validTypes = [".jpg", ".jpeg", ".png"];
        const isValid = validTypes.some(ext => file.name.includes(ext));
        return isValid;
    }

    const handleImageUpload = async (file) => {

        const isValid = validateImage(file);
        if (!isValid) return;

        updateCover(file, blogData.id)
            .then(resp => {
                const newImage = resp.coverImage;
                setCoverImage(newImage.url);
                setBlogData(prev => ({
                    ...prev,
                    coverImage: newImage
                }))
                SnackbarAlert.success('Imagem de Capa atualizada com sucesso!')
            })
            .catch(() => SnackbarAlert.error('Ocorreu um problema ao atualizar a imagem de capa, por favor tente novamente'))
    };

    const handleClickSectionChange = (newState) => {
        setIncludeHeaderCta(newState);

        if (newState)
            return;

        updateChanges('headerCta', null);
    }

    useEffect(() => {
        findTags()
            .then(res => setTags(res.map(tag => ({
                value: tag,
                label: `${tag[0].toUpperCase()}${tag.slice(1)}`,
            }))));
    }, []);

    return (
        <div className="w-full">
            <Card
                title="Configurações Principais"
            >
                <Form
                    onFieldChange={updateMainInfo}
                    form={mainInfoForm}
                    initialValues={initialValues}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24} lg={12} xl={12}>
                            <FormItem
                                name="title"
                                label="Título do Artigo"
                            >
                                <Textarea
                                    placeholder="Título do Artigo"
                                />
                            </FormItem>
                        </Col>
                        <Col span={24} lg={12} xl={12}>
                            <FormItem
                                name="tags"
                                label="Categorias"
                            >
                                <CustomSelect
                                    placeholder="Selecione as categorias"
                                    emptyText="Nenhuma categoria encontrada. Cadastre uma nova categoria"
                                    loading={findingTags}
                                    options={tags}
                                    allowClear
                                    multiSelect
                                    allowSearch
                                    onAddNewItem={handleAddNewTag}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Row className="mt-6">
                    <Col span={24} lg={16} xl={16}>
                        <FormItem label="Imagem de Capa">
                            <DragAndDropFile
                                handleFile={handleImageUpload}
                                value={coverImage}
                                isImage
                                loading={updatingCover}
                            />
                        </FormItem>
                    </Col>
                </Row>

            </Card>
            <Card
                className="mt-6"
                title="Seção de Clique"
                extra={(
                    <Switch onChange={handleClickSectionChange} initialState={!!blogData?.headerCta} />
                )}
            >
                {includeHeaderCta && (
                    <>
                        <Form
                            form={headerForm}
                            onFieldChange={updateHeaderCtaInfo}
                            initialValues={headerCtaInitialValues}
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <FormItem
                                        name="title"
                                        label="Texto Principal"
                                        required
                                    >
                                        <Input placeholder="Insira o texto" />
                                    </FormItem>
                                </Col>
                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="buttonLabel"
                                        label="Texto do Botão"
                                        required
                                    >
                                        <Input placeholder="Insira o texto do botão" />
                                    </FormItem>
                                </Col>
                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="buttonUrl"
                                        label="Link do Botão"
                                        required
                                    >
                                        <Input placeholder="https://facilitajuridico.com.br/" />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>

                        <div className="text-base mt-6 mb-2 text-[#999]">
                            Preview:
                        </div>

                        <div className="flex justify-center">
                            <div className="w-full max-w-3xl py-3 px-4 rounded-lg bg-[#fafafa]">

                                <BlogHeaderCta
                                    title={changes.headerCta?.title || headerCtaInitialValues.title}
                                    buttonLabel={changes.headerCta?.buttonLabel || headerCtaInitialValues.buttonLabel}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Card>

            <SaveChangesFooter
                onSave={handleSave}
                showCancel={false}
                hasChanges={!!Object.keys(changes).length}
                loading={loading.editingBlog}
            />

        </div>
    )
}