import { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import swal from 'sweetalert';

import ProgressDeterminate from '../../components/ProgressDeterminate';

import {
    Button, Grid, List, ListItem, ListItemText, ListItemIcon, Typography, Divider,
    Avatar, Dialog, DialogContent as MuiDialogContent, DialogTitle, DialogActions,
    Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CircularProgress
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AttachFileOutlined, ExpandMore, Error } from "@material-ui/icons";

import styled from "styled-components";

import documentsService from "../../domain/services/solicitation/documents.service";
import loginService from "../../domain/services/login.service";

import { stepsSolicitation } from "../../domain/constant/steps.solicitation.constant";

const DialogContent = styled(MuiDialogContent)`

    .MuiAccordion-root.Mui-expanded,
    .MuiAccordionSummary-content.Mui-expanded {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

`;

function CorruptedDocumentsModal({ isOpen, request, onClose, alert, editCheckPending }) {

    const [documents, setDocuments] = useState({ corrupted: [], clean: [] });
    const [expanded, setExpanded] = useState(true);
    const [isSaving, setSaving] = useState(false);

    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => getCorruptedFiles(request), []);

    const getCorruptedFiles = (request) => {
        documentsService.find(request.id)
            .then(res => handleSetFiles(res.data))
            .catch(() => onClose());
    }

    const handleSetFiles = (documents) => {

        const corruptedFiles = [];
        const cleanFiles = [];

        documents.forEach(element => element.verificationInfo.success ?
            cleanFiles.push(element)
            : corruptedFiles.push(element)
        );

        if (!corruptedFiles.length) {
            onClose();
            editCheckPending();
            return;
        }

        cleanFiles.length ? setExpanded(true) : setExpanded(false);

        setDocuments({ ...documents, corrupted: corruptedFiles, clean: cleanFiles });
    }

    const checkUpload = async (requestId, response) => {

        const documentIds = (response || []).map(item => item.document.id);

        if (documentIds.length) {
            await documentsService.checkUpload(requestId, documentIds, false)
                .then(alert("Upload Concluído", "success"))
                .catch(error => alert(error.response.data.message, "error"));
        }
    }

    const removeDocuments = async (files) =>
        Promise.all(files.map(element =>
            documentsService.remove(element)
        )).catch(() => alert('Erro ao remover arquivos corrompidos.', "error"));

    const getRepeatedFiles = (newFiles) => {

        const formatedFiles = [...Array.from(newFiles)];

        return formatedFiles.filter(element => !documents.clean.some(doc => doc.name === element.name));
    }

    const replaceFile = async (newFile, oldFile, request) => {

        if (!newFile.length)
            return;

        setSaving(true);

        const formatNewFile = getRepeatedFiles(newFile);

        await trackPromise(handleManageDocuments(oldFile, formatNewFile, request));
    }

    const handleManageDocuments = async (oldFiles, newFiles, request) =>
        removeDocuments(oldFiles)
            .then(() => documentsService.saveAll(request.id, newFiles, true))
            .then(async (res) => {
                await checkUpload(request.id, res);
                getCorruptedFiles(request, true);
            })
            .finally(() => setSaving(false))
            .catch(() => getCorruptedFiles(request));


    const onConfirmCorruptedFiles = () => {
        swal({
            title: 'Realmente deseja continuar?', icon: 'warning',
            text: `
                Todos os documentos corrompidos serão excluídos permanentemente. ${getPendingMessage()}
            `,
            buttons: {
                cancel: 'Voltar', confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' },
            }
        }).then(async res => {
            if (res) {
                await removeDocuments(documents.corrupted);
                onClose();
            }
        });
    }

    const getPendingMessage = () => {

        const showMessage = (request.status == stepsSolicitation.PENDING && loginService.isCustomer() && !!editCheckPending);

        return showMessage ? 'As pendências não serão resolvidas!' : '';
    }

    const loading = () => {
        return (
            promiseInProgress &&
            <Card mb={12} xs={12} style={{ marginLeft: "-10px", marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                <CardContent style={{ display: "-webkit-inline-box", paddingTop: "20px", paddingBottom: "20px" }}>
                    <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                    <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                        <Typography variant="h6" gutterBottom> Aguarde </Typography>
                        <Typography variant="body2" gutterBottom> Carregando informações... </Typography>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    return (
        <Dialog open={isOpen}
            disableBackdropClick disableEscapeKeyDown
            maxWidth="xs" fullWidth
        >
            <DialogTitle>Anexo de documentos</DialogTitle>

            <DialogContent>
                {
                    !editCheckPending &&
                    <Alert variant="outlined" severity="info" style={{ fontSize: "11px", padding: "2px 0 0 10px", alignItems: 'center' }}>
                        <b>Sua solicitação foi salva.</b> ID: {request.id} <br />
                        Mas é importante que substitua os arquivos corrompidos para que ela seja entregue no prazo.
                    </Alert>
                }

                {loading()}

                <Grid container style={{ marginTop: "10px", alignItems: "center" }} >
                    <Typography style={{ width: "auto" }}>
                        Arquivos Corrompidos: {documents.corrupted.length} de {documents.corrupted.length + documents.clean.length}
                    </Typography>
                </Grid>

                <Divider my={6} style={{ marginTop: "10px" }} />


                {(promiseInProgress || !documents.clean.length) ||
                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid container md={6} xs={6} style={{ padding: "0 0 0 2px" }}>
                                    <Error style={{ fontSize: "18px", marginRight: "10px", color: "#ff9800" }} />
                                    <Typography style={{ width: "auto" }}>Arquivos corrompidos</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails style={{ borderTop: "1px solid #d8d8d8", display: "block" }}>
                            <List component="nav" dense={true}
                                style={{ width: "100%", maxHeight: "200px", overflow: "auto", borderBottom: "1px solid #e0e0e0", display: "block" }}>
                                {
                                    documents.corrupted.map((item, i) => (
                                        <ListItem button style={{ background: "white", paddingLeft: "0", paddingRight: "0" }} key={i}>
                                            <ListItemIcon>
                                                <Avatar alt={item.name}
                                                    style={{ background: "#ff0000a6", color: "white", width: "24px", height: "24px", fontSize: "10px", fontWeight: 600 }}>
                                                    <AttachFileOutlined style={{ fontSize: "16px" }} />
                                                </Avatar>
                                            </ListItemIcon>
                                            <Grid container justify="flex-start">
                                                <ListItemText style={{ flex: "5 1 auto" }} size="small"
                                                    primary={`${item.name} - Arquivo Corrompido`} />
                                            </Grid>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </AccordionDetails>
                    </Accordion>
                }

            </DialogContent>

            <ProgressDeterminate style={{ display: "block" }} />

            <DialogActions>
                <Button variant="text" disabled={(promiseInProgress || isSaving)} onClick={onConfirmCorruptedFiles}>
                    Não substituir
                </Button>
                <>
                    <input style={{ 'display': 'none' }} id="allFiles" type="file" multiple
                        onChange={(e) => replaceFile(e.target.files, documents.corrupted, request)} />
                    <label htmlFor="allFiles">
                        <Button variant="text" color="primary" component="span"
                            disabled={(promiseInProgress || isSaving)} style={{ height: "40px" }}
                        >
                            Substituir
                        </Button>
                    </label>
                </>
            </DialogActions>
        </Dialog>
    );
}

export default CorruptedDocumentsModal;