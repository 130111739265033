import commentsService from "../solicitation/comments.service";
import requestStatusService from "./request.status.service";
import requestService from "../request/request.service";

const handleResolvePending = async (requestId, observation, requestChanges) => {

    await checkToSaveComment(requestId, observation);
    await checkToSaveFields(requestId, requestChanges);

    return requestStatusService.resolvePending(requestId);
}

const checkToSaveComment = async (requestId, observation) => {

    if (!observation.length)
        return;

    return commentsService.save({ description: observation, requestId });
}

const checkToSaveFields = async (requestId, requestChanges) => {

    if (!Object.keys(requestChanges).length)
        return;

    return requestService.editRequestAndFields({ id: requestId, ...requestChanges });
}

export default {
    handleResolvePending
}