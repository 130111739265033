import {
    Grid,
    List, ListItem as MuiListItem, ListItemText as MuiListItemText
} from "@material-ui/core";
import React from "react";
import styled from "styled-components/macro";


const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: #F7F9FC;
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
    return (
        <Wrapper>
            <Grid container spacing={0}>
                <Grid container item xs={12} justifyContent="center">
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={`© ${new Date().getFullYear()} - Facilita Jurídico - Versão: 4.2.0`}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default Footer;
