
import Async from "../../components/Async";

const AuthGuard = Async(() => import("../auth.guard"));

const Login = Async(() => import('../../pages/login/login'));
const UserAccessLink = Async(() => import('../../pages/login/user.access.link'));
const ResetPassword = Async(() => import('../../pages/login/reset.password'));
const ChangePassword = Async(() => import('../../pages/login/change.password'));

const routes = {
    login: {
        id: 'login',
        path: '/login',
        component: Login
    },
    resetPassword: {
        id: 'resetPassword',
        path: '/login/resetPassword',
        component: ResetPassword
    },
    changePassword: {
        id: 'changePassword',
        path: '/login/changePassword',
        component: ChangePassword,
        guard: AuthGuard
    },
    userAccessLinkWithRef: {
        id: 'userAccessLink',
        path: "/login/:uuid/:ref",
        component: UserAccessLink
    },
    userAccessLink: {
        id: 'userAccessLink',
        path: "/login/:uuid",
        component: UserAccessLink
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}
