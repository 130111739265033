import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Description } from '@material-ui/icons';

function DocumentPreview(props) {
  const closeModal = () => {
    props.onSelect({}, false);
  };

  const getUrl = () =>
    props.file.name.indexOf('.doc') != -1 ||
    props.file.name.indexOf('.xls') != -1 ||
    props.file.name.indexOf('.ppt') != -1 ||
    props.file.name.indexOf('.dot') != -1 ||
    props.file.name.indexOf('.csv') != -1
      ? 'https://view.officeapps.live.com/op/embed.aspx?src=' + props.file.url
      : props.file.url;

  return (
    <Dialog open={props.file.show} onClose={closeModal} maxWidth='md' fullWidth>
      <DialogTitle>
        <Description style={{ marginBottom: '-4px', color: '#376fd0' }} /> Visualizar Arquivo
      </DialogTitle>
      <DialogContent>
        <iframe src={getUrl()} width='100%' height='400px' />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant='text'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentPreview;
