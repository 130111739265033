export const packages = {
    infinity: {
        id: 24,
        key: 'infinity',
        label: 'Infinity',
    },
    premium: {
        id: 23,
        key: 'premium',
        label: 'Premium',
    },
    start: {
        id: 20,
        key: 'start',
        label: 'Start',
    },
};