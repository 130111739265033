import { useState } from 'react';

import swal from 'sweetalert';

import { Button, CircularProgress } from '@material-ui/core';

import requestServicesService from '../../../domain/services/solicitation/request.services.service';

const ConvertAnalysisNotion = ({ mainRequestId, setAlert, setIsLoading, isLoading }) => {

    const [isSubmitting, setSubmitting] = useState(false);

    const confirmConversion = async () => {

        setIsLoading(true);
        setSubmitting(true);

        await requestServicesService.convertAnalysisToNotion(mainRequestId)
            .then((res) => onSuccess(res.data))
            .catch((error) => setAlert(error.response.data.message || "Erro ao atualizar modalidade do serviço, contate o suporte", 'warning'))
            .finally(() => {
                setIsLoading(false);
                setSubmitting(false);
            });
    }

    const checkConversion = () =>
        swal({
            title: "Deseja realizar a conversão da Indicação de Petição?",
            text: 'Se confirmar, será cobrado a diferença do valor do parecer',
            icon: "info",
            buttons: { confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' } }
        }).then((res) => {

            if (res)
                confirmConversion();
        });

    const onSuccess = () =>
        swal({
            title: "Serviço atualizado como Parecer!",
            text: `Você será redirecionado para o serviço`,
            icon: "success",
            buttons: { confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' } }
        }).then(() => {
            window.location.href = `/solicitations/${mainRequestId}`;
        });

    return (
        <Button
            variant="outlined" color="primary" style={{ fontWeight: 600 }}
            onClick={checkConversion} disabled={isLoading || isSubmitting}
        >
            {!isSubmitting ? "Converter para Parecer" :
                <span>
                    Atualizando serviço...
                    <CircularProgress style={{
                        marginLeft: 8, width: 16,
                        height: 16, color: 'greenyellow'
                    }} />
                </span>
            }
        </Button>
    );
}

export default ConvertAnalysisNotion;
