import { useEffect, useState } from "react";

import { Grid, Typography, Card, Chip, CardContent, Divider, Tooltip } from "@material-ui/core";
import { Edit, EditOutlined, Group } from "@material-ui/icons";
import { ArrowLeft, ArrowRight } from "react-feather";

import { usePromiseTracker, trackPromise } from 'react-promise-tracker';

import LoadingProgress from "../../LoadingProgress";
import ForbiddenPageCard from "../../ForbiddenPageCard";
import RequestFinancialEditionModal from "./request.financial.edition.modal.component";
import RequestFinancialRemoveDiscountModal from "./request.financial.remove.discount.modal.component";

import utilsProvider from "../../providers/utils.provider";

import loginService from "../../../domain/services/login.service";
import requestService from "../../../domain/services/request/request.service";

function RequestFinancial({ request, mainRequestId, setAlert, isSmall }) {

    const { promiseInProgress } = usePromiseTracker();

    const [pricesByService, setPricesByService] = useState([]);

    const [editionModal, setEditionModal] = useState({});
    const [removeDiscountModal, setRemoveDiscountModal] = useState({});

    useEffect(() => findPricesByService(mainRequestId), []);

    const findPricesByService = (mainId) =>
        trackPromise(
            requestService
                .findPricesByMainId(mainId)
                .then(setPricesByService)
                .catch(() => setAlert('Não foi possível encontrar os preços dos faciliters.', 'error'))
        );


    const getServiceIndexByType = {
        customer: ({ serviceId, type, billingId }) => pricesByService.findIndex(s => s.serviceId == serviceId && s.type == type && s.billingId == billingId),
        faciliter: ({ responsibleId }) => pricesByService.findIndex(s => s.responsibleId == responsibleId),
        faciliterReviewer: ({ responsibleId }) => pricesByService.findIndex(s => s.responsibleId == responsibleId)
    }

    const handleChange = (serviceId, type, newPrice, responsibleId, billingId) => {

        findPricesByService(mainRequestId);

        const serviceIndex = getServiceIndexByType[type]({ serviceId, responsibleId, type, billingId })


        if (serviceIndex < 0)
            return;

        const newPricesByService = [...pricesByService];

        newPricesByService[serviceIndex].amount = newPrice;

        setPricesByService(newPricesByService);
    }

    const openEditionModal = (price, type) => {

        const priceInfo = {
            billingIds: price.billingIds,
            price: price.individualPrice || price.amount,
            serviceId: price.serviceId,
            serviceName: price.serviceName,
            responsibleName: price.responsibleName,
            mainId: mainRequestId,
            requestId: price.requestId,
            responsibleId: price.responsibleId,
            type,
            label: price.label,
        }

        setEditionModal({ isOpen: true, priceInfo });
    }

    const openRemoveDiscountModal = (price) =>
        setRemoveDiscountModal({
            isOpen: true,
            data: {
                requestId: price.requestId,
                responsibleId: price.responsibleId,
                responsibleName: price.responsibleName,
                serviceName: price.serviceName
            }
        });

    const groupCustomerPrices = (priceList) => {
        const groupedPrices = priceList.reduce((list = [], price) => {
            const groupIndex = list.findIndex(group =>
                group.serviceId === price.serviceId &&
                group.packageId === price.packageId &&
                group.label === price.label
            );

            if (groupIndex === -1) {
                return [
                    ...list,
                    {
                        serviceId: price.serviceId,
                        packageId: price.packageId,
                        label: price.label,
                        individualPrice: price.amount,
                        amount: price.amount,
                        billingIds: [price.billingId],
                        packageDescription: price.packageDescription,
                        serviceName: price.serviceName,
                    }
                ]
            }

            list[groupIndex].amount += price.amount;
            list[groupIndex].billingIds.push(price.billingId);

            return list;

        }, []);

        const groupedByPackage = groupedPrices.reduce((list = [], price) => {
            const groupIndex = list.findIndex(group => group.packageId === price.packageId);

            if (groupIndex === -1) {
                return [
                    ...list,
                    {
                        packageId: price.packageId,
                        packageDescription: price.packageDescription,
                        services: [price],
                    }
                ];
            }

            list[groupIndex].services.push(price);

            return list;
        }, []);

        return groupedByPackage;
    }

    const getCustomerPrices = () => {

        const prices = pricesByService.filter(price => price.type == 'customer');

        if (prices.length == 0)
            return (
                <Grid item container alignItems="center" style={{ padding: '15px 20px 5px' }}>
                    <Group color="disabled" style={{ marginRight: 15 }} />
                    Não há débitos nessa solicitação.
                </Grid>
            )

        const groupedPrices = groupCustomerPrices(prices);

        return (
            <Grid container direction="column" spacing={6}>

                {groupedPrices.map((price, index) =>
                    <Grid item container direction="column" key={index}>
                        <Typography style={{ marginBottom: 15 }}>
                            <strong>{price.packageDescription}</strong>
                            <Tooltip placement="top" title={(<span className="text-sm">Id do Pacote</span>)}>
                                <span className="ml-2 px-2 py-[2px] rounded-lg border border-[#376fd0] bg-[#376fd022] text-xs text-[#264884]">
                                    #{price.packageId}
                                </span>
                            </Tooltip>

                        </Typography>
                        <Grid container direction="column" spacing={4} className="!ml-0 px-2 py-1 border-l border-[#ddd]">
                            {price.services.map(service => (

                                <Grid
                                    key={index} item container spacing={4}
                                    justifyContent="space-between" alignItems="center"
                                >

                                    <Grid item xs={5} container direction="column">

                                        <Typography style={{
                                            lineHeight: 1,
                                            fontSize: 'inherit'
                                        }}>
                                            {service.label} <span className="text-[#0007]">{service.billingIds.length}x</span>
                                        </Typography>

                                    </Grid>

                                    <Grid
                                        item xs={3} container direction="column"
                                        alignItems="center"
                                    >

                                        {service.amount == 0 ?
                                            'Grátis'
                                            :
                                            (utilsProvider.formatNumberCurrency(service.amount) || '-')
                                        }

                                    </Grid>

                                    <Grid
                                        item xs={4} container direction="column"
                                        style={{ marginTop: -5, gap: 6 }}
                                    >
                                        {!!service.serviceId && (
                                            <>
                                                <button
                                                    style={{ fontSize: 14, textAlign: 'center' }}
                                                    className="text-[#376fd0] flex items-center gap-1"
                                                    onClick={(e) => {
                                                        openEditionModal(service, 'customer');
                                                    }}
                                                >
                                                    <EditOutlined className="!text-base" />
                                                    editar
                                                </button>
                                            </>
                                        )}
                                    </ Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={8}>
                    <Divider />
                </Grid>

                <Grid item container spacing={1} alignItems="center" xs={8}>

                    <Grid item xs style={{ marginRight: '3%' }}>
                        <Typography align="right" color="textSecondary" style={{ lineHeight: 1.1 }}>
                            Valor total do cliente:
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography align="right">
                            <strong>
                                {utilsProvider.formatNumberCurrency(
                                    prices.reduce((acc, servicePrices) =>
                                        acc += (parseFloat(servicePrices.amount) || 0), 0
                                    )
                                )}
                            </strong>
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>
        )
    }

    const getFacilitersPricesByService = () => {

        const user = loginService.getUserAuthentication();

        const servicesWithFaciliters = pricesByService
            .filter(s => ['faciliter', 'faciliterReviewer'].includes(s.type) && s.responsibleUserId != user?.id);

        if (!servicesWithFaciliters.length)
            return (
                <Grid item container alignItems="center" style={{ padding: '15px 20px 5px' }}>
                    <Group color="disabled" style={{ marginRight: 15 }} />
                    Não há faciliters vinculados aos serviços.
                </Grid>
            )

        return servicesWithFaciliters.map((price, index) => (
            <Grid item container direction="column" key={index}>

                <Typography style={{ marginBottom: 15 }}>

                    <strong>{(price.label || price.serviceName)}</strong>

                </Typography>

                <div className="flex flex-col ml-0 p-2.5 border-l border-[#dddddd] justify-end">
                    <div className="flex justify-between items-center pr-10">
                        <div className="flex flex-col">
                            <Typography
                                gutterBottom
                                color="textSecondary"
                                className="tracking-wider"
                            >
                                {price.isReviewer ? 'REVISOR' : 'FACILITER'}
                            </Typography>

                            <Typography className="text-xs leading-none">
                                {price.responsibleName}
                            </Typography>
                        </div>

                        <div className="flex flex-col items-end w-1/4">
                            {(price.priceFees || []).length > 0 ? (
                                <div className="flex flex-col gap-1 items-between min-w-[200px]">
                                    {price.priceFees.map((fee, index) => (
                                        <div key={index} className="flex justify-between">
                                            <p className="text-xs text-gray-600">
                                                {fee.label}
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                {utilsProvider.formatNumberCurrency(fee.price)}
                                            </p>
                                        </div>
                                    ))}
                                    <Divider style={{ margin: '5px 0' }} />
                                </div>
                            ) : null}

                            <p className="text-xs text-center">
                                Total: {utilsProvider.formatNumberCurrency(price.amount)}
                            </p>

                            {price.tipValue ? (
                                <p className="text-xs text-center text-gray-600">
                                    + Gorjeta: {utilsProvider.formatNumberCurrency(price.tipValue)}
                                </p>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            {!!price.serviceId && (
                                <>
                                    <button
                                        className="text-sm text-center text-[#376fd0] flex items-center gap-1"
                                        onClick={(e) => {
                                            openEditionModal(price, price.type);
                                        }}
                                    >
                                        <EditOutlined className="!text-base" />
                                        editar
                                    </button>
                                </>
                            )}

                            {price.isPriceDiscounted && (
                                <a
                                    href="#"
                                    className="text-sm text-center leading-tight text-primary"
                                    onClick={(e) => {
                                        openRemoveDiscountModal(price, 'faciliter');
                                        e.preventDefault();
                                    }}
                                >
                                    remover desconto
                                </a>
                            )}
                        </div>
                    </div>
                </div>

            </Grid>
        ));
    }

    const financialTypes = [
        {
            label: 'Entradas',
            description: 'Valores pagos pelo cliente',
            Icon: ArrowRight,
            iconColor: 'green',
            validator: () => loginService.hasPermissionRole('admin', loginService.getUserAuthentication()),
            component: () => getCustomerPrices()
        },
        {
            label: 'Saídas',
            description: 'Valores pagos aos faciliters',
            Icon: ArrowLeft,
            iconColor: 'red',
            component: () => (
                <Grid container direction="column" spacing={6}>
                    {getFacilitersPricesByService()}
                </Grid>
            )
        }
    ]

    const checkIsFaciliterReviewer = () => {
        const user = loginService.getUserAuthentication();
        return (request.faciliterReviewers || [])[0]?.userId == user.id;
    }

    if (promiseInProgress || !request.id)
        return (
            <LoadingProgress />
        )

    if (!loginService.isInternal() && !checkIsFaciliterReviewer())
        return (
            <ForbiddenPageCard />
        )

    return (
        <>

            <Grid container direction='column'>
                {
                    financialTypes
                        .filter(ft => !ft.validator || ft.validator())
                        .map((item, index, arr) => (
                            <Grid
                                key={index} item container spacing={6}
                                justifyContent='space-between'
                                direction={isSmall ? 'column' : 'row'}
                                style={{
                                    marginTop: !!index && arr.length > 0 && (isSmall ? 20 : 60)
                                }}
                            >

                                <Grid item xs={isSmall ? 12 : 3}>

                                    <Typography variant='h4' gutterBottom>
                                        <Grid container alignItems="center">

                                            <item.Icon style={{
                                                marginRight: 10,
                                                color: item.iconColor
                                            }} />

                                            {item.label}

                                        </Grid>
                                    </Typography>

                                    <Typography style={{ color: 'gray' }}>
                                        {item.description}
                                    </Typography>

                                </Grid>

                                <Grid item xs={isSmall ? 12 : 8}>
                                    <Card style={{ width: '100%' }}>
                                        <CardContent>
                                            {item.component()}
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>
                        ))
                }
            </Grid >

            <RequestFinancialEditionModal
                isOpen={editionModal.isOpen}
                priceInfo={editionModal.priceInfo || {}}
                onSave={handleChange}
                handleClose={() => setEditionModal({ ...editionModal, isOpen: false })}
                setAlert={setAlert}
            />

            <RequestFinancialRemoveDiscountModal
                isOpen={removeDiscountModal.isOpen}
                faciliterServiceInfo={removeDiscountModal.data || {}}
                refresh={() => findPricesByService(mainRequestId)}
                handleClose={() => setRemoveDiscountModal({ ...removeDiscountModal, isOpen: false })}
                setAlert={setAlert}
            />

        </>
    );
}

export default RequestFinancial;