import React, { useState } from "react";

import {
    Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Fab
} from "@material-ui/core";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import { EventOutlined, FilterList } from "@material-ui/icons";

import CorrespondentService from "../../../domain/services/correspondent.service";
import { events as eventsLoad } from '../../../domain/services/api';

function RatingFilter(props) {

    const [period, setPeriod] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const handleChangePeriod = (value, index) => {

        if (!value) return;

        let item = [...period];
        item[index] = value;

        setPeriod([...item]);
    }

    const filterFeedbacks = async () => {

        if (!period[0] || !period[1]) return;

        eventsLoad.emmit('onLoadingList', true);

        let filterPeriod = [moment(period[0]).format("YYYY-MM-DD"), moment(period[1]).format("YYYY-MM-DD")];

        const res = await CorrespondentService.filterFeedbacksFaciliter({
            userId: props.userId, period: filterPeriod
        });

        props.onChangeList([...res.data]);

        eventsLoad.emmit('onLoadingList', false);
        closeFilterModal();
    }

    const openFilter = () => {
        setOpenDialog(true);
    }

    const closeFilterModal = () => {
        setOpenDialog(false);
    }

    return (
        <React.Fragment>
            <Fab type="button" mr={2} color="primary" onClick={openFilter}
                style={{
                    marginTop: '10px', minHeight: "25px", height: "25px", minWidth: "25px", width: "25px"
                }}>
                <FilterList fontSize="small" style={{ paddingTop: "3px" }} />
            </Fab>

            <Dialog open={openDialog} onClose={closeFilterModal} maxWidth="sm">
                <DialogTitle>
                    <EventOutlined style={{ marginBottom: "-3px", fontSize: "18px", color: "#376fd0" }} />
                    {" "} Filtrar Avaliações
                </DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <Grid container spacing={6}>
                            <Grid item md={12} style={{ width: '100%' }}>
                                <DatePicker name="initialDateFilter" label="Data Inicial" fullWidth
                                    okLabel="Confirmar" cancelLabel="Cancelar" format="dd/MM/yyyy"
                                    value={(period[0] || null)} onChange={(value) => { handleChangePeriod(value, 0) }}
                                />

                                <DatePicker name="finalDateFilter" label="Data Final"
                                    style={{ marginTop: '10px' }} fullWidth
                                    okLabel="Confirmar" cancelLabel="Cancelar" format="dd/MM/yyyy"
                                    value={(period[1] || null)} onChange={(value) => { handleChangePeriod(value, 1) }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={filterFeedbacks} color="primary"> Confirmar </Button>
                    <Button onClick={closeFilterModal} color="primary"> Fechar </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default RatingFilter;


