import { getFinishRegisterRequiredFields, getRequiredFields } from "../domain/constant/client.register.required.fields";

import { flattenObject, getMissingFields } from "../components/providers/utils.provider";

export const checkLastStep = (data) => {

    const finalStep = 4;

    for (let i = 2; i < finalStep; i++) {

        const missingFields = checkRegisterFields(i, data);

        if (missingFields.length > 0)
            return i;
    }

    return finalStep;
}

export const checkRegisterFields = (step, data = {}) => {

    const requiredFields = getRequiredFields(step, data);
    const allFields = flattenObject(data);

    return getMissingFields(allFields, requiredFields);
}

export const checkFinishRegisterFields = (data = {}, isCorporation, withAddress) => {

    const requiredFields = getFinishRegisterRequiredFields(data, isCorporation, withAddress);
    const allFields = flattenObject(data);

    return getMissingFields(allFields, requiredFields);
}

export const getCurrentStep = (urlParams) => {

    const query = new URLSearchParams(urlParams);
    const queryStep = query.get('step');
    let currentStep = queryStep ? +queryStep : 1;

    return currentStep;
}

export default {
    checkLastStep,
    checkRegisterFields,
    checkFinishRegisterFields,
    getCurrentStep,
}