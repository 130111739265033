export const segmentations = {
    DEFAULT: {
        key: 'DEFAULT',
    },
    INFINITY: {
        key: 'INFINITY',
    },
    GOLD: {
        key: 'GOLD',
    },
    FIT: {
        key: 'FIT',
    },
}