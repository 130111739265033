import { get, post, remove } from '../api';

const find = async (requestId) =>
    get({ type: 'user', service: `requests/tags/${requestId}` })


const findAll = async () =>
    get({ type: 'user', service: 'requests/tags' })

const save = async (tag, requestId) =>
    post({ type: 'user', service: `requests/tags/${requestId}`, data: { ...tag, requestId } });

const deleteById = async ({ id }) =>
    remove({ type: 'user', service: `requests/tags/${id}` });

const removeAll = async (name) =>
    remove({ type: 'user', service: `requests/tags/${name}/all` });

const saveAll = async (requestId, tags) => {

    if (!tags || !tags.length)
        return;

    return Promise.all(
        tags.map(x => save(x, requestId))
    )
}

const map = (requestId, list, tags) => {
    return list.map((item) => {
        item.requestId = requestId;

        let tag = tags.find((tag) => tag.name == item.name);
        if (tag) {
            item.id = tag.id;
            item.checked = true;
        }
        return item;
    });
}

export default {
    find,
    findAll,
    save,
    deleteById,
    removeAll,
    saveAll,
    map
}