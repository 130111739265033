
import React from "react";

import Async from "../../components/Async";

import { AccountBalanceWallet, AttachMoney, PeopleRounded, Person, Star } from "@material-ui/icons";

import FaciliterFeedbacks from "../../pages/admin/correspondents/faciliter.feedbacks";
import FaciliterInvoices from "../../pages/admin/correspondents/invoices";
import faciliterAssureIncomeService from "../../domain/services/correspondent/faciliter.assure.income.service";

const AuthGuard = Async(() => import("../auth.guard"));

const Correspondent = Async(() => import('../../pages/admin/correspondents/correspondent'));
const CorrespondentDetails = Async(() => import('../../pages/admin/correspondents/correspondent.details'));
const FaciliterProfileView = Async(() => import("../../pages/profile/faciliter.profile"));


//external register
const ExternalCorrespondent = Async(() => import('../../pages/admin/correspondents/external-correspondent'));

const routes = {
    correspondents: {
        id: 'correspondents',
        name: 'Faciliters',
        path: '/faciliter',
        component: Correspondent,
        guard: AuthGuard,
        icon: <PeopleRounded />,
        roles: ['admin', 'operator'],
    },
    correspondentsDetails: {
        id: 'correspondentsDetails',
        name: 'Faciliters',
        path: '/faciliter/:id',
        component: CorrespondentDetails,
        disabledMenu: true,
        guard: AuthGuard,
        icon: <PeopleRounded />,
        roles: ['admin', 'operator'],
    },
    correspondentProfile: {
        id: 'correspondentProfile',
        name: 'Meu perfil',
        path: '/correspondent/profile',
        component: FaciliterProfileView,
        disabledMenu: false,
        icon: <Person />,
        guard: AuthGuard,
        roles: ['faciliter'],
    },
    faciliterFeedbacks: {
        id: 'faciliterFeedbacks',
        name: 'Meus Feedbacks',
        path: '/feedbacks',
        component: FaciliterFeedbacks,
        icon: <Star />,
        guard: AuthGuard,
        roles: ['faciliter'],
    },
    faciliterInvoices: {
        id: 'faciliterInvoices',
        name: 'Faturamentos',
        path: '/invoices',
        component: FaciliterInvoices,
        icon: <AttachMoney />,
        roles: ['faciliter'],
    },
    assureIncome: {
        id: 'assureIncome',
        name: 'Renda Garantida',
        path: '/correspondent/profile?tab=assureIncome',
        component: FaciliterProfileView,
        disabledMenu: false,
        icon: <AccountBalanceWallet />,
        guard: AuthGuard,
        roles: ['faciliter'],
        validator: () => faciliterAssureIncomeService.canAddOrIsAssureIncome(),
    }
};


const externalRoutes = {
    externalCorrespondents: {
        id: 'externalCorrespondents',
        name: 'Faciliters',
        path: '/faciliter/:uuid/:isFaciliter/:faciliterType',
        component: ExternalCorrespondent
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}