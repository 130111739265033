import { useState, useEffect } from "react";

import { Box, Button, Grid, Paper, Popover, Tooltip, Typography } from "@material-ui/core";
import { Add, AttachFileOutlined } from "@material-ui/icons";

import VirtualizedList from '../../VirtualizedList';
import RequestDocumentList from "../documents/request.document.list.component";

import documentsService from "../../../domain/services/solicitation/documents.service";

import { trackPromise } from 'react-promise-tracker';

const PendingSolveDocumentForm = ({ request, newFiles, setNewFiles, setAlert, isLoading }) => {

    const [allDocs, setAllDocs] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => { getDocuments(request.id) }, []);

    const getDocuments = (requestId, selectedFiles = []) =>
        trackPromise(
            documentsService
                .find(requestId)
                .then(({ data = [] }) => {
                    const docs = data.map((item) => ({
                        ...item, title: getDocListItem(item.name)
                    }));
                    setAllDocs(docs);
                    getNewFilesList(data, selectedFiles);
                })
        );

    const getNewFilesList = (docs, selectedFiles) =>
        Promise
            .all(
                selectedFiles.map(async (item) => docs.find((d) => d.name == item.name) || item)
            )
            .then(res => setNewFiles([...newFiles, ...res]));

    const getDocListItem = (docName) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: 3, background: '#8479E1', borderRadius: '100%' }}></div>
            <div
                style={{
                    borderRadius: 10,
                    padding: '3px 8px 3px 8px',
                    fontSize: 11,
                    fontWeight: 600,
                }}
            >
                {docName}
            </div>
        </div>
    )

    const handleSaveFiles = (requestId, selectedFiles) => {

        const files = [...Array.from(selectedFiles)];

        trackPromise(
            documentsService
                .saveAll(requestId, files)
                .then((res = []) => checkUpload(requestId, res))
                .then(() => getDocuments(requestId, files))
                .catch((err) => setAlert(err.response.data.message
                    || 'Não foi possível resolver pendência!'))
        );
    }

    const checkUpload = async (requestId, docs) => {

        const documentsIds = docs.map(item => item.document.id);

        if (!documentsIds.length)
            return;

        await trackPromise(
            documentsService
                .checkUpload(requestId, documentsIds, false)
                .then(() => setAlert('Upload Concluído', 'success'))
                .catch((err) => setAlert(err.response.data.message
                    || 'Não foi possível resolver pendência!'))
        );
    }

    const handleRemoveDocument = (doc) =>
        !isLoading && trackPromise(
            documentsService
                .remove(doc)
                .then(() => {
                    setAlert('Documento removido com sucesso', 'success')
                    removeInList(doc.id, newFiles, setNewFiles);
                    removeInList(doc.id, allDocs, setAllDocs);
                })
        );

    const removeInList = (id, list, setNewList) => {

        const newList = [...list];

        const itemIndex = newList.findIndex((item) => item.id == id);

        if (itemIndex < 0)
            return;

        newList.splice(itemIndex, 1);

        setNewList(newList);
    }

    return (
        <>
            <Grid
                container justifyContent='space-between'
                style={{ marginBottom: 5 }} alignItems='center'
            >
                <Grid container item md>
                    <AttachFileOutlined
                        style={{ fontSize: 18, marginRight: 5 }} />

                    <Typography variant='h6'>
                        Anexar documentos
                    </Typography>
                </Grid>

                <Button
                    variant='outlined' size='small'
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    Ver todos
                </Button>
            </Grid>

            <RequestDocumentList
                request={request}
                documents={newFiles}
                removeDocument={handleRemoveDocument}
            />

            <Grid container justifyContent='center'>
                <div>
                    <input
                        id='files' type='file' multiple
                        style={{ display: 'none' }}
                        onChange={(e) => handleSaveFiles(request.id, e.target.files)}
                    />
                    <label htmlFor='files'>
                        <Tooltip title='Novo documento'>
                            <Button
                                variant='outlined' color='primary'
                                component='span' fullWidth
                                disabled={isLoading}
                                style={{ borderRadius: 20 }}
                            >
                                <Add />
                            </Button>
                        </Tooltip>
                    </label>
                </div>
            </Grid>

            <Popover
                open={Boolean(anchorEl)} anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)} variant
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper variant="outlined">
                    <Box style={{ minWidth: 250 }}>
                        <Box p={3}>
                            <Typography style={{ color: '#555', fontSize: 12, fontWeight: '600' }}>
                                Documentos anexados
                            </Typography>
                        </Box>

                        <VirtualizedList list={allDocs} color='red' />

                        <Box p={1} style={{ background: '#eee' }}>
                            <Typography style={{ color: '#555', fontSize: 12 }}>
                                {allDocs.length} {allDocs.length > 1 ? 'documentos' : 'documento'}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Popover>
        </>
    );
}

export default PendingSolveDocumentForm;