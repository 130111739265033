export default function TableOfContentsDesktop({
    tableOfContent = [],
}) {
    return (
        <div className="w-full h-full bg-white border border-[#ededed]">
            <div className="w-full bg-white h-screen p-6 sticky top-0 text-[#131313cc] overflow-y-auto">
                <div className="font-semibold">
                    Sumário <span className="opacity-50 font-normal">(Preview)</span>
                </div>
                <div className="my-4 h-[1px] bg-[#ededed] w-full" />
                <div className="text-[13px] flex flex-col gap-3">
                    {tableOfContent.map((item, index) => (
                        <div
                            key={index}
                            className=""
                            style={{
                                paddingLeft: (item.level - 1) * 12,
                            }}
                        >
                            {item.text}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}