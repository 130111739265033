import { Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import customerOfficeService from "../../../domain/services/client/customer.office.service";
import CustomerOfficeForm from "./customer.office.form.component";
import { useEffect, useState } from "react";

export default function CustomerOfficeModal({
    onClose = () => {},
    isInternal,
    customer
}) {

    const [open, setOpen] = useState(false);

    const checkShowForm = () => {

        const questionsToShow = customerOfficeService.getQuestionsToShow(customer.profileQuestions);

        if (!questionsToShow.length) {
            onClose();
            return;
        }

        setOpen(true);
    }

    useEffect(() => {
        checkShowForm();
    }, []);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown={!isInternal}
            onClose={handleClose}
        >
            <DialogTitle>
                <InfoOutlined style={{ marginBottom: -8, fontSize: 30, color: "#376fd0", marginRight: 5 }} />
                {" "} {customerOfficeService.getVariableCopy('title')} {customer.name}

                {!isInternal &&
                    <span role="img" aria-label="Waving Hand Sign"
                        style={{ marginLeft: 5 }}>
                        👋
                    </span>
                }
            </DialogTitle>

            <Divider variant="fullWidth" />

            <DialogContent>

                {!isInternal && '* Por favor, preecha esse formulário para conhecermos um pouco mais sobre você e o seu escritório.'}

                <CustomerOfficeForm
                    customer={customer}
                    onSubmit={handleClose}
                    isInternal={isInternal}
                />
            </DialogContent>

            <Divider variant="fullWidth" />
        </Dialog >
    );
}