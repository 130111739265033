const fields = [
    {
        label: 'Informe a data e horário do início da audiência',
        key: 'start',
        required: true,
        type: 'datetime'
    },
    {
        label: 'Informe a data e horário do fim da audiência',
        key: 'end',
        required: true,
        type: 'datetime'
    },
    {
        label: 'Foi feita a proposta da forma solicitada pelo cliente?',
        key: 'proposalWasMade',
        required: true,
        type: 'boolean',
        options: [
            {
                value: 'true',
                label: 'Sim'
            },
            {
                value: 'false',
                label: 'Não'
            }
        ]
    },
    {
        label: 'Foi aceita a proposta?',
        key: 'proposalWasAccepted',
        required: true,
        dependencyField: 'proposalWasMade',
        dependencyValue: 'true',
        type: 'boolean',
        options: [
            {
                value: 'true',
                label: 'Sim'
            },
            {
                value: 'false',
                label: 'Não'
            }
        ]
    },
    {
        label: 'Informe as condições da proposta que foi aceita',
        key: 'proposalAcceptanceTerms',
        required: true,
        dependencyField: 'proposalWasAccepted',
        dependencyValue: 'true',
        type: 'text'
    },
    {
        label: 'Informe o motivo de não ter sido realizada a proposta de acordo',
        key: 'proposalNotMadeReason',
        required: true,
        dependencyField: 'proposalWasMade',
        dependencyValue: 'false',
        type: 'text'
    },
    {
        label: 'Informe o motivo do não aceite da proposta de acordo e qual seria a proposta aceitável pela parte contrária',
        key: 'proposalNotAcceptanceReason',
        required: true,
        dependencyField: 'proposalWasAccepted',
        dependencyValue: 'false',
        type: 'text'
    },
    {
        label: 'Todas as partes/testemunhas estavam presentes?',
        key: 'allWitnessPresent',
        required: true,
        type: 'boolean',
        options: [
            {
                value: 'true',
                label: 'Sim'
            },
            {
                value: 'false',
                label: 'Não'
            }
        ]
    },
    {
        label: 'Quais partes/testemunhas estavam ausentes na audiência?',
        key: 'absentWitnesses',
        required: true,
        dependencyField: 'allWitnessPresent',
        dependencyValue: 'false',
        type: 'text'
    },
    {
        label: 'Houve a dispensa/recusa de oitiva de alguma testemunha?',
        key: 'wasRefusalToHearWitness',
        required: true,
        type: 'boolean',
        options: [
            {
                value: 'Sim',
                label: 'Sim'
            },
            {
                value: 'Não',
                label: 'Não'
            },
            {
                value: 'Não se aplica',
                label: 'Não se aplica'
            }
        ]
    },
    {
        label: 'Informe quais as testemunhas foram dispensadas',
        key: 'refusalToHearWitnesses',
        required: true,
        dependencyField: 'wasRefusalToHearWitness',
        dependencyValue: 'Sim',
        type: 'text'
    },
    {
        label: 'Informe o motivo da dispensa de oitiva das testemunhas',
        key: 'refusalToHearWitnessReason',
        required: true,
        dependencyField: 'wasRefusalToHearWitness',
        dependencyValue: 'Sim',
        type: 'text'
    },
    {
        label: 'Constou protesto em ata?',
        key: 'wasProtestsToRefusalToHearWitness',
        required: true,
        dependencyField: 'wasRefusalToHearWitness',
        dependencyValue: 'Sim',
        type: 'boolean',
        options: [
            {
                value: 'true',
                label: 'Sim'
            },
            {
                value: 'false',
                label: 'Não'
            }
        ]
    },
    {
        label: 'Foi concedido algum prazo em audiência?',
        key: 'wasDueDateGranted',
        required: true,
        type: 'boolean',
        options: [
            {
                value: 'true',
                label: 'Sim'
            },
            {
                value: 'false',
                label: 'Não'
            }
        ]
    },
    {
        label: 'Qual o prazo concedido e a data para cumprimento?',
        key: 'dueDateGranted',
        required: true,
        dependencyField: 'wasDueDateGranted',
        dependencyValue: 'true',
        type: 'text'
    },
    {
        label: 'Faça um histórico dos acontecimentos ocorridos na audiência',
        key: 'historic',
        required: true,
        type: 'text'
    },
    {
        label: 'As atas e áudios sobre os acontecimentos da audiência foram anexados! Obs.: Para anexar, vá até a aba de anexos nos detalhes da solicitação!',
        key: 'documentsUploaded',
        required: true,
        type: 'checkbox'
    }
]

export default fields;