import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const createSnackbar = (message, severity = 'success', duration = 3000) => {
    const snackbarRoot = document.createElement('div');
    document.body.appendChild(snackbarRoot);

    const handleClose = () => {
        ReactDOM.unmountComponentAtNode(snackbarRoot);
        document.body.removeChild(snackbarRoot);
    };

    ReactDOM.render(
        <Snackbar
            open
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>,
        snackbarRoot
    );
};

const SnackbarAlert = {
    success: (message, duration = 3000) => createSnackbar(message, 'success', duration),
    info: (message, duration = 3000) => createSnackbar(message, 'info', duration),
    warning: (message, duration = 3000) => createSnackbar(message, 'warning', duration),
    error: (message, duration = 3000) => createSnackbar(message, 'error', duration),
};

export default SnackbarAlert;
