import { useTour } from "../../context/tourContext";
import { announcements } from "../../domain/constant/announcements.constant";
import Tour from "../shared/Tour";
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function PetitionIndicationTour({
    open,
    onClose,
    onFinish,
    allowNext = false,
}) {
    const { refGroups } = useTour();

    const {
        indicationOfPetitions,
        previewEye,
        petitionInformation,
        startSolicitation,
    } = refGroups[announcements.tourPetitionIndication] || {};

    const petitionIndicationTourSteps = [
        {
            title: 'Indicações de petições',
            content: 'Veja o que os nossos especialistas tem de sugestão para o seu caso!'
        },
        {
            title: 'Indicações de petições',
            content: 'Veja as petições cabíveis para o seu caso que nossos especialistas encontraram',
            target: () => indicationOfPetitions.ref.current,
            placement: 'top',
        },
        {
            title: 'Pré-visualização',
            content: (
                <span>
                    Clique no ícone <VisibilityIcon size="small" color="primary" variant="outlined" />  para ter uma pré-vizualização da indicação
                </span>
            ),
            target: () => previewEye.ref.current,
            enableInteraction: true,
            triggerNext: allowNext,
            disableNext: true,
            placement: 'top',
        },
        {
            title: 'Informações da indicação de petição',
            content: 'Aqui você pode ver os detalhes da petição cabível',
            target: () => petitionInformation.ref.current,
            placement: 'top',
        },
        {
            title: 'Botão de solicitação',
            content: (
                <span>
                    Caso deseje seguir com a petição indicada pelo nosso especialista, basta clicar em <span className="text-[#376fd0] font-semibold"> 
                        Solicitar o serviço agora! </span>. Lembrando que uma nova cobrança será feita para o desenvolvimento dessa petição.
                </span>
            ),
            target: () => startSolicitation.ref.current,
            placement: 'top',
        },
    ];

    return (
        <Tour
            open={open}
            onClose={onClose}
            onFinish={onFinish}
            steps={petitionIndicationTourSteps}
        />
    )
}