import { Box, Button, Dialog, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { InfoRounded } from "@material-ui/icons"

const RequestInstructionsReviewPetitionIA = ({ openInDialog }) => {

    const [dialogOpen, setDialogOpen] = useState(false)

    return (
        <React.Fragment>
            {openInDialog ?
                <DialogInstrunctions open={dialogOpen} setOpen={setDialogOpen} /> :
                <InstrunctionsContent />
            }
        </React.Fragment>
    )
}

const InstrunctionsContent = () => (
    <Box style={{ marginTop: "40px" }}>

        <Typography variant="h6" ant="h6" gutterBottom style={{ marginTop: '15px', marginBottom: 20, fontWeight: 'normal' }}>
            Quais atualizações serão necessárias ?
        </Typography>

        <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line", display: "block", fontWeight: 500 }}>
            1 - Inserir Jurisprudências nos tópicos com marcações:
        </Typography>

        <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line", display: "block", marginBottom: 10 }}>
            No documento que será atualizado terá as marcações nos tópicos indicando onde deverá ser colacionadas as jurisprudências.
            Após incluir a jurisprudência no tópico, a marcação com a indicação de colagem da jurisprudência deverá ser removida deixando apenas a jurisprudência cabível ao caso.
        </Typography>

        <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line", display: "block", fontWeight: 500 }}>
            2 - Validar estruturação dos tópicos e fundamentação:
        </Typography>

        <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line", display: "block" }}>
            Revise a estrutura da petição, assegurando que a sequência dos tópicos esteja lógica e coerente e garantindo que não haja repetição desnecessárias.
        </Typography>

        <Typography variant="h6" ant="h6" gutterBottom style={{ marginTop: '30px', marginBottom: 10, fontWeight: 'normal' }}>
            Qual documento será atualizado ?
        </Typography>

        <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line" }}>
            O documento que será atualizado, estará anexado na aba de documentos da solicitação com as seguinte marcações: "Petição" e "Revisar e Incluir Jurisprudências"
        </Typography>
    </Box>
)

const DialogInstrunctions = ({ open, setOpen }) => (
    <React.Fragment>

        <Button
            variant="contained"
            color="primary"
            startIcon={<InfoRounded stle={{ fontSize: "16px" }} />}
            style={{ pointerEvents: 'all', height: "29px", fontWeight: 600, fontSize: "12px", marginRight: "10px" }}
            onClick={() => { setOpen(true) }}
        >
            Ver Instruções de Serviço
        </Button>

        <Dialog open={open} onClose={setOpen} className="relative z-10">

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="sm:flex sm:items-start" style={{ marginRight: 30 }}>
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                <InfoRounded aria-hidden="true" className="h-6 w-6 text-blue-600" />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <Typography variant="h5" className="text-lg font-bold text-gray-900" style={{ marginTop: 5 }}>
                                    Instruções do serviço
                                </Typography>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        <InstrunctionsContent />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 sm:mt-10 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </React.Fragment>
)

export default RequestInstructionsReviewPetitionIA