import React, { useState } from 'react';

import {
    Button, Popper, Grow, Paper, MenuList, MenuItem, ClickAwayListener, Divider, Grid, Typography
} from '@material-ui/core';
import { Cancel, ExpandLess, ExpandMore, Edit } from '@material-ui/icons';

import swal from 'sweetalert2';

import AdvanceRequestComponent from './advance.request.component';

import requestValidator from '../../domain/validators/request.validator';

import loginService from '../../domain/services/login.service';
import solicitationService from '../../domain/services/solicitation/solicitation.service';

import moment from 'moment-timezone';
import advanceService from '../../domain/services/request/advance.service';

import { servicesByTypeKey } from "../../domain/constant/request.services.constant";

const AdvanceDueDate = ({ request = {}, onRefreshRequest, mainRequestId }) => {

    const [advanceModalOpen, setAdvanceModalOpen] = useState(false);
    const [openEditDropdown, setOpenEditDropdown] = useState(false);

    const hearingDueDateAdvanceMessage = (loginService.isInternal() && [servicesByTypeKey.hearing.id].includes(request.serviceTypeId)) ? '<br /><br /><strong>Importante:</strong> Esta operação irá alterar apenas a data de entrega da solicitação, mas manterá a data da audiência.' : '';

    const editButtonAnchorRef = React.useRef(null);

    const handleCloseEditDropdown = (event) => {
        if (editButtonAnchorRef.current && editButtonAnchorRef.current.contains(event.target))
            return;

        setOpenEditDropdown(false);
    };

    const handleConfirmCancel = async () => {

        const newAllServicesDueDate = request.advanceInfo?.oldAllServicesDueDate ? request.advanceInfo.oldAllServicesDueDate : await solicitationService.calculateAllServicesDueDate(mainRequestId);

        const formattedAllServicesDueDate = moment(newAllServicesDueDate).format("DD [de] MMMM [de] YYYY [às] HH:mm");

        const confirmationMessage = {
            title: 'Deseja realmente cancelar a antecipação do prazo da solicitação?',
            html: `
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                    <strong>Atenção:</strong> A nova data de entrega da solicitação será<br>
                    <b>${formattedAllServicesDueDate}</b>
                </p>
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                    O crédito utilizado para antecipar será estornado ao pacote!
                </p>
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                    ${hearingDueDateAdvanceMessage}
                </p>
            `
        }

        showAdvanceCancelConfirmation(confirmationMessage);
    }

    const showAdvanceCancelConfirmation = (confirmationMessage, skipValidation) => {
        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">${confirmationMessage.title}</p>`,
            html: confirmationMessage.html,
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Manter a entrega antecipada',
            showCancelButton: true,
        })
            .then(result => result.isConfirmed && cancel(skipValidation))
    }

    const cancel = (skipValidation = false) => {
        advanceService.cancelAdvance(mainRequestId, skipValidation)
            .then(onAdvanceCancelSuccess)
            .catch(onAdvanceCancelError)
    }

    const onAdvanceCancelSuccess = data => {

        if (onRefreshRequest)
            onRefreshRequest();

        const formattedAllServicesDueDate = moment(data.allServicesDueDate).tz('America/Sao_Paulo').format("DD [de] MMMM [de] YYYY [às] HH:mm");

        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">Antecipação da data de entrega cancelada!</p>`,
            html: `
            <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                <strong>Atenção:</strong> A nova data de entrega da solicitação é<br>
                <b translate="no">${formattedAllServicesDueDate}</b>
            </p>`,
            icon: 'success',
            confirmButtonText: 'Fechar'
        })
    }


    const onAdvanceCancelError = (err) => {

        const resData = err?.response?.data || {}

        if (!resData.canSkipValidation) {
            swal.fire({
                title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">Não foi possível cancelar a antecipação da data de entrega!</p>`,
                html: `
                    ${resData.message ? ' <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;"><b>Motivo:</b> ' + resData.message : ''}
                    <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">Contate o suporte ou tente novamente mais tarde!</p>
                `,
                icon: 'error',
                confirmButtonText: 'Fechar'
            });
            return;
        }

        const confirmationMessage = {
            title: resData.message,
            html: `
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                    Tem certeza que deseja cancelar a antecipação da solicitação mesmo assim?
                </p>
            `
        }

        showAdvanceCancelConfirmation(confirmationMessage, true)
    }

    const getMainContent = () => {

        if (requestValidator.canEditAdvance(request))
            return (
                <Grid item style={{ display: 'flex' }}>
                    <Typography
                        style={{
                            borderRadius: '3px',
                            border: '1px solid #F59313',
                            padding: '2px 10px',
                            color: '#F59313',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        Entrega Antecipada
                    </Typography>

                    <Grid>
                        <Button
                            ref={editButtonAnchorRef}
                            id="composition-button"
                            style={{
                                marginLeft: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '3px',
                                backgroundColor: '#376FD0',
                                color: 'white',
                                fontWeight: 'bold'
                            }}
                            onClick={() => setOpenEditDropdown(!openEditDropdown)}
                            endIcon={openEditDropdown ? <ExpandLess /> : <ExpandMore />}
                        >
                            Editar
                        </Button>
                        <Popper open={openEditDropdown} anchorEl={editButtonAnchorRef.current} role={undefined} transition>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleCloseEditDropdown}>
                                            <MenuList autoFocusItem={openEditDropdown} id="menu-list-grow">
                                                <MenuItem
                                                    onClick={(event) => {
                                                        handleCloseEditDropdown(event);
                                                        handleConfirmCancel()
                                                    }}
                                                >
                                                    <Cancel style={{ marginRight: 7, width: 18 }} />
                                                    Desfazer antecipação da entrega
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem
                                                    onClick={(event) => {
                                                        handleCloseEditDropdown(event)
                                                        setAdvanceModalOpen(true)
                                                    }}>
                                                    <Edit style={{ marginRight: 7, width: 18 }} />
                                                    {loginService.isInternal() ? 'Alterar antecipação' : 'Alterar data da entrega'}
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            );

        if (requestValidator.canAdvanceRequest(request))
            return (
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setAdvanceModalOpen(true)}
                        fullWidth
                        style={{
                            pointerEvents: 'all',
                            fontWeight: 600, marginRight: 10
                        }}
                    >
                        Antecipar Entrega
                    </Button>
                </Grid>
            );

        return null
    }

    return (
        <React.Fragment>
            {getMainContent()}

            <AdvanceRequestComponent
                mainRequestId={mainRequestId}
                open={advanceModalOpen}
                setOpen={setAdvanceModalOpen}
                onRefreshRequest={onRefreshRequest}
                isEdit={request.isDueDateAdvanced}
                requestServiceKey={request.servicekey}
            />
        </React.Fragment>
    )
}

export default AdvanceDueDate;
