import { Button, Dialog } from "@material-ui/core";
import { Info } from "@material-ui/icons";

export default function AnswerAdditionalQuestionsModal({
    open,
    onCancel,
    onOk
}) {
    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth
            onClose={onCancel}
        >
            <div className="p-6 text-[15px]">
                <div className="flex gap-3">
                    <div className="">
                        <Info fontSize="large" color="primary" />
                    </div>
                    <div>
                        <div className="flex items-center justify-between mb-2">
                            <div className="text-lg font-semibold">Acompanhamento de Progresso</div>
                        </div>
                        Gostaria de responder algumas perguntas para acompanhar o progresso do seu escritório?
                        <div className="flex justify-end gap-3 mt-5">
                            <Button variant="outlined" onClick={onCancel}>
                                <span className="text-sm">Fechar</span>
                            </Button>
                            <Button variant="contained" color="primary" onClick={onOk}>
                                <span className="text-sm">Responder</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog >
    );
}