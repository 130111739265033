import { Grid } from '@material-ui/core';

import SkeletonLoading from '../SkeletonLoading';

import requestFieldsConfig from './fields/request.fields.config';
import requestFieldsService from '../../domain/services/request/request.fields.service';

export default function RequestProcessInformation(props) {

    const { request, stepFields, isEdit } = props;

    return (
        <SkeletonLoading
            isDataLoading={!request.id || !stepFields.length}
            variant='rect' size={{ height: 110 }}
        >
            <Grid
                container spacing={8}
                style={requestFieldsService.checkDisableFieldsStyle(request, isEdit)}
            >
                {stepFields
                    .map(field => ({
                        ...requestFieldsConfig.getComponentField({ ...props, field })
                    }))}
            </Grid>
        </SkeletonLoading>
    );
}