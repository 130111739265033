import moment from "moment";
import EventEmmiter from '../../../providers/event.emmiter';
import { events as event, get, post, put } from '../api';

export const events = new EventEmmiter();

const findPackageOptions = async () =>
    get({ type: 'user', service: `/marketing/packages/` });

const findPackageInfoById = async (id) =>
    get({ type: 'user', service: `/marketing/packages/${id}` });

const edit = async (id, data) => {

    delete data["showForm"];

    const newData = {
        ...data,
        created: data.created && (moment(data.created).format("YYYY-MM-DDTHH:mm")),
        expireDate: data.expireDate && (moment(data.expireDate).format("YYYY-MM-DDTHH:mm")),
        expiredAt: data.expiredAt && (moment(data.expiredAt).format("YYYY-MM-DDTHH:mm")),
        concludedAt: data.concludedAt && (moment(data.concludedAt).format("YYYY-MM-DDTHH:mm"))
    }

    const res = await put({ type: 'user', service: `financial/packages/${id}`, data: newData });
    events.emmit('onEditPackage');

    return res;
}

const findAll = async () => {

    event.emmit('onLoadingList', true);
    const res = await get({ type: 'user', service: '/financial/packages' });
    event.emmit('onLoadingList', false);

    return res
}

const findHistory = async () => get({ type: 'user', service: '/financial/packages/list/history' });

const findBillings = async (packageIds) => {
    const multiplePackageIds = Array.isArray(packageIds) ? packageIds : [packageIds];
    return post({ type: 'user', service: `financial/billings/packages`, data: { packageIds: multiplePackageIds } });
}

const findByCustomer = async (customerId) =>
    get({ type: 'user', service: `financial/packages/customers/${customerId}/` });

const findAvailablesByCustomer = async (customerId) =>
    get({ type: 'user', service: `financial/packages/customers/${customerId}/availables` });

const findUnavailablesByCustomer = async (customerId) =>
    get({ type: 'user', service: `financial/packages/customers/${customerId}/unavailables` });

const findById = async (id) => get({ type: 'user', service: `financial/packages/${id}/` });

const findOptionsQuantity = async (customerId) =>
    get({ type: 'user', service: `/marketing/packages/customers/${customerId}/` });

const findPeriodicUpsell = async (customerId, productId) =>
    post({ type: 'user', service: `/financial/packages/find-periodic-upsell`, data: { customerId, productId } })

const extendPackage = async (packageId) =>
    post({ type: 'user', service: `/financial/packages/extend`, data: { packageId } })

const getExpiredPackageAsDiscount = async (packageId) =>
    post({ type: 'user', service: `/financial/packages/expired-package-voucher`, data: { packageId } })

const newValue = () => ({
    customerId: "", price: "", remainingBalance: ""
});

const mapToExcel = (packages) => {
    return packages.map((item) => ({
        "ID do cliente": item.customerId,
        "Cliente": item.customer,
        "Email": item.email,
        "Telefone": item.telephone,
        "Data de Criação do cliente": moment.utc(item.customerCreated).format('DD/MM/YYYY'),
        "Total de pacotes": item.totalPackages,
        "Pacotes Ativos": item.activePackages,
        "Valor Total": parseFloat(item.totalPrice),
        "Saldo": parseFloat(item.remainingBalance),
        "Última Compra": item.lastPurchase ? moment.utc(item.lastPurchase).format('DD/MM/YYYY') : null,
        "Última Solicitação": item.lastRequest ? moment.utc(item.lastRequest).format('DD/MM/YYYY') : null,
        "Solicitações Em Andamento": item.pendingRequest,
        "Deletado?": item.deleted ? moment.utc(item.delete).format('DD/MM/YYYY') : "",
    }))
}

const mapHistoryToExcel = (packages) =>
    packages.map((item) => ({
        "ID Do Cliente": item.customerId,
        "Cliente": item.customer,
        "Descrição": item.description,
        "Data de Criação": moment.utc(item.created).format('DD/MM/YYYY'),
        "Data para Expirar": item.expireDate && moment.utc(item.expireDate).format('DD/MM/YYYY'),
        "Data de Expiração": item.expiredAt && moment.utc(item.expiredAt).format('DD/MM/YYYY'),
        "Valor": parseFloat(item.price),
        "Saldo": parseFloat(item.remainingBalance),
        "Última Solicitação": item.lastRequest ? moment.utc(item.lastRequest).format('DD/MM/YYYY') : null,
        "Solicitações Em Andamento": item.pendingRequest,
        "Data Finalização": item.concludedAt && moment.utc(item.concludedAt).format('DD/MM/YYYY'),
        "Deletado?": item.deleted ? moment.utc(item.delete).format('DD/MM/YYYY') : "",
    }))

export default {
    findPackageOptions,
    findPackageInfoById,
    edit,
    findAll,
    findHistory,
    findBillings,
    findByCustomer,
    findAvailablesByCustomer,
    findUnavailablesByCustomer,
    findById,
    findOptionsQuantity,
    newValue,
    mapToExcel,
    mapHistoryToExcel,
    findPeriodicUpsell,
    extendPackage,
    getExpiredPackageAsDiscount,
}