import { useState, useEffect } from 'react';

import { Collapse, Grid, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import RadioButtonField from './radio.button.field';
import FormField from '../../FormField';

export default function MultilineTextField({
    name, label, required, extraInfo, value = '', confirmationLabel, setFieldValue,
    minLength, tooltip, confirmationTooltip, needConfirmation, fieldProps, onError
}) {

    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {

        const alreadyConfirmed = (value || !needConfirmation)

        if (!alreadyConfirmed)
            return;

        setIsConfirmed(true);

    }, []);

    const changeConfirmation = (n, value) => {

        setIsConfirmed(value);

        if (!!value)
            return;

        setFieldValue(name, '');
    }

    const hasConfirmation = () => {

        if (!needConfirmation)
            return null;

        return (
            <Grid item xs={12}>
                <RadioButtonField
                    label={confirmationLabel}
                    name={`has${name}`}
                    value={isConfirmed}
                    setFieldValue={changeConfirmation}
                    tooltip={confirmationTooltip}
                    fieldProps={fieldProps}
                    onError={onError}
                />
            </Grid>
        )
    }

    return (
        <>

            {hasConfirmation()}

            <Grid item xs={12}>
                <Collapse in={isConfirmed}>
                    <FormField
                        {...fieldProps}
                        type='multiline'
                        name={name}
                        field={label}
                        currentValue={value}
                        required={required}
                        onChange={setFieldValue}
                        onError={onError}
                        size='medium'
                        placeholder={extraInfo}
                        helperText={minLength ? `Mínimo de caracteres: ${value?.length} de ${minLength}` : ""}
                        customInputProps={{
                            endAdornment: tooltip && (
                                <Tooltip title={
                                    <p style={{ fontSize: 12, lineHeight: 1.3, fontStyle: 'italic' }}>{tooltip}</p>
                                }>
                                    <HelpOutline style={{ width: 17, marginLeft: 5 }} />
                                </Tooltip>
                            ),
                        }}
                        titleSize={15}
                        titleWeight={600}
                        spacing={10}
                    />
                </Collapse>
            </Grid>

        </>
    );
}
