import api from '../services/api';

const baseUrl = 'requests'

const findAll = async () =>
    api.get({ type: 'user', service: `${baseUrl}/services` });

const findAllServiceTypes = async (correspondentId) =>
    api.get({ type: 'user', service: `${baseUrl}/${correspondentId}/service-type` });

    export default {
    findAll,
    findAllServiceTypes
}