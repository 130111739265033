import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components/macro';
import swal from 'sweetalert2';
import moment from 'moment';

import {
    Button, IconButton, Typography, Dialog, DialogTitle as MuiDialogTitle,
    DialogContent, DialogActions, Grid, Snackbar, CircularProgress, FormControl,
    Select, MenuItem, Card
} from '@material-ui/core';

import { Close } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import LoadingProgress from '../LoadingProgress';

import urlQueryProvider from '../../providers/url.query.provider';
import datetimeProvider from '../../providers/datetime.provider';

import requestAdvanceService from '../../domain/services/request/advance.service';
import loginService from '../../domain/services/login.service';
import { requestServices } from '../../domain/constant/request.services.constant';

import advanceService from '../../domain/services/request/advance.service';

const DialogTitle = styled(MuiDialogTitle)`
    h2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;

const AdvanceRequestComponent = (props) => {

    const { mainRequestId, open, setOpen, onRefreshRequest, isEdit, requestServiceKey = null } = props;

    let { search } = useLocation();

    const [loading, setLoading] = useState(false);
    const [statusMsg, setStatusMsg] = useState({});

    const [selectedAdvanceId, setSelectedAdvanceId] = useState(null);
    const [advanceOptions, setAdvanceOptions] = useState([]);

    const hasAdvanceOptions = advanceOptions.length > 0;
    const hasBalanceToAdvance = advanceOptions.length > 0 && advanceOptions.filter(a => a.billing).length > 0;

    const hearingDueDateAdvanceMessage = (loginService.isInternal() && [requestServices.HEARING, requestServices.HEARINGANALYSIS, requestServices.HEARINGINPERSON].includes(requestServiceKey)) ? 'Importante: Esta operação irá alterar apenas a data de entrega da solicitação, mas manterá a data da audiência.' : '';

    const labelsByMethod = {
        'edit': {
            modalTitle: 'Alterar antecipação',
            defaultErrorMessage: 'Não foi possível alterar a antecipação!',
            saveConfirmation: 'Deseja confirmar a alteração da antecipação?',
            saveConfirmationButton: 'Confirmar alteração',
            skipValidationConfirmation: 'Tem certeza que deseja alterar a antecipação mesmo assim?',
            skipValidationConfirmationButton: 'Confirmar alteração',
            onSaveSuccess: 'Antecipação alterada com sucesso!'
        },
        'advance': {
            modalTitle: 'Antecipar Solicitação',
            defaultErrorMessage: 'Não foi possível antecipar a data de entrega solicitação!',
            saveConfirmation: 'Deseja confirmar a antecipação da data de entrega?',
            saveConfirmationButton: 'Confirmar antecipação',
            skipValidationConfirmation: 'Tem certeza que deseja antecipar o prazo da solicitação mesmo assim?',
            skipValidationConfirmationButton: 'Confirmar antecipação',
            onSaveSuccess: 'Data de entrega antecipada com sucesso!'
        }
    }

    const method = isEdit ? 'edit' : 'advance';
    const labels = labelsByMethod[method];

    const defaultErrorMessage = labels.defaultErrorMessage;

    useEffect(() => {
        urlQueryProvider.checkRedirectionInUrl(search, 'userId', checkAutomaticAdvance)
    }, [advanceOptions]);

    useEffect(() => {
        loadAdvanceOptions();
    }, [mainRequestId, open]);

    const checkAutomaticAdvance = (customerUserId) => {

        const loggedUser = loginService.getUserAuthentication();

        if (loggedUser.id != customerUserId || isEdit)
            return;

        urlQueryProvider.checkRedirectionInUrl(search, 'automaticAdvanceDueDate', handleAutomaticAdvance);
    }

    const handleAutomaticAdvance = (advancedDueDate) => {

        if (!advanceOptions.length && !open) {
            handleModal(true);
            return;
        }

        const advanceId = advanceOptions.find(ad => datetimeProvider.isSameDate(ad.newDueDate, advancedDueDate))?.id;

        if (!advanceId) {
            setAlertStatus('A antecipação ofertada não está mais diponível!', 'error');
            return;
        }

        setSelectedAdvanceId(advanceId);
        save(advanceId);
    }

    const loadAdvanceOptions = async () => {
        if (!mainRequestId || !open)
            return

        setLoading(true);
        await requestAdvanceService.findAvailableByMainId(mainRequestId)
            .then(setAdvanceOptions)
            .finally(() => setLoading(false));
    }

    const handleSave = () => {

        if (loading)
            return

        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">${labels.saveConfirmation}</p>`,
            html: `
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                    <strong>Atenção:</strong> A nova data de entrega da solicitação será confirmada de acordo com a disponibilidade de produção.
                </p>
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                As correções seguirão o prazo padrão: 48 horas, exceto para clientes com Assinatura Gold.
                </p>`,
            icon: 'warning',
            confirmButtonText: labels.saveConfirmationButton,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        })
            .then(result => result.isConfirmed && save(selectedAdvanceId))
    }

    const handleSaveSkipingValidation = (data = {}) => {

        if (!data.canSkipValidation)
            return setAlertStatus((data.message || defaultErrorMessage), 'error');

        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">${data.message}</p>`,
            html: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">${labels.skipValidationConfirmation}</p>`,
            icon: 'warning',
            confirmButtonText: labels.skipValidationConfirmationButton,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        })
            .then(result => result.isConfirmed && save(selectedAdvanceId, true))
            .catch(err => setAlertStatus((err?.response?.data?.message || defaultErrorMessage), 'error'));
    }

    const save = async (selectedAdvanceId, skipValidation = false) => {

        setLoading(true);

        await requestAdvanceService.advance(mainRequestId, selectedAdvanceId, skipValidation)
            .then(onSaveSuccess)
            .catch(err => handleSaveSkipingValidation(err?.response?.data))
            .finally(() => setLoading(false));
    }

    const onSaveSuccess = ({ allServicesDueDate }) => {

        handleModal(false);

        if (onRefreshRequest)
            onRefreshRequest(allServicesDueDate);

        swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">${labels.onSaveSuccess}</p>`,
            html: `<p translate="no" style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">Novo prazo de entrega: <strong>${moment(allServicesDueDate).tz('America/Sao_Paulo').format('DD [de] MMMM [de] YYYY [às] HH:mm')}</strong></p>`,
            icon: 'success',
            confirmButtonText: 'Fechar'
        })
    }

    const handleModal = (isOpen) =>
        setOpen(isOpen)

    const handleCloseAlert = () =>
        setStatusMsg({ ...statusMsg, open: false });

    const setAlertStatus = (msg, severity) =>
        setStatusMsg({ text: msg, date: new Date(), open: true, severity });

    return (
        <React.Fragment>

            <Dialog open={open} onClose={() => { handleModal(false) }} maxWidth='xs' fullWidth>

                <DialogTitle style={{ display: 'flex', background: 'rgb(238 238 238 / 50%)', borderBottom: '3px solid rgb(25, 118, 210)' }}>
                    <Typography variant='h4' style={{ display: 'flex', alignItems: 'center' }}>
                        {labels.modalTitle}
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={() => { handleModal(false) }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>

                    {loading ? <LoadingProgress /> : hasAdvanceOptions ? hasBalanceToAdvance ? (
                        <React.Fragment>
                            <Typography variant='subtitle2' style={{ margin: '10px 0', fontSize: 12, fontWeight: 'bold' }}>
                                Selecione o(s) dia(s) para a entrega da solicitação
                            </Typography>

                            <p>{hearingDueDateAdvanceMessage}</p>

                            <Grid container spacing={6} style={{ marginBottom: '15px' }} direction='column'>

                                <Grid item xs={12}>
                                    <FormControl m={2} style={{ width: '100%' }}>

                                        <Select
                                            value={selectedAdvanceId}
                                            required
                                            displayEmpty
                                            renderValue={!selectedAdvanceId ? () => <div style={{ color: '#aaa' }}>Selecione uma antecipação</div> : null}
                                            labelId='advances'
                                            onChange={(e) => setSelectedAdvanceId(e.target.value)}
                                        >
                                            {advanceOptions.map((item) => (
                                                <MenuItem disabled={!item.billing || !item.canAdvance} key={item.id} value={item.id}>
                                                    {advanceService.getAdvanceLabel(item)}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                </Grid>

                                {selectedAdvanceId && (
                                    <Grid container>
                                        <Typography style={{ margin: 12 }} variant='subtitle1'>
                                            Novo prazo de entrega: <strong> {moment((advanceOptions.find(a => a.id == selectedAdvanceId) || {}).newDueDate).format('DD [de] MMMM [de] YYYY [às] HH:mm')}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Card>
                                <Alert severity='error'>
                                    <AlertTitle style={{ fontWeight: 'bold', marginBottom: 10 }}>Você não possui saldo para antecipar um prazo de entrega!</AlertTitle>

                                    <Typography variant='subtitle2'>
                                        Realize a compra de saldo na loja e antecipe o prazo de entrega nos detalhes da solicitação
                                    </Typography>
                                </Alert>
                            </Card>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Card>
                                <Alert severity='error'>
                                    <AlertTitle style={{ fontWeight: 'bold', marginBottom: 10 }}>Não existem opções de antecipações disponíveis para essa solicitação!</AlertTitle>
                                </Alert>
                            </Card>
                        </React.Fragment>
                    )}

                </DialogContent>

                <DialogActions style={{ padding: '15px', background: 'rgb(238 238 238 / 50%)', borderTop: '3px solid rgb(25, 118, 210)' }}>

                    <Button onClick={() => { handleModal(false) }} variant='contained' style={{ width: '100px', height: '32px' }}>
                        Fechar
                    </Button>

                    {hasBalanceToAdvance ? (

                        <Button
                            variant='contained'
                            color='primary'
                            style={{ height: '32px' }}
                            onClick={handleSave}>
                            {!loading ? 'Confirmar Antecipação' :
                                <span>
                                    Carregando...
                                    <CircularProgress m={2} style={{ marginLeft: '8px', width: '16px', height: '16px', color: 'greenyellow' }} />
                                </span>
                            }
                        </Button>
                    ) : (
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ height: '32px' }}
                            onClick={() => { window.location.href = '/marketplace' }}>
                            {!loading ? 'Comprar Antecipação' :
                                <span>
                                    Carregando...
                                    <CircularProgress m={2} style={{ marginLeft: '8px', width: '16px', height: '16px', color: 'greenyellow' }} />
                                </span>
                            }
                        </Button>
                    )}

                </DialogActions>

            </Dialog>

            <Snackbar
                onClose={handleCloseAlert}
                open={statusMsg.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
            >
                <Alert severity={statusMsg.severity}>{statusMsg.text}</Alert>
            </Snackbar>

        </React.Fragment >
    );
}

export default AdvanceRequestComponent;
