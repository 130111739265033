import api from '../api';
import loginService from '../login.service';
import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";
import repository from '../../repositories/request/request.correction.repository';

const save = async (data) =>
    api.post({ type: 'user', service: `requests/${data.requestId}/corrections`, data });

const update = async (data) =>
    api.put({ type: 'user', service: `requests/${data.requestId}/corrections/${data.id}`, data });

const findByRequestId = (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/corrections` });

const findById = (requestId, id) =>
    api.get({ type: 'user', service: `requests/${requestId}/corrections/${id}` });

const checkIsReadOnly = (correctionValues, status) => {

    const isReadOnlyToCustomer = correctionValues.isReadOnly && status != stepsSolicitation.CORRECTION;

    return {
        internal: false,
        faciliter: true,
        customer: isReadOnlyToCustomer,
        customerUser: isReadOnlyToCustomer
    }[loginService.getUserAuthentication().source]
}

const newCorrectionValues = () => ({
    isReadOnly: false
})

const checkCorrectionAvailability = async (requestId) =>
    repository.checkNewCorrectionAvalailabilty(requestId);

const findCorrectionFieldsByService = async (serviceId) => repository.findCorrectionFieldsByService(serviceId);

const findMatchingCorrectionFields = async (currentValues, serviceId) => {

    const allFields = await repository.findAllCorrectionFields();

    const fieldIsFilled = (field) => Object.keys(currentValues).includes(field.key);

    const fieldMatchServiceId = (field) => field.serviceId.includes(serviceId);

    return allFields?.data.filter((field) => fieldIsFilled(field) || fieldMatchServiceId(field));
}

const buildCreateAndUpdateCorrectionPayload = (updatedPayload, correctionFields, props, correctionValues) => {
    return Object.keys(updatedPayload).reduce((accumulator, key) => {
        if (!(updatedPayload[key])) {
            return accumulator;
        }
    
        const correctionField = correctionFields.find(service => service.key === key);

        if (!correctionField) return accumulator
         
        const payloadItem = {
            requestId: props.mainRequestId,
            fieldId: correctionField.uuid,
            value: updatedPayload[key]
        };

        if (!props.isCreating) {
            const correctionId = correctionValues.fieldsIds[key];
            payloadItem.id = correctionId;
        }

        accumulator.push(payloadItem);
    
    
        return accumulator;
    }, []);
};

const returnActionToSaveOrUpdateCorrection = (values, props, correctionFields, correctionValues) => {

    const { customerId } = props.request;
    const { reviewers = [], faciliterReviewers = [] } = props.request;
    
    const { isReadOnly, ...payload } = values;
    const reviewerUserId = reviewers[0]?.userId || faciliterReviewers[0]?.userId;
    

    const updatedPayload = {
        ...payload,
        customerId,
        reviewerUserId
    };

    const finalPayload = buildCreateAndUpdateCorrectionPayload(updatedPayload, correctionFields, props, correctionValues);

    const action = props.isCreating
        ? () => save({ data: finalPayload, reviewerUserId })
        : () => update({ data: finalPayload, id: correctionValues.correctionSource });
    
    return action;

}

export default {
    checkCorrectionAvailability,
    findCorrectionFieldsByService,
    returnActionToSaveOrUpdateCorrection,
    findMatchingCorrectionFields,
    findByRequestId,
    findById,
    checkIsReadOnly,
    newCorrectionValues
}