import React, { createContext, createRef, useContext, useState } from 'react';

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {

    const [refGroups, setRefGroups] = useState({});

    const registerRef = (tourName, refName, stepOptions = {}) => {
        if (!refGroups[tourName]) {
            setRefGroups((prevGroups) => ({
                ...prevGroups,
                [tourName]: {},
            }));
        }

        if (!refGroups[tourName]?.[refName]) {
            const ref = createRef();

            setRefGroups((prevGroups) => ({
                ...prevGroups,
                [tourName]: {
                    ...prevGroups[tourName],
                    [refName]: {
                        ref,
                        stepOptions,
                    },
                },
            }));

            return ref;
        }

        return refGroups[tourName][refName].ref;
    };

    return (
        <TourContext.Provider value={{ refGroups, registerRef }}>
            {children}
        </TourContext.Provider>
    );
};