import { useState } from "react";

import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControl, FormControlLabel,
    Button, Typography, Checkbox, Divider
} from "@material-ui/core";

import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";


const checklistItems = [
    {
        title: "Documento anexado",
        label: "O documento final da solicitação foi anexado corretamente?",
        expanded: true,
    }
];

const ChecklistConclude = (props) => {

    const { request, onSave, onCloseDialog } = props;

    const [open, setOpen] = useState(true);

    const [checklist, setChecklist] = useState(checklistItems);

    const handleClose = () => {
        setOpen(false);
        onCloseDialog();
    }

    const handleChange = (index, checked) => {

        let items = [...checklist];
        items[index].checked = checked;

        setChecklist([...items]);
    }

    const handleNextStep = () => {
        onSave({ stepName: stepsSolicitation.CONCLUDED, requestId: request.id });
        onCloseDialog();
    }

    const isAllChecked = () => {

        if (checklist.every(({ checked }) => checked))
            return false;

        return true;
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">

            <DialogTitle style={{ padding: "32px 32px 10px" }}>VALIDAÇÃO PARA CONCLUSÃO</DialogTitle>

            <DialogContent style={{ padding: "12px 32px 10px" }}>

                <Typography style={{ width: "100%", whiteSpace: "normal", marginBottom: "10px" }}>
                    Para concluir a solicitação, é necessário validar se os documentos foram anexados corretamente
                </Typography>

                <Divider style={{ marginBottom: "10px" }} />

                {checklist.map((item, index) => (
                    <FormControl key={index} component="fieldset" style={{ margin: "10px 0", width: "100%" }}>

                        <Typography variant="subtitle1" style={{ color: "13px !important" }}>
                            {item.title}
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={item.name}
                                    checked={!!item.checked}
                                    onChange={(e) => handleChange(index, e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={item.label}
                        />

                        <Divider style={{ marginTop: "5px" }} />

                    </FormControl>
                ))}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="text" color="primary">
                    Fechar
                </Button>

                <Button
                    onClick={handleNextStep}
                    variant="text"
                    color="primary"
                    disabled={isAllChecked()}
                >
                    Prosseguir
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChecklistConclude;
