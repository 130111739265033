import React, { useEffect, useState } from "react";

import {
    Button,
    Badge,
    List,
    ListItem,
    ListItemText,
    Divider,
    TextField,
    Select,
    InputLabel,
    Input,
    FormControl,
    MenuItem,
    Checkbox,
    FormHelperText,
    CircularProgress
} from "@material-ui/core";

import { Instagram, YouTube } from "@material-ui/icons";
import TikTokIcon from "../../TikTokIcon";

import customerOfficeService from "../../../domain/services/client/customer.office.service";
import loginService from "../../../domain/services/login.service";

const FormField = ({
    index,
    label,
    description,
    name,
    value,
    onChange,
    options,
    multiple,
    isFirst,
    canEdit,
    invalidField,
    type,
}) => {

    const getValue = () => value || (multiple ? [] : '');

    const getSelect = () => (
        <FormControl id={name} m={2} style={{ width: "100%", marginBottom: "10px" }} error={invalidField}>
            <InputLabel>{description}</InputLabel>
            <Select
                id={name}
                multiple={multiple}
                labelId={name}
                value={getValue()}
                onChange={e => onChange(name, e.target.value)}
                input={<Input />}
                renderValue={(selected) => multiple ? selected.join(", ") : selected}
                inputProps={{ readOnly: !canEdit }}
            >
                {
                    options.map((item, index) => (
                        multiple ? (
                            <MenuItem key={item.id} value={item.name}>
                                <Checkbox checked={getValue().indexOf(item.name) != - 1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ) : (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        )
                    ))
                }
            </Select>
            {invalidField && <FormHelperText>Campo obrigatório</FormHelperText>}
        </FormControl>
    );

    const getText = () => (
        <TextField
            name={name}
            value={value}
            multiline={true}
            onChange={e => onChange(name, e.target.value)}
            variant="outlined"
            label={description}
            style={{ marginTop: "10px" }}
            fullWidth
            inputProps={{ readOnly: !canEdit }}
            error={invalidField}
            helperText={invalidField && "Campo obrigatório"}
        />
    );

    const FormComponent = {
        select: getSelect,
        text: getText,
    };

    return (
        <>
            {!isFirst && <Divider variant="fullWidth" component="li" />}

            <ListItem style={{ display: 'block', padding: "0 0 15px 0", marginTop: "10px" }}>
                <ListItemText
                    primary={
                        <>
                            <Badge badgeContent={index} color="primary" style={{ marginLeft: "10px" }} />

                            <span style={{ marginBottom: "20px", marginLeft: "20px", color: "gray", fontWeight: 600 }}>
                                {label}
                            </span>
                        </>
                    }
                />

                {FormComponent[type]() || <></>}

            </ListItem>
        </>
    )
}

export default function CustomerOfficeForm({
    isInternal,
    onSubmit = () => {},
    customer: customerProp,
    setAlert = () => {}
}) {

    const user = loginService.getUserAuthentication();

    const customer = customerProp || { ...user.customer, name: user.name };

    const profileQuestions = customerOfficeService.getQuestionsToShow(null, false);

    const [profileAnswers, setProfileAnswers] = useState(customer.profileQuestions || {});
    const [invalidFields, setInvalidFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [specialties, setSpecialties] = useState([]);

    useEffect(() => {
        getAreas();
    }, []);

    const getAreas = () =>
        customerOfficeService.getAreas()
            .then(data => setSpecialties(data));

    const save = async (customerId, questions) => {
        setLoading(true);

        customerOfficeService
            .save(customerId, questions)
            .then(() => {
                onSubmit();
                setAlert('Salvo com sucesso!', 'success');
            })
            .finally(() => setLoading(false));
    }

    const handleSave = async (customerId, questions) => {

        const missingAnswers = customerOfficeService.getMissingAnswers(profileAnswers);

        if (missingAnswers.length == 0 || isInternal)
            return save(customerId, questions);

        setInvalidFields(missingAnswers.map(missingAnswer => missingAnswer.name));
    }

    const handleFieldChange = (key, value) =>
        setProfileAnswers({ ...profileAnswers, [key]: value });

    const getSocialMediaLabel = () => (
        <span>
            Queremos conhecer um pouquinho mais sobre você, poderia adicionar as suas redes sociais?
            <div className="inline-flex gap-1 ml-2 translate-y-[6px] items-center">
                <Instagram />
                <YouTube />
                <TikTokIcon />
            </div>
        </span>
    )

    const getSaveButton = () => {

        const hasPermission = loginService.isCustomer() || loginService.isValidRoles(['operator', 'admin']);

        if (!hasPermission)
            return null;

        return (
            <Button
                variant="contained" color="primary"
                onClick={() => handleSave(customer.id, profileAnswers)}
            >
                {loading && <CircularProgress color="white" size={14} className="mr-2" />} Salvar
            </Button>
        )
    }

    return (
        <List>
            {
                profileQuestions.map((item, index) => (
                    <FormField
                        key={index}
                        type={item.type}
                        index={index + 1}
                        label={customerOfficeService.getVariableCopy(item.name + 'Label', getSocialMediaLabel())}
                        description={customerOfficeService.getVariableCopy(item.name + 'Description')}
                        name={item.name}
                        value={profileAnswers[item.name]}
                        options={item.options || specialties}
                        isFirst={!index}
                        multiple={item.multiple}
                        onChange={handleFieldChange}
                        canEdit={loginService.isCustomer() || loginService.isValidRoles(['operator', 'admin'])}
                        invalidField={invalidFields.includes(item.name)}
                    />
                ))
            }
            <div className="float-right">
                {getSaveButton()}
            </div>
        </List>
    );
}
