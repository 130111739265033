import { useEffect, useState } from "react";

import moment from "moment";

import {
    Avatar, Grid, List, ListItem, ListItemText, ListItemIcon, Tooltip, Link,
} from "@material-ui/core";
import { AttachFileOutlined, GetApp, Visibility } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import DocumentPreview from './documents/document.preview.component';
import LoadingProgress from "../LoadingProgress";

import solicitationService from "../../domain/services/solicitation/solicitation.service";

function SkeletonDocuments({ area, modality, emptyComponent = null }) {

    const [skeletons, setSkeletons] = useState([]);
    const [preview, setPreview] = useState({ show: false });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => find(), [area]);

    const find = async () =>
        solicitationService
            .getSkeletons(area, modality)
            .then(res => setSkeletons(res.data || []))
            .finally(() => setIsLoading(false));

    const visibility = async (file, show) => setPreview({ ...file, show });

    const getItemDocument = (item) => {
        let dateTime = moment.utc(item.created).format("DD-MM-YYYY HH:mm");

        return (
            <ListItemText style={{ flex: "5 1 auto" }} size="small"
                primary={item.name} secondary={`${dateTime}`} />
        )
    }

    if (isLoading)
        return (
            <LoadingProgress />
        )

    if (!skeletons.length)
        return emptyComponent || (
            <Alert severity="info">
                Não há anexos
            </Alert>
        );

    return (
        <>

            <section>
                <List
                    component="nav" dense={true}
                    style={{
                        width: "100%", height: "auto", overflow: "auto",
                        borderBottom: "1px solid #e0e0e0", display: "block"
                    }}
                >
                    {
                        skeletons.map((item, i) => (
                            <ListItem button style={{ paddingLeft: "0" }} key={i}>

                                <ListItemIcon>
                                    <Avatar
                                        alt={item.name}
                                        style={{
                                            marginLeft: "10px", color: "white", width: "24px",
                                            height: "24px", fontSize: "10px", fontWeight: 600
                                        }}
                                    >
                                        <AttachFileOutlined style={{ fontSize: "12px" }} />
                                    </Avatar>
                                </ListItemIcon>

                                <Grid container justifyContent="flex-start">
                                    {getItemDocument(item)}
                                </Grid>

                                <Grid container justifyContent="flex-end">

                                    <Tooltip title="Visualizar">
                                        <Link
                                            style={{ marginLeft: "10px", marginRight: "10px" }}
                                            onClick={() => { visibility(item, true) }}
                                        >
                                            <Visibility style={{ fontSize: "16px" }} />
                                        </Link>
                                    </Tooltip>

                                    <Tooltip title="Download">
                                        <Link
                                            href={item.url} target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <GetApp style={{ fontSize: "16px" }} />
                                        </Link>
                                    </Tooltip>

                                </Grid>

                            </ListItem>
                        ))
                    }
                </List>
            </section>

            {preview.show && <DocumentPreview file={preview} onSelect={visibility} />}

        </>
    );
}

export default SkeletonDocuments;


