import { Box, Paper, Typography } from '@material-ui/core';

const MenuPage = ({ children, title, footer }) => {
  return (
    <Paper variant="outlined">
      <Box style={{ minWidth: 250 }}>
        <Box p={3}>
          <Typography style={{ color: '#555', fontSize: '12px', fontWeight: '600' }}>
            {title}
          </Typography>
        </Box>
        {children}
        <Box p={1} style={{ background: '#eee' }}>
          <Typography style={{ color: '#555', fontSize: '12px' }}>{footer}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default MenuPage;
