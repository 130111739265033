import { IconButton } from '@material-ui/core';
import SimpleModal from '../../shared/SimpleModal';
import { Close, WhatsApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import announcementService from '../../../domain/services/marketing/announcement.service';
import { announcements } from '../../../domain/constant/announcements.constant';

export default function ModalInviteWhatsapp({
    open,
    onClose,
}) {

    const closeModal = (additionalInformation) => {
        announcementService.markViewed(announcements.postPurchaseWhatsAppInvite, additionalInformation)
            .then(onClose)
            .catch(() => onClose())
    }

    const handleClose = () => {
        closeModal();
    }

    const handleSubmit = () => {
        closeModal({ stopRecurrence: true });
    }

    return (
        <>
            <SimpleModal
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                bodyStyle={{ padding: 0 }}
            >
                <div className="absolute right-4 top-4 z-[4]">
                    <IconButton
                        aria-label="close"
                        size='small'
                        onClick={handleClose}
                    >
                        <Close className="!text-[#999]" />
                    </IconButton>
                </div>
                <div className="relative bg-[#ece5dd]">
                    <div
                        className='absolute left-0 top-0 w-full h-full'
                        style={{
                            backgroundImage: 'url(https://facilita-app.s3.sa-east-1.amazonaws.com/whatsappBackground.png)',
                            opacity: 0.5,
                        }}
                    />

                    <div className='relative flex flex-col items-center text-base text-center p-10 pb-12 z-[2]'>

                        <div className='relative'>
                            <WhatsApp
                                style={{
                                    fontSize: 60,
                                    position: 'relative',
                                    color: '#fff',
                                    zIndex: 3,
                                    filter: 'drop-shadow(2px 2px 4px #a9a9a9)',
                                }}
                            />
                            <div className="absolute left-0 top-0 w-full h-full"
                                style={{
                                    clipPath: 'polygon(51% 11%, 78.25% 22.5%, 86.96% 46.24%, 77.39% 73.5%, 52.89% 88.51%, 29.75% 81.75%, 14% 84%, 19.31% 68.01%, 15.86% 43.53%, 27.23% 20.51%)',
                                    background: 'radial-gradient(#50ec8a, #329b59)',
                                }}
                            />
                        </div>

                        <div className='mt-8 text-2xl font-semibold'>
                            Receba promoções exclusivas
                        </div>

                        <div className='mt-8'>
                            Ganhe <b>cupons de desconto</b>, renove com <b>condições especiais</b> e tenha acesso a nossa <b>masterclass exclusiva</b> entrando no nosso <b>grupo do WhatsApp</b>.
                        </div>

                        <Link to={{ pathname: 'https://chat.whatsapp.com/FMyq9Xg771S2fI6qRyOnoK' }} target="_blank" className='w-full mt-10'>
                            <button
                                className='py-4 px-10 rounded bg-[#128c7e] text-lg text-white font-bold hover:brightness-105'
                                onClick={handleSubmit}
                            >
                                Aproveitar Oportunidade
                            </button>
                        </Link>
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}