import React, { useEffect, useState, useContext } from 'react';

import { DocsContext } from '../../context/docs.context';

import moment from 'moment';

import {
  Avatar,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Link,
  Dialog,
  DialogTitle,
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Divider
} from '@material-ui/core';
import {
  AttachFileOutlined,
  Close,
  DescriptionOutlined,
  GetApp,
  Visibility,
} from '@material-ui/icons';

import solicitationService from '../../domain/services/solicitation/solicitation.service';
import DocumentPreview from '../solicitations/documents/document.preview.component';

const SkeletonDocumentsList = ({ area, modality }) => {
  const [skeletons, setSkeletons] = useState([]);
  const [preview, setPreview] = useState({ show: false });

  const {
    showSkeletonDocumentsList,
    setShowSkeletonDocumentsList
  } = useContext(DocsContext);

  const find = async () => {
    const res = await solicitationService.getSkeletons(area, modality);
    setSkeletons(res.data || []);
  };

  useEffect(() => find(), [area, modality]);

  const visibility = async (file, show) => {
    setPreview({ ...file, show });
  };

  const getItemDocument = (item) => {
    let dateTime = moment.utc(item.created).format('DD-MM-YYYY HH:mm');

    return (
      <ListItemText
        style={{ flex: '5 1 auto' }}
        size='small'
        primary={item.name}
        secondary={`${dateTime}`}
      />
    );
  };

  return (skeletons || []).length > 0 && (
    <>
      <Dialog open={showSkeletonDocumentsList} onClose={() => setShowSkeletonDocumentsList(!showSkeletonDocumentsList)} maxWidth='sm' fullWidth>
        <DialogTitle style={{ display: 'flex' }}>
          <Typography style={{ marginTop: '8px', fontSize: '1rem', width: '100%' }} variant='h4'>
            <DescriptionOutlined
              style={{
                color: '#376fd0',
                marginBottom: '-5px',
                marginRight: '7px',
              }}
            />
            Esqueletos
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setShowSkeletonDocumentsList(!showSkeletonDocumentsList)}
            style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Card mb={6} style={{ borderBottom: '1px solid #e0e0e0', borderTop: '1px solid #e0e0e0' }}>
          <DialogContent>
            <CardContent style={{ padding: '3px' }}>
              <List
                component='nav'
                dense={true}
                style={{
                  width: '100%',
                  height: 'auto',
                  overflow: 'auto',
                  display: 'block',
                }}
              >
                {skeletons &&
                  skeletons.map((item, i) => (
                    <ListItem button style={{ paddingLeft: '0' }} key={i}>
                      <ListItemIcon>
                        <Avatar
                          alt={item.name}
                          style={{
                            marginLeft: '10px',
                            color: 'white',
                            width: '24px',
                            height: '24px',
                            fontSize: '10px',
                            fontWeight: 600,
                          }}
                        >
                          <AttachFileOutlined style={{ fontSize: '12px' }} />
                        </Avatar>
                      </ListItemIcon>
                      <Grid container justifyContent='flex-start'>
                        {getItemDocument(item)}
                      </Grid>
                      <Grid container justifyContent='flex-end'>
                        <Tooltip title='Visualizar'>
                          <IconButton
                            style={{
                              marginLeft: '10px',
                              marginRight: '10px',
                            }}
                            onClick={() => {
                              visibility(item, true);
                            }}
                          >
                            <Visibility style={{ fontSize: '16px' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Download'>
                          <IconButton href={item.url} target='_blank' rel='noopener noreferrer'>
                            <GetApp style={{ fontSize: '16px', color: '#376fd0' }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </DialogContent>
        </Card>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            onClick={() => setShowSkeletonDocumentsList(!showSkeletonDocumentsList)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {preview.show && <DocumentPreview file={preview} onSelect={visibility} />}
    </>
  );
}

export default SkeletonDocumentsList