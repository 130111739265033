import { get, post, put } from '../api';

import swal from 'sweetalert';

import loginService from '../login.service';
import solicitationService from './solicitation.service';

import stepsSolicitation from '../../constant/steps.solicitation.constant';

import repository from '../../repositories/request.services.repository';
import modalities from '../../constant/modalities.constant';

const checkVersion = async (id) =>
    get({ type: 'user', service: `requests/services/${id}/id` });

const getAvailableServicesToCreateAfterConclusion = async (id) =>
    get({ type: 'user', service: `requests/services/${id}/available-after-conclusion` });

const getDoneServicesForCorrection = async (id) =>
    get({ type: 'user', service: `requests/services/${id}/done-for-correction` });

const findProductionServices = async (id) =>
    get({ type: 'user', service: `requests/services/${id}/production-services` });

const findAllServices = async (id) =>
    get({ type: 'user', service: `requests/services/${id}/all-services` });

const create = async (requestId, data) =>
    post({ type: 'user', service: `requests/services/${requestId}`, data });

const correction = async (requestId, data) =>
    post({ type: 'user', service: `requests/services/${requestId}/correction`, data });

const editService = async (id, data) =>
    put({ type: 'user', service: `requests/services/${id}`, data })

const convertAnalysisToNotion = async (requestId) =>
    put({ type: 'user', service: `requests/services/${requestId}/convert/analysis/notion`, data: {} });

const findAll = async () =>
    repository.findAll()
        .then(res => res.data);

const findAllServiceTypes = async (correspondentId) =>
    repository.findAllServiceTypes(correspondentId)
        .then(res => res.data);

const checkFinalService = async (requestId) => {

    const { data } = await solicitationService.find(requestId).catch(() => ({ data: {} }));

    return data.isFinalService;
}

const isSubService = (request) =>
    (request.mainId && request.id != request.mainId);

const willCreateService = (request) =>
    (!!request.forwardCalculationServiceId || request.forwardToPetition || request.forwardToAnalysis || request.forwardToHearing || request.forwardToHearingInPerson);

const checkToRedirectService = async (status, request) => {

    if (status != stepsSolicitation.CONCLUDED)
        return;

    if (!loginService.isInternal() && ![modalities.hearingAnalysis.serviceKey].includes(request.serviceKey))
        return

    if (!isSubService(request) && !willCreateService(request))
        return;

    const isFinalService = await checkFinalService(request.id);

    if (isFinalService)
        return;

    swal({
        title: "Serviço concluído",
        text: `Você será redirecionado para o próximo serviço da solicitação`,
        icon: "success",
        buttons: { confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' } }
    }).then(() => {
        window.location.href = `/solicitations/${(request.mainId || request.id)}`;
    });
}

const getSelectedServices = (services, request, isEdit, newRequestServiceFlag, checked) =>
    services.reduce((acc, service) => {

        const serviceInRequest = (newRequestServiceFlag == service.requestDatabaseFlag) ?
            checked
            : checkRequestHasService(service, request, isEdit);

        if (serviceInRequest)
            acc.push(service);

        return acc;

    }, []);

const checkRequestHasService = (service, request, isEdit) => {

    const serviceFlag = service.requestDatabaseFlag == 'forwardCalculationServiceId' && !isEdit ? 'needCalculation' : service.requestDatabaseFlag;

    return !!request[serviceFlag] || (request.serviceId == service.id);
}

const isRequestService = (service, productionServices) =>
    productionServices.some(productionService => productionService.serviceId == service.id);

const getRequestFinalService = (request, allServices) =>
    allServices
        .filter(s => checkRequestHasService(s, request))
        .reduce((finalService, service) =>
            service.serviceDevelopmentPriority < finalService.serviceDevelopmentPriority ? service : finalService
        );

export default {
    findAll,
    findAllServiceTypes,
    checkVersion,
    getAvailableServicesToCreateAfterConclusion,
    getDoneServicesForCorrection,
    findProductionServices,
    findAllServices,
    create,
    correction,
    convertAnalysisToNotion,
    checkToRedirectService,
    editService,
    getSelectedServices,
    checkRequestHasService,
    isRequestService,
    getRequestFinalService
}