import { useState } from 'react';

const useSnackbar = (initialState) => {

    const [alertMessage, setAlertMessage] = useState({ ...initialState });

    const setAlert = (text, severity) =>
        setAlertMessage({ ...alertMessage, isOpen: true, text, severity, date: new Date() });

    const closeAlert = () =>
        setAlertMessage({ ...alertMessage, isOpen: false });

    return { snackbarAlert: alertMessage, setAlert, closeAlert };
};

export default useSnackbar;