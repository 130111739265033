import { get, post, put } from './api'

import { daysOfWeek } from '../constant/days-of-week.constant'
import levelComplexityConstant from '../constant/level.complexity.constant'

const CorrespondentService = {}

CorrespondentService.findHistoryByCorrespondentId = async (id) => get({ type: 'user', service: `correspondents/${id}/history` })

CorrespondentService.filter = async (data) => await post({ type: 'user', service: 'correspondents/filter', data })

CorrespondentService.findById = async (id) => await get({ type: 'user', service: `correspondents/${id}` })

CorrespondentService.findAvailabilityByUserId = async (userId) => await get({ type: 'user', service: `correspondents/availability/${userId}` })

CorrespondentService.findFeedbacksFaciliter = async (userId) => await get({ type: 'user', service: `correspondents/faciliters/${userId}/feedbacks` })

CorrespondentService.filterFeedbacksFaciliter = async (filter) =>
    await post({ type: 'user', service: 'correspondents/faciliters/feedbacks-filter', data: filter })

CorrespondentService.getAreas = async () => await get({ type: 'user', service: 'correspondents/faciliters/areas' })

CorrespondentService.getServices = async () => await get({ type: 'user', service: 'correspondents/service-types' })

CorrespondentService.getPetitionModalities = async () => await post({ type: 'user', service: 'requests/modalities/services', data: [1] })

CorrespondentService.getUUID = async () => await get({ type: 'user', service: 'correspondents/external-uuid' })

CorrespondentService.getInvoices = async (userId) => await get({ type: 'user', service: `correspondents/${userId}/invoice` })

CorrespondentService.getInvoicesByPeriod = async (userId, period) =>
    await get({ type: 'user', service: `correspondents/${userId}/invoice?period=${period}` })

CorrespondentService.getInvoiceRequests = async (userId, invoiceId) =>
    await get({ type: 'user', service: `correspondents/${userId}/invoice/${invoiceId}/requests` })

CorrespondentService.getInformationLink = async (faciliterId) =>
    await get({ type: 'user', service: `correspondents/${faciliterId}/information-link` })

CorrespondentService.saveAction = async (correspondent) =>
    correspondent.id ? CorrespondentService.edit(correspondent.id, { ...correspondent }) : CorrespondentService.save({ ...correspondent })

CorrespondentService.save = async (data) => await post({ type: 'user', service: 'correspondents', data: data })

CorrespondentService.externalSave = async (data) => await post({ type: 'user', service: 'correspondents/' + data.uuid, data: data })

CorrespondentService.edit = async (id, data) => {

    Object.keys(data).forEach((key) => !getFieldsToEdit().includes(key) && delete data[key]);

    return await put({ type: 'user', service: `correspondents/${id}`, data });
}

CorrespondentService.action = async (id, reason) => put({ type: 'user', service: `correspondents/${id}/action`, data: reason })

CorrespondentService.concludedRating = async (rating, members, isAnalyseRating = false) =>
    await Promise.all(
        members.map(
            async (member) =>
                await put({
                    type: 'user',
                    service: `requests/${rating.requestId}/members/rating/concluded`,
                    data: { ...rating, userId: member.userId, isAnalyseRating },
                }),
        ),
    )

CorrespondentService.mapCorrespondent = (correspondent) => {
    let faciliterInfo = !correspondent.isFaciliter ? newFaciliterInfo() : correspondent.faciliterInfo
    let bankData = !correspondent.bankData ? newBankData() : correspondent.bankData

    return { ...correspondent, faciliterInfo, bankData }
}

CorrespondentService.newCorrespondent = () => ({
    name: '',
    email: '',
    rg: '',
    cpf: '',
    oab: '',
    oabUf: '',
    cellphone: '',
    isFaciliter: false,
    inactive: false,
    faciliterInfo: newFaciliterInfo(),
    bankData: newBankData(),
    address: newAddress(),
})

CorrespondentService.mapToExcel = (correspondents) =>
    correspondents.map((item) => ({
        ID: item.id,
        Nome: item.name,
        Email: item.email,
        RG: item.rg,
        CPF: item.cpf,
        Celular: item.cellphone,
        OAB: item.oab,
        'OAB UF': item.oabUf ? new Date(item.created).toLocaleDateString() : null,
        Disponibilidade: CorrespondentService.getAvailability(item.faciliterInfo),
        Especialidade: (item.specialties || '').toString(),
        'Nível de Complexidade':
            item.additionalInformation && item.additionalInformation.levelComplexity
                ? levelComplexityConstant[item.additionalInformation.levelComplexity].label
                : '',
        Score: item.score && parseFloat(item.score).toFixed(2),
        'É Premium ?': item.faciliterInfo?.isPremium ? 'Sim' : 'Não',
        'Petições Na Semana': item.totalWeek,
        'Petições Em Andamento': item.inProgress,
        'Petições Não Iniciadas': item.notStarted,
        conta: (item.bankData || {}).account,
        agencia: (item.bankData || {}).agency,
        banco: (item.bankData || {}).bank,
        PIX: (item.bankData || {}).pix,
        "Favorecido": item?.bankData?.favored,
        "CPF/CNPJ do Titular da Conta": item?.bankData?.accountOwnerIdentification

    }))

CorrespondentService.getAvailability = (faciliterInfo) =>
    `${getAvailabilityDays(faciliterInfo, 'startDay')} - ${getAvailabilityDays(faciliterInfo, 'endDay')} / ${getAvailabilityHours(
        faciliterInfo,
        'startHour',
    )} - ${getAvailabilityHours(faciliterInfo, 'endHour')}`

CorrespondentService.getAvailabilityDays = (faciliterInfo) =>
    `${getAvailabilityDays(faciliterInfo, 'startDay')} - ${getAvailabilityDays(faciliterInfo, 'endDay')}`

CorrespondentService.getAvailabilityHours = (faciliterInfo) =>
    `${getAvailabilityHours(faciliterInfo, 'startHour')} - ${getAvailabilityHours(faciliterInfo, 'endHour')}`

CorrespondentService.recoverSpecialties = (specialties, correspondent) => {
    if (!correspondent.isFaciliter || !(correspondent.specialties || []).length > 0) return { faciliterSpecialties: [], faciliterSubspecialties: [] }

    const faciliterSpecialties = specialties
        .filter((s) => !s.parentId && (correspondent.specialties || []).find((cs) => cs == s.name))
        .map((s) => s.name)

    const faciliterSubspecialties = specialties
        .filter((s) => s.parentId && (correspondent.specialties || []).find((cs) => cs == s.name))
        .map((s) => s.name)

    return { faciliterSpecialties, faciliterSubspecialties }
}

let newBankData = () => ({ bank: '', agency: '', account: '', pix: '' })

let newFaciliterInfo = () => ({
    availability: { startDay: 0, endDay: 0, startHour: '', endHour: '' },
    specialties: [],
})

let newAddress = () => ({ street: '', district: '', zipCode: '', number: '' })

let getAvailabilityDays = (faciliterInfo, day) => {
    if (faciliterInfo && faciliterInfo.availability) {
        let availability = faciliterInfo.availability
        return daysOfWeek[availability[day]].label
    }

    return null
}

let getAvailabilityHours = (faciliterInfo, hour) => {
    if (faciliterInfo && faciliterInfo.availability) return faciliterInfo.availability[hour]

    return null
}

const getFieldsToEdit = () => [
    "name",
    "email",
    "oab",
    "oabUf",
    "rg",
    "cpf",
    "cellphone",
    "bankData",
    "faciliterInfo",
    "additionalInformation",
    "address",
    "hearingDistricts",
    "doesOnlyHearing"
];

export default CorrespondentService
