import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Typography, IconButton, InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import solicitationService from '../../../domain/services/solicitation/solicitation.service';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { trackPromise } from 'react-promise-tracker';
import memberExternalTipService from "../../../domain/services/member/member.external.tip.service";
import loginService from "../../../domain/services/login.service";
import masksProvider from '../../../providers/masks';

const RequestCustomerTipForm = ({ request = {}, setAlert, isOpen, onClose, onFinish }) => {

  const [tipAmount, setTipAmount] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleSendTip = (mainId, tipAmount) => {
    setConfirmOpen(false)
    const customerId = loginService.getUserAuthentication().customer.id;
    trackPromise(
      memberExternalTipService
        .sendTip({
          mainId,
          customerId,
          value: parseFloat(tipAmount.replace(',', '.'))
        })
        .then(() => setAlert('Gorjeta enviada com sucesso!', 'success'))
        .then(() => onFinish())
        .then(() => onClose())
        .catch((err) => setAlert(err.response?.data?.message || 'Ocorreu um erro ao fornecer a gorjeta para o faciliter!', 'error')
    ))
   
  };

  const handleChangeAmount = (e) => {
    const newValue = masksProvider.formatCurrency(e.target.value);

    e.target.value = newValue;
    setTipAmount(newValue);
  };

  const presetTipAmounts = ['20', '50', '100'];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onClose}
        maxWidth='sm'
      >
        <DialogTitle >
          Sua Gorjeta faz a diferença! 🌟
          <IconButton onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            #{request.mainId || request.id} {solicitationService.getTitle(request)}</Typography>
          <hr style={{ margin: '20px 0' }} />
          <Typography variant="subtitle1" >
            Obrigado por escolher reconhecer o trabalho bem feito!
            <br></br><br></br>
          </Typography>
          <Typography variant="subtitle1" >
            Sua contribuição é um sinal valioso do reconhecimento em nosso serviço.
            Por favor, selecione o valor que gostaria de dar como gorjeta para os faciliters que
            trabalharam na sua solicitação:
          </Typography>
          <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            {presetTipAmounts.map((presetAmount, index) => (
              <Button
                style={{ margin: '10px' }}
                key={index}
                variant='outlined'
                size='large'
                onClick={() => setTipAmount(presetAmount + ',00')}
              >
                {`R$ ${presetAmount}`}
              </Button>
            ))}
          </Grid>
          <hr style={{ margin: '20px 0' }} />

          <Typography variant="subtitle2">
            Ou insira um valor à sua escolha:
          </Typography>
          <Grid container justifyContent='center'>

            <TextField
              style={{ maxWidth: '180px' }}
              value={tipAmount}
              onChange={(e) => handleChangeAmount(e)}
              margin="dense"
              variant="outlined"
              label="Valor a ser enviado"
              type="text"
              InputProps={{
                startAdornment: <InputAdornment position="center">R$</InputAdornment>,
                style: { fontSize: '1.5rem' }
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={() => setConfirmOpen(true)}>
            Enviar gorjeta
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogTitle>Confirmar pagamento</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Você tem certeza que deseja enviar uma gorjeta de <strong>R$ {tipAmount}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant='contained' onClick={() => setConfirmOpen(false)}>
            Cancelar
          </Button>
          <Button size="large" color='primary' variant='contained' onClick={() => handleSendTip(request.mainId || request.id, tipAmount)}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RequestCustomerTipForm;
