import {
    Grid,
    List, ListItem as MuiListItem, ListItemText as MuiListItemText
  } from "@material-ui/core";
  import React from "react";
  import styled from "styled-components/macro";
  
  
  const Wrapper = styled.div`
    padding: ${(props) => props.theme.spacing(1) / 4}px
      ${(props) => props.theme.spacing(4)}px;
    position: relative;
  `;
  
  const ListItem = styled(MuiListItem)`
    display: inline-block;
    width: auto;
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
    &,
    &:hover,
    &:active {
      color: #ff0000;
    }
  `;
  
  const ListItemText = styled(MuiListItemText)`
    span {
      color: ${(props) => props.theme.footer.color};
    }
  `;
  
  function AlternativeFooter() {
    return (
      <Wrapper>
          <Grid container item justifyContent="center">
            <List>
              <ListItem button={true}>
                <ListItemText
                  primary={`© ${new Date().getFullYear()} - Facilita Jurídico`}
                />
              </ListItem>
            </List>
          </Grid>
      </Wrapper>
    );
  }
  
  export default AlternativeFooter;
  