import React from "react";

import moment from "moment"

import {
    List, ListItem, ListItemText, ListItemIcon,
    Typography, Tooltip, Link, Avatar, Chip, Grid, Checkbox
} from "@material-ui/core";

import {
    AttachFileOutlined, Close, GetApp, Visibility,
    Edit, LockOpenOutlined, LockOutlined, FileCopyOutlined
} from "@material-ui/icons";
import { Check } from "react-feather";

import documentsService from "../../../domain/services/solicitation/documents.service";
import loginService from "../../../domain/services/login.service";
import { action } from '../../../domain/constant/documents.history.constant';

const RequestDocumentList = (
    { request, documents = [], selectFile, visibility,
        handleOpenConfig, removeDocument = () => { },
        update, isEdit, isProcessDocumentSelection,
        setProcessMainDocument = () => { }, unsetProcessMainDocument = () => { }
    }
) => {

    const checkColorFile = (item) => {

        let color = "gray";

        if (item.verificationInfo)
            color = (!item.verificationInfo.success) ? "#ff0000a6" : "#008000b8";

        return color;
    }

    const checkColorRow = (item) => item.checked ? "#f0f8ffdb" : "white";

    const getTag = (key, item) => {

        const tagInfo = documentsService.tagInfoByKey(key, item, request);

        return (
            <Tooltip title={tagInfo.message}>
                <Grid item style={{ marginRight: 8 }}>
                    <Chip
                        label={tagInfo.title} size="small"
                        style={{
                            background: tagInfo.color, color: '#FFF',
                            height: 18, fontSize: 10, fontWeight: 600,
                        }}
                    />
                </Grid>
            </Tooltip>
        )
    }

    const getInternalTags = (item) => {

        if (loginService.isCustomer())
            return;

        return (
            <>

                {item.forCorrection && getTag('correction', item)}

                {item.isProcessMainDocument && getTag('isProcessMainDocument', item)}

                {item.isServiceMainDocument && getTag('serviceMainDocument', item)}

                {item.isVisibleCustomer && (
                    item.serviceId ? getTag('finalVersion', item) : getTag('isVisibleCustomer', item)
                )}

                {loginService.isInternal() && item.isIaPetitionReview && getTag('isIAPetitionReview', item)}

                {!loginService.isCustomer() && item.wasDevelopedWithIa && getTag('wasDevelopedWithIa', item)}

            </>
        )
    }

    const getCustomerTags = (item) => {

        if (!loginService.isCustomer())
            return;

        return (
            <>

                {item.isProcessMainDocument && getTag('isProcessMainDocument', item)}

                {item.forCorrection && getTag('correction', item)}

                {item.source != 'customer' && getTag('internalUpload', item)}

            </>
        )
    }

    const handleChangeProcessDocument = (doc) => {
        if (doc.isProcessMainDocument) return unsetProcessMainDocument(doc);
        return setProcessMainDocument(doc);
    }

    const getItemDocument = (item) => {

        const documentValue = {
            name: item.name,
            created: (item.created || moment()),
            senderUserName: loginService.isCustomer() ? "" : `${(item.senderUserName || loginService.getUserAuthentication().name)} - `,
            error: (item.verificationInfo && !item.verificationInfo.success) ? " - Arquivo Corrompido" : ""
        }

        const dateTime = convertDateTimeToLocaleString(documentValue.created);

        return (
            <ListItemText style={{ flex: "5 1 auto", marginRight: 10 }} size="small"
                primary={
                    <Grid>
                        <Grid container alignItems="center">

                            <Grid style={{ marginRight: 8 }}>
                                {documentValue.name} {documentValue.error}
                            </Grid>

                            {getInternalTags(item)}

                            {getCustomerTags(item)}

                        </Grid>
                        {getNameTypeChip(item)}
                    </Grid>
                }
                secondary={`${documentValue.senderUserName}${dateTime}`}
            />
        );
    }

    const getNameTypeChip = (item) => {

        if (!item.nameType) return null

        const title = documentsService
            .uploadListByAreaId(request.areaId)
            .find(ul => ul.nameType == item.nameType)?.title

        return (
            <Chip
                avatar={<Check />} size="small"
                style={{
                    background: '#B9F8D3', fontStyle: 'italic',
                    color: '#184D47', margin: '5px 0', height: '20px'
                }}
                label={
                    <Tooltip title={title || ""}>
                        <Typography style={{
                            overflow: "hidden", textOverflow: "ellipsis",
                            maxWidth: '225px', fontSize: "10px"
                        }}>
                            {title}
                        </Typography>
                    </Tooltip>
                }
            />
        )
    }

    const convertDateTimeToLocaleString = (date) => {

        let convertDate = date ? new Date(date) : "";

        if (convertDate)
            convertDate = `${convertDate.toLocaleDateString('pt-BR')} - ${convertDate.toLocaleTimeString('pt-BR')} `;

        return convertDate;
    }

    const ActionButton = ({ description, color, icon: Icon, onClick }) => (
        <Tooltip title={description}>
            <Link style={{ marginLeft: 8, color }} onClick={onClick}>
                <Icon style={{ fontSize: 16 }} />
            </Link>
        </Tooltip>
    )

    const getVisibilityButton = (doc) => {

        if (!visibility)
            return null;

        return (
            <ActionButton
                description="Visualizar documento" icon={Visibility}
                onClick={() => viewFile(doc)}
            />
        )
    }

    const downloadFile = async (doc) => {
        if (loginService.isFaciliter())
            await registerDocumentHistory(doc, action.DOWNLOAD_DOCUMENT)

        documentsService.downloadFile(doc.url, doc.name)
    }

    const viewFile = async (doc) => {
        if (loginService.isFaciliter())
            await registerDocumentHistory(doc, action.VIEW_DOCUMENT)

        visibility(doc, true)
    }

    const registerDocumentHistory = async (doc, action) => {
        const documentHistory = {
            documentId: doc.id,
            action: action.action,
            description: `O faciliter ${loginService.getUserAuthentication().name} ${action.pastAction} o arquivo ${doc.name}.`
        }

        await documentsService.createDocumentInteractionRecord(request.id, documentHistory).catch(err => null)
    }

    const getRemoveButton = (doc, i) => {

        if (doc.isProcessMainDocument && loginService.isFaciliter()) return null;

        const uploadedDoc = doc.senderUserId == loginService.getUserAuthentication().id;
        const canRemove = documentsService.isAllowEnableToCustomer(request) || uploadedDoc

        if (!canRemove || isProcessDocumentSelection)
            return null;

        return (
            <ActionButton
                description="Remover documento" icon={Close}
                onClick={() => removeDocument(doc, i)} color='red'
            />
        )
    }

    const getEditServiceConfigButton = (doc) => {

        const uploadedDoc = doc.senderUserId == loginService.getUserAuthentication().id;
        const canEditConfig = documentsService.isAllowEnableToCustomer(request) || uploadedDoc

        if (!canEditConfig || doc.source == 'customer' || loginService.isCustomer() || isProcessDocumentSelection)
            return null;

        if (request.isLegacyServiceDocument)
            return (
                <ActionButton
                    description="Editar configurações de serviço"
                    icon={doc.isVisibleCustomer ? LockOpenOutlined : LockOutlined}
                    color={!doc.isVisibleCustomer && 'gray'}
                    onClick={() => update(doc.id, { isVisibleCustomer: !doc.isVisibleCustomer })}
                />
            )

        return (
            <ActionButton
                description="Editar configurações de serviço"
                icon={Edit} onClick={() => handleOpenConfig(doc, true)}
            />
        )
    }

    const scroll = !!(isProcessDocumentSelection && documents.length > 3) || false;

    const listStyle = {
        width: "100%",
        height: "auto",
        marginBottom: 12,
        overflow: isProcessDocumentSelection ? "auto" : "visible",
        display: "block",
        maxHeight: scroll ? 200 : "none"
    };


    if (documents.length)
        return (
            <section style={{ width: "100%" }}>

                {!!isEdit &&
                    <>
                        <Typography variant='h6' style={{ marginBottom: 10, display: 'block' }}>
                            <strong>Documentos da Solicitação </strong>
                        </Typography>

                        <Typography variant='caption' color='textSecondary'>
                            Selecione os documentos para Ativar/Cancelar a opção de Download
                        </Typography>
                    </>
                }

                <List
                    component="nav" dense={true}
                    style={listStyle}
                >
                    {documents.map((doc, i) => (
                        !(doc.isProcessMainDocument && doc.source != 'customer' && loginService.isCustomer()) && (
                            <ListItem
                                key={i}
                                button
                                onClick={() => handleChangeProcessDocument(doc)}
                                style={{
                                    background: checkColorRow(doc),
                                    paddingLeft: 0,
                                    width: "100%",
                                    borderBottom: i < documents.length - 1 ? 'solid 1px #ededed' : 'none',
                                }}
                            >

                                <section
                                    style={{ width: "100%", display: "contents" }}
                                    onClick={() => !!selectFile && selectFile(i, !doc.checked)}
                                >

                                    <ListItemIcon>
                                        <Avatar
                                            alt={doc.name}
                                            style={{
                                                background: checkColorFile(doc),
                                                color: "white", width: 24, height: 24,
                                                fontSize: 10, fontWeight: 600, marginLeft: 15
                                            }}
                                        >
                                            <AttachFileOutlined style={{ fontSize: "16px" }} />
                                        </Avatar>
                                    </ListItemIcon>

                                    {getItemDocument(doc)}


                                </section>

                                {getVisibilityButton(doc)}

                                {
                                    !isProcessDocumentSelection &&
                                    <ActionButton
                                        description="Baixar documento" icon={GetApp}
                                        onClick={() => downloadFile(doc)}
                                    />
                                }

                                {getRemoveButton(doc, i)}

                                {getEditServiceConfigButton(doc)}

                                {
                                    isProcessDocumentSelection &&
                                    <Checkbox
                                        edge="end"
                                        checked={doc.isProcessMainDocument}
                                        onChange={() => { }}
                                        color="primary"
                                    />
                                }

                            </ListItem>

                        )

                    ))}
                </List>

            </section>
        )

    return null;
}

export default RequestDocumentList;