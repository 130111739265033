import api from '../api';

import EventEmmiter from '../../../providers/event.emmiter';

export const events = new EventEmmiter();

const find = (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/comments/` });

const save = async (comment) =>
    api.post({ type: 'user', service: `requests/${comment.requestId}/comments`, data: comment });

const update = async (comment) =>
    api.put({ type: 'user', service: `requests/${comment.requestId}/comments/${comment.id}`, data: comment });

const remove = async (comment) =>
    api.remove({ type: 'user', service: `requests/${comment.requestId}/comments/${comment.id}` });

export default {
    find,
    save,
    update,
    remove
}