const getProfileRequiredFields = (data, isFinishingRegister, isCorporation) => {

    const requiredFields = ['oab', 'channel'];

    if (!isFinishingRegister)
        requiredFields.concat(['personType', 'identity'])

    if (data.personType == 'corporation' || isCorporation)
        requiredFields.push('companyName');

    if (data.acquisition?.channel == 'Outros')
        requiredFields.push('description');

    return requiredFields;
}

const getBasicDataRequiredFields = (data) => {

    const requiredFields = ['name', 'email', 'telephone'];

    if (!data.id)
        requiredFields.push('password');

    return requiredFields;
}

export const getAddressRequiredFields = () =>
    (['zipCode', 'street', 'district', 'state', 'city', 'number']);

export const getRequiredFields = (step, data = {}) => ([
    () => getBasicDataRequiredFields(data),
    () => getProfileRequiredFields(data),
    () => getAddressRequiredFields()
])[step - 1]();

export const getFinishRegisterRequiredFields = (data = {}, isCorporation, withAddress) => {

    const profileFields = getProfileRequiredFields(data, true, isCorporation);

    if (!withAddress)
        return profileFields;

    return [...profileFields, ...getAddressRequiredFields()]
}

export const officeCollaboratorsRange = ['1', '2 a 3', '4 a 5', '6 a 10', 'Acima de 10'];

export const purchaseReasons = [
    'Otimização de tempo',
    'Ampliar a área de atuação',
    'Falta de tempo',
    'Desafogar as atividades',
    'Organização',
    'Aumento da demanda',
    'Troca de experiência',
    'Dificuldade em contratar profissionais qualificados',
    'Falta de tempo para atender os clientes',
    'Redução de custos operacionais',
    'Ter mais tempo para os clientes',
    'Outros'
];

export default {
    getRequiredFields,
    getFinishRegisterRequiredFields,
    getAddressRequiredFields,
    officeCollaboratorsRange,
    purchaseReasons
};