import stepsSolicitation from '../../constant/steps.solicitation.constant';

import api from '../api';
import loginService from '../login.service';

import memberRepository from '../../repositories/member/member.repository';

const find = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/members` });

const findReviewers = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/members/reviewers` });

const findAnalyst = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/members/analysts` });

const save = async (requestId, member) =>
    api.post({ type: 'user', service: `requests/${requestId}/members`, data: member });

const externalLink = async (requestId, member) =>
    api.post({ type: 'user', service: `requests/${requestId}/members/external-link`, data: member });

const saveFaciliterReviewer = async (data) =>
    api.post({ type: 'user', service: `requests/${data.requestId}/members/faciliter-reviewer`, data });

const saveReviewer = async (data) =>
    api.post({ type: 'user', service: `requests/${data.requestId}/members/reviewer`, data });

const saveAnalyst = async (data) =>
    api.post({ type: 'user', service: `requests/${data.requestId}/members/analyst`, data });

const remove = async (member) =>
    api.post({ type: 'user', service: `requests/${member.requestId}/members/${member.id}`, data: member });

const saveServiceIaRating = async (rating) =>
    api.post({ type: 'user', service: `requests/${rating.requestId}/members/service-ia-rating`, data: rating });

const updateInternalPetitionInfo = async (internal, requestId, userId) =>
    api.put({
        type: 'user', service: `requests/${requestId}/members/internal/users/${userId}`,
        data: internal
    });

const updateFaciliterPrice = async (requestId, responsibleId, price) =>
    api.put({ type: 'user', service: `/requests/${requestId}/members/faciliter-price`, data: { responsibleId, price } });

const updateFaciliterReviewerPrice = async (requestId, price) =>
    api.put({ type: 'user', service: `/requests/${requestId}/faciliter-reviewer-price`, data: { price } });

const removeReviewer = async (data) =>
    api.post({ type: 'user', service: `requests/${data.requestId}/members/reviewer/${data.id}`, data });

const removeAnalyst = async (data) =>
    api.post({ type: 'user', service: `requests/${data.requestId}/members/analyst/${data.id}`, data });

const removeFaciliterReviewer = async (data) =>
    api.remove({ type: 'user', service: `requests/${data.requestId}/members/faciliter-reviewer`, data: { data } })

const findConcludeds = async (params) =>
    api.post({ type: 'user', service: `requests/0/members/concluded/filter`, data: params });

const map = (requestId, correspondents, members) => {
    return (correspondents || []).map(correspondent => {

        const member = members.find((member) => member.correspondentId == correspondent.id) || { id: null };

        return {
            ...correspondent,
            requestId,
            correspondentId: correspondent.id,
            memberId: member.id,
            checked: !!member.id
        }

    });
}

const mergeReviewers = (reviewers, linkeds) => {
    return (reviewers || []).map(reviewer => {
        const member = linkeds.find(item => item.userId == reviewer.id) || { id: null };
        return { ...reviewer, reviewerId: member.id, checked: !!member.id }
    });
}

const mergeAnalysts = (analysts, linkeds) => {
    return (analysts || []).map(analyst => {
        const member = linkeds.find(item => item.userId == analyst.id) || { id: null };
        return { ...analyst, analystId: member.id, checked: !!member.id }
    });
}

const findAllFeedbackByRequestId = (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/members/find-all` });

const findAnalyseFaciliterByRequestId = (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/members/faciliter-analyst` });

const removeRatingDiscount = (requestId, responsibleId) =>
    memberRepository.removeRatingDiscount(requestId, responsibleId);

const update = (member) =>
    memberRepository.update(member);

const checkToShowCorrectionConfirmationModalToFaciliter = (request) => {

    const { responsibleOperationTimestamps, status } = request;

    const correctionTimestamps = responsibleOperationTimestamps?.correction || {};
    const currentCorrectionTimestampsKey = Object.keys(correctionTimestamps).sort((a, b) => b - a)[0];
    const currentCorrectionTimestamps = correctionTimestamps[currentCorrectionTimestampsKey] || {};

    return loginService.isFaciliter()
        && status === stepsSolicitation.NOT_STARTED
        && !!currentCorrectionTimestamps.correctionConfirmationSentAt
        && !currentCorrectionTimestamps.correctionConfirmedAt;
}

const addCorrectionTimestamp = (requestId, responsibleId, newCorrectionTimestampKeys) =>
    memberRepository.addCorrectionTimestamp(requestId, responsibleId, newCorrectionTimestampKeys);

const confirmRequestCorrection = async (request = {}) => {

    const { realId, responsibleId } = request;
    const newTimestampKeysToAdd = ['correctionConfirmedAt'];

    return addCorrectionTimestamp(realId, responsibleId, newTimestampKeysToAdd);
}

const cancelRequestCorrection = async (request = {}) => {

    const { realId, responsibleId } = request;
    const newTimestampKeysToAdd = ['correctionCancelledAt'];

    await addCorrectionTimestamp(realId, responsibleId, newTimestampKeysToAdd);

    const data = {
        id: request.responsibleId,
        addtionalInformation: {
            ...request.responsibleAdditionalInformation,
            reason: 'Faciliter indicou que não conseguiria fazer a correção na plataforma.'
        }
    }
    return remove(data);
}

export default {
    find,
    findReviewers,
    findAnalyst,
    save,
    externalLink,
    saveReviewer,
    saveFaciliterReviewer,
    saveAnalyst,
    remove,
    saveServiceIaRating,
    update,
    updateInternalPetitionInfo,
    updateFaciliterPrice,
    updateFaciliterReviewerPrice,
    removeReviewer,
    removeAnalyst,
    removeFaciliterReviewer,
    findConcludeds,
    map,
    mergeReviewers,
    mergeAnalysts,
    findAllFeedbackByRequestId,
    findAnalyseFaciliterByRequestId,
    removeRatingDiscount,
    checkToShowCorrectionConfirmationModalToFaciliter,
    confirmRequestCorrection,
    cancelRequestCorrection
}