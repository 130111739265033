import moment from 'moment';
import ReactPlayer from 'react-player';
import { Typography } from "@material-ui/core";
import { Mic } from "@material-ui/icons";

import loginService from '../../../domain/services/login.service';
import announcementService from '../../../domain/services/marketing/announcement.service';

import { announcements } from '../../../domain/constant/announcements.constant';
import ScrollPopup from '../../shared/ScrollPopup';
import { useState } from 'react';

export default function AudioRecorderAnnouncementFlyout({ isNewRequest, anchorRef }) {
    
    const checkIfCanShowModal = () => {
        const currentUser = loginService.getUserAuthentication();
        const customerAlreadySawAnnouncement = announcementService.isShowed(announcements.registerRequestWithAudio);
        const createdAfterLaunch = moment(currentUser.created).isAfter(moment('2024-04-08'));
        return loginService.isCustomer() && !customerAlreadySawAnnouncement && !createdAfterLaunch && isNewRequest && !currentUser.isFirstAccess;
    };
    
    const [showPopup, setShowPopup] = useState(checkIfCanShowModal());

    const handleClose = () => {
        announcementService.markViewed(announcements.registerRequestWithAudio);
        setShowPopup(false);
    }

    return (
        <ScrollPopup
            anchorRef={anchorRef}
            show={showPopup}
            onClose={handleClose}
            checkOnScroll={checkIfCanShowModal}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 15,
                marginBottom: 10,
                gap: 4,
                textAlign: 'center',
            }}>

                <strong
                    className="font-semibold"
                    style={{ fontSize: '1.15em', marginBottom: 5, fontWeight: 'bold' }}
                >
                    🌟 Nova ferramenta de gravação!
                </strong>

                <Typography variant="body2" className="text-sm">
                    Agora você pode enviar áudios em sua solicitação.
                </Typography>

                <Typography variant="body2" className="text-sm">
                    É só clicar em <Mic /> e gravar!
                </Typography>

            </div>

            <div style={{ overflow: 'hidden', clipPath: 'inset(2px)' }}>
                <ReactPlayer
                    url="https://facilita-app.s3.sa-east-1.amazonaws.com/audioFinal.mp4"
                    playing loop muted
                    width="500px"
                    height="auto"
                />
            </div>
        </ScrollPopup>
    )
}