import { useEffect, useState } from 'react';

import { Collapse } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Check, Warning } from '@material-ui/icons';

import datetimeProvider from '../../providers/datetime.provider';

import crmSellerService from '../../domain/services/crm/crm.seller.service';

const SellerDailyTargetSidebarPanel = () => {

    const [sellerGoals, setSellerTarget] = useState({});
    const [goalsUpdateTime, setGoalsUpdateTime] = useState(new Date());

    useEffect(() => {
        findSellerTodayarget();
    }, []);

    const findSellerTodayarget = () =>
        crmSellerService
            .findSellerTodayTarget()
            .then(res => {
                setSellerTarget(res);
                updateTime();
            })
            .catch(console.error);

    const updateTime = () =>
        setGoalsUpdateTime(new Date());

    const styleInfoByTargetReached = {
        true: {
            title: 'Parabéns! Você bateu sua meta, continue o bom trabalho.',
            severity: 'success',
            getIcon: () => <Check fontSize='small' style={{ color: 'green', marginLeft: 2 }} />,
            revenueToReachTargetLabel: 'acima da meta',
            targetLabelColor: '[green]',
            opportunitiesToReachTargetLabel: 'vinculações acima da meta',
        },
        false: {
            title: 'Você ainda não atingiu suas metas!',
            severity: 'warning',
            getIcon: () => <Warning style={{ fontSize: 16, color: 'orange', marginLeft: 2 }} />,
            revenueToReachTargetLabel: 'para a meta',
            opportunitiesToReachTargetLabel: 'vinculações para a meta',
        }
    };

    const getEmptyListFeedbackCard = () => {

        if (!sellerGoals.percentOfTarget)
            return null;

        const isTargetReached = sellerGoals.percentOfTarget >= 100 && sellerGoals.opportunitiesToReachTarget == 0;
        const { severity, title } = styleInfoByTargetReached[isTargetReached];

        return (
            <div className='mb-3'>
                <Alert icon={false} severity={severity}>
                    <AlertTitle>{title}</AlertTitle>
                </Alert>
            </div>
        )
    }

    const formatCurrency = (value) =>
        new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(value || 0);

    const getRevenueToReachTargetInfo = () => {

        const isTargetReached = sellerGoals.percentOfTarget >= 100;
        const { getIcon, revenueToReachTargetLabel, targetLabelColor } = styleInfoByTargetReached[isTargetReached];
        const value = (isTargetReached ? sellerGoals.extraRevenue : sellerGoals.revenueToReachTarget) || '';

        return (
            <div className='flex flex-col mb-3'>
                <span className='text-lg items-center flex gap-1'>
                    {formatCurrency(value)}
                    {getIcon()}
                </span>
                <p className={`text-xs mt-[-2px] text-${targetLabelColor || 'gray-500'}`}>
                    {revenueToReachTargetLabel}
                </p>
            </div>
        )
    }

    const getOpportunitiesToReachTargetInfo = () => {

        const isTargetReached = sellerGoals.opportunitiesToReachTarget == 0;
        const { getIcon, opportunitiesToReachTargetLabel, targetLabelColor } = styleInfoByTargetReached[isTargetReached];
        const value = isTargetReached ? sellerGoals.extraLinkedOpportunities : sellerGoals.opportunitiesToReachTarget;

        return (
            <div className='flex flex-col mb-4'>
                <span className='text-lg items-center flex gap-1'>
                    {value || 0}
                    {getIcon()}
                </span>
                <p className={`text-xs mt-[-2px] text-${targetLabelColor || 'gray-500'}`}>
                    {opportunitiesToReachTargetLabel}
                </p>
            </div>
        )
    }

    return (
        <Collapse in={!!parseFloat(sellerGoals.target)}>
            <div className='bg-white pt-6 pb-3 px-5 text-black flex flex-col gap-1 border-b border-gray'>

                {getEmptyListFeedbackCard()}

                <div className='flex flex-row justify-between items-center gap-2 mb-2'>

                    <div className='text-[8px]'>
                        {sellerGoals.percentOfTarget || 0}%
                    </div>

                    <div className='w-[90%] mr-3'>
                        <div className="overflow-hidden rounded-full bg-gray-200">
                            <div style={{ width: `${sellerGoals.percentOfTarget}%` }} className="h-1 rounded-full bg-indigo-600" />
                        </div>
                    </div>

                </div>

                <p className='mb-1'>
                    Meta do dia: <b>{formatCurrency(sellerGoals.target)}</b>
                </p>

                <p className='mb-4'>
                    Vendas do dia: <b>{formatCurrency(sellerGoals.todayRevenue)}</b>
                </p>

                {getRevenueToReachTargetInfo()}

                {getOpportunitiesToReachTargetInfo()}


                <p className='text-[10px] text-gray-400 text-end'>
                    Atualizado dia {datetimeProvider.formatDateTime(goalsUpdateTime, 'DD/MM/YYYY [às] HH:mm', true)}
                </p>

            </div>
        </Collapse>
    );
}

export default SellerDailyTargetSidebarPanel;