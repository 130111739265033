import { Button, Tooltip } from "@material-ui/core";
import { FavoriteOutlined, FavoriteBorderOutlined } from "@material-ui/icons";
import { useState } from "react";
import requestFavoriteService from "../../../domain/services/request/request.favorite.service";
import LoadingSpin from "../../LoadingSpin";

export default function CustomerRequestFavoriteButton({ requestData, setAlert }) {

    const [mouseOverFavoriteButton, setMouseOverFavoriteButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [favoritedId, setFavoritedId] = useState(requestData.favoritedId);

    const isFavorited = !!favoritedId;

    const removeFavorite = () => {
        requestFavoriteService.removeFavorite(requestData.id, favoritedId)
            .then(() => {
                setAlert('Favorito Removido com Sucesso!');
                setFavoritedId(null);
            })
            .catch(() => setAlert('ocorreu um problema, por favor tente novamente', 'error'))
            .finally(() => setLoading(false));
    }

    const addFavorite = () => {
        requestFavoriteService
            .createFavoriteService({
                requestId: requestData.id,
                customerId: requestData.customerId,
                serviceId: requestData.serviceId,
                areaId: requestData.areaId,
                modalityId: requestData.modalityId,
                correspondentId: requestData.correspondentId,
            })
            .then(({ data }) => {
                setAlert('Faciliter Favoritado com Sucesso!');
                setFavoritedId(data[0].id);
            })
            .catch((err) => setAlert('Ocorreu um problema ao favoritar o faciliter, por favor tente novamente', 'error'))
            .finally(() => setLoading(false));
    }

    const handleClick = () => {
        setLoading(true);

        if (isFavorited) {
            removeFavorite();
            return;
        }

        addFavorite();
    }

    const getButtonIcon = () => {

        if (loading) return LoadingSpin;

        if (isFavorited) {
            return mouseOverFavoriteButton ? FavoriteBorderOutlined : FavoriteOutlined;
        }

        return mouseOverFavoriteButton ? FavoriteOutlined : FavoriteBorderOutlined;
    }

    const getButtonText = () => {

        if (isFavorited) {
            return mouseOverFavoriteButton ? 'Remover Favorito' : 'Faciliter Favorito';
        }

        return 'Favoritar Faciliter';
    }

    const getTooltipText = () => {
        if (isFavorited) return 'Esse serviço foi concluído por um faciliter favoritado. Gostaria de removê-lo dos seus favoritos?';
        return 'Ao favoritar, você possibilita suas futuras solicitações a serem resolvidas pelo mesmo Faciliter.';
    }

    const Icon = getButtonIcon();

    return (
        <>
            <Tooltip
                title={(
                    <span style={{ fontSize: 12 }}>{getTooltipText()}</span>
                )}
                placement="top"
            >
                <Button
                    variant="outlined"
                    startIcon={<Icon style={{ color: '#ff6868' }} />}
                    onMouseOver={() => setMouseOverFavoriteButton(true)}
                    onMouseLeave={() => setMouseOverFavoriteButton(false)}
                    onClick={handleClick}
                >
                    {getButtonText()}
                </Button>
            </Tooltip>
        </>
    );
}