import { createContext, useContext, useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import BlogsService from "../../domain/services/marketing/blog.service";

export const BlogContext = createContext();

export function BlogProvider({ children, blogId }) {

    const [findBlog, findingBlog] = useRequest(BlogsService.findById);
    const [editBlog, editingBlog] = useRequest(BlogsService.edit);

    const [blogData, setBlogData] = useState({});
    const [changes, setChanges] = useState({});

    const refreshBlog = () => {
        findBlog(blogId)
            .then(blog => setBlogData(blog));
    }

    useEffect(() => {
        refreshBlog();
    }, []);

    const updateChanges = (field, value) => {
        setChanges((prev = {}) => ({ ...prev, [field]: value }));
    };

    const saveChanges = () =>
        editBlog(blogData.id, changes)
            .then(() => {
                setChanges({});
                setBlogData(prev => ({
                    ...prev,
                    ...changes,
                }));
            });

    const discardChanges = () => {
        setChanges({});
    };

    const loading = {
        findingBlog,
        editingBlog,
    };

    return (
        <BlogContext.Provider
            value={{
                blogData,
                setBlogData,
                changes,
                updateChanges,
                saveChanges,
                discardChanges,
                refreshBlog,
                loading,
            }}
        >
            {children}
        </BlogContext.Provider>
    );
}

export function useBlog() {
    return useContext(BlogContext);
}
