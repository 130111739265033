import api from '../../services/api';

const checkNewCorrectionAvalailabilty = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/corrections/check-availability` });

const findCorrectionFieldsByService = async (serviceId) =>
    api.get({ type: 'user', service: `requests/services/correction-fields/${serviceId}` })

const findAllCorrectionFields = async () =>
    api.get({ type: 'user', service: `requests/services/correction-fields/` })

export default {
    checkNewCorrectionAvalailabilty,
    findCorrectionFieldsByService,
    findAllCorrectionFields
}