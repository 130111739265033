import styled from 'styled-components';

import { Accordion, Card, Grid } from '@material-ui/core';

export const OptionsSelectAllGrid = styled(Grid)`
  && {
    .MuiFab-root {
      height: 30px;
      margin-left: 5px;
      min-height: 30px;
      min-width: 30px;
      width: 30px;
    }
  }
`;

export const LoadingCard = styled(Card)`
  && {
    .MuiCard-root {
      box-shadow: unset;
      margin-top: 10px;
      width: 100%;
    }

    .MuiCardContent-root {
      display: -webkit-inline-box;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .MuiCircularProgress-root {
      height: 34px;
      margin-top: 5px;
      width: 34px;
    }

    .MuiGrid-root {
      margin-left: 20px;
      width: 100%;
    }
  }
`;

export const AccordionStyled = styled(Accordion)`
  && {
    .MuiAccordionDetails-root {
      border-top: 1px solid #d8d8d8;
      display: block;
    }

    .MuiAlert-outlinedWarning {
      font-size: 11px;
      height: auto;
      margin-top: 10px;
      overflow: auto;
      padding: 2px 0 0 10px;
      width: 100%;
    }

    .MuiAvatar-circular {
      color: white;
      font-size: 10px;
      font-weight: 600;
      height: 24px;
      width: 24px;
    }

    .downloads-warning {
      color: rgb(0 0 0 / 34%);
      font-weight: 600;
      margin-bottom: 10px;
      padding-top: 10px;
    }

    .MuiButton-containedPrimary {
      height: 30px;
      min-height: 30px;
    }

    .item-section {
      display: contents;
      width: 100%;
    }
  }
`;
