import { useState, useEffect } from "react";

import FormField from "../../FormField";

import solicitationService from '../../../domain/services/solicitation/solicitation.service';

function SelectModality({
    modalityId, serviceId, setMultipleValues, excludeModalities = [],
    isEdit, openEditModal, label = 'Modalidade da Petição', ...props
}) {

    const [isShowingField, setIsShowingField] = useState(false);

    const [modalities, setModalities] = useState([]);
    const [allModalities, setAllModalities] = useState([]);

    useEffect(() => getModalities(), []);

    useEffect(() => {

        const newAvailableModalities = allModalities.filter(m => (!serviceId || m.serviceId == serviceId));
        setModalities(newAvailableModalities);

        if (newAvailableModalities.length > 1) {
            setIsShowingField(true);
            return;
        }

        setIsShowingField(false);

    }, [serviceId, allModalities]);

    const handleChange = (name, value) => {

        if (isEdit) {
            openEditModal({ modalityId: value });
            return;
        }

        const newModality = allModalities.find(m => m.id == value);

        setMultipleValues({ [name]: value, modalityKey: newModality.key })
    }

    const getModalities = async () => {

        const { data } = await solicitationService.getModalities();

        const values = (data || []).filter(item => !excludeModalities.includes(item.id));

        return setAllModalities(values);
    }

    return isShowingField && (
        <FormField
            type="select"
            name='modalityId'
            field={label}
            placeholder='Selecione a modalidade da petição'
            currentValue={modalityId || ''}
            disabled={props.disabled} required
            options={modalities.map(m => ({ ...m, value: m.id }))}
            onChange={handleChange}
            onError={props.onError}
            {...props.fieldProps}
        />
    );
}

export default SelectModality;
