
import React, { useState } from 'react';

import Sidebar from '../components/Sidebar';
import Header from '../components/AppBar';

import { Hidden, CssBaseline, withWidth } from '@material-ui/core';

import { AppContent, Drawer, GlobalStyle, Root, MainContent } from './styles/menu.layout.styles';

import { EditorMenu } from '../components/smartdocs/editor.menu';


const DocsEditorLayout = ({ children, routes }) => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <Drawer>
                <Hidden implementation="js">
                    <Sidebar
                        routes={routes}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                </Hidden>
            </Drawer>
            <AppContent>
                <Header
                    hidden={false}
                    onDrawerToggle={handleDrawerToggle}
                    editorMenu={<EditorMenu />}
                />
                <MainContent p={0}>{children}</MainContent>
            </AppContent>
        </Root>
    );
};

export default withWidth()(DocsEditorLayout);
