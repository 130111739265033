import React, { useState, useEffect } from "react";

import {
    List, ListItem, ListItemText, ListItemIcon,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Avatar, Tooltip, Chip, Card, CardContent, CircularProgress, Grid, Typography
} from "@material-ui/core";

import { PersonAdd, CheckBox } from "@material-ui/icons";

import EmptyList from "../EmptyList";

import memberService from "../../domain/services/solicitation/member.service";
import userService from "../../domain/services/user.service";
import loginService from "../../domain/services/login.service";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

function SelectReviewers(props) {

    const [filterText, setFilterText] = useState("");

    const [listReviewers, setListReviewers] = useState([]);
    const [linkedReviewers, setLinkedReviewers] = useState([]);
    const [reviewer, setReviewer] = useState({});

    const [open, setOpen] = useState(false);
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        if (!props.isTableView)
            find();

        recoveryReviewersRequest(props.reviewers);
    }, []);

    const find = async () => {
        const { data } = await userService.findByRoles(["reviewer"]).catch(err => ({ data: [] }));
        setListReviewers(data);
        refreshList(data);
    }

    const onChange = (field, value) => {
        reviewer[field] = value;
        setReviewer({ ...reviewer });
    }

    const findReviewers = async () => {
        const { data } = await memberService.findReviewers(props.requestId).catch(err => ({ data: [] }));
        setLinkedReviewers(data);
        return data;
    }

    const recoveryReviewersRequest = (reviewersRequest) => {
        let item = (reviewersRequest || []).map(item => {
            item.id = item.responsibleId;
            return item;
        });
        setLinkedReviewers(item);
        return item;
    }

    const checkReviewer = async (item) => {
        if (!!item.checked) {
            setReviewer({ ...item, isConfirmRemove: true });
            return;
        }
        await save(item);
    }

    const save = async (item) => {
        await memberService.saveReviewer({ userId: item.id, requestId: props.requestId });
        refreshList(listReviewers);
    }

    const handleDelete = (item) =>
        setReviewer({ ...item, reviewerId: item.id, isConfirmRemove: true });

    const remove = async (item) => {
        await memberService.removeReviewer({
            id: item.reviewerId,
            requestId: props.requestId,
            addtionalInformation: { reason: item.reason },
            userId: item.userId,
            isReviewer: true
        });
        refreshList(listReviewers);
        setReviewer({ isConfirmRemove: false });
    }

    const refreshList = async (list) =>
        Promise
            .resolve(findReviewers())
            .then(data => mapWithReviewers(data, list));


    const mapWithReviewers = (linkeds, list) => {
        const result = memberService.mergeReviewers(list, linkeds);
        setListReviewers(result);
        return result;
    }

    const listSearch = (text) => {
        let filterList = listReviewers;

        if (text)
            filterList = filterList.filter((item) => item.name.toLowerCase().indexOf(text) != -1);

        return filterList;
    }

    const changeFilter = (value) => {
        setFilterText(value);
    }

    const openReviewers = () => {
        setOpen(true);

        if (!listReviewers.length)
            trackPromise(find());
    }

    const closeModal = () => {
        setOpen(false);
    }

    const addReviewersInTable = () => {
        if (!(linkedReviewers || []).length)
            return (
                <Tooltip title="Adicionar Revisor Interno">
                    <Chip size="small" label="Revisor" variant="outlined"
                        avatar={<PersonAdd style={{ color: "#4782da", width: "16px", height: "16px" }} />}
                        style={{ fontWeight: "500", marginRight: "5px", marginBottom: "8px", border: '1px solid #4782da', color: '#4782da' }}
                        onClick={openReviewers}
                    />
                </Tooltip>
            );
    }

    const getListLinkeds = (reviewers) => {
        return (
            <div style={{ display: "inline" }}>
                {reviewers.map((item, index) => (
                    <Tooltip title={item.name} key={index} style={{ zIndex: "999999", cursor: "pointer" }}>
                        <Chip size="small" label="Revisor" variant="outlined"
                            avatar={
                                <Avatar alt={item.name} src="/static/img/avatars/avatar-1.jpg"
                                    style={{
                                        fontWeight: 600, background: "#4782da",
                                        color: "#fff", width: "20px", height: "20px"
                                    }}>{loginService.getNameInitials(item.name)}</Avatar>
                            }
                            style={{ fontWeight: "500", color: "#4782da", border: '1px solid #4782da', marginRight: "5px", marginBottom: "8px" }}
                            onDelete={() => { handleDelete(item); }}
                        />
                    </Tooltip>
                ))}
            </div>
        );
    }

    const reviewersForm = () => {
        return (
            (listSearch(filterText) || []).map((item, i) => (
                <ListItem button style={{ paddingLeft: "0" }} onClick={() => { checkReviewer(item) }} key={i}>
                    <ListItemIcon>
                        <Avatar alt={item.name} src="/static/img/avatars/avatar-2.jpg"
                            style={{
                                width: "28px", height: "28px", fontSize: "11px", fontWeight: 600,
                                background: "#376fd0", color: "#fff"
                            }}>{loginService.getNameInitials(item.name)}</Avatar>
                    </ListItemIcon>
                    <ListItemText primary={item.name} style={{ whiteSpace: "nowrap", width: "18em", overflow: "hidden", textOverflow: "ellipsis" }} />
                    {item.checked ? <CheckBox style={{ fontSize: "20px", color: "#a4dd00" }} /> : null}
                </ListItem>
            ))
        );
    }

    const confirmRemoveDialog = () => {

        const close = () =>
            setReviewer({ ...reviewer, isConfirmRemove: false, checked: true });

        return (
            <Dialog open={(reviewer.isConfirmRemove || false)} onClose={close} maxWidth="sm">
                <DialogTitle>Remover revisor</DialogTitle>
                <DialogContent>
                    <section style={{ width: "200px" }}>
                        <TextField id="reason" autoFocus margin="dense" label="Motivo da inativação" fullWidth
                            onChange={(e) => { onChange('reason', e.target.value); }} />
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">Cancelar</Button>
                    <Button onClick={() => { remove(reviewer) }} color="primary">Excluir</Button>
                </DialogActions>
            </Dialog>
        );

    }

    const loading = () => {
        return (
            promiseInProgress &&
            <Card mb={12} xs={12} style={{ marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                <CardContent style={{ display: "-webkit-inline-box", paddingTop: "20px", paddingBottom: "20px" }}>
                    <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                    <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                        <Typography variant="h6" gutterBottom> Aguarde </Typography>
                        <Typography variant="body2" gutterBottom> carregando lista... </Typography>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    return (
        <React.Fragment>
            {(linkedReviewers || []).length ? getListLinkeds(linkedReviewers) : null}
            {addReviewersInTable()}

            <Dialog open={open} onClose={closeModal} disableEscapeKeyDown>
                <DialogTitle>Revisores</DialogTitle>

                <TextField name="search" label="Buscar revisores" value={filterText}
                    onChange={(e) => { changeFilter(e.target.value.toLowerCase()) }}
                    style={{ margin: "0 20px 10px" }} my={2} />

                <DialogContent>
                    <List component="nav" dense={true} style={{ padding: "0", height: "120px", overflow: "auto" }}>
                        {loading()}
                        {(!promiseInProgress && listReviewers.length) ? reviewersForm() : <EmptyList />}
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeModal} color="primary"> Fechar </Button>
                </DialogActions>
            </Dialog>

            {confirmRemoveDialog()}

        </React.Fragment>
    );
}

export default SelectReviewers;


