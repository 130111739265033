import React, { useState, useEffect } from 'react';

import { LinearProgress as MuiLinearProgress, Box, Typography, Paper } from "@material-ui/core";

import { events } from '../domain/services/api';

import { withStyles } from '@material-ui/core/styles';

const LinearProgress = withStyles(() => ({
    root: { height: 8, borderRadius: 3 },
    bar: { borderRadius: 3 },
}))(MuiLinearProgress);

const ProgressDeterminate = () => {

    const [value, setValue] = useState({ show: false, progress: 0, info: "" });

    useEffect(() => {
        const handleLoadChange = (progressValue) => {
            setValue(progressValue);
        }

        events.on('onProgressDeterminate', handleLoadChange);

        return () => {
            events.removeListener('onProgressDeterminate', handleLoadChange);
        }
    })

    window.onbeforeunload = confirmExit;
    function confirmExit() {
        if (value.show)
            return "É possível que os uploads feitos não sejam salvos.";
    }

    return (
        value.show ?
            <Paper style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "10px", marginRight: "10px", width: "100%" }}>
                <LinearProgress my={2} variant="determinate" value={value.progress} color="secondary" />
                <Typography variant="body2"
                    style={{ marginLeft: "15%", fontWeight: "600", fontSize: "12px" }}>
                    {`${value.progress}% - ${value.info}`}
                </Typography>
            </Paper> : null
    )
}

export default ProgressDeterminate;