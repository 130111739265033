import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom";

import service from "../../domain/services/marketing/page.tracking.service";

const PageTracking = () => {
	const appName = 'facilita-app'
	const location = useLocation();
	const timeSpentRef = useRef({ start: Date.now(), pathname: location.pathname });
	const storage = window.localStorage
	let user = storage.getItem(`@${appName}/user`)
	let userId = user ? JSON.parse(user).id : ''

	const sendEvent = (eventType, data) => {
	
    if (data.id){	
		service.update(data.id, {timeSpent: data.timeSpent}) 
		return 
	}

    save(eventType, data)

	};

	const save = async (eventType, data) => {

		try{

			let result = await  service.save({
				applicationName: appName,
				eventType: eventType,
			   ...data
			 })
		
		    if (result.status === 200 && eventType === 'page_view' ){ 
			    storage.setItem("lastEventId", result.data.id) 
		    }
			
		}catch (err){
		}
	}

	const handlePageTimeTracking = (pathname) => {
		const now = Date.now();
		const timeSpent = (now - timeSpentRef.current.start) / 1000;
        const lastEventId = storage.getItem(`lastEventId`)

		sendEvent('page_view', { timeSpent, id: lastEventId });

		timeSpentRef.current = { start: now, pathname};
	};

	const elementHandlers = {
		BUTTON: (target) => sendEvent('button_click', { clickElement: target.innerText, 
			                                            pageUrl: window.location.href, 
														pathName: location.pathname,
														userId: userId,
														additional: { search: location.search }}),

		SELECT: (target) => sendEvent('select_change', { clickElement: target.innerText, 
			                                             pageUrl: window.location.href, 
														 pathName: location.pathname,
														 userId: userId,
														 additional: { search: location.search }}),
	};


	const handleEvent = (event) => {
		const target = event.target;
		const tagName = target.tagName;

		if (elementHandlers[tagName]) {
			elementHandlers[tagName](target);
		}
	};

	useEffect(() => {
		sendEvent('page_view', { pageUrl: window.location.href,
			                     pathName: location.pathname,
			                     userId: userId,
			                     additional: { search: location.search }});

		handlePageTimeTracking(location.pathname);

	}, [location]);

	useEffect(() => {

		document.addEventListener('click', handleEvent);
		document.addEventListener('change', handleEvent);


		const handleBeforeUnload = () => {
			handlePageTimeTracking(timeSpentRef.current.pathname);
		};
		window.addEventListener('beforeunload', handleBeforeUnload);


		return () => {
			document.removeEventListener('click', handleEvent);
			document.removeEventListener('change', handleEvent);
			window.removeEventListener('beforeunload', handleBeforeUnload);

			handlePageTimeTracking(timeSpentRef.current.pathname);
		};
	}, []);

	return null
};

export default PageTracking