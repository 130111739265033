import { useState, useRef, useEffect } from 'react';

import { IconButton, Tooltip, Typography, Button, Box, CircularProgress, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Stop, Delete } from "@material-ui/icons";

import AudioPlayer from '../../AudioPlayer';

import requestFieldsService from '../../../domain/services/request/request.fields.service';
import loginService from '../../../domain/services/login.service';

const AudioRecorder = ({
    isNewRequest, requestId, field, recordingState, isLoading,
    handleStopRecording, uploadedAudio, handleRemoveAudio, showErrorMessage
}) => {

    const [timerSeconds, setTimerSeconds] = useState(180);

    const [transcribedText, setTranscribedText] = useState(uploadedAudio?.audioTranscription || '');
    const [isTranscribing, setIsTranscribing] = useState(false);

    const timerIdRef = useRef(null);

    useEffect(() => {

        if (recordingState !== 'recording') {
            clearInterval(timerIdRef.current);
            setTimerSeconds(180);
            return
        }

        timerIdRef.current = setInterval(() => {
            setTimerSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(timerIdRef.current);

    }, [recordingState]);

    useEffect(() => {

        if (timerSeconds !== 0)
            return;

        handleStopRecording();

    }, [timerSeconds]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleTranscribe = async () => {
        setIsTranscribing(true);
        requestFieldsService
            .transcribeAudioField(requestId, field.id)
            .then(setTranscribedText)
            .finally(() => setIsTranscribing(false));
    }

    if (showErrorMessage)
        return (
            <Typography color='error'>
                Não foi possível recuperar o áudio gravado. Por favor, tente novamente.
            </Typography>
        )

    if (recordingState === 'recording')
        return (
            <>

                <Box display='flex' alignItems='center' sx={{ mb: 2 }}>

                    <Typography color='primary' style={{ marginRight: 10 }}>
                        Gravando...
                    </Typography>


                    <Button
                        size='small' startIcon={<Stop />} variant="outlined"
                        onClick={handleStopRecording}
                    >
                        Parar
                    </Button>

                </Box>

                <Typography>
                    Tempo de áudio restante: <span style={{ fontWeight: 'bold' }}>{formatTime(timerSeconds)}</span>
                </Typography>

            </>
        )

    return (
        <div className='w-full max-w-full'>
            {isNewRequest && (
                <Alert severity='info' className='mt-1'>
                    Seu áudio ficou bom? Se precisar regravar, basta clicar no ícone da lixeira para deletar e gravar novamente.
                </Alert>
            )}

            <Box display='flex' alignItems='center' gridGap={10} sx={{ mt: 2 }} style={{ pointerEvents: 'auto' }}>

                <AudioPlayer src={uploadedAudio?.url} type={uploadedAudio?.fileType} />

                {!loginService.isFaciliter() && (
                    <Tooltip title="Excluir">
                        <IconButton
                            onClick={handleRemoveAudio}
                            size='small'
                            disabled={isLoading}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                )}

                {!transcribedText && !loginService.isCustomer() && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTranscribe}
                        disabled={isTranscribing || isLoading}
                    >
                        {
                            !isTranscribing ?
                                "Transcrever"
                                : (
                                    <Box sx={{ mx: 5, mt: 1 }}>
                                        <CircularProgress
                                            color='primary'
                                            size={18}
                                        />
                                    </Box>
                                )
                        }
                    </Button>
                )}


            </Box>

            {!loginService.isCustomer() && (
                <Collapse in={!!transcribedText}>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Transcrição do áudio:</b> {transcribedText || '-'}
                    </Typography>
                </Collapse>
            )}

        </div>
    );
};

export default AudioRecorder;