import { useState, useEffect } from "react";
import moment from "moment";

import {
    Typography, Grid, Card, List, ListItem, ListItemText,
    ListItemSecondaryAction, Tooltip, Fab, Avatar, ListItemIcon,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { EditOutlined, MoreHoriz } from "@material-ui/icons";

import CustomerReturnSolicitation from "./customer.return.solicitation.component";
import LoadingProgress from "../LoadingProgress";

import requestCorrectionService from "../../domain/services/request/request.correction.service";

function CorrectionsList({ request, mainRequestId, setAlert }) {

    const [corrections, setCorrections] = useState([]);
    const [correctionForm, setCorrectionForm] = useState({ open: false });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => get(mainRequestId), []);

    const get = async (mainId) => {

        setIsLoading(true);

        await requestCorrectionService
            .findByRequestId(mainId)
            .then(({ data }) => setCorrections(data || []))
            .finally(() => setIsLoading(false));
        
            
    }
    const onSelect = (values) =>
        setCorrectionForm({ ...values, isReadOnly: true, open: true });

    const toggleModal = (isOpen) =>
        setCorrectionForm((prevState) => ({ ...prevState, open: isOpen }));

    const findCorrectionType = (item) => {
        if (!item?.technicalQuestion) return "Correção"
        return "Dúvida técnica"
    }

    const getDetails = (item) => {

        let secondary = `Criado em: ${moment(item.created).format("DD/MM/YYYY HH:mm")}`;
        secondary += item.resolved ? ` - Resolvido em: ${moment(item.resolved).format("DD/MM/YYYY HH:mm")}` : "";

        return (
            <ListItemText style={{ width: '100%' }} secondary={secondary}
                primary={
                    <Typography variant="body1" style={{ fontSize: "13px", width: "100%" }}>
                        <span>Motivo da correção: {findCorrectionType(item)} </span>
                    </Typography>
                }
            />
        );
    }

    if (isLoading)
        return (
            <LoadingProgress />
        )

    return (
        <>

            <CustomerReturnSolicitation
                request={request}
                mainRequestId={mainRequestId}
                open={correctionForm.open}
                setOpen={toggleModal}
                onSave={() => get(request.id)}
                correctionValues={correctionForm}
                isLoading={isLoading}
                cancelCorrection={() => toggleModal(false)}
                setAlert={setAlert}
            />

            <Grid item>
                {corrections?.length ? (
                    <List
                        component="nav" dense={true}
                        style={{
                            width: "100%", height: "auto",
                            overflow: "auto", display: "block"
                        }}
                    >
                        {corrections.map(item => (
                            <ListItem
                                key={item.correctionSource} button
                                style={{ paddingLeft: 10, borderBottom: "1px solid #f6f6f6" }}
                            >

                                <ListItemIcon>
                                    <Avatar style={{
                                        width: 24, height: 24,
                                        background: item.resolved ? "green" : "orange"
                                    }}>
                                        <EditOutlined style={{ fontSize: 16, fontWeight: 600 }} />
                                    </Avatar>
                                </ListItemIcon>

                                {getDetails(item)}

                                <ListItemSecondaryAction>
                                    <Tooltip title="Visualizar detalhes da correção">
                                        <Fab
                                            size="small" variant="round" color="primary"
                                            style={{ width: 28, height: 28, minHeight: 0 }}
                                            onClick={() => { onSelect(item) }}
                                        >
                                            <MoreHoriz fontSize="small" style={{ fontSize: 15 }} />
                                        </Fab>
                                    </Tooltip>
                                </ListItemSecondaryAction>

                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <section>
                        <Card style={{
                            marginTop: 10, borderRadius: 0,
                            backgroundColor: "unset"
                        }}>
                            <Alert mt={2} mb={1} severity="info">
                                Não há correções solicitadas
                            </Alert>
                        </Card>
                    </section>
                )}
            </Grid>

        </>
    );
}

export default CorrectionsList;