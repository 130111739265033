import { get, post, put } from './api';

import datetimeProvider from '../../providers/datetime.provider';
import serviceWorker from "./service.worker.service";

const storageNames = [
    { name: '@facilita-app/user', keyData: 'user', isJson: true },
    { name: '@facilita-app/token', keyData: 'token', isJson: false }
];

const getUserAuthentication = () => JSON.parse(localStorage.getItem('@facilita-app/user'));

const checkCurrentUserSource = (sourceName) => (getUserAuthentication()?.source === sourceName);

const isMainCustomer = () =>
    checkCurrentUserSource('customer');

const isCustomer = () =>
    checkCurrentUserSource('customer') || checkCurrentUserSource('customerUser');

const isCustomerAdmin = () =>
    isValidRoles(["customer", "customer-user-admin"], getUserAuthentication());

const isFaciliter = () => checkCurrentUserSource('faciliter');

const isFaciliterReviewer = () => isFaciliter() && (getUserAuthentication()?.faciliter?.isReviewer) === true

const isExternal = () => isFaciliter() || isCustomer();

const isInternal = () => checkCurrentUserSource('internal');

const signIn = (user) =>
    post({ type: 'user', service: 'users/authenticate', data: user })
        .then(results => {
            setUserAuthentication(results.data);
            redirectLastAccess(results.data);
            return results;
        });

const signInWithUUID = (uuid, ref = 'email') =>
    get({ type: "user", service: `users/authenticate/${uuid}?ref=${ref}` })
        .then(results => {
            setUserAuthentication(results.data);
            return results;
        });

const signInLead = (email) =>
    post({ type: 'user', service: 'users/authenticate-lead', data: { email } })
    .then(results => {
        setUserAuthentication(results.data);
        return results;
    });

const resetPassword = (data) =>
    put({ type: 'user', service: 'users/new-password', data });

const updatePassword = (data) =>
    put({ type: 'user', service: 'users/refresh/password', data });

const signOut = () => {

    try {
        storageNames.forEach(x => localStorage.removeItem(x.name));
    } catch (error) {
        console.error(error);
    }
    serviceWorker.userLogout();
}

const setUserAuthentication = (data) => {
    storageNames.forEach((x) => {
        localStorage.setItem(x.name, x.isJson ? JSON.stringify(data[x.keyData]) : data[x.keyData])
    })
    serviceWorker.setAuthenticatedUser()
}

const refreshUserAuthentication = (data) => {
    if (typeof data === 'function') {
        const newUserData = data(getUserAuthentication());
        localStorage.setItem('@facilita-app/user', JSON.stringify(newUserData))
        return;
    }

    localStorage.setItem('@facilita-app/user', JSON.stringify(data));
}


const redirectLogin = (email, password) => {
    signIn({ email, password }).then((res) => setUserAuthentication(res.data))
        .finally(() => {
            window.location.href = "/";
        })
}

const isLogin = () =>
    localStorage.getItem('@facilita-app/token') ? true : false;

const getToken = () =>
    localStorage.getItem('@facilita-app/token');

const isValidRoles = (roles, currentUser) => {

    const user = currentUser || getUserAuthentication();

    if (!user) return false;

    return (!roles)
        ? true
        : roles.some((routeRole) => user.roles.some(userRole => userRole.name === routeRole));
}

const hasPermissionRole = (role, currentUser) =>
    (currentUser?.roles || []).some(item => item.name === role);

const getNameInitials = (name) => {
    if (name)
        return name.match(/\b\w/g).toString().toUpperCase().replace(',', ' ').substring(0, 3);
}

const saveAccess = () => {

    const data = {
        userId: getUserAuthentication()?.id,
        url: window.location.href,
        datetime: new Date()
    }

    localStorage.setItem('@facilita-app/lastAccess', JSON.stringify(data));
}

const redirectLastAccess = ({ user }) => {

    const lastAccess = JSON.parse(localStorage.getItem('@facilita-app/lastAccess'));

    if (!lastAccess)
        return;

    localStorage.removeItem('@facilita-app/lastAccess');

    const redirectUser = user.id == lastAccess.userId && isValidRedirectionTime(lastAccess.datetime);

    if (!redirectUser)
        return;

    window.location.href = lastAccess.url
}

const isValidRedirectionTime = (lastAccessDatetime) => {
    const validRedirectionTime = 5;
    return datetimeProvider.getTimeDifference('minutes', lastAccessDatetime) <= validRedirectionTime;
}

const isFinancial = () => {
    return isValidRoles(["financial"]);
}

export default {
    checkCurrentUserSource,
    isInternal,
    isExternal,
    isMainCustomer,
    isCustomer,
    isCustomerAdmin,
    isFaciliter,
    isFaciliterReviewer,
    isFinancial,
    signIn,
    signInWithUUID,
    signInLead,
    resetPassword,
    updatePassword,
    signOut,
    setUserAuthentication,
    refreshUserAuthentication,
    isLogin,
    getUserAuthentication,
    getToken,
    isValidRoles,
    hasPermissionRole,
    redirectLogin,
    getNameInitials,
    saveAccess
}