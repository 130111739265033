import React, { useEffect, useState } from 'react';

import QuillEditor from "../../../shared/quill/QuillEditor";
import TableOfContentsDesktop from "./tableOfContents/tableOfContentsDesktop";
import { useBlog } from "../../../../context/marketing/blogContext";
import SaveChangesFooter from "./saveChangesFooter";
import SnackbarAlert from "../../../shared/SnackbarAlert";

export default function BlogContent() {

    const { updateChanges, blogData, saveChanges, changes, loading } = useBlog();

    const [content, setContent] = useState(blogData.content || '');
    const [toc, setToc] = useState([]);

    const onBlogChange = (content) => {
        generateTableOfContents(content);
        setContent(content);
        updateChanges('content', content);
    }

    const generateTableOfContents = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const headings = doc.querySelectorAll('h1, h2, h3');
        const tocList = [];

        headings.forEach((heading) => {
            const headingText = heading.innerText;
            const tag = heading.tagName.toLowerCase();

            const level = parseInt(tag.slice(1));

            const id = heading.id;

            tocList.push({
                text: headingText,
                tag,
                level,
                id,
            });
        });

        setToc(tocList);
    };

    const handleSave = () => {
        saveChanges()
            .then(() => SnackbarAlert.success('Alterações salvas com sucesso!'));
    }

    useEffect(() => {
        generateTableOfContents(blogData.content);
    }, [])

    return (
        <div className="w-full relative">
            <div className="w-full flex justify-center lg:justify-start gap-8">

                <div className="w-full flex justify-center">
                    <div className="w-full flex flex-col gap-8 max-w-3xl text-[#131313]">
                        <div className="w-full">

                            <QuillEditor value={content} onChange={onBlogChange} />
                        </div>
                    </div>
                </div>

                <div className="hidden xl:block w-full max-w-sm">
                    <TableOfContentsDesktop tableOfContent={toc} />
                </div>

            </div>

            <SaveChangesFooter
                onSave={handleSave}
                showCancel={false}
                hasChanges={!!Object.keys(changes).length}
                loading={loading.editingBlog}
            />
        </div>
    )
}
