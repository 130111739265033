import { Tooltip } from "@material-ui/core"

export default function Card({
    actions = [],
    cover,
    title,
    extra,
    children,
    className = "",
    bodyClassName = "",
    style,
}) {

    const renderAction = (action) => (
        <button
            type="button"
            onClick={action.onClick} className="w-full py-2 text-center hover:text-primary"
            style={{ transition: 'background-color .3s ease' }}
        >
            {action.render}
        </button>
    )

    return (
        <div
            className={`w-full flex flex-col rounded-lg bg-white border border-[#ededed] text-[#131313] ${className}`}
            style={style}
        >

            {cover && (
                <div className="w-full h-44 overflow-hidden rounded-t-lg shrink-0">
                    {cover}
                </div>
            )}

            <div className={`h-full p-8 ${bodyClassName}`}>

                {title && (
                    <div className="flex justify-between items-center">
                        <div className="text-base font-semibold">
                            {title}
                        </div>
                        {extra}
                    </div>
                )}

                <div className="h-full">
                    {!!children && !!title && <div className="my-6 h-[1px] bg-[#ededed] w-full" />}

                    <div className="h-full">
                        {children}
                    </div>
                </div>
            </div>

            {!!actions.length && (
                <div className="mt-auto shrink-0 flex border-t border-[#ededed]">
                    {actions.map((action, index) => (
                        <>
                            <div className="w-full flex justify-center py-2">
                                {action.tooltip ? (
                                    <Tooltip title={<span className="text-xs">{action.tooltip}</span>} className="!text-xs" placement="top">
                                        {renderAction(action)}
                                    </Tooltip>
                                ) : (
                                    renderAction(action)
                                )}
                            </div>

                            {index < actions.length - 1 && (
                                <div className="my-3 w-[2px] h-auto bg-[#dedede]" />
                            )}
                        </>
                    ))}
                </div>
            )}
        </div>
    )
}