import { Avatar, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import loginService from "../../../domain/services/login.service";

const FaciliterRatingContent = ({ name, score, feedback, source, isAnalysisRating }) => {

    const getFeedback = () => {

        if (!loginService.isFaciliter())
            return feedback;

        return source == 'customer' ? 'Avaliado pelo cliente.' : feedback
    }

    return (
        <List
            component="nav" dense={true}
            style={{
                width: "100%", height: "auto", overflow: "auto", display: "block",
                borderBottom: isAnalysisRating ? "" : "1px solid #e0e0e0"
            }}
        >

            <Typography variant="h5">
                {isAnalysisRating ? "Avaliação da Indicação de Petição" : "Avaliação da petição"}
            </Typography>

            <ListItem button style={{ paddingLeft: "0" }}>

                <ListItemIcon style={{ minWidth: "40px" }}>
                    <Avatar alt={name} src="/static/img/avatars/avatar-1.jpg"
                        style={{ width: "24px", height: "24px", fontSize: "10px", fontWeight: 600 }} />
                </ListItemIcon>

                <ListItemText>{name}</ListItemText>

                <Rating defaultValue={score} precision={0.5} readOnly size="small" />

                <Chip size="small" label={score}
                    style={{
                        color: 'white', fontWeight: '600', height: '16px',
                        fontSize: '10px', backgroundColor: '#00800082', marginLeft: "10px"
                    }}
                />
            </ListItem>

            <ListItem fullWidth my={2} style={{ paddingLeft: "3px", paddingTop: "10px", paddingBottom: "1px" }}>
                <h4 style={{ color: "rgb(0 0 0 / 80%)", fontWeight: "600", display: "contents" }}>Feedback:</h4>
            </ListItem>

            <ListItem fullWidth my={2} style={{ paddingLeft: "3px", paddingBottom: "10px" }}>
                <span style={{ color: "rgb(0 0 0 / 80%)", wordBreak: "normal" }}>
                    {getFeedback()}
                </span>
            </ListItem>
        </List>
    )
}

export default FaciliterRatingContent;