import repository from '../../repositories/crm/crm.seller.repository';

import loginService from '../login.service';

const findSellerTodayTarget = async () => {

    const userId = loginService.getUserAuthentication()?.id;

    return repository
        .findTodayTargetBySellerUserId(userId)
        .then(res => res.data || {});
}


export default {
    findSellerTodayTarget
}