import { Grid } from "@material-ui/core";

import FormField from "../../FormField";

import masks from "../../../providers/masks"

const ExternalRegisterProfile = ({ customer, setCustomer, fieldsWithError, afterRegister, isCorporation }) => {

    const handleChange = (field, value) =>
        setCustomer({ [field]: value });

    const handleChangeAcquisitionInfo = (fieldName, value) => {
        const currentInfo = { ...customer.acquisition } || {};
        currentInfo[fieldName] = value;
        handleChange('acquisition', currentInfo);
    }

    const handleChangeCompanyInfo = (fieldName, value) => {
        const currentInfo = { ...customer.company } || {};
        currentInfo[fieldName] = value;
        handleChange('company', currentInfo);
    }

    const getBaseDateFields = () =>
        customer.personType == 'individual' ? (
            <FormField
                field='CPF' name='identity' placeholder='Digite seu CPF'
                currentValue={customer.identity} onChange={handleChange}
                onError={fieldsWithError.includes('identity')}
                titleSize={afterRegister && 14} mask={masks.cpf} required
                titleColor={afterRegister && 'grey'}
            />
        ) : (
            <Grid item container spacing={4}>
                <Grid item md={5} xs={12}>
                    <FormField
                        field='CNPJ' name='identity' required
                        currentValue={customer.identity} mask={masks.cnpj}
                        onChange={handleChange} placeholder={`Digite o CNPJ`}
                        titleSize={afterRegister && 14}
                        titleColor={afterRegister && 'grey'}
                        onError={fieldsWithError.includes('identity')}
                    />
                </Grid>

                <Grid item md={7} xs={12}>
                    {getCompanyName()}
                </Grid>
            </Grid>
        )

    const getIdentityFields = () => (
        <>
            <FormField
                field='Tipo de pessoa' name='personType' type='select'
                currentValue={customer.personType} onChange={handleChange}
                options={[
                    { name: 'Pessoa Física', value: 'individual' },
                    { name: 'Pessoa Jurídica', value: 'corporation' }
                ]}
                onError={fieldsWithError.includes('personType')} required
                titleSize={afterRegister && 14}
                titleColor={afterRegister && 'grey'}
            />

            {!!customer.personType && getBaseDateFields()}
        </>
    )

    const checkShowCompanyName = () =>
        isCorporation && getCompanyName();

    const getCompanyName = () => (
        <FormField
            field='Razão Social' name='companyName'
            placeholder='Digite a Razão Social' required
            currentValue={customer.company?.companyName}
            onChange={handleChangeCompanyInfo}
            onError={fieldsWithError.includes('companyName')}
            titleSize={afterRegister && 14} titleColor={afterRegister && 'grey'}
        />
    )

    return (
        <Grid item container direction="column" spacing={5}>

            {!afterRegister ? getIdentityFields() : checkShowCompanyName()}

            <FormField
                field='OAB' name='oab' placeholder='Digite sua OAB'
                currentValue={customer.oab} onChange={handleChange}
                onError={fieldsWithError.includes('oab')} required
                titleSize={afterRegister && 14}
                titleColor={afterRegister && 'grey'}
            />

            <FormField
                field='Como nos conheceu?' name='channel' type='select'
                options={[
                    { name: 'Linkedin', value: 'Linkedin' },
                    { name: 'Facebook', value: 'Facebook' },
                    { name: 'Instagram', value: 'Instagram' },
                    { name: 'TikTok', value: 'TikTok' },
                    { name: 'Youtube', value: 'Youtube' },
                    { name: 'Google', value: 'Google' },
                    { name: 'Email', value: 'Email' },
                    { name: 'Indicação', value: 'Indicação' },
                    { name: 'Outros', value: 'Outros' }
                ]}
                currentValue={customer.acquisition?.channel}
                onChange={handleChangeAcquisitionInfo} required
                onError={fieldsWithError.includes('channel')}
                titleSize={afterRegister && 14}
                titleColor={afterRegister && 'grey'}
            />

            {customer.acquisition?.channel == 'Outros' &&
                <FormField
                    field='Descreva por onde nos conheceu' name='description'
                    currentValue={customer.acquisition?.description}
                    onChange={handleChangeAcquisitionInfo} required
                    onError={fieldsWithError.includes('description')}
                    titleSize={afterRegister && 14}
                    titleColor={afterRegister && 'grey'}
                />
            }
        </Grid>
    )
}

export default ExternalRegisterProfile;