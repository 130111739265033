import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import swal from 'sweetalert';

import {
    Button, IconButton, TextField as MuiTextField, Typography, Dialog,
    DialogTitle as MuiDialogTitle, DialogContent, DialogActions, Grid,
    Snackbar, CircularProgress, Divider
} from '@material-ui/core';

import { Close, InsertDriveFile, InsertDriveFileOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import SelectModality from '../fields/select.modality.component';
import stepFormFieldsConfig from '../fields/request.fields.config';
import AnalysisPartsForm from './analysis.parts.form.component';

import useDebounce from '../../../providers/debounce';
import utilsProvider from '../../providers/utils.provider';

import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';
import requestAnalysisService from '../../../domain/services/request.analysis.service';

import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";
import modalities from '../../../domain/constant/modalities.constant';


const TextField = styled(MuiTextField)`
    margin-bottom: 20px !important;
    textarea {
        min-height: 24px !important;
    },
    cursor: default;
`;

const DialogTitle = styled(MuiDialogTitle)`
    h2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;

const RequestAnalysisForm = ({ request, isFaciliterPremium, setAlert }) => {

    const initialState = () => ({ requestId: request.id, isFaciliterPremium });

    const [analysis, setAnalysis] = useState(initialState());

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatedWithoutSaving, setUpdatedWithoutSaving] = useState(false);

    const [fields, setFields] = useState([]);

    const [fieldChange, setFieldChange] = useState({});
    const debouncedField = useDebounce(fieldChange, 1000);

    const fieldStyles = {
        titleColor: 'rgb(69 69 69)',
        clean: true,
        titleSize: 13
    }

    useEffect(() => {

        if (debouncedField.value)
            editField(debouncedField.value);

    }, [debouncedField]);

    const getAnalysisFields = async (areaId, modalityId) => {

        if (!areaId || !modalityId)
            return;

        return await requestRegistryService
            .findFieldsToAnalysis(areaId, modalityId)
            .then((res) => setFields(res.data || []));
    }

    const getAnalysis = async () => {

        setLoading(true);

        await Promise
            .all([
                requestAnalysisService.getRegistrationRecord(request.id).catch(() => ({})),
                requestAnalysisService.getAnalysis(request.id).catch(() => ({})),
                requestRegistryService.getDynamicFieldsAnalysis(request.id).catch(() => ({}))
            ])
            .then(setAnalysisValue)
            .catch((error) => setAlert(error?.response?.data?.message || "Erro ao obter a Indicação de Petição", 'error'))

        handleOpen();
        setLoading(false);
    }

    const setAnalysisValue = async ({ 0: registrationRecord, 1: formValues, 2: fieldValues }) => {

        const analysts = request.analysts || [];

        let analysisValue = { ...analysis, ...formValues, analystUserId: analysts[0]?.userId };

        analysisValue = await setAnalysisForwardModality(request, analysisValue);

        setAnalysis({
            ...analysisValue,
            fields: requestRegistryService.integrateFields(analysisValue, (fieldValues || [])),
            duration: requestAnalysisService.getTimeByTotalMin(analysisValue.duration)
        });

        await getAnalysisFields(request.areaId, analysisValue.modalityId);

        if (!analysis.attorney || analysis.attorney == '') {
            const attorney = registrationRecord.data.find(data => data.fieldKey == 'attorney')
            setFieldValue('attorney', attorney?.value || '')
        }
    }

    const setAnalysisForwardModality = async (request, analysis) => {

        const initPetitionIndication = (request.isPetitionIndication && !analysis.modalityId);

        if (!initPetitionIndication)
            return analysis;

        const analysisValue = {
            ...analysis,
            modalityId: request.forwardModalityId,
            modality: request.forwardModality
        }

        return await saveAnalysis(analysisValue);
    }

    const changeModality = (modalityId) => {

        const analysisValues = { ...analysis, modalityId: modalityId, typeAction: [], cummulativeOrders: [] };

        setAnalysis(analysisValues);

        getAnalysisFields(request.areaId, modalityId);

        saveAnalysis(analysisValues);
    }

    const setFieldValue = (name, value) =>
        setAnalysis((item) => ({ ...item, [name]: value }));

    const editField = async (field) => {

        await requestAnalysisService
            .editField(request.id, field)()
            .catch((error) => setAlert(error.response.data.message, 'error'));
    }
    const onSave = async (values, { setSubmitting }) => {

        setSubmitting(true);

        await saveAnalysis(values, true)
            .then(() => setAlert("Salvo com sucesso", 'success'))
            .then(() => setUpdatedWithoutSaving(false))
            .finally(() => setSubmitting(false));
    }

    const saveAnalysis = async (values) => {

        if (utilsProvider.isNullOrEmpty(values) || utilsProvider.isObjectEmpty(values))
            return;

        return await requestAnalysisService
            .editRequestAnalysis({ ...values, id: analysis?.id || values.id })
            .then((response) => onSaveSuccess(response, values))
            .catch((error) => setAlert(error.response.data.message, 'error'));
    }

    const onSaveSuccess = (response, formValues) => {

        const values = { ...formValues, ...analysis, id: analysis.id || response.data.id };
        setAnalysis(values);

        return values;
    }

    const handleOpen = () => setOpen(true);

    const handleClose = (submitForm) => {

        setOpen(false);

        if (!updatedWithoutSaving)
            return;

        showAlertDialog(
            "Deseja salvar as alterações antes de sair ?",
            "info", "Ao sair sem salvar, as alterações realizadas poderão ser perdidas", "Salvar"
        ).then(willSave => willSave && submitForm());
    }

    const showAlertDialog = async (title, icon, text, buttonText) =>
        swal({ title, text, icon, buttons: { cancel: 'Fechar', confirm: { text: buttonText, className: 'MuiButton-containedPrimary' } } });

    const setUpFields = (fields, values, setFieldValue) => {

        if (!fields.length)
            return null;

        return fields.map((field) => ({
            ...stepFormFieldsConfig.getComponentField({
                field,
                request: values,
                fieldProps: fieldStyles,
                setValue: (fieldName, fieldsValue, value) => {
                    editField({ id: field.id, value });
                    setFieldValue('fields', fieldsValue);
                }
            })
        }))
    }

    const disableStyle = () => {

        if (request.usedReferenceRequestId && request.status == stepsSolicitation.CONCLUDED)
            return { pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' };
    }

    return (
        <>

            <Button
                variant="contained"
                color="primary"
                startIcon={<InsertDriveFileOutlined stle={{ fontSize: "16px" }} />}
                style={{ pointerEvents: 'all', height: "29px", fontWeight: 600, fontSize: "12px", marginRight: "10px" }}
                onClick={() => { getAnalysis() }}
            >
                {!loading ? "Detalhes da Indicação de Petição" :
                    <span>
                        Detalhes da Indicação de Petição
                        <CircularProgress m={2} style={{ marginLeft: '8px', width: '16px', height: '16px', color: 'greenyellow' }} />
                    </span>
                }
            </Button>

            {open &&
                <Formik initialValues={analysis} onSubmit={onSave}>
                    {({ handleChange, handleBlur, setFieldValue, handleSubmit, values, isSubmitting, submitForm }) => (
                        <form name='requestAnalysisForm' onSubmit={handleSubmit}>
                            <Dialog
                                maxWidth="md" fullWidth
                                open={open}
                                onClose={() => { handleClose(submitForm) }}
                            >
                                <DialogTitle style={{ display: 'flex', background: "rgb(238 238 238 / 50%)", borderBottom: "3px solid rgb(25, 118, 210)" }}>
                                    <Typography variant="h4" style={{ paddingTop: '12px', display: 'flex', alignItems: 'center' }}>
                                        <InsertDriveFile style={{ color: '#376fd0', fontSize: '24px', marginRight: '5px' }} /> Indicação de Petição
                                    </Typography>

                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { handleClose(submitForm) }}
                                        style={{
                                            position: 'absolute', right: 8, top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <Close />
                                    </IconButton>

                                </DialogTitle>

                                <DialogContent>

                                    <div style={disableStyle()}>

                                        {(request.status == stepsSolicitation.CONCLUDED && !request.usedReferenceRequestId) &&
                                            <Alert severity="info" style={{ width: '98%', margin: "20px 10px" }}>
                                                <AlertTitle>Indicação de Petição finalizada</AlertTitle>

                                                A Indicação de Petição não pode mais ser editada, pois já foi concluída.
                                            </Alert>
                                        }

                                        {!!request.usedReferenceRequestId &&
                                            <Alert severity="info" style={{ width: '98%', margin: "20px 10px" }}>
                                                <AlertTitle>Indicação de Petição utilizada como referência para a criação da petição</AlertTitle>

                                                Qualquer edição será atualizado no serviço de petição.
                                            </Alert>
                                        }

                                        <section style={{ display: 'flex', flexDirection: 'column', padding: '30px 10px' }}>

                                            <Grid container spacing={6} style={{ marginBottom: 10 }}>
                                                <Grid item xs={12}>
                                                    <SelectModality
                                                        modalityId={values.modalityId}
                                                        excludeModalities={[
                                                            modalities.analysis.id,
                                                            modalities.calculation.id,
                                                            modalities.hearing.id,
                                                            modalities.hearingAnalysis.id,
                                                            modalities.notion.id
                                                        ]}
                                                        setMultipleValues={(values) => {
                                                            setFieldValue('modalityId', values.modalityId)
                                                            changeModality(values.modalityId);
                                                        }}
                                                        fieldProps={fieldStyles}
                                                    />
                                                </Grid>

                                                {setUpFields(fields, values, setFieldValue)}

                                            </Grid>

                                            <TextField
                                                multiline
                                                required
                                                name="facts"
                                                label="Breve relato do caso"
                                                InputLabelProps={{ shrink: true }}
                                                value={values.facts}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUpdatedWithoutSaving(true);
                                                }}
                                            />

                                            <TextField
                                                multiline
                                                required
                                                name="petitionTopics"
                                                label="Tópicos obrigatórios na petição"
                                                InputLabelProps={{ shrink: true }}
                                                value={values.petitionTopics}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUpdatedWithoutSaving(true);
                                                }}
                                            />

                                            <TextField
                                                id="attorney"
                                                name="attorney"
                                                InputLabelProps={{ shrink: true }}
                                                value={values.attorney}
                                                label="Advogado Subscritor"
                                                multiline
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUpdatedWithoutSaving(true);
                                                }}
                                            />

                                        </section>

                                        <section>
                                            <Typography variant='h4' style={{ margin: '10px 10px', color: '#000000b0', fontWeight: '450' }}>
                                                Qualificação das partes
                                            </Typography>

                                            <Divider style={{ marginBottom: '10px' }} />

                                            {(analysis.id && values.modalityId) ?
                                                <AnalysisPartsForm
                                                    analysisId={analysis.id}
                                                    requestId={request.id}
                                                    modalityId={values.modalityId}
                                                /> :
                                                <Alert variant="filled" severity="info" style={{ margin: '20px 0 10px', width: "100%" }}>
                                                    Para informar as partes, selecione a modalidade.
                                                </Alert>
                                            }
                                        </section>

                                    </div>

                                </DialogContent>

                                <DialogActions style={{ padding: "15px", background: "rgb(238 238 238 / 50%)", borderTop: "3px solid rgb(25, 118, 210)" }}>

                                    <Button
                                        variant="contained"
                                        disabled={isSubmitting}
                                        onClick={() => { handleClose(submitForm) }}
                                    >
                                        Fechar
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        {isSubmitting ?
                                            <span>
                                                Salvando
                                                <CircularProgress m={2} style={{ marginLeft: '5px', width: '16px', height: '16px' }} />
                                            </span> : "Salvar"
                                        }
                                    </Button>

                                </DialogActions>

                            </Dialog>

                        </form>
                    )}
                </Formik>
            }

        </>
    );
}

export default RequestAnalysisForm;
