import firebase from "firebase";
import "firebase/firestore";

const config = {
    apiKey: "AIzaSyAIBy-H_w3KK1E2VuU-SMVzo6JZa3Oz3KM",
    authDomain: "facilita-juridico.firebaseapp.com",
    projectId: "facilita-juridico",
    storageBucket: "facilita-juridico.appspot.com",
    messagingSenderId: "341850929881",
    appId: "1:341850929881:web:de8cfc841c065d6de61cc8",
    measurementId: "G-SS4YCML6XS"
};

const app = firebase.initializeApp(config);

const getCollection = (collection) =>
    app.firestore().collection(collection);

const docsByCondition = (collectionValue, conditional) => {

    let collection = getCollection(collectionValue);
    if (conditional && conditional.field)
        return collection.where(conditional.field, conditional.comparison, conditional.value);

    return collection;
}

const handleSnapshot = (snapshot, callback) =>
    snapshot.docChanges()
        .forEach((change) => callback({ ...change.doc.data(), id: change.doc.id, type: change.type }))

export const trackRealTimeChanges = (collection, conditional, onChange) =>
    docsByCondition(collection, conditional)
        .onSnapshot((snapshot) => handleSnapshot(snapshot, onChange))


export const getDocsByCondition = async (collection, conditional) => {

    if (!conditional.field || !conditional.comparison)
        return null;

    const docs = []

    await docsByCondition(collection, conditional)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id });
            });
        })

    return docs
}

export const getDocByCollection = (collection, doc, callback) =>
    getCollection(collection).doc(`${doc}`)
        .onSnapshot({ includeMetadataChanges: true }, callback)

export const removeDocFromCollection = (collection, doc) =>
    getCollection(collection)
        .doc(`${doc}`).delete()
        .catch((error) => console.error("Erro exclusão document firebase: ", error));
