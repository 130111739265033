import repository from '../../repositories/request/request.fields.repository';

import loginService from '../login.service';
import solicitationService from '../solicitation/solicitation.service';

const createFieldOption = async (fieldId, data) =>
    repository
        .createFieldOption(fieldId, data)
        .then(res => res.data);

const checkDisableFieldsStyle = (request, isEdit) => {

    if (!loginService.isFaciliter() && (!isEdit || solicitationService.canAddContent(request)))
        return;

    const elements = document.querySelectorAll('.audio-component > *');
    elements.forEach(element => element.style.pointerEvents = 'auto')

    return { pointerEvents: 'none', opacity: 1 };
}

const transcribeAudioField = async (requestId, fieldId) =>
    repository
        .transcribeAudioField(requestId, fieldId)
        .then(res => res.data);

const findMissingByRequestId = async (requestId) =>
    repository
        .findMissingByRequestId(requestId)
        .then(res => res.data || []);

export default {
    createFieldOption,
    checkDisableFieldsStyle,
    transcribeAudioField,
    findMissingByRequestId
}