import { Grid, Typography, Button, CircularProgress } from "@material-ui/core"
import { StarRounded } from "@material-ui/icons";
import { useState } from "react";

import SimpleModal from "../../shared/SimpleModal";
import loginService from "../../../domain/services/login.service";

import { trackPromise } from 'react-promise-tracker';
import memberService from "../../../domain/services/solicitation/member.service";

const RequestIaRatingDialog = ({ request = {}, initialRating, setAlert, isOpen, onClose, onFinish }) => {

    const [rating, setRating] = useState({ value: initialRating });
    const [hoveredStar, setHoveredStar] = useState(null);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    const ratings = [
        'Muito Ruim',
        'Ruim',
        'Mediano',
        'Bom',
        'Muito Bom',
    ];

    const customerUserId = loginService.getUserAuthentication().id;

    const mainRequestId = request.mainId || request.id;

    const handleChange = (field, value) =>
        setRating({ ...rating, [field]: value });

    const handleSubmit = async (rating, message) => {
        setLoading(true);
        trackPromise(
            saveRating(rating.value, message)
                .then(res => { if (res) { onFinish(); setLoading(false) } })
                .catch((err) => setAlert(err.response?.data?.message))
        );
    };
    const saveRating = (ratingValue, message) =>
        memberService.saveServiceIaRating({
            requestId: mainRequestId,
            userId: customerUserId,
            value: ratingValue,
            message: message
        });


    const handleStarClick = (value) => {
        handleChange('value', value);
    }

    const renderStars = () => (
        <div className="w-full flex justify-center gap-2">
            {ratings.map((ratingText, index) => {

                const value = index + 1;

                return (
                    <div className="flex flex-col justify-center">
                        <button
                            className="rounded-full p-2 bg-transparent hover:bg-[#00000009] group"
                            onMouseEnter={() => setHoveredStar(value)}
                            onMouseLeave={() => setHoveredStar(null)}
                            onClick={() => handleStarClick(value)}
                        >
                            <StarRounded
                                style={{
                                    fontSize: 56,
                                    color: (value <= hoveredStar || (!hoveredStar && value <= rating?.value)) ? '#ffb400' : '#ccc',
                                    transition: 'color .2s ease'
                                }}
                            />
                        </button>
                        <div className="text-sm text-[#666] text-center">
                            {ratingText}
                        </div>
                    </div>
                )
            })}
        </div>
    )

    return (
        <SimpleModal
            open={isOpen}
            onClose={onClose}
            disableBackdropClick
            maxWidth="sm"
            bodyStyle={{ padding: 40 }}
        >
            <div
                style={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <span className="text-lg text-center font-semibold">
                    Antes de concluir o serviço, avalie a qualidade do documento revisado
                </span>

            </div>

            <Grid className="text-base mt-3">

                <div className="text-[#444]">
                    Prezamos pela qualidade do serviço que chega até você e precisamos da sua contribuição para evoluir continuamente!
                </div>

                <div className="mt-10 pb-4">
                    {renderStars()}
                </div>

                <div className="mt-10 mb-5 pb-4 w-100 border-red-100">
                    <textarea class="resize rounded-md w-full" onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>

                <Typography variant="subtitle1" style={{ lineHeight: 1.5, marginBottom: 10 }}>
                    Agradecemos desde já pela sua colaboração e atenção. Juntos, vamos elevar
                    nossos serviços a um novo patamar de excelência! 🚀
                </Typography>


                <div className="mt-10 flex justify-center">
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={!rating || !message}
                        onClick={() => handleSubmit(rating, message)}
                    >
                        <span className="text-base font-semibold">
                            Concluir tarefa e enviar avaliação!
                        </span>
                        {loading && <CircularProgress size={24} className="ml-2" />}
                    </Button>
                </div>
            </Grid>

        </SimpleModal>
    )
}

export default RequestIaRatingDialog;