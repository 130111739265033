import { Typography } from "@material-ui/core";

const FormFieldTitle = ({
    field = '', clean, onError, titleSize = 18, cleanTitle,
    titleColor, color, spacing = 0, type = 'text', titleWeight
}) => {

    return (
        <Typography style={{
            color: onError ? 'red' : (titleColor || color),
            marginBottom: type != 'checkbox' && spacing,
            fontSize: titleSize,
            lineHeight: 1.2,
            fontWeight: titleWeight ? titleWeight : clean || cleanTitle ? 'normal' : 'bold',
        }}>
            {field}
        </Typography>
    )
}

export default FormFieldTitle;