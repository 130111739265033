import { Button, Grid, IconButton as MuiIconButton, Typography } from "@material-ui/core";
import { Instagram, Language, LinkedIn, YouTube } from "@material-ui/icons";

import styled from "styled-components/macro";

const Wrapper = styled.div`
    padding: 1vh 25px;
    background: #376fd0;
    color: white;
    font-weight: bold;

    font-size: min(3vw, 2vh);

    button {
        font-size: min(3vw, 2vh);
        color: white;
        font-weight: bold;
    }
`;

const IconButton = styled(MuiIconButton)`
    padding: 0;
`;

const sendToLink = (url) => window.open(url, "_blank");

const SocialMediaButtons = () => (
    <Grid spacing={2}>
        <IconButton size="small"
            onClick={() => sendToLink('https://www.instagram.com/facilita.juridico/')}
        >
            <Instagram style={{ fontSize: '140%' }} />
        </IconButton>

        <IconButton size="small"
            onClick={() => sendToLink('https://www.youtube.com/c/FacilitaJur%C3%ADdico')}
        >
            <YouTube style={{ fontSize: '140%' }} />
        </IconButton>

        <IconButton size="small"
            onClick={() => sendToLink('https://www.linkedin.com/company/facilita-jur%C3%ADdico')}
        >
            <LinkedIn style={{ fontSize: '140%' }} />
        </IconButton>

        <IconButton size="small"
            onClick={() => sendToLink('https://facilitajuridico.com/')}
        >
            <Language style={{ fontSize: '140%' }} />
        </IconButton>
    </Grid>
)

const ExternalRegisterFooter = () => (
    <Wrapper>
        <Grid
            container justifyContent="space-between"
            style={{ maxWidth: '1000px', margin: '0 auto' }}
        >
            <Grid style={{ height: '100%' }}>
                <Typography style={{
                    fontSize: 'inherit', fontWeight: 'bold'
                }}>
                    © {new Date().getFullYear()} - Facilita Jurídico
                </Typography>
            </Grid>

            <Grid>
                <Button
                    onClick={() => sendToLink('https://bit.ly/3Qr8DBr')}
                    style={{ padding: 0 }}
                >
                    Ajuda
                </Button>
            </Grid>

            <SocialMediaButtons />
        </Grid>
    </Wrapper>
)

export default ExternalRegisterFooter;
