import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Grid, Button, Divider, CircularProgress, Tooltip } from '@material-ui/core';

import { EditorMenuGridContainer } from '../../layouts/styles/menu.layout.styles';

import { DocsContext } from '../../context/docs.context';
import solicitationService from '../../domain/services/solicitation/solicitation.service';
import DetailsComponentMenu from './details.component.menu'

import { events } from '../../domain/services/solicitation/final.documents.service';
import { CheckCircleOutlined, ErrorOutline } from '@material-ui/icons';

export function EditorMenu() {
    const {
        docEditorContainer,
        currentDocument,
        customerFormattings,
        firstLineIndentation,
        indentation,
        buttons = [],
        CM_TO_POINTS
    } = useContext(DocsContext);

    const [request, setRequest] = useState({});
    const [infoSave, setInfoSave] = useState({});

    const params = useParams();
    const id = params.id;

    useEffect(() => {

        events.on('onProgressDocument', getInfoSave)

        return () => events.removeListener('onProgressDocument', getInfoSave);

    }, []);

    const getInfoSave = (value) =>
        setInfoSave({ ...value });

    const convertDate = (date) => (date ? moment.utc(date).format('YYYY-MM-DDTHH:mm') : '');

    const find = async () => {
        const { data } = await solicitationService.find(id);
        setRequest({
            ...data,
            operationalDueDate: convertDate(data.operationalDueDate),
            dueDate: convertDate(data.dueDate),
            servicePublishedAt: !!data.servicePublishedAt && convertDate(data.servicePublishedAt),
        });

        return data;
    };

    useEffect(() => find(), []);

    const getGMTDate = (gmtDate) => {
        return gmtDate && moment(gmtDate).format('DD/MM/YYYY HH:mm:ss');
    }

    return (
        <EditorMenuGridContainer style={{ width: '85%', display: 'flex' }} container>
            {buttons.map((button, index) => (
                <Fragment key={index}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => button.onClick(docEditorContainer, currentDocument, customerFormattings, firstLineIndentation)}>
                            {button.icon}
                            {button.label}
                        </Button>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" />
                </Fragment>
            ))}
            <DetailsComponentMenu request={request} />
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ cursor: 'default' }}
                >
                    Recuo: {`${isNaN((indentation / CM_TO_POINTS).toFixed(1)) ? 'Não identificada' : (indentation / CM_TO_POINTS).toFixed(1) + 'cm'}`}
                </Button>
            </Grid>
            <Divider orientation="vertical" variant="middle" />
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ cursor: 'default' }}
                >
                    Recuo 1ª Linha: {`${isNaN((firstLineIndentation / CM_TO_POINTS).toFixed(1)) ? 'Não identificada' : (firstLineIndentation / CM_TO_POINTS).toFixed(1) + 'cm'}`}
                </Button>
            </Grid>
            <Divider orientation="vertical" variant="middle" />
            {infoSave.isSaving ?
                <Grid item style={{ display: "contents" }}>
                    <CircularProgress style={{ width: "20px", height: "20px", marginLeft: "10px", marginRight: "10px", color: "greenyellow" }} />
                    <b>Salvando... </b>
                </Grid> :
                <Grid item style={{ display: "contents", fontSize: "12px" }}>
                    {!infoSave.error ?
                        <CheckCircleOutlined style={{ width: "18px", height: "18px", marginRight: "10px", color: "greenyellow" }} /> :
                        <Tooltip title={infoSave.error}>
                            <ErrorOutline style={{ width: "18px", height: "18px", marginRight: "10px", color: "red" }} />
                        </Tooltip>
                    }
                    <b>Salvo pela última vez: {getGMTDate(infoSave.date)} </b>
                </Grid>
            }
        </EditorMenuGridContainer>
    );
}
