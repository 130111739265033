import React, { useState } from "react";

import {
    Tooltip, TableSortLabel, TableBody, Table, TableHead, TableRow, TableCell, Fab, Chip, Typography
} from "@material-ui/core";

import { Rating } from "@material-ui/lab";

import { MoreHoriz } from "@material-ui/icons";

import moment from "moment";

import utilsProviders from "../../providers/utils.provider";
import loginService from "../../../domain/services/login.service";

const RatingTable = (props) => {

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("requestId");

    const columns = [
        { id: "requestId", label: "ID" },
        { id: "serviceName", label: "Serviço" },
        { id: "concludedAt", label: "Conclusão" },
        { id: "senderUserName", label: "Revisor" },
        { id: "score", label: "Score" },
        { id: "feedback", label: "Feedback" }
    ];

    const requestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    }

    const openRequest = (requestId) => {
        window.location.href = `/solicitations/${requestId}`;
    }

    const getFeedback = (item) => {

        if (!loginService.isFaciliter())
            return item.feedback;

        return item.senderSource == 'customer' ? 'Avaliado pelo cliente.' : item.feedback
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map((item) => (
                        <TableCell key={item.id} sortDirection={orderBy === item.id ? order : false}>
                            <TableSortLabel
                                active={orderBy === item.id}
                                direction={orderBy === item.id ? order : "asc"}
                                onClick={() => { requestSort(item.id) }}>
                                {item.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            {utilsProviders.sortList(props.feedbacks, utilsProviders.getComparator(order, orderBy))
                .map((item, i) => (
                    <TableBody key={i}>
                        <TableRow>
                            <TableCell style={{ width: "40px" }}>{item.requestId}</TableCell>
                            <TableCell style={{ width: "200px" }}>{item.serviceName} {" - "} {item.area} {" - "} {item.modality}</TableCell>
                            <TableCell>{moment.utc(item.concludedAt).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{item.senderUserName}</TableCell>
                            <TableCell>
                                <Chip size="small" label={parseFloat(item.score).toFixed(2)}
                                    style={{
                                        color: 'white', fontWeight: '600', height: '16px', marginBottom: "5px", width: "fit-content",
                                        fontSize: '10px', backgroundColor: '#00800082', marginLeft: "20px", display: "block"
                                    }}
                                />
                                <Rating value={(item.score || 0)} precision={0.5} readOnly size="small" />
                            </TableCell>
                            <TableCell>
                                <Tooltip title={getFeedback(item)}>
                                    <Typography style={{
                                        overflow: "hidden",
                                        display: '-webkit-box',
                                        '-webkit-box-orient': 'vertical',
                                        '-webkit-line-clamp': '4'
                                    }} >
                                        {getFeedback(item)}
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell padding="checkbox">
                                <Tooltip title="Detalhes da solicitação">
                                    <Fab size="small" variant="round" color="primary"
                                        style={{ width: "28px", height: "28px", minHeight: "0" }}
                                        onClick={() => { openRequest(item.requestId) }}>
                                        <MoreHoriz fontSize="small" style={{ fontSize: "15px" }} />
                                    </Fab>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
        </Table>
    );
}

export default RatingTable;
