import moment from "moment";
import loginService from "../../../domain/services/login.service";
import Button from "../../shared/Button";
import { Link } from "react-router-dom";
import { Chip } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import utilsProvider from "../../providers/utils.provider";
import Description from "../../shared/Description";

export default function RatingListMobile({ ratings = [] }) {

    const columns = [
        { id: "requestId", label: "ID" },
        { id: "serviceName", label: "Serviço" },
        { id: "concludedAt", label: "Conclusão" },
        { id: "senderUserName", label: "Revisor" },
        { id: "score", label: "Score" },
        { id: "feedback", label: "Feedback" }
    ];

    const getFeedback = (rating) => {

        if (!loginService.isFaciliter())
            return rating.feedback;

        return rating.senderSource == 'customer' ? 'Avaliado pelo cliente.' : rating.feedback
    }

    return (
        <div className="flex flex-col gap-6">
            {utilsProvider.sortList(ratings, utilsProvider.getComparator('desc', 'concludedAt')).map(rating => (
                <div className="flex flex-col gap-2 w-full bg-white rounded text-sm p-4">
                    <div className="flex justify-between items-center">
                        <div className="text-xs px-2 py-[2px] rounded-full bg-[#00000008]">#{rating.requestId}</div>
                        <div className="flex gap-2">
                            <div className="text-xs px-2 py-[2px] rounded-md font-semibold text-white bg-[#00800082]">
                                {parseFloat(rating.score).toFixed(2)}
                            </div>
                            <Rating value={(rating.score || 0)} precision={0.5} readOnly size="small" />
                        </div>
                    </div>
                    <div className="font-semibold text-base my-1">
                        {rating.serviceName} {" - "} {rating.area} {" - "} {rating.modality}
                    </div>
                    <Description title="Conclusão">
                        {moment.utc(rating.concludedAt).format('DD/MM/YYYY')}
                    </Description>
                    <Description title="Revisor">
                        {rating.senderUserName}
                    </Description>
                    <Description title="Feedback">
                        {getFeedback(rating) || '--'}
                    </Description>
                    <div className="mt-3 pt-2 border-t border-[#ededed] flex justify-center">
                        <Link to={`/solicitations/${rating.requestId}`}>
                            <Button type="link">
                                Detalhes da solicitação
                            </Button>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
}