import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  AttachFileOutlined,
  Close,
  GetApp,
  InsertDriveFile,
  Visibility,
} from '@material-ui/icons';

import DocumentPreview from '../solicitations/documents/document.preview.component';

import clientDocumentsService from '../../domain/services/client.documents.service';

import { DocsContext } from '../../context/docs.context';

import swal from 'sweetalert';

const PetitionModelDocumentsList = ({ customerId }) => {

  const [models, setModels] = useState([]);
  const [preview, setPreview] = useState({ show: false });

  const { setSelectedLetterheadToLoad, loadLetterheadModalOpen, setLoadLetterheadModalOpen } = useContext(DocsContext);

  useEffect(() => getAll(), [customerId]);

  const getAll = () => {

    if (!customerId) return;

    clientDocumentsService.find(customerId).then((res) => setModels(res.data || []));
  }

  const getItemDocument = (item) => {
    let dateTime = moment.utc(item.created).format('DD/MM/YYYY');
    return (
      <>
        <Tooltip title={item.name}>
          <Typography>{item.name}</Typography>
        </Tooltip>
        <small style={{ color: 'gray', marginRight: '20px' }}>{dateTime}</small>
      </>
    );
  };

  const visibility = async (file, show) =>
    setPreview({ ...file, show });

  const confirmLoadLetterHead = (item) => {

    setLoadLetterheadModalOpen(false);

    swal({
      title: "Deseja carregar o timbrado?",
      text: "Ao carregar o timbrado, o conteúdo do documento será perdido!",
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Carregar"],
    }).then(async willLoad => {

      if (!willLoad) {
        setLoadLetterheadModalOpen(true);
        return
      }

      setSelectedLetterheadToLoad(item);
    });
  }

  return (models || []).length > 0 ? (
    <Dialog
      open={loadLetterheadModalOpen}
      onClose={() => setLoadLetterheadModalOpen(!loadLetterheadModalOpen)}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle style={{ display: 'flex' }}>
        <Typography style={{ marginTop: '8px', fontSize: '1rem', width: '100%' }} variant='h4'>
          <InsertDriveFile
            style={{
              color: '#376fd0',
              marginBottom: '-5px',
              marginRight: '7px',
            }}
          />
          Timbrados
        </Typography>
        <IconButton
          aria-label='close'
          onClick={() => setLoadLetterheadModalOpen(!loadLetterheadModalOpen)}
          style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Card mb={6} style={{ borderBottom: '1px solid #e0e0e0', borderTop: '1px solid #e0e0e0' }}>
        <DialogContent>
          <CardContent style={{ padding: '3px' }}>
            {models.map((item, i) => (
              <ListItem button style={{ paddingLeft: '0' }} key={i}>
                <ListItemIcon>
                  <Avatar
                    alt={item.name}
                    style={{
                      marginLeft: '10px',
                      color: 'white',
                      width: '24px',
                      height: '24px',
                      fontSize: '10px',
                      fontWeight: 600,
                    }}
                  >
                    <AttachFileOutlined style={{ fontSize: '12px' }} />
                  </Avatar>
                </ListItemIcon>
                <Grid container justifyContent='flex-start'>
                  {getItemDocument(item)}
                </Grid>
                <Grid container justifyContent='flex-end'>
                  <Tooltip title='Visualizar'>
                    <IconButton
                      style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        visibility(item, true);
                      }}
                    >
                      <Visibility style={{ fontSize: '16px' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Download'>
                    <IconButton href={item.url} target='_blank' rel='noopener noreferrer'>
                      <GetApp style={{ fontSize: '16px', color: '#376fd0' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Carregar timbrado ao documento'>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => confirmLoadLetterHead(item)}
                    >
                      Carregar timbrado
                    </Button>
                  </Tooltip>
                </Grid>
              </ListItem>
            ))}
          </CardContent>
        </DialogContent>
      </Card>
      <DialogActions>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '0px 15px' }}>
          <Typography variant='body2'>
            ** Selecione um timbrado antes de iniciar o desenvolvimento da petição!<br />
            Caso selecione depois, o conteúdo do documento escrito será perdido
          </Typography>
          <Button
            color='primary'
            variant='text'
            onClick={() => setLoadLetterheadModalOpen(!loadLetterheadModalOpen)}
          >
            Fechar
          </Button>
        </div>
      </DialogActions>
      {preview.show && <DocumentPreview file={preview} onSelect={visibility} />}
    </Dialog>
  ) : null;
}

export default PetitionModelDocumentsList
