import datetimeProvider from "../../../providers/datetime.provider"

export const yearlyEvents = {
    blackFriday: {
        key: 'blackfriday',
        label: 'Black Friday',
        initialDate: '2024-11-11',
        finalDate: '2024-11-17',
        colors: {
            primary: '#131313',
            secondary: '#333',
        },
        validator: function () {
            return datetimeProvider.getDate().isBetween(this.initialDate, this.finalDate, 'day', '[]');
        },
    },
    // Exemplo de outro evento
    // christmas: {
    //     key: 'christmas',
    //     label: 'Natal',
    //     initialDate: '2024-12-16',
    //     finalDate: '2024-12-30',
    //     colors: {
    //         primary: '#cc1818',
    //         secondary: '#ff6666',
    //     },
    //     validator: function () {
    //         return datetimeProvider.getDate().isBetween(this.initialDate, this.finalDate, 'day', '[]');
    //     },
    // },
};


export const getCurrentEvent = () => {
    return Object.values(yearlyEvents).find(event => event.validator());
}

const yearlyEventsService = {
    yearlyEvents,
    getCurrentEvent,
};

export default yearlyEventsService;