import { get, post, remove } from "../api";

const findRequestServiceToFavorite = async (requestId) =>
    get({ type: 'user', service: `requests/${requestId}/favorite/find-request` });

const createFavoriteService = (body) =>
    post({ type: 'user', service: `requests/${body.requestId}/favorite/`, data: body })

const removeFavorite = (requestId, favoritedId) =>
    remove({ type: 'user', service: `requests/${requestId}/favorite/${favoritedId}` })

export default {
    findRequestServiceToFavorite,
    createFavoriteService,
    removeFavorite,
}