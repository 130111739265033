import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";

import { spacing } from "@material-ui/system";
import { Hidden, CssBaseline, Paper as MuiPaper, withWidth } from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiAccordion-root:before {
    height: 0 !important;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: background: #bdbdbd #f7f9fc;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }
  
  *::-webkit-scrollbar-track {
    background: #f7f9fc;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 5px;
    border: 3px solid #f7f9fc;
  }
  .swal2-container {
    z-index: 2147483647 !important;
  };

  .MuiInputLabel-shrink {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .MuiPaper-rounded {
    border-radius: unset !important;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 8%) !important;
  }

  .MuiAutocomplete-tag {
    max-width: calc(100% - 30px) !important;
  }

  textarea {
    min-height: 24px !important;
  }

  .MuiInputLabel-shrink {
    font-size: 16px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
    height: 20px !important;
  }

  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  label + .MuiInput-formControl {
    margin-top: 20px !important;
  }

  .PrivateNotchedOutline-legendLabelled-28 {
    font-size: 0.92em !important;
  }

  legend {
    font-size: 0.93em !important;
  }

`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: #F7F9FC !important;
  margin-top: 50px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const RequestFormLayout = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default withWidth()(RequestFormLayout);
