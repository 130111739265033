import { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../shared/Button";

export default function SaveChangesFooter({
    onSave,
    onCancel,
    hasChanges = false,
    showCancel = true,
    loading,
    saveLabel = 'Salvar Alterações'
}) {

    const [footerWidth, setFooterWidth] = useState(0);

    const containerRef = useRef(null);

    const calculateParentWidth = () => {
        if (!containerRef.current) setFooterWidth(0);
        setFooterWidth(containerRef.current?.parentNode.offsetWidth);
    }

    useEffect(() => {
        calculateParentWidth();
        window.addEventListener('resize', calculateParentWidth)
        return () => window.removeEventListener('resize', calculateParentWidth)
    }, [containerRef.current]);

    return (
        <div className="w-full" ref={containerRef}>
            <div
                className="box-content h-16 fixed bottom-0 right-0 bg-white border-t border-l border-[#dedede] z-10 flex gap-4 justify-end items-center px-5 md:pl-8 xl:pr-12 overflow-hidden"
                style={{
                    width: footerWidth,
                    transition: 'transform .3s ease',
                    transform: hasChanges ? 'translateY(0)' : 'translateY(100%)'
                }}
            >
                {showCancel && (
                    <Button
                        type="secondary"
                        onClick={onCancel}
                    >
                        Cancelar
                    </Button>
                )}
                <Button
                    type="primary"
                    onClick={onSave}
                    loading={loading}
                >
                    {saveLabel}
                </Button>
            </div>
        </div>
    )
}