import api from './api';

import { events } from '../../domain/services/api';

const find = async (customerId) => api
    .get({ type: 'user', service: `customers/${customerId}/documents` });

const saveAll = async (customerId, files) => {

    if (!files || files.length == 0)
        return;

    let res = await api.post({
        type: 'user', service: `customers/${customerId}/documents`,
        data: files.map(getDocument.bind(null, customerId))
    })

    return await requestFiles(files, (res.data || []));
}

const remove = async (item) =>
    api.remove({ type: 'user', service: `customers/${item.customerId}/documents/${item.documentId}` });

const getDocument = (customerId, item) => ({
    customerId,
    name: item.name,
    fileSize: item.size,
    fileType: item.type
})

const uploadFile = (file, signedRequest, item) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);

    xhr.upload.onprogress = (e) =>
        setProgress({ total: e.total, loaded: e.loaded, filename: item.document.name, type: "Upload" });

    xhr.onloadend = () => checkFileError(item, item.document.url, resolve);

    xhr.send(file);
})

const checkFileError = (file, url, resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            resolve({ ...file, success: (xhr.status === 200) });
        }

    };
    xhr.send();
}

const setProgress = (progressData) => {
    if (!progressData.loaded) return;

    let progress = Math.round((progressData.loaded / progressData.total) * 100);
    events.emmit('onProgressDeterminate',
        { info: `Fazendo ${progressData.type} do arquivo: ${progressData.filename}`, progress, show: true }
    );
}

const requestFiles = async (files, data) => {
    events.emmit('onProgressDeterminate', { info: 'Verificando os arquivos', progress: 0, show: true });

    let reponseFiles = await Promise.all(data.map(async (item) => {
        if (!item.document)
            return;

        let file = files.find((x) => x.name == item.document.name);
        return await uploadFile(file, item.signedRequest, item);
    }));

    events.emmit('onProgressDeterminate', { info: 'Upload concluído com sucesso', progress: 100, show: false });

    let fileErrors = reponseFiles.filter((item) => !item.success);
    return await removeFileErrors(fileErrors);
}

const removeFileErrors = async (files) => {
    return Promise.all(files.map(async (item) => {
        await remove(item.document);
        return item;
    }));
}


export default {
    find,
    saveAll,
    remove
};