import { useState } from "react";
import { announcements } from "../../../domain/constant/announcements.constant";
import loginService from "../../../domain/services/login.service";
import announcementService from "../../../domain/services/marketing/announcement.service";
import ScrollPopup from "../../shared/ScrollPopup";

export default function PremiumRequestPopup({
    anchorRef,
    isNewRequest,
}) {
    const user = loginService.getUserAuthentication();

    const checkIfCanShowModal = () => {
        const customerAlreadySawAnnouncement = announcementService.isShowed(announcements.clientPremiumRequestIncentive);
        return loginService.isCustomer() && !customerAlreadySawAnnouncement && isNewRequest && !user.isFirstAccess;
    };

    const [showPopup, setShowPopup] = useState(checkIfCanShowModal());

    const handleClose = () => {
        announcementService.markViewed(announcements.clientPremiumRequestIncentive);
        setShowPopup(false);
    }

    const renderBulletPoint = (title) => (
        <div className="flex gap-2 items-center">
            <div className="h-2 w-2 rounded-full bg-[#376fd0] mt-[2px] shrink-0" />
            <div>
                <span className="font-semibold mr-1">{title}</span>
            </div>
        </div>
    )

    return (
        <ScrollPopup
            anchorRef={anchorRef}
            show={showPopup}
            onClose={handleClose}
            scrollPercentage={0.9}
            checkOnScroll={checkIfCanShowModal}
        >
            <div className="flex flex-col gap-5 text-base max-w-xl p-3 pt-0">
                <div className="font-semibold">
                    Solicite um Faciliter Premium
                </div>
                <div className="text-sm">
                    Um Faciliter Premium é um profissional que se destacou por seu excelente desempenho, recebendo avaliações altas de diversos clientes.
                </div>

                <div className="font-semibold">
                    Por que escolher o serviço premium?
                </div>

                <div className="flex flex-col gap-3 text-sm">
                    {renderBulletPoint('Qualidade Superior')}
                    {renderBulletPoint('Atendimento Prioritário')}
                    {renderBulletPoint('Maior Satisfação')}
                </div>

                <div className="text-[#376fd0] text-sm italic">
                    Ao optar pelo <b>serviço premium</b>, você utiliza <b>2 créditos</b> ao invés de 1, mas o investimento vale a pena pela tranquilidade e segurança de saber que seu pedido está em mãos experientes.
                </div>
            </div>
        </ScrollPopup>
    );
}