import { useState } from 'react';

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle, IconButton, Typography
} from '@material-ui/core';
import { Close, Assignment, ThumbUpOutlined } from '@material-ui/icons';
import { DownloadCloud } from 'react-feather';

import AnalysisView from './analysis.view.component';

import requestAnalysisService from '../../../domain/services/request.analysis.service';

export default function RequestAnalysisModal({ requestId, onClose }) {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose();
    }

    return (
        <Dialog open={open} maxWidth='xs' fullWidth onClose={handleClose}>
            <DialogTitle style={{ display: 'inline-flex', backgroundColor: '#eee', width: '100%', minWidth: '100%' }}>
                <Typography
                    variant="h4"
                    style={{
                        paddingTop: '12px',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        minWidth: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Assignment style={{ color: '#376fd0', fontSize: '25px', marginRight: '7px' }} />
                    Indicação de Petição
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <AnalysisView requestId={requestId} />
            </DialogContent>

            <DialogActions style={{ background: "rgb(238, 238, 238)" }}>
                <Button variant='contained' onClick={() => handleClose()}>Fechar</Button>
                <Button variant='contained' color='primary'
                    onClick={() => requestAnalysisService.downloadPdf(requestId)}>
                    <DownloadCloud size="15px" style={{ marginRight: 10 }} />
                    Baixar Indicação de Petição em PDF
                </Button>
            </DialogActions>
        </Dialog>
    );
}
