import { useCallback, useMemo, useState } from 'react';

import api from '../../domain/services/api';

import { Star } from '@material-ui/icons';

import FacilitaSnackbar from '../FacilitaSnackbar';
import SelectResponsible from './select.responsible.component';
import OperationalDueDateModal from '../OperationalDueDateModal';

import CorrespondentService from '../../domain/services/correspondent.service';
import MemberService from '../../domain/services/solicitation/member.service';
import useSnackbar from '../../hooks/useSnackbar';

const SelectFaciliterReviewer = ({
    requestId,
    faciliterReviewer,
    operationalDueDate,
    clientDueDate,
    callback,
}) => {
    const [list, setList] = useState([]);

    const { snackbarAlert, setAlert, closeAlert } = useSnackbar();

    const [selectedFaciliter, setSelectedFaciliter] = useState();

    const [openDueDateModal, setOpenDueDateModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const defaultParams = useMemo(
        () => ({
            filter: [
                { name: 'isInactive', value: [false] },
                { name: 'isReviewer', value: true },
            ],
            orderBy: [{ name: 'name', value: 'asc' }],
        }),
        [],
    );

    const getList = useCallback(
        async (filter = [], orderBy) => {
            const results = await CorrespondentService.filter({
                filter: [...filter, ...defaultParams.filter],
                orderBy: orderBy || defaultParams.orderBy,
            }).then((response) => api.extractValueByKey('results', response));

            setList(build(results));
        },
        [defaultParams],
    );

    const build = (list) =>
        list.map((item) => ({
            item,
            id: item.id,
            title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ padding: '3px', background: '#8479E1', borderRadius: '100%' }}></div>
                    <div
                        style={{
                            borderRadius: '10px',
                            padding: '3px 8px 3px 8px',
                            fontSize: '11px',
                            fontWeight: '600',
                        }}
                    >
                        {item.name}
                    </div>
                    {item.score && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '3px 5px 3px 5px',
                                background: '#DBDFFD',
                                borderRadius: '10px',
                                fontSize: '10px',
                                color: '#8479E1',
                                fontWeight: '600',
                            }}
                        >
                            <Star style={{ fontSize: '10px', marginRight: '3px', color: '#8479E1' }} />
                            {item.score ? item.score.toFixed(1) : 'Não calculado'}
                        </div>
                    )}
                </div>
            ),
        }));

    const onSelectFaciliter = ({ id: correspondentId }) => {
        setOpenDueDateModal(true);
        setSelectedFaciliter(correspondentId);
    };

    const onSave = async (newOperationalDueDate) => {
        setIsLoading(true);
        try {
            await MemberService.saveFaciliterReviewer({
                correspondentId: selectedFaciliter,
                requestId,
                operationalDueDate: newOperationalDueDate,
            });
            setAlert('Faciliter revisor adicionado com sucesso');
        } catch (err) {
            setAlert(
                err?.response?.data?.message ||
                'Possível problema ao adicionar o faciliter revisor. Tente novamente ou contate o suporte.'
                , 'error'
            );
        }
        setIsLoading(false);
        callback();
        setOpenDueDateModal(false);
    };

    const onDelete = async ({ reason, price }) => {
        try {
            await MemberService.removeFaciliterReviewer({
                requestId,
                price,
                addtionalInformation: { reason },
            });
            setAlert('Faciliter revisor removido com sucesso.');
            callback();
        } catch (error) {
            setAlert(
                error?.response?.data?.message ||
                'Possível problema ao remover o faciliter revisor. Tente novamente ou contate o suporte.'
                , 'error'
            );
            callback();
        }
    };

    return (
        <>
            <SelectResponsible
                responsible={faciliterReviewer}
                requestId={requestId}
                data={list}
                getList={getList}
                label="Faciliter Revisor"
                title="Adicionar Faciliter Revisor"
                color="#8479E1"
                onSelect={onSelectFaciliter}
                onDelete={onDelete}
            />

            <OperationalDueDateModal
                operationalDueDate={operationalDueDate}
                clientDueDate={clientDueDate}
                onSave={onSave}
                open={openDueDateModal}
                setOpenDueDateModal={setOpenDueDateModal}
                subtitle={`Ao vincular o faciliter revisor você pode atualizar o prazo de entrega do
        operacional, devendo ser menor que o prazo do cliente.`}
                isLoading={isLoading}
            />

            <FacilitaSnackbar alert={snackbarAlert} onClose={closeAlert} />
        </>
    );
};

export default SelectFaciliterReviewer;
