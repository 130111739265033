import Button from "../shared/Button";
import SimpleModal from "../shared/SimpleModal";
import { useTheme } from '@material-ui/core';

export default function PremiumRequestIncentiveModal({
    open,
    onClose,
}) {

    const theme = useTheme();

    const primaryColor = theme.palette.primary.main;

    const renderBulletPoint = (title, text) => (
        <div className="flex gap-2 items-start">
            <div className="h-2 w-2 rounded-full mt-[10px] shrink-0" style={{ background: primaryColor }} />
            <div>
                <span className="font-semibold mr-1">{title}</span>
                {text}
            </div>
        </div>
    )

    return (
        <SimpleModal
            title="Conheça o Serviço Premium"
            open={open}
            maxWidth="md"
            onClose={onClose}
        >
            <div className="flex flex-col gap-6 text-base max-w-4xl">
                <div className="font-semibold" style={{ color: primaryColor }}>
                    Você pode solicitar que a sua solicitação seja feita por um Faciliter Premium.
                </div>
                <div className="">
                    Um Faciliter Premium é um profissional que se destacou por seu excelente desempenho, recebendo avaliações altas de diversos clientes. Eles são reconhecidos por sua precisão, rapidez e atenção aos detalhes, garantindo que sua solicitação seja tratada com o máximo cuidado e expertise.
                </div>

                <div className="font-semibold" style={{ color: primaryColor }}>
                    Por que escolher o serviço premium?
                </div>

                <div className="flex flex-col gap-3">
                    {renderBulletPoint(
                        'Qualidade Superior:',
                        'Seu pedido será tratado por um dos nossos melhores faciliters, assegurando a mais alta qualidade.',
                    )}
                    {renderBulletPoint(
                        'Atendimento Prioritário:',
                        'Desfrute de um atendimento mais rápido e eficiente, com prioridade em cada etapa do processo.',
                    )}
                    {renderBulletPoint(
                        'Maior Satisfação:',
                        'Beneficie-se da experiência e dedicação de um profissional altamente avaliado, aumentando as chances de sucesso da sua solicitação.',
                    )}
                </div>

                <div className="italic" style={{ color: primaryColor }}>
                    Ao optar pelo <b>serviço premium</b>, você utiliza <b>2 créditos</b> ao invés de 1, mas o investimento vale a pena pela tranquilidade e segurança de saber que seu pedido está em mãos experientes.
                </div>

                <div className="flex justify-end mt-2">
                    <Button
                        onClick={onClose}
                    >
                        Entendi
                    </Button>
                </div>
            </div>

        </SimpleModal>
    );
}