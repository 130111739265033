import repository from '../../../repositories/request.hearing.analysis.repository';

import loginService from '../../login.service';

import requestValidator from '../../../validators/request.validator';

const canViewForm = (request) =>
    requestValidator.isHearingAnalysisModality(request.modalityId) && (loginService.isInternal() || loginService.isFaciliter())

const findFields = async () =>
    repository.findFields()
        .then(res => res.data);

const findValuesByRequestId = async (requestId) =>
    repository.findValuesByRequestId(requestId)
        .then(res => res.data);

const saveAnalysis = async (requestId, values) =>
    repository.saveAnalysis(requestId, values)
        .then(res => res.data);

const formatValues = (data) =>
    data.reduce((acc, cur) => ({ ...acc, [cur.fieldId]: cur.value }), {})

export default {
    canViewForm,
    findFields,
    findValuesByRequestId,
    saveAnalysis,
    formatValues
}