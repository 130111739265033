import { useEffect, useState } from "react";

export default function Switch({
    onChange = (newState) => {},
    initialState = false,
    value,
}) {

    const [currentState, setCurrentState] = useState(initialState);

    const handleChange = () => {
        setCurrentState(prev => !prev);
        onChange(!currentState);
    }

    useEffect(() => {
        setCurrentState(value);
    }, [value]);

    return (
        <button
            type="button"
            className={`
                rounded-full w-12 p-[2px]
                ${currentState ? 'bg-primary' : 'bg-[#777]'}
            `}
            onClick={handleChange}
        >
            <div
                className={`
                    rounded-full h-5 w-5 bg-white
                    ${currentState ? 'ml-[100%]' : 'ml-0'}
                    ${currentState ? '-translate-x-full' : 'translate-x-0'}
                `}
                style={{
                    transition: 'margin .3s ease, transform .3s ease',
                }}
            />
        </button>
    )
}