import { Popover } from '@material-ui/core';
import { cloneElement, useState } from 'react';

export default function Menu({
  children,
  button,
  onOpen = () => null,
  anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const clonedButton = cloneElement(button, { onClick: handleClick });

  return (
    <>
      {clonedButton}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        variant
      >
        {children}
      </Popover>
    </>
  );
}
