import React, { useEffect, useState } from "react";

import moment from "moment";

import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress,
    TextField, FormControl, Card, CardContent, Grid, Typography
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { WarningOutlined } from "@material-ui/icons";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import solicitationService from "../../../domain/services/solicitation/solicitation.service";
import loginService from "../../../domain/services/login.service";
import { events } from "../../../domain/services/api";

function PendingForm(props) {

    const [open, setOpen] = useState(true);

    const [additionalInformation, setAdditionalInformation] = useState(props.additionalInformation || {});

    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => newValue(), []);

    const saveReasonPending = async () => {
        if (!additionalInformation || !(additionalInformation.pending || {}).reason)
            return;

        trackPromise(
            solicitationService.editValue(props.requestId, mapValue())
                .then(props.onSave)
                .then(() => events.emmit('onRefresh'))
                .finally(() => setOpen(false))
        );
    }

    const setFieldValue = (field, value) =>
        setAdditionalInformation(item => ({ ...item, pending: { ...item.pending, [field]: value } }));

    const newValue = () =>
        setAdditionalInformation({ ...additionalInformation, pending: { reason: "" } });

    const mapValue = () => {
        let values = { ...additionalInformation };
        values.pending = {
            reason: values.pending.reason,
            //categoryId: values.pending.categoryId,
            date: moment().format("YYYY-MM-DDTHH:mm"),
            senderUserId: loginService.getUserAuthentication().id
        };

        setAdditionalInformation(values);
        return { additionalInformation: values };
    }

    const closeModal = () => {
        setOpen(false);
        props.cancelPending();
    }

    const loading = () => {
        return (
            promiseInProgress &&
            <Card mb={12} xs={12} style={{ marginLeft: "-10px", marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                <CardContent style={{ display: "-webkit-inline-box", paddingTop: "20px", paddingBottom: "20px" }}>
                    <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                    <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                        <Typography variant="h6" gutterBottom> Aguarde </Typography>
                        <Typography variant="body2" gutterBottom> Atualizando as informações... </Typography>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={closeModal} maxWidth="xs" fullWidth>
                <DialogTitle>
                    <WarningOutlined style={{ marginBottom: "-4px", fontSize: "20px", color: "orange" }} />{" "}
                    Enviar para Pendência
                </DialogTitle>
                <DialogContent>
                    {loading()}

                    {!promiseInProgress &&
                        <>
                            {
                                /* <Grid container justify="flex-end"
                                    style={{ display: "inline-flex", width: "auto", float: "right", marginBottom: "10px" }}>
                                    <TicketTagsForm
                                        categoryId={(additionalInformation.pending || {}).categoryId}
                                        onSave={setFieldValue} />
                                </Grid> */
                            }
                            <Alert mt={2} mb={1} severity="info"
                                style={{ fontSize: "10px", padding: "2px 10px", width: "100%" }}>
                                Será gerado um comentário visível ao cliente.
                            </Alert>

                            <FormControl fullWidth my={2} variant="outlined" style={{ marginTop: '20px' }}>
                                <TextField
                                    name="correction" label="Motivo da Pendência"
                                    multiline={true} variant="outlined"
                                    onChange={(e) => { setFieldValue("reason", e.target.value) }}
                                    InputLabelProps={{ shrink: true }} />

                                <span style={{ color: "rgb(0 0 0 / 44%)", fontWeight: "600", fontSize: "10px" }}>
                                    * Informe o Motivo
                                </span>
                            </FormControl>
                        </>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary" disabled={promiseInProgress}> Cancelar </Button>
                    <Button onClick={saveReasonPending} color="primary" disabled={promiseInProgress}> Salvar </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default PendingForm;


