const getMissingFieldsToSavePart = (part = {}) => {

    const requiredFields = [
        { key: 'value' },
        { key: 'partTypeId' },
        { key: 'isRepresented' }
    ];

    const missingFields = [];

    for (const field of requiredFields)
        if (!part[field.key])
            missingFields.push(field.key)

    return missingFields;
}

export default {
    getMissingFieldsToSavePart
}