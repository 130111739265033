import api from '../../services/api';

const baseUrl = 'requests/';

const findNewRequest = async (id) => ({
    data: {
        id,
        services: [1, 3],
        serviceTypeId: 1,
        areaId: 2,
        serviceId: 1
    }
});

const findPricesByMainId = async (mainId) =>
    api.get({ type: 'user', service: baseUrl + `${mainId}/prices` });

const findAllAreas = async () =>
    api.get({ type: 'user', service: `${baseUrl}/areas` });

const findAreasByModalityId = async (modalityId) =>
    api.get({ type: 'user', service: `${baseUrl}/areas-by-modality/${modalityId}` });

const faciliterCorrectionDropout = async (requestId, correspondentId) =>
    api.post({ type: 'user', service: `${baseUrl}/${requestId}/members/${correspondentId}/correction-dropout`, data: {} });

const editModality = async (requestId, requestChanges, fieldsChanges) =>
    api.put({ type: 'user', service: `${baseUrl}/${requestId}/change-modality`, data: { requestChanges, fieldsChanges } });

const calculateAllServicesDueDate = async (mainId) =>
    api.get({ type: 'user', service: baseUrl + `deadline/${mainId}/calculate-all-services` })

const findServiceFields = async (mainId) =>
    api.get({ type: 'user', service: baseUrl + `fields/${mainId}/service-fields` });

const findMenuOptions = async (requestId) =>
    api.get({ type: 'user', service: baseUrl + `details/${requestId}/menu-options` });

const findModalitiesByServiceId = async (serviceId) =>
    api.post({ type: 'user', service: `${baseUrl}modalities/services`, data: [serviceId] });

const findAllAvailableCitiesByState = async (requestId, stateSigla) =>
    api.get({ type: 'user', service: `${baseUrl}/${requestId}/members/states/${stateSigla}/available-cities` });

const changeServiceToPremium = async (requestId) =>
    api.put({ type: 'user', service: `${baseUrl}/${requestId}/premium-service/` });

const removePremiumServiceFromRequest = async (requestId) =>
    api.put({ type: 'user', service: `${baseUrl}/${requestId}/premium-service/remove` });

export default {
    findNewRequest,
    findPricesByMainId,
    findAllAreas,
    findAreasByModalityId,
    faciliterCorrectionDropout,
    editModality,
    calculateAllServicesDueDate,
    findServiceFields,
    findMenuOptions,
    findModalitiesByServiceId,
    findAllAvailableCitiesByState,
    changeServiceToPremium,
    removePremiumServiceFromRequest
}