import React, { useContext } from 'react'

import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';

import {
  Close,
  FormatIndentDecrease,
  FormatLineSpacing,
  FormatSize,
  TextFormat,
} from '@material-ui/icons';

import { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import customerService from '../../domain/services/customer/customer.service';
import { DocsContext } from '../../context/docs.context';

const TextFormatRequirementList = ({ customerId }) => {

  const [textFormatRequirement, setTextFormatRequirement] = useState(null);

  const { showFormattings, setShowFormattings } = useContext(DocsContext);

  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    if (!customerId)
      return

    trackPromise(customerService.getTextFormatRequirement(customerId))
      .then((res) => setTextFormatRequirement(res.data[0].textFormatRequirement));
  }, [customerId]);

  return textFormatRequirement && (
    <>
      <Dialog open={showFormattings} onClose={() => setShowFormattings(!showFormattings)} maxWidth='sm' fullWidth>
        <DialogTitle style={{ display: 'flex' }}>
          <Typography style={{ marginTop: '8px', fontSize: '1rem', width: '100%' }} variant='h4'>
            <TextFormat
              style={{
                color: '#376fd0',
                marginBottom: '-5px',
                marginRight: '7px',
              }}
            />
            Critérios de formatação
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setShowFormattings(!showFormattings)}
            style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Card mb={6} style={{ borderBottom: '1px solid #e0e0e0', borderTop: '1px solid #e0e0e0' }}>
          <DialogContent>
            <CardContent style={{ padding: '3px' }}>
              {!promiseInProgress && textFormatRequirement && (
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  style={{ padding: '8px 0px 1px 0px' }}
                  spacing={2}
                >
                  {textFormatRequirement.fontFamily && (
                    <Grid item>
                      <Chip
                        icon={<TextFormat />}
                        label={`Fonte: ${textFormatRequirement.fontFamily}`}
                      />
                    </Grid>
                  )}
                  {textFormatRequirement.fontSize && (
                    <Grid item>
                      <Chip
                        icon={<FormatSize />}
                        label={`Tamanho da fonte: ${textFormatRequirement.fontSize}`}
                      />
                    </Grid>
                  )}
                  {textFormatRequirement.retreatParagraph && (
                    <Grid item>
                      <Chip
                        icon={<FormatIndentDecrease />}
                        label={`Recuo de parágrafo: ${textFormatRequirement.retreatParagraph}`}
                      />
                    </Grid>
                  )}
                  {textFormatRequirement.lineSpacing && (
                    <Grid item>
                      <Chip
                        icon={<FormatLineSpacing />}
                        label={`Espaçamento entre linhas: ${textFormatRequirement.lineSpacing}`}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </CardContent>
          </DialogContent>
        </Card>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            onClick={() => setShowFormattings(!showFormattings)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TextFormatRequirementList