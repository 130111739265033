
import React from "react";

import Async from "../../components/Async";

import { Book } from "@material-ui/icons";
import BlogEditorPage from "../../pages/marketing/blog/blogEditorPage";


const BlogPage = Async(() => import("../../pages/marketing/blog/blogPage"));

const routes = {
    blog: {
        id: 'blog',
        name: 'Blog',
        path: '/blog',
        component: BlogPage,
        icon: <Book />,
        roles: ['admin'],
        isNew: true,
    },
    blogEditorSpecific: {
        id: 'blogEditorSpecific',
        name: 'Editor de Blog',
        path: '/blog/editor/:id',
        component: BlogEditorPage,
        roles: ['admin'],
        layoutProps: {
            hideSideBar: true,
        },
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}