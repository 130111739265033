const modalities = {
    analysis: {
        id: 1,
        key: 'analysis',
        processDocumentRequired: false
    },
    notion: {
        id: 2,
        key: 'notion',
        label: 'Parecer',
        processDocumentRequired: false
    },
    contract: {
        id: 3,
        key: 'contract',
        processDocumentRequired: false
    },
    initial: {
        id: 4,
        key: 'initial',
        processDocumentRequired: false
    },
    contestation: {
        id: 5,
        key: 'contestation',
        processDocumentRequired: true
    },
    manifestation: {
        id: 6,
        key: 'manifestation',
        processDocumentRequired: true
    },
    counterReasons: {
        id: 8,
        key: 'counterReasons',
        processDocumentRequired: true
    },
    resources: {
        id: 9,
        key: 'resources',
        processDocumentRequired: true
    },
    calculation: {
        id: 10,
        key: 'calculation',
        serviceId: 3,
        areaIds: [1, 2, 4, 5, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        processDocumentRequired: false
    },
    hearing: {
        id: 11,
        key: 'hearing',
        serviceId: 4,
        areaIds: [1, 2, 3, 5, 18, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        processDocumentRequired: true
    },
    hearingAnalysis: {
        id: 12,
        key: 'hearingAnalysis',
        serviceKey: 'hearingAnalysis',
        areaIds: [1, 2, 3, 5, 18, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        processDocumentRequired: true
    },
    hearingInPerson: {
        id: 15,
        key: 'hearingInPerson',
        serviceId: 4,
        areaIds: [1, 2, 3, 5, 18, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        processDocumentRequired: true
    }
}

export default modalities;