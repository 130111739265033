import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import Routes from './routes/Routes';

function App() {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
            <Routes />
        </SnackbarProvider>
    );
}

export default App;
