const updateData = [
    'id',
    'customerId',
    'dueDate',
    'processNumber',
    'operationalDueDate',
    'type',
    'additionalInformation',
    'modalityId',
    'areaId',
    'checklist',
    'servicePublishedAt',
    'freeJustice',
    'state',
    'city',
    'citationDate',
    'faciliterServiceId',
    'forwardToPetition',
    'forwardToAnalysis',
    'forwardCalculationServiceId',
    'forwardModalityId',
    'isPetitionIndication',
    'needCalculation',
    'forwardToHearing',
    'forwardToHearingAnalysis',
    'mainServiceId',
    'hearingDateTime',
    'forwardToHearingInPerson',
    'linkPremiumFaciliter',
    'checkIn',
    'processDocumentAttachment'
]

const checkAttributeByInterface = (attr, operationInterface) =>
    operationInterface.includes(attr);

const mountUpdateObj = (request, operationInterface) =>
    Object.keys(request)
        .filter(key => checkAttributeByInterface(key, operationInterface))
        .reduce((requestObj, key) => ({ ...requestObj, [key]: request[key] }), {})

const checkIfCanBeUpdated = (request) => {

    if (Object.keys(request).length < 2)
        return null;

    return request;
}

const update = async (request) => await Promise
    .resolve(mountUpdateObj(request, updateData))
    .then(checkIfCanBeUpdated)

export default {
    update
}