import moment from 'moment';

import { Grid, Tooltip } from '@material-ui/core';

import FormField from '../../FormField';
import { CalendarToday } from '@material-ui/icons';

export default function DateTimePickerField({
    name, label, value, extraInfo, setFieldValue,
    handleInUtc = true, fieldProps, onError, disabled = false,
    inputVariant = 'outlined',
    showIcon = true,
    titleStyle = {
        titleSize: 15,
        titleWeight: 600,
        spacing: 10,
    }
}) {

    const onChange = (name, newValue) => {

        if (!newValue)
            return;

        const datetime = handleInUtc ? moment(newValue).utc().format() : moment(newValue).format();

        setFieldValue(name, datetime);
    }

    return (
        <Grid item xs={12}>
            <FormField
                {...fieldProps}
                type='datetime'
                name={name}
                field={label}
                currentValue={value ? moment(value).toISOString() : null}
                helperText={extraInfo}
                onChange={onChange}
                required
                onError={onError}
                disabled={disabled}
                placeholder="Selecione a data"
                ampm={false}
                inputVariant={inputVariant}
                InputProps={{
                    endAdornment: showIcon ? <CalendarToday style={{color: '#0007' }} /> : undefined,
                }}
                {...titleStyle}
            />
        </Grid>
    );
}
