import api from '../../services/api';

const checkExtensionDeadlinePossibility = async (requestId, userId, intendedDate) => 
    api.post({ type: 'user', service: `requests/${requestId}/members/extend-deadline`, data: { userId, intendedDate } });

const confirmConclusionExpectation = async (requestId, userId) =>
    api.post({ type: 'user', service: `requests/${requestId}/members/confirm-deadline-notification`, data: { userId } });

const confirmOneHourOperationalDeadlineExtension = async (requestId, userId) =>
    api.post({ type: 'user', service: `requests/${requestId}/members/confirm-extended-deadline-notification`, data: { userId } });

const updateOperationalDueDateByExtension = async (requestId, userId, newOperationalDueDate) => 
    api.put({ type: 'user', service: `requests/${requestId}/members/extend-deadline`, data: { userId, newOperationalDueDate } });

export default {
    checkExtensionDeadlinePossibility,
    updateOperationalDueDateByExtension,
    confirmConclusionExpectation,
    confirmOneHourOperationalDeadlineExtension
}