import { useState } from 'react';
import swal from 'sweetalert';

import { Button, CircularProgress } from '@material-ui/core';

import requestStatusService from '../../domain/services/solicitation/request.status.service';

const CustomerRevokeButton = ({ onFinish, setAlert, isList = false, mainId }) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleRevokeRequest = async () =>
        Promise
            .resolve(setIsLoading(true))
            .then(() => requestStatusService.revokeRequest(mainId))
            .then(() => {
                setAlert('Solicitação arquivada com sucesso!', 'success');
                onFinish();
            })
            .catch(() =>
                swal({
                    title: 'Sua solicitação já foi encaminhada para a produção.',
                    text: `
                        Infelizmente, devido aos custos envolvidos neste processo, 
                        não podemos mais arquivar os serviços solicitados.

                        Se você tiver alguma dúvida ou precisar de assistência, 
                        por favor, clique no botão abaixo para falar com nosso 
                        suporte. Estamos sempre à disposição para ajudá-lo!

                        Agradecemos pela sua compreensão!
                    `,
                    icon: false,
                    buttons: {
                        confirm: 'Falar com o Suporte',
                        cancel: 'Tudo bem',
                    }
                }).then(res => res && window.open('https://wa.me/5515998526603', '_blank'))
            )
            .finally(() => {
                setIsLoading(false)
            });

    const handleClick = () =>
        swal({
            title: "Arquivar solicitação",
            text: `Ao confirmar esta ação, a solicitação será descontinuada e o saldo ficará disponível para uso. Deseja continuar?`,
            icon: "warning",
            buttons: {
                cancel: 'Cancelar',
                confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' }
            }
        }).then(res => !!res && handleRevokeRequest());

    const getButton = () => ({
        true: (
            <Button
                variant='text'
                color='primary'
                className="w-full"
                onClick={handleClick}
            >
                <span className='text-sm'>Arquivar</span>
            </Button>
        ),
        false: (
            <Button
                color="primary" onClick={handleClick}
                fullWidth disabled={isLoading} style={{
                    pointerEvents: 'all',
                    fontWeight: 600, marginRight: 10
                }}
            >
                Arquivar solicitação
                {isLoading && <CircularProgress size={15} style={{ marginLeft: 8 }} />}
            </Button>
        )
    })[isList];

    return getButton();
}

export default CustomerRevokeButton;
