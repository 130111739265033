import Quill from "quill";
import { useEffect, useRef, useState } from "react"

import 'quill/dist/quill.snow.css';
import { ButtonBlot } from "./customFormats/ButtonBlot";

export default function QuillViewer({
    content,
}) {

    const quillRef = useRef(null);

    const [quillInstance, setQuillInstance] = useState(null);

    useEffect(() => {
        Quill.register(ButtonBlot);

        const quill = new Quill(quillRef.current, {
            theme: 'snow',
            readOnly: true,
            modules: {
                toolbar: false,
            }
        });

        setQuillInstance(quill);
    }, []);
    
    useEffect(() => {
        if (!quillInstance) return;
        quillInstance.root.innerHTML = content;
    }, [content, quillInstance])
    

    return (
        <div className="w-full">
            <div ref={quillRef} className="w-full !border-0 !p-0 !-mx-3 !-my-[15px]" />
        </div>
    )
}