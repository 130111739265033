
import { useEffect, useMemo, useState } from "react";

import { Settings } from "@material-ui/icons";
import { Tag } from "react-feather";
import { Divider, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import AnalysisPartsView from "./analysis.parts.view.component";
import LoadingProgress from "../../LoadingProgress";

import requestAnalysisService from '../../../domain/services/request.analysis.service';
import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';

const AnalysisView = ({ requestId }) => {

    const [analysis, setAnalysis] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => fetchAnalysisData(requestId), []);

    const fetchAnalysisData = async (requestId) =>
        Promise
            .all([
                requestAnalysisService.getAnalysis(requestId).catch(() => ({})),
                requestRegistryService.getDynamicFieldsAnalysis(requestId).catch(() => ({}))
            ])
            .then(setAnalysisValue)
            .catch((err) => { console.log(err) })
            .finally(() => setIsLoading(false));

    const setAnalysisValue = (values) => {

        const analysis = (values[0] || {});
        const fieldValues = (values[1] || []);

        setAnalysis({ ...analysis, ...requestRegistryService.integrateFields(analysis, fieldValues) });
    }

    const headData = useMemo(() => {

        if (!analysis)
            return [];

        return ([
            {
                title: 'Id da Solicitação',
                icon: () => <Tag style={{ fontSize: "30px", color: "#105b7f" }} />,
                info: analysis.requestId
            },
            {
                title: 'Modalidade',
                icon: () => <Settings style={{ fontSize: "30px", color: "#105b7f" }} />,
                info: analysis.modality
            }
        ])
    }, [analysis])

    const bodyData = useMemo(() => {
        if (!analysis) return []
        return ([
            {
                title: 'Tipo de ação',
                text: (analysis.typeAction?.values || [])[0]?.optionName || <i style={{ color: "#999" }}>Não configurado</i>
            },
            {
                title: 'Breve relato do caso',
                text: analysis.facts || <i style={{ color: "#999" }}>Não configurado</i>
            },
            {
                title: 'Principais tópicos da petição',
                text: analysis.petitionTopics || <i style={{ color: "#999" }}>Não configurado</i>
            },
            {
                title: 'Dados do advogado subscritor',
                text: analysis.attorney || <i style={{ color: "#999" }}>Não configurado</i>
            }
        ])
    }, [analysis])

    const getContent = () => {

        if (isLoading)
            return (
                <LoadingProgress message="Buscando Indicação de Petição..." />
            )

        if (!analysis)
            return (
                <Alert severity="error">
                    <AlertTitle>
                        <strong>Não foi possível encontrar a Indicação de Petição</strong>
                    </AlertTitle>
                    Por favor tente novamente mais tarde ou entre em contato com nosso suporte.
                </Alert>
            )

        return (
            <>
                <Grid container spacing={4} style={{ marginTop: "4px" }}>
                    {headData.map(obj =>
                        <Grid item xs={12} sm={6} md={6} >
                            <div style={{ padding: "10px 15px 10px 15px", borderRadius: "10px", background: "#cdebf9", display: 'flex', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {obj.icon()}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="p" gutterBottom style={{ color: "#105b7f" }}>
                                            {obj.title}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom style={{ color: "#105b7f" }} >
                                            {obj.info}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )}
                </Grid>
                {bodyData.map(item => (
                    <>
                        <Divider style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)', backgroundColor: 'transparent', marginTop: '20px' }} />
                        <Typography variant="h5" ant="h6" gutterBottom style={{ marginTop: '15px' }}>
                            {item.title}
                        </Typography>
                        <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line" }}>
                            {item.text}
                        </Typography>
                    </>
                ))}

                <Divider style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)', backgroundColor: 'transparent', marginTop: '20px' }} />
                <Typography variant="h5" ant="h6" gutterBottom style={{ marginTop: '15px' }}>
                    Qualificação das partes
                </Typography>
                <section style={{ marginTop: 10, marginLeft: -10 }}>
                    <AnalysisPartsView analysisId={analysis.id} />
                </section>
            </>
        )
    }

    return getContent();
}

export default AnalysisView