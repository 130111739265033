export const blogStatus = {
    draft: {
        name: 'draft',
        label: 'Rascunho',
    },
    inactive: {
        name: 'inactive',
        label: 'Inativo',
    },
    published: {
        name: 'published',
        label: 'Publicado',
    },
};