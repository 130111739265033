import {
    Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ExpandMore, Star } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import loginService from "../../../domain/services/login.service";
import memberService from "../../../domain/services/solicitation/member.service";
import FaciliterRatingContent from "./faciliter.rating.content";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

function FaciliterRating(props) {

    const { promiseInProgress } = usePromiseTracker();

    const [expanded, setExpanded] = useState(true);

    const [faciliters, setFaciliters] = useState([]);

    useEffect(() => findFeedbacksFaciliters(), [])

    const findFeedbacksFaciliters = () =>
        trackPromise(
            memberService.findAllFeedbackByRequestId(props.request?.id || props.requestId)
                .then(res => setFaciliters(res.data || []))
                .catch(err => null)
        );

    if (loginService.isCustomer())
        return null;

    const getEmptyCard = () => {

        const showEmptyCard = !promiseInProgress && faciliters.length == 0;

        return showEmptyCard && (
            <Card style={{ marginTop: 20, borderRadius: 0, backgroundColor: 'unset' }}>
                <Alert mt={2} mb={1} severity='info'>
                    Não há avaliações de faciliter
                </Alert>
            </Card>
        )

    }

    return (
        <React.Fragment>
            <Card mb={6} style={{ marginBottom: '10px' }}>
                <CardContent style={{ padding: "3px" }}>
                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>

                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Star style={{ fontSize: "18px", marginRight: "10px" }} />
                            <Typography variant="h6">Avaliações Faciliter</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ borderTop: "1px solid #d8d8d8", display: "block" }}>

                            {faciliters.map((faciliter) => (
                                <FaciliterRatingContent
                                    name={faciliter.name}
                                    score={faciliter.score}
                                    feedback={faciliter.feedback}
                                    source={faciliter.senderSource}
                                    isAnalysisRating={faciliter.service_type == 'analysis'}
                                />
                            ))}

                            {getEmptyCard()}

                        </AccordionDetails>

                    </Accordion>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

export default FaciliterRating;


