import { useEffect, useState } from 'react';

import swal from 'sweetalert2';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import FormField from '../../FormField';
import RadioButtonField from './radio.button.field';

import utilsProvider from '../../providers/utils.provider';

import requestFieldsService from '../../../domain/services/request/request.fields.service';

import { requestServices } from "../../../domain/constant/request.services.constant";

export default function SelectAutocompleteField({
    id, name, label, required, value = {}, request, confirmationLabel,
    needConfirmation, isDisabled, extraInfo, getOptions, setFieldValue,
    fieldProps, hasCustomFieldOptions, onError,
    fieldServiceName, fieldKey
}) {

    const [options, setOptions] = useState([]);

    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (!!value) {

            const requestField = (request.fields || {})[name] || {}
            const selectedOption = (requestField.values || [])[0] || requestField.value;

            if (!selectedOption)
                return

            setOptions([{
                name: selectedOption.optionName || (requestField.optionName || requestField.name),
                id: selectedOption.value || selectedOption
            }]);
        }

        const alreadyConfirmed = (value || !needConfirmation)

        if (!alreadyConfirmed)
            return;

        setIsConfirmed(true);

    }, []);

    const findOptions = async () => {

        setIsLoading(true);

        await getOptions(id, name, request.areaId, fieldKey)
            .then(res => setOptions([...res.data]))
            .finally(() => setIsLoading(false));
    }

    const filterOptions = (options, { inputValue }) => {

        let filtered = !inputValue ? options :
            options.filter(item => item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);

        if (hasCustomFieldOptions && !fieldKey && inputValue && !options.some(option => (option.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)))
            return [{ name: `Adicionar: "${inputValue}"`, value: 0, isInputValue: true, inputValue }];

        return [...filtered];
    }

    const handleChange = (item) => {
    
        if (item && item.isInputValue) {
            addFieldValue(id, { name: item.inputValue });
            return;
        }

        setFieldValue(name, item?.value, item?.name);
    }

    const onChange = (name, value, item) => {

        if (!item?.warning) {
            handleChange(item);
            return;
        }

        if([request.modalityKey,request.serviceKey].includes(requestServices.HEARINGANALYSIS))
            item.warning += '<br /><br />Além disso, será necessário realizar um parecer jurídico para a análise.'

        const confirmCopy = [request.modalityKey,request.serviceKey].includes(requestServices.HEARINGANALYSIS) && request.forwardToHearingAnalysis ? 'Entendo e aceito a cobrança' : 'Confirmar'
        swal
            .fire({
                title: 'Atenção',
                html: `
                    <div style="text-align: left;">
                        <p>${item.warning}</p>
                    </div>
                `,
                icon: 'warning',
                confirmButtonText: confirmCopy,
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
            })
            .then(result => !!result.isConfirmed && handleChange(item))
    }

    const addFieldValue = async (fieldId, newOption) => {

        const data = await requestFieldsService.createFieldOption(fieldId, newOption);
        setOptions([...options, { name: data.name, id: data.id }])
        setFieldValue(name, data.id, data.name);
    }

    const changeConfirmation = (name, value) => {

        setIsConfirmed(value);

        if (!!value)
            return;

        setFieldValue(name, null);
    }

    const hasConfirmation = () => {

        if (!needConfirmation)
            return null;

        return (
            <Grid item xs={12}>
                <RadioButtonField
                    label={confirmationLabel}
                    name={`has${name}`}
                    value={isConfirmed}
                    setFieldValue={changeConfirmation}
                    fieldProps={fieldProps}
                    onError={onError}
                />
            </Grid>
        )
    }

    const getField = () => {

        if (needConfirmation && !isConfirmed)
            return null;

        const selectedItem = options.find(op => op.id == value);

        return (
            <Grid item xs={12}>
                <FormField
                    {...fieldProps}
                    type='autocomplete'
                    name={name}
                    field={name !== 'typeAction' ? label : (
                        <>
                            {label}
                            <Typography
                                gutterBottom
                                style={{
                                    fontFamily: 'satoshi, sans-serif',
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: '#131313aa',
                                    marginTop: 4,
                                }}>
                                Não sabe o tipo de ação? Peça uma indicação de petição.
                            </Typography>
                        </>
                    )}
                    titleSize={15}
                    titleWeight={600}
                    spacing={10}
                    placeholder={`Selecione ${fieldServiceName?.toLowerCase() || ''}`}
                    isLoading={isLoading}
                    currentValue={value}
                    options={options.map(item => ({ ...item, value: item.id }))}
                    required={required}
                    hasCustomFieldOptions={hasCustomFieldOptions}
                    onOpen={findOptions}
                    onChange={onChange}
                    filterOptions={filterOptions}
                    disabled={isDisabled}
                    onError={onError}
                    helperText={!!selectedItem?.price ? (
                        <Typography variant='subtitle2' style={{ marginTop: 5, display: 'block' }}>
                            Valor do serviço: {utilsProvider.formatNumberCurrency(selectedItem.price)}
                        </Typography>
                    ) : extraInfo}
                    inputProps={{
                        style: {
                            paddingTop: 9.5,
                            paddingBottom: 9.5,
                        }
                    }}
                    getCustomRenderProps={(params) => ({
                        InputProps: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }
                    })}
                />
            </Grid>
        )
    }

    return (
        <>

            {hasConfirmation()}

            {getField()}

        </>
    );
}
