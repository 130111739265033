import { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import RadioButtonField from './radio.button.field';
import FormField from '../../FormField';

export default function InputTextField({
    name, label, required, extraInfo, setFieldValue, onError,
    value, confirmationLabel, needConfirmation, fieldProps
}) {

    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {

        const alreadyConfirmed = (value || !needConfirmation)

        if (!alreadyConfirmed)
            return;

        setIsConfirmed(true);

    }, []);

    const changeConfirmation = (n, value) => {

        setIsConfirmed(value);

        if (!!value)
            return;

        setFieldValue(name, "");
    }

    const hasConfirmation = () => {

        if (!needConfirmation)
            return null;

        return (
            <Grid item xs={12} sm={6}>
                <RadioButtonField
                    label={confirmationLabel}
                    name={`has${name}`}
                    value={isConfirmed}
                    setFieldValue={changeConfirmation}
                    fieldProps={fieldProps}
                    onError={onError}
                />
            </Grid>
        )
    }

    return (
        <>

            {hasConfirmation()}

            {isConfirmed && (
                <Grid item xs={12}>
                    <FormField
                        {...fieldProps}
                        field={label}
                        name={name}
                        placeholder={extraInfo}
                        currentValue={value}
                        required={required}
                        onChange={setFieldValue}
                        onError={onError}
                        titleSize={15}
                        titleWeight={600}
                        spacing={10}
                        inputProps={{
                            style: {
                                paddingTop: 9.5,
                                paddingBottom: 9.5,
                            }
                        }}
                    />
                </Grid>
            )}

        </>
    );
}
