import React, { useContext, useState } from 'react';
import { DocsContext } from '../../context/docs.context';
import { Grid, Menu, MenuItem, Typography, Divider, Button } from '@material-ui/core'

import LetterheadsDocuments from './letterhead.documents'
import TextFormatRequirementList from './text.format.requirement.list';
import FinalDocumentsForm from './final.documents.list';
import SkeletonDocumentsList from './skeleton.documents.list';
import RequestAnalysisCustomerView from '../requests/analysis/request.analysis.customer.view';

import { EditorMenuGridContainer } from '../../layouts/styles/menu.layout.styles';

import loginService from '../../domain/services/login.service';

const DetailsComponentMenu = ({ request }) => {
  const {
    setLoadLetterheadModalOpen,
    setShowFormattings,
    setShowSkeletonDocumentsList,
    setShowFinalDocuments
  } = useContext(DocsContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const options = [
    {
      name: 'letterheads',
      label: 'Timbrados',
      action: () => {
        setLoadLetterheadModalOpen(true);
        setAnchorEl(null);
      }
    },
    {
      name: 'formattings',
      label: 'Formatações',
      action: () => {
        setShowFormattings(true);
        setAnchorEl(null);
      }
    },
    ...(loginService.isInternal() ? [{
      name: 'petitionDocuments',
      label: 'Petições',
      action: () => {
        setShowFinalDocuments(true);
        setAnchorEl(null);
      }
    }] : []),
    {
      name: 'skeletons',
      label: 'Esqueletos',
      action: () => {
        setShowSkeletonDocumentsList(true);
        setAnchorEl(null);
      }
    }
  ]

  return (
    <Grid item>
      <EditorMenuGridContainer style={{ width: '180px' }}>
        <Button
          onClick={handleClick}
          variant='text'
        >
          Referências para Petição
        </Button>
        <Divider orientation="vertical" variant="middle" />
      </EditorMenuGridContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' },
        }}
      >
        {options.map((option) =>
          option.hidden ? null : (
            <MenuItem key={option.name} onClick={option.action}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0 5px 0' }}>
                {option.icon}
                <Typography style={{ marginLeft: '8px', fontWeight: '500', color: option.color }}>{option.label}</Typography>
              </div>
            </MenuItem>
          ),
        )}
      </Menu>

      <LetterheadsDocuments customerId={request.customerId} />

      <RequestAnalysisCustomerView request={request} hiddenButton={true} />

      <TextFormatRequirementList customerId={request.customerId} />

      <FinalDocumentsForm request={request} />

      <SkeletonDocumentsList area={request.area} modality={request.modality} />

    </Grid>
  )
}

export default DetailsComponentMenu