import api from '../api';
import axios from 'axios';
import moment from 'moment';

import { serviceUrl } from '../../../components/solicitations/development/configs/index';
import { SfdtExport } from '@syncfusion/ej2-react-documenteditor';

import EventEmmiter from "../../../providers/event.emmiter";

export const events = new EventEmmiter();

const getDocument = (item) => ({
    name: item.name,
    fileSize: item.size,
    fileType: item.type,
});

const findByRequestId = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/final-documents` });

const getDocumentDiffs = async (requestId, firstDocumentId, secondDocumentId) =>
    api.get({ type: 'user', service: `requests/${requestId}/final-documents/diff/${firstDocumentId}/${secondDocumentId}` });

const findLetterhead = async (requestId) =>
    api
        .get({ type: 'user', service: `requests/${requestId}/development/letterhead` })
        .catch((err) => {
            console.error('document:service:findLetterhead', err);
            return null;
        });

const findFinalPetition = async (requestId) =>
    api
        .get({ type: 'user', service: `requests/${requestId}/development?step=FINAL` })
        .catch((err) => {
            console.error('document:service:findFinalPetition', err);
            return null;
        });

const initDevelopment = async (requestId) => {
    const { data } = await api.post({
        type: 'user',
        service: `requests/${requestId}/development`,
    });

    return data;
};

const finishDevelopment = async (requestId, file, fileName) => {
    const { data } = await api.post({
        type: 'user',
        service: `requests/${requestId}/development/finish`,
        data: {
            ...getDocument(file),
            name: fileName,
            bucketName: 'request-petition-documentsf',
        },
    })

    return data;
};

const initReview = async (requestId) =>
    api.post({ type: 'user', service: `requests/${requestId}/development/review` });

const returnToDevelopment = async (requestId) =>
    api.post({ type: 'user', service: `requests/${requestId}/development/returnToDevelopment` });

const finishReview = async (requestId) =>
    api.post({ type: 'user', service: `requests/${requestId}/development/finish-review` });

const addToDictionary = async (requestId, word) =>
    api.post({ type: 'user', service: `requests/${requestId}/development/add-to-dictionary`, data: { word } });

const initCorrection = async (requestId) => {
    const { data } = await api.post({ type: 'user', service: `requests/${requestId}/development/correction` });
    return data;
};

const finishCorrection = async (requestId) =>
    api.post({ type: 'user', service: `requests/${requestId}/development/finish-correction` });

const findCustomerFinalDocuments = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/documents/petition` }).catch((err) => {
        console.error('document:service', err);
        return [];
    });

const save = async (document, documentEditor) => {

    events.emmit("onProgressDocument", { isSaving: true, date: (document?.updated || document?.created) });

    const data = getDocumentToSave(document, documentEditor);

    const response = await api
        .put({ type: 'user', service: `requests/${document.requestId}/development/${document.id}/save`, data })
        .then(res => {
            events.emmit("onProgressDocument", { isSaving: false, date: (res.data.updated || moment()) });
            return res;
        }).catch(error => events.emmit("onProgressDocument", { isSaving: false, error: error.response.data.message, date: moment() }));

    return response;
}

const loadDefaultFile = async (fileUrl) =>
    axios.post(`${serviceUrl}ImportFileURL`, { fileUrl }, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });

const getDocumentToSave = (document, editor) => ({
    petitionDocumentId: document.petitionDocumentId,
    document: {
        id: document.id,
        requestId: document.requestId,
        bucketName: 'request-petition-documentsf',
    },
    sfdtDocument: new SfdtExport(editor.documentEditor.documentHelper).write()
})

const clearToFinalVersion = (editor) => {
    try {
        editor.documentEditor.editor.deleteAllComments();
        editor.documentEditor.revisions.acceptAll();
    } catch (err) {
        console.error(err);
    }
}

export default {
    findByRequestId,
    findLetterhead,
    findFinalPetition,
    getDocumentDiffs,
    initDevelopment,
    finishDevelopment,
    initReview,
    finishReview,
    returnToDevelopment,
    addToDictionary,
    initCorrection,
    finishCorrection,
    findCustomerFinalDocuments,
    save,
    loadDefaultFile,
    clearToFinalVersion
};
