import { Button, Tooltip } from "@material-ui/core";
import { MonetizationOn } from "@material-ui/icons";
import RequestCustomerTipForm from "./request.customer.tip.form.component";
import { useEffect, useState } from "react";
import memberExternalTipService from "../../../domain/services/member/member.external.tip.service";
import moment from "moment";
import LoadingSpin from "../../LoadingSpin";

export default function RequestCustomerTipButton({ requestData, setAlert }) {

    const [openTipForm, setOpenTipForm] = useState(false);
    const [existingTip, setExistingTip] = useState(null);
    const [loading, setLoading] = useState(false);

    const tipAmount = existingTip?.tipInfo?.amount;

    const handleClick = () => {
        if (loading) {
            return;
        }
        setOpenTipForm(true);
    }

    const fetchTip = () => {
        setLoading(true);
        memberExternalTipService
            .findByMainId(requestData.mainId || requestData.id)
            .then(({ data: tips }) => {
                if (!tips.length) {
                    return;
                }

                setExistingTip(tips[0]);
            })
            .catch(err => ([]))
            .finally(() => setLoading(false));
    }

    const handleFinish = () => {
        fetchTip();
    }

    const handleClose = () => {
        setOpenTipForm(false);
    }

    useEffect(() => {
        fetchTip();
    }, []);

    const renderButton = () => {

        if (!tipAmount) {
            return (
                <Button
                    style={{ width: 170 }}
                    variant="outlined"
                    startIcon={loading ? <LoadingSpin /> : <MonetizationOn style={{ color: '#2e9234' }} />}
                    onClick={handleClick}
                >
                    Dar Gorjeta
                </Button>
            );
        }

        return (
            <Button
                style={{ width: 170, cursor: 'default' }}
                variant="outlined"
                startIcon={<MonetizationOn style={{ color: '#2e9234' }} />}
            >
                {`R$ ${parseFloat(tipAmount).toFixed(2)}`}
            </Button>
        )
    }

    const tooltipText = tipAmount
        ? `Gorjeta fornecida em  ${moment(existingTip.tipInfo.created).format('DD/MM/YYYY HH:mm')}`
        : 'Uma pequena gorjeta pode ser um grande incentivo para eles continuarem fazendo um trabalho excepcional'

    return (
        <>
            <Tooltip
                title={(
                    <span style={{ fontSize: 12 }}>{tooltipText}</span>
                )}
                placement="top"
            >
                {renderButton()}
            </Tooltip>
            <RequestCustomerTipForm
                isOpen={openTipForm}
                request={requestData || {}}
                setAlert={setAlert}
                onFinish={handleFinish}
                onClose={handleClose}
            />
        </>
    );
}