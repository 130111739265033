import { DocsContextProvider } from "./docs.context"
import { ThemeProvider } from "./themeContext"
import { TourProvider } from "./tourContext"

export default function Providers({ children }) {
    return (
        <DocsContextProvider>
            <ThemeProvider>
                <TourProvider>
                    {children}
                </TourProvider>
            </ThemeProvider>
        </DocsContextProvider>
    )
}