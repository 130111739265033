import React, { useEffect, useState } from 'react';

import {
    Avatar, Card, Divider, Grid, List, ListItem, ListItemText, Typography
} from '@material-ui/core';

import { Person } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import LoadingProgress from '../../LoadingProgress';

import requestAnalysisService from '../../../domain/services/request.analysis.service';


function AnalysisPartsView({ analysisId }) {

    const [loading, setLoading] = useState(false);

    const [partTypes, setPartTypes] = useState([]);
    const [parts, setParts] = useState([]);

    useEffect(() => getParts(), []);

    const getParts = async () => {

        setLoading(true);

        const { data } = await requestAnalysisService.getParts(analysisId).catch(() => ({ data: [] }));

        const parts = (data || []);
        setParts(parts);
        setPartTypes(getPartTypes(parts));

        setLoading(false);
    }

    const getPartTypes = (parts) => {

        const partTypes = parts
            .map(item => ({ id: item.partTypeId, name: item.partTypeName }))
            .reduce(distinctPartTypes, []);

        return partTypes;
    }

    const distinctPartTypes = (self, value) => {

        if (!self.some(item => item.id == value.id))
            self.push(value);

        return self;
    }

    const getPartsByType = (parts, partTypeId) =>
        parts.filter(value => value.partTypeId == partTypeId);

    const getPartsContent = (partType) => {

        const partList = getPartsByType(parts, partType.id);

        if (!parts.length)
            return (
                <List style={{ width: '100%', paddingTop: 0 }}>
                    <ListItem>
                        <ListItemText fontSize="16px" primary={`Não há partes adicionada como: ${partType.name}`} />
                    </ListItem>
                </List>
            );

        return (
            <List style={{ width: '100%', paddingTop: 0 }}>
                {partList.map((part) => (
                    <React.Fragment key={part.id}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <Grid container spacing={2}>
                                        <Grid item xs={2} md={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Avatar style={{ width: "30px", height: "30px" }}><Person fontSize='small' /></Avatar>
                                        </Grid>
                                        <Grid item xs={10} md={11}>
                                            <Typography variant="h4" className="MuiInputLabel-shrink" style={{ fontSize: "13px" }}>
                                                {part.partTypeName}
                                                {part.isRepresented &&
                                                    <span style={{ marginLeft: "5px" }}>
                                                        - Parte representada
                                                    </span>
                                                }
                                            </Typography>
                                            <Typography variant="body1" className="MuiInputLabel-shrink">
                                                {(part.value || part.name)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        );
    }


    return (
        <React.Fragment>

            <Grid container spacing={6}>

                {loading &&
                    <div style={{ margin: '20px', width: "98%" }}>
                        <LoadingProgress />
                    </div>
                }

                {(!loading && !parts.length) &&
                    <Alert variant="filled" severity="info" style={{ margin: '20px', width: "98%" }}>
                        Não foi indicado as partes do processo.
                    </Alert>
                }


                {!loading && partTypes.map((partType, i) => (
                    <List
                        key={i}
                        style={{ width: '94%', margin: '20px', padding: "0", background: "#fff" }}>

                        <Card>
                            <ListItem style={{ padding: "0" }}>
                                <ListItemText
                                    primary={
                                        <Grid item xs={12}
                                            style={{ display: "flex", borderBottom: "1px dashed #d4d4d4", justifyContent: "space-between" }}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    fontSize: "16px", fontWeight: "500", color: "rgba(0, 0, 0, 0.8)", margin: "10px"
                                                }}
                                            >
                                                {partType.name}
                                            </Typography>
                                        </Grid>
                                    }
                                />
                            </ListItem>

                            {getPartsContent(partType)}

                        </Card>
                    </List>
                ))}
            </Grid>

        </React.Fragment>
    );
}

export default AnalysisPartsView;