import { useEffect, useRef, useState } from "react";

import Recorder from 'recorder-js';

import documentsService from "../../domain/services/solicitation/documents.service";
import loginService from "../../domain/services/login.service";

const useRequestAudioRecorder = (requestId, field) => {

    const [showRecordButton, setShowRecordButton] = useState(false);

    const [recordingState, setRecordingState] = useState('idle');
    const [uploadedAudio, setUploadedAudio] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const recorder = useRef(null);
    const audioStream = useRef(null);

    useEffect(() => { checkShowRecordButton() }, []);

    useEffect(() => {

        if (!requestId || !field?.id)
            return;

        findFieldAudio(true);
    }, [requestId]);

    const checkShowRecordButton = async () => {

        const customerCanRecordAudio = loginService.getUserAuthentication().customer?.canRecordAudio;
        if (!field.acceptAudioAnswer || !customerCanRecordAudio)
            return;

        setShowRecordButton(true);
    }

    const findFieldAudio = async (withoutErrorMessage) =>
        documentsService
            .findByRequestIdAndFieldId(requestId, field.id)
            .then((audio) => setUploadedAudio(audio || null))
            .catch(() => !withoutErrorMessage && setShowErrorMessage(true));

    const startRecording = async () => {

        setShowErrorMessage(false);

        try {

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const newRecorder = new Recorder(audioContext);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            await newRecorder.init(stream);
            await newRecorder.start();

            audioStream.current = stream;
            recorder.current = newRecorder;

            setRecordingState('recording');
        } catch (err) {
            setShowErrorMessage(true);
            console.log('startRecording', err);
        }
    }

    const handleStopRecording = async () => {

        const isRecording = (recordingState === 'recording');
        if (!isRecording)
            return;

        setIsLoading(true);
        setRecordingState('stopped');

        try {
            const { blob } = await recorder.current.stop();
            audioStream.current.getTracks().forEach(track => track.stop());
            await handleUploadAudio(blob, 'wav');
        } catch (err) {
            console.log('handleStopRecording', err);
        } finally {
            setIsLoading(false)
        }
    };

    const handleUploadAudio = async (audioBlob, extension) => {

        audioBlob.name = `${field.name}-${requestId}.${extension}`;
        audioBlob.fieldId = field.id;

        await documentsService
            .saveAll(requestId, [audioBlob], false)
            .then(findFieldAudio);
    };

    const handleRemoveAudio = async () => {
        setIsLoading(true);
        return documentsService
            .remove(uploadedAudio)
            .then(() => {
                setRecordingState('idle');
                setUploadedAudio(null);
            })
            .finally(() => setIsLoading(false));
    }

    return {
        showRecordButton,
        showErrorMessage,
        showAudioRecorder: uploadedAudio || recordingState === 'recording',
        handleStartRecording: startRecording,
        uploadedAudio,
        recordingState,
        isLoading,
        handleStopRecording,
        audioUrl: uploadedAudio?.url,
        handleRemoveAudio
    }
}

export default useRequestAudioRecorder;