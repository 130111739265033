import { useEffect, useState } from "react";
import ratingService from "../../../domain/services/solicitation/rating.service";
import RatingForm from "./rating.form.component";

const AnalysisRatingForm = ({ request, onSave, onRefreshRequest, ...props }) => {

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        const res = await ratingService.findAnalysisQuestions(request.id);
        let listQuestions = (res.data || []);
        setQuestions(listQuestions);
        setResultQuestions(listQuestions);
    }

    const setResultQuestions = async (listQuestions) => {
        const res = await ratingService.findAnalysisResults(request.referenceRequestId);
        let resultQuestions = ratingService.mapQuestionResults(listQuestions, (res.data || []));
        setQuestions(resultQuestions);
    }

    const saveResultQuestion = async (resultQuestion, members) => {
        await ratingService.saveAnalysisResultQuestion(resultQuestion, request.referenceRequestId, members);
    }

    const concluded = async (feedback, members, closeModal) => {

        await saveResultQuestion({ ...questions[0], justification: feedback }, members);

        ratingConcluded(closeModal);
    }

    const ratingConcluded = async (closeModal) => {
        await onSave();
        onRefreshRequest();
        closeModal();
    }

    return (
        <RatingForm
            questions={questions}
            setQuestions={setQuestions}
            saveResultQuestion={saveResultQuestion}
            ratingConcluded={ratingConcluded}
            concluded={concluded}
            name="Ficha de avaliação da Indicação de Petição"
            dontShowFaciliterName={true}
            feedbackLabel='Justifique sua avaliação'
            requestId={request.id}
            {...props}
        />
    )
}

export default AnalysisRatingForm;