
import { ViewCarousel } from "@material-ui/icons";
import Async from "../../components/Async";

const AuthGuard = Async(() => import("../auth.guard"));
const BannerFacilitaFriday = Async(() => import('../../pages/marketing/banner.facilita.friday.page'));
const Banner = Async(() => import('../../pages/marketing/banner'));

const routes = {
    wall: {
        id: 'advertising',
        name: 'Mural de propaganda',
        path: '/advertising',
        component: BannerFacilitaFriday
    },
    banner: {
        id: 'banners',
        name: 'Banners',
        icon: <ViewCarousel />,
        path: '/banners',
        component: Banner,
        roles: ['admin', 'email'],
        guard: AuthGuard,
        isNew: true,
    },
}

const pages = Object.values(routes);


export default {
    routes,
    pages
}