import styled, { createGlobalStyle } from 'styled-components/macro';
import { spacing } from '@material-ui/system';
import { Grid, Paper as MuiPaper } from '@material-ui/core';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .e-control {
    width: 90%
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiTypography-body1 {
    white-space: nowrap;
    width: 14em; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiAccordion-root:before {
    height: 0 !important;
  }

  *::-webkit-scrollbar-track {
    background: #f7f9fc;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 5px;
    border: 3px solid #f7f9fc;
  }
`;

export const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('xl')} {
    flex: 1;
  }
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
  align-items: center;
  background: #f7f9fc !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

export const EditorMenuGridContainer = styled(Grid)`
  && {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 5%;
    width: 80%;

    .MuiButtonBase-root {
      margin-top: 0 !important;
      background-color: transparent;
      padding: 0 6px;
    }

    span {
      color: #fff;

      svg {
        margin-right: 5px;
      }
    }

    hr {
      background-color: #fff;
      height: 20px;
      width: 1px;
    }
  }
`;
