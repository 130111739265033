import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import * as Yup from "yup";
import { Formik } from "formik";

import swal from 'sweetalert';

import {
    List, ListItem, ListItemText, ListItemIcon, TextField, Button, Fab, Avatar, Tooltip, Chip,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing } from "@material-ui/system";

import { CompactPicker } from 'react-color';

import { LocalOfferOutlined, CheckBox, Close, LocalOffer, Add, Cancel } from "@material-ui/icons";

import tagService from "../../domain/services/solicitation/tag.service";

const Alert = styled(MuiAlert)(spacing);

const ListTags = (props) => {

    const [tagsFiltered, setTagsFiltered] = useState([]);

    useEffect(() => {
        onSearch(document.getElementById("searchTag").value);
    }, [props.list]);

    const onSearch = (search) => {
        let filtered = [...props.list];

        if (search)
            filtered = filtered.filter((item) => item.name.toLowerCase().indexOf(search) != -1);

        setTagsFiltered(filtered);
    }

    const checkCategory = (tag) => {
        tag.checked = !tag.checked;
        save(tag);
    }

    const save = (tag) => {
        setTagsFiltered([...tagsFiltered]);
        props.onSave([...props.list]);

        if (props.requestId)
            props.onUpdate(tag);
    }

    const checkRemove = (tag) =>
        swal({
            title: "Excluir Categoria", text: "Realmente deseja excluir?", icon: "warning",
            buttons: {
                confirm: { text: 'Excluir', className: 'MuiButton-containedPrimary' },
                cancel: 'Cancelar',
            }
        }).then(res => {
            if (res) props.onDelete(tag);
        });

    const optionDeleteComments = (tag) => (
        <Tooltip title="Excluir">
            <Fab
                size="small" color="default" onClick={() => checkRemove(tag)}
                style={{
                    marginLeft: '10px', height: "20px", minHeight: "20px",
                    minWidth: "20px", width: "20px"
                }}
            >
                <Close style={{ fontSize: "14px" }} />
            </Fab>
        </Tooltip>
    );

    return (
        <React.Fragment>

            <TextField id="searchTag" label="Buscar categorias" onChange={(e) => { onSearch(e.target.value.toLowerCase()) }}
                style={{ marginBottom: "20px" }} fullWidth my={2} />

            <List component="nav" dense={true} style={{ padding: "0", height: "120px", overflow: "auto" }}>
                {
                    (tagsFiltered || []).length ? (tagsFiltered.map((tag, i) => (

                        <ListItem button key={i} style={{ paddingLeft: "0" }} onClick={() => { checkCategory(tag) }}>
                            <ListItemIcon>
                                <Avatar alt={tag.name} style={{ backgroundColor: `${tag.color}`, color: "white", width: "24px", height: "24px", fontSize: "10px", fontWeight: 600 }}>
                                    <LocalOfferOutlined style={{ fontSize: "12px" }} />
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText style={{ whiteSpace: "nowrap", width: "18em", overflow: "hidden", textOverflow: "ellipsis" }} primary={tag.name} />
                            {tag.checked ? <CheckBox style={{ fontSize: "20px", color: "#a4dd00" }} /> : null}
                            {optionDeleteComments(tag)}
                        </ListItem>

                    ))) : <Alert mt={2} mb={1} severity="info" style={{ marginBottom: "10px" }}>Não há categorias</Alert>
                }
            </List>
        </React.Fragment>
    );
}

const NewTag = (props) => {

    const [color, setColor] = useState("");

    const selectColor = (e, values) => {
        values.color = e.hex;
        setColor(e.hex);
    }

    return (
        <Formik
            onSubmit={props.onSave} initialValues={props.tag}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string().required("Informe o nome da categoria"),
                    color: Yup.string().required("Selecione uma cor"),
                })
            }
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>

                    {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning"> {errors.submit} </Alert>
                    )}

                    <TextField
                        name="name" label="Nome" value={values.name} fullWidth
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur} onChange={handleChange} my={2}
                    />

                    <TextField
                        name="color" label="Cor" value={values.color} fullWidth
                        style={{ marginTop: "10px" }} error={Boolean(touched.color && errors.color)}
                        helperText={touched.color && errors.color}
                        onBlur={handleBlur} onChange={handleChange} my={2}
                    />

                    <CompactPicker
                        name="colorPicker" color={color}
                        onChange={(e) => selectColor(e, values)}
                    />

                    <Button
                        type="submit" variant="text" color="primary" mt={3}
                        style={{ marginTop: "70px", right: "95px", position: "absolute" }}
                    >
                        Salvar
                    </Button>

                    <Button
                        type="button" variant="text" color="primary" mt={3} onClick={props.onCancel}
                        style={{ marginTop: "70px", right: "10px", position: "absolute" }}
                    >
                        Cancelar
                    </Button>
                </form>
            )
            }
        </Formik >
    );
}

function TagsForm(props) {

    const [open, setOpen] = useState(false);
    const [listTags, setListTags] = useState([]);
    const [tag, setTag] = useState({ showForm: false });

    useEffect(() => getTags(), []);

    const getTags = async () => {
        try {
            const res = await tagService.findAll();
            let tags = (res.data || []);
            setListTags(tags);
            setSolicitationTags(tags);
        } catch (error) { }
    }

    const setSolicitationTags = async (list) => {
        if (!props.requestId)
            return;

        try {
            const res = await tagService.find(props.requestId);
            let tags = tagService.map(props.requestId, list, (res.data || []));
            changeTags(tags);

            return tags;
        } catch (error) {
            error = (error || "");
        }
    }

    const addTags = (values) => {
        let item = { ...values, checked: true };
        if (props.requestId) {
            updateTags(item);
            cancelForm();
            return;
        }

        let tags = [...listTags];
        tags.push(item);
        changeTags(tags);
        cancelForm();
    }

    const changeTags = (tags) => {
        let selecteds = getCheckedTags(tags);
        props.onSave("tags", selecteds);
        setListTags(tags);
    }

    const updateTags = async (tag) => {
        if (tag.checked)
            return tagService.save(tag, props.requestId).then(getTags);

        tagService.deleteById(tag).then(getTags);
    }

    const handleDelete = (item) =>
        updateTags({ ...item, checked: false });

    const removeTag = async (tag) =>
        tagService.removeAll(tag.name).finally(getTags);

    const getCheckedTags = (tags) =>
        tags.filter((item) => item.checked);

    const newTag = () =>
        setTag({ name: "", color: "#0062b1", showForm: true });

    const cancelForm = () =>
        setTag({ ...tag, showForm: false });

    const openModal = () =>
        setOpen(true);

    const closeModal = () =>
        setOpen(false);

    const NewTagOptions = () => {
        return (
            (props.tags || []).length ? (
                <Tooltip title="Adicionar Categorias">
                    <Fab
                        mr={2} color="secondary" size="small" onClick={openModal}
                        style={{
                            width: "22px", minHeight: "22px", height: "23px",
                            marginLeft: "2px", marginRight: "10px"
                        }}
                    >
                        <Add style={{ fontSize: "14px" }} />
                    </Fab>
                </Tooltip>
            ) : (
                <Tooltip title="Adicionar Categorias">
                    <Chip
                        size="small" label="Categorias"
                        variant="outlined" onClick={openModal}
                        style={{ fontWeight: 500, marginRight: 10 }}
                        avatar={
                            <LocalOffer style={{
                                width: 16, color: "#376fd0", height: 16
                            }} />
                        }
                    />
                </Tooltip>
            )
        );
    }

    return (
        <React.Fragment>

            {
                (props.tags || []).length ?
                    props.tags.map((tag, i) => (
                        <Tooltip title={tag.name} key={i}>
                            <Chip
                                size="small" variant="outlined" label={tag.name}
                                onDelete={() => handleDelete(tag)}
                                deleteIcon={<Cancel style={{ color: "white" }} />}
                                avatar={
                                    <LocalOfferOutlined style={{
                                        color: "white", width: 16, height: 16
                                    }} />
                                }
                                style={{
                                    backgroundColor: tag.color, color: 'white',
                                    fontWeight: 600, fontSize: 10, marginRight: 5
                                }}
                            />
                        </Tooltip>
                    )) : null
            }

            {NewTagOptions()}

            <Dialog open={open} onClose={closeModal} disableEscapeKeyDown>

                <DialogTitle>Categorias</DialogTitle>

                <DialogContent>
                    {
                        tag.showForm ?
                            <NewTag tag={tag}
                                onSave={addTags} onCancel={cancelForm} />
                            :
                            <ListTags
                                requestId={props.requestId} list={listTags}
                                onSave={changeTags} onUpdate={updateTags} onDelete={removeTag} />
                    }
                </DialogContent>

                <DialogActions>
                    {
                        !tag.showForm ?
                            <>
                                <Button onClick={newTag} color="primary"> Nova Categoria </Button>
                                <Button onClick={closeModal} color="primary"> Fechar </Button>
                            </> : null
                    }
                </DialogActions>

            </Dialog>

        </React.Fragment>
    );
}

export default TagsForm;


