import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, Typography } from '@material-ui/core';

import requestValidator from '../../domain/validators/request.validator';

import billingsService from '../../domain/services/financial/billing.service';
import AdvanceRequestForm from './requestCreation/advanceRequestForm';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoadingSpin from '../LoadingSpin';
import requestAdvanceService from '../../domain/services/request/advance.service';
import datetimeProvider from '../../providers/datetime.provider';
import { CalendarTodayOutlined, Info, KeyboardArrowDown } from '@material-ui/icons';
import Statistic from '../shared/Statistic';

import { requestServices, servicesByTypeKey } from '../../domain/constant/request.services.constant';
import PendingModalContent from './registration/PendingModalContent';
import SimpleModal from '../shared/SimpleModal';
import solicitationService from '../../domain/services/solicitation/solicitation.service';

export default function RequestConclusion({ request, errorFinished, setMultipleValues }) {
    const [services, setServices] = useState([]);
    const [selectedAdvance, setSelectedAdvance] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectRef, setSelectRef] = useState(null);
    const [showServicePrices, setShowServicePrices] = useState(false);
    const [showAntecipationButton, setShowAntecipationButton] = useState(false);

    const [createdRequest, setCreatedRequest] = useState({});

    useEffect(() => {
        if (request.status == 'NEW' || !request.id) return;

        loadServicesReceipt();

        solicitationService.find(request.id)
            .then(({ data }) => setCreatedRequest(data));

    }, [request.status]);

    const loadServicesReceipt = async () =>
        billingsService.findByRequestId(request.id).then(setServices);

    const saveAdvance = (advanceData) => {
        setLoading(true);

        requestAdvanceService
            .advance(request.id, advanceData.id, false)
            .then(({ allServicesDueDate }) => {
                if (setMultipleValues) {
                    setMultipleValues({
                        allServicesDueDate: allServicesDueDate,
                        isDueDateAdvanced: true,
                        advancedDueDate: true
                    });
                }
                loadServicesReceipt();
            })
            .catch((err) => setError(true))
            .finally(() => setLoading(false));
    };

    const openConfirmAdvance = (advanceData, lastAdvanceOption) => {
        Swal.fire({
            title: `<p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 30; font-weight: 500;">Deseja confirmar a antecipação da entrega?</p>`,
            html: `
                        <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                            ⚠️A nova data só será confirmada de acordo com a disponibilidade de produção..
                        </p>
                        <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                            As correções seguirão com o mesmo prazo padrão: 48h, excetuando clientes Gold.
                        </p>`,
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
        }).then((result) => {
            if (!result.isConfirmed) {
                setSelectedAdvance(lastAdvanceOption);
                return;
            }

            saveAdvance(advanceData);
        });
    };

    const handleAntecipationSelect = (advanceData) => {
        setSelectedAdvance(advanceData);
        openConfirmAdvance(advanceData, selectedAdvance);
    };

    const supportButton = () => (
        <Link
            to={{ pathname: 'https://bit.ly/3Qr8DBr' }}
            target="_blank"
            style={{ fontWeight: 500, textDecoration: 'none', color: 'blue' }}
        >
            <span>Suporte da Facilita Jurídico</span>
        </Link>
    );

    const getErrorContent = () => (
        <div style={{ textAlign: 'justify', width: '100%' }}>
            <Alert severity="warning">
                <AlertTitle>Não foi possível concluir o cadastro</AlertTitle>
                <p>{errorFinished}</p>
                <strong sty>Contate o suporte através do link</strong>:{supportButton()}
            </Alert>
        </div>
    );

    const getRequestIdButton = () => (
        <Link to={`/solicitations/${request.id}`} className="text-[#376fd0]">
            #{request.id}
        </Link>
    );

    const getSuccessContent = () => {
        if (
            [servicesByTypeKey.hearing.id].includes(request.serviceTypeId) &&
            datetimeProvider.getBusinessDatesCount(request.hearingDateTime) < 5
        ) {
            return getHearingAdvanceContent();
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="!font-semibold" style={{ fontSize: 20, marginBottom: 16 }}>
                    Parabéns! Sua solicitação {getRequestIdButton()} foi cadastrada com sucesso!
                </span>
                <div className="flex mt-3 justify-between flex-wrap gap-4 md:gap-2">
                    <div className="flex flex-wrap items-center text-base gap-2 pb-1">
                        <Statistic
                            title="Previsão de entrega"
                            value={moment.utc(request.allServicesDueDate.slice(0, 16), 'YYYY-MM-DD HH:mm').tz('America/Sao_Paulo').format('DD [de] MMMM [de] YYYY [às] HH:mm')}
                            prefix={<CalendarTodayOutlined />}
                        />
                    </div>
                    {requestValidator.canAdvanceRequest(request) && !selectedAdvance && (
                        <div className="flex w-full md:w-auto items-end">
                            <Button
                                color="primary"
                                className="!text-base !font-semibold text-nowrap !w-full md:!w-auto"
                                variant="outlined"
                                onClick={triggerSelectOpen}
                            >
                                Preciso receber antes
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getHearingAdvanceContent = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontSize: 20, fontWeight: 500, marginBottom: 30 }}>
                        Solicitação cadastrada com sucesso!
                    </Typography>
                    <Typography style={{ fontSize: 15, marginBottom: 10 }}>
                        Número Identificador da Solicitação:{' '}
                        <strong style={{ marginLeft: '2px' }}> {request.id} </strong>
                    </Typography>
                    <Typography style={{ fontSize: 15, marginBottom: 5 }}>
                        Entrega em:{' '}
                        <strong>
                            {' '}
                            {moment(request.hearingDateTime)
                                .add(1, 'days')
                                .format('DD [de] MMMM [de] YYYY [às] HH:mm')}
                        </strong>
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            marginTop: 10
                        }}
                    >
                        <span
                            style={{
                                borderRadius: '5px',
                                border: '1px solid #376fd0',
                                padding: '15px',
                                color: '#376fd0',
                                cursor: 'default',
                                fontWeight: 'bold',
                                marginLeft: 5
                            }}
                        >
                            Prazo de entrega antecipado!
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const redirectToRequest = () => {
        window.location.href = `/solicitations/${request.id}`;
    };

    const handleSubmit = () => {
        if (loading) return;

        redirectToRequest();
    };

    const triggerSelectOpen = () => {
        if (!selectRef) return;

        setShowAntecipationButton(true);
        selectRef.click();
    };

    if (errorFinished) return getErrorContent();

    const renderServices = () => {
        const initialServices = services.map((service) => ({
            label: service.serviceName,
            value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                parseFloat(service.amount)
            )
        }));

        return (
            <div
                style={{
                    height: showServicePrices ? initialServices.length * 33 : 0,
                    transition: 'height .3s ease',
                    overflow: 'hidden',
                    lineHeight: 2
                }}
            >
                {initialServices.map((serv) => (
                    <div className="h-8 flex" style={{ borderBottom: 'solid 1px #ededed' }}>
                        {serv.label} - {serv.value}
                    </div>
                ))}
            </div>
        );
    };

    const getFaciliterComuteChargeInfo = () => {

        const hasHearingInPersonService = services.some(s => s.serviceKey == requestServices.structure.hearingInPerson.key);
        if (!hasHearingInPersonService)
            return;

        return (
            <div className='p-3 bg-blue-200 rounded mt-5'>
                <p>
                    <Info fontSize='small' className='mr-2 mt-[-2px] text-blue-600' />
                    Informamos que poderá haver uma cobrança de deslocamento do audiencista até o local da audiência. Fique tranquilo, você será avisado caso isso seja necessário.
                </p>
            </div>
        )
    }

    if (!createdRequest.id)
        return <></>;

    return (
        <SimpleModal
            open={true}
            bodyStyle={{ padding: 0 }}
            maxWidth={createdRequest.isFinancialPending ? 'md' : 'sm'}
        >
            {createdRequest.isFinancialPending ? (
                <PendingModalContent
                    request={request}
                    title={
                        <>
                            Renove o seu pacote <span className="font-extrabold">com desconto</span>{' '}
                            para finalizar o cadastro da solicitação{' '}
                            <span className="text-[#376fd0]">#{request.id}</span>
                        </>
                    }
                />
            ) : (
                <div style={{ textAlign: 'justify', fontSize: '14px', padding: 40 }}>
                    {getSuccessContent()}

                    {requestValidator.canAdvanceRequest(request) && (
                        <div
                            className="mt-6"
                            style={{ display: showAntecipationButton ? 'block' : 'none' }}
                        >
                            <AdvanceRequestForm
                                mainRequestId={request.id}
                                onSelect={handleAntecipationSelect}
                                getAntecipationButtonRef={setSelectRef}
                                selectValue={selectedAdvance?.id || null}
                            />
                        </div>
                    )}

                    {services.length > 0 && (
                        <div style={{ marginTop: 12 }}>
                            <Button
                                variant="text"
                                color="primary"
                                className="!text-sm !my-3 !px-0"
                                fullWidth
                                prop
                                onClick={() => setShowServicePrices((prev) => !prev)}
                            >
                                <div className="flex gap-2 w-full justify-start items-center text-start">
                                    Clique aqui para ver os serviços solicitados
                                    <KeyboardArrowDown
                                        style={{
                                            transform: showServicePrices
                                                ? 'rotate(180deg)'
                                                : 'rotate(0)',
                                            transition: 'transform .3s ease'
                                        }}
                                    />
                                </div>
                            </Button>

                            {renderServices()}
                        </div>
                    )}

                    {getFaciliterComuteChargeInfo()}

                    <div style={{ margin: '24px 0' }}>
                        <div
                            style={{
                                fontSize: 11,
                                letterSpacing: 'normal',
                                wordSpacing: 'normal'
                            }}
                        >
                            *A Facilita Jurídico não se responsabiliza pela tempestividade da
                            solicitação. {' '}
                            {!createdRequest.isFinancialPending && (
                                <span>
                                    Caso precise antecipar a data de entrega, selecione uma{' '}
                                    <strong>opção acima</strong>.
                                </span>
                            )}
                        </div>
                    </div>

                    {error && (
                        <div className="mt-4">
                            <Alert severity="error">
                                <AlertTitle>
                                    Ocorreu um problema ao confirmar a antecipação, por favor tente
                                    novamente
                                </AlertTitle>
                            </Alert>
                        </div>
                    )}

                    <div className="mt-6 flex justify-center gap-3">
                        <Button
                            variant="contained"
                            color="primary"
                            className="!px-10 !font-semibold"
                            onClick={handleSubmit}
                        >
                            {loading ? <LoadingSpin color="#fff" /> : 'Acompanhar Solicitação'}
                        </Button>
                    </div>
                </div>
            )}
        </SimpleModal>
    );
}
