import { post, get } from '../api';

const purchaseRequest = async (purchaseData) =>
    post({
        type: 'user',
        service: `customers/purchase/request`,
        data: purchaseData
    });

const purchaseCreditPackage = async (purchaseData) => {
    const res = await post({
        type: 'user',
        service: `customers/purchase/credit-package`,
        data: purchaseData
    });

    return res;
}

const purchaseSubscription = async (purchaseData) =>
    post({
        type: 'user',
        service: `customers/purchase/subscription`,
        data: purchaseData
    });

const purchaseExternal = async (data) =>
    post({
        type: 'user',
        service: `customers/purchase/external`,
        data: {
            ...data,
            netPrice: getNetPrice(data.price, (data.tax || 0)),
            tax: 0
        }
    });

const findRecurrenceSettings = async () =>
    get({
        type: 'user',
        service: `customers/purchase/recurrence-settings`,
        data: {}
    }).then(res => res.data);

const getNetPrice = (price, tax) =>
    (parseFloat(price) - parseFloat(tax));

const findLastPurchase = () =>
    get({
        type: 'user',
        service: `customers/purchase/last-purchase`,
        data: {}
    }).then(res => res.data);

const getCustomerConversionData = async (eventName, pixelID, user, transactionId) => {

    return {
        eventName: eventName,
        pixelID: pixelID,
        email: user.email,
        transactionId: transactionId,
        userAgent: window.navigator.userAgent
    }
}

const submitPurchaseData = async (purchaseData) =>
    post({
        type: 'user',
        service: `customers/purchase/submit-purchase-data`,
        data: purchaseData
    }).catch(err => null);

export default {
    getNetPrice,
    purchaseRequest,
    purchaseCreditPackage,
    purchaseSubscription,
    purchaseExternal,
    findRecurrenceSettings,
    findLastPurchase,
    getCustomerConversionData,
    submitPurchaseData
}