import stepFormFields from '../fields/request.fields.config';

export default function PremiumField({ request = {}, stepFields, setValue, isEdit, setAlert}) {

    const premiumFieldData = stepFields[0] || request?.fields?.linkPremiumFaciliter || false;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            {premiumFieldData && stepFormFields.getComponentField({ field: premiumFieldData, request, setValue, isEdit, setAlert })}
        </div>
    );
}