import React, { useEffect, useState } from 'react';

import {
    Button,
    IconButton,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Snackbar,
    CircularProgress,
    FormControl
} from '@material-ui/core';
import { Close, InsertDriveFileOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import requestHearingAnalysisService from '../../../domain/services/request/analysis/request.hearing.analysis.service';
import stepsSolicitation from '../../../domain/constant/steps.solicitation.constant';

const useStyles = makeStyles((theme) => ({
    button: {
        pointerEvents: 'all',
        fontWeight: 600,
        fontSize: 12,
        padding: '5px 10px'
    },
    buttonSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    loadingIcon: {
        color: 'greenyellow',
        width: 15,
        marginLeft: 10
    },
    dialogHeader: {
        background: "rgb(238 238 238 / 50%)",
        borderBottom: "3px solid rgb(25, 118, 210)"
    },
    dialogContent: {
        padding: '30px 10px'
    },
    dialogActions: {
        padding: 15,
        background: "rgb(238 238 238 / 50%)",
        borderTop: "3px solid rgb(25, 118, 210)"
    }
}));

const RequestHearingAnalysisForm = ({ request }) => {

    const classes = useStyles();

    const [values, setValues] = useState({});
    const [fields, setFields] = useState([]);

    const [loading, setLoading] = useState(false);
    const [statusMsg, setStatusMsg] = useState({ open: false, text: '', severity: 'warning' })
    const [openDialog, setOpenDialog] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setLoading(true)
        requestHearingAnalysisService.findFields()
            .then(setFields)
            .then(() => setLoading(false))

    }, [])

    useEffect(() => {

        if (!request.id)
            return

        setLoading(true)
        requestHearingAnalysisService.findValuesByRequestId(request.id)
            .then(requestHearingAnalysisService.formatValues)
            .then(setValues)
            .then(() => setLoading(false))

    }, [request?.id])

    const onChange = (fieldId, value) => {
        setValues({ ...values, [fieldId]: value })
    }

    const saveAnalysis = () => {

        if (saving)
            return

        setSaving(true);
        requestHearingAnalysisService.saveAnalysis(request.id, values)
            .then(() => setStatusMsg({ open: true, text: 'Análise salva com sucesso!', severity: 'success' }))
            .catch(err => setStatusMsg({ open: true, text: 'Erro ao salvar a análise! Contate o suporte', severity: 'error' }))
            .finally(() => setSaving(false))
    }

    const disableStyle = () => {

        if (![stepsSolicitation.CONCLUDED, stepsSolicitation.CORRECTION].includes(request.status))
            return {}

        return { pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' };
    }


    return (
        <React.Fragment>
            <Grid container>
                <Button
                    onClick={() => setOpenDialog(true)}
                    variant="contained"
                    color="primary"
                    startIcon={<InsertDriveFileOutlined stle={{ fontSize: "15px" }} />}
                    className={classes.button}
                >
                    <span className={classes.buttonSpan}>
                        <Typography variant='button'>Detalhes da Análise</Typography>
                        {loading && <CircularProgress size={15} className={classes.loadingIcon} />}
                    </span>
                </Button>
            </Grid>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle className={classes.dialogHeader}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Typography variant="h4">
                            Análise de Audiência
                        </Typography>
                        <IconButton onClick={() => setOpenDialog(false)}>
                            <Close />
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid
                        container direction='column' spacing={10}
                        className={classes.dialogContent} style={disableStyle()}
                    >
                        {fields
                            .filter(field =>
                                !field.dependencyField
                                || request?.fields[field.dependencyField] == true
                                || ((request?.fields[field.dependencyField] || {}).value == true)
                            )
                            .map(field => (
                                <Grid item key={field.key}>
                                    <FormControl fullWidth>

                                        <TextField
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label={field.label}
                                            multiline
                                            variant='outlined'
                                            minRows={4}
                                            onChange={(e) => onChange(field.id, e.target.value)}
                                            value={values[field.id]}
                                        />

                                    </FormControl>
                                </Grid>
                            ))}
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>

                    <Button onClick={() => setOpenDialog(false)} variant="contained">
                        Fechar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveAnalysis}
                    >
                        Salvar
                    </Button>

                </DialogActions>

            </Dialog >

            <Snackbar
                onClose={() => setStatusMsg({ ...statusMsg, open: false })}
                open={statusMsg.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={statusMsg.severity}
                >
                    {statusMsg.text}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default RequestHearingAnalysisForm;
