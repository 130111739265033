import React, { useState, useEffect } from "react";

import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    MobileStepper, Button, Typography, useTheme, Grid, FormControl, TextField
} from "@material-ui/core";

import Rating from '@material-ui/lab/Rating';

import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";

import memberService from "../../../domain/services/solicitation/member.service";
import solicitationService from "../../../domain/services/solicitation/solicitation.service";

const RatingForm = (props) => {

    const {
        requestId, questions, setQuestions, saveResultQuestion, isModalOpen, concluded,
        closeModal, name, dontShowFaciliterName = false, feedbackLabel = 'Feedback', showReviewForAllSituations = false
    } = props;

    const theme = useTheme();

    const [members, setMembers] = useState([]);
    const [feedback, setFeedback] = useState("");

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        let isSubscribed = true;

        if (isSubscribed)
            findMembers();

        return () => (isSubscribed = false);
    }, [requestId])

    const findMembers = async () => {

        const { data } = await memberService.find(requestId)
            .catch(err => ({ data: [] }));

        setMembers(data);

        return data;
    }

    const setQuestionAnswer = (e, index) => {
        let item = [...questions];
        item[index].answer = parseInt(e.target.value);
        setQuestions(item);
        handleNext();
    }

    const handleNext = () => {
        if (!questions[activeStep].answer)
            return;

        saveResultQuestion({ ...questions[activeStep] }, members);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const getStepperOptions = (totalQuestions) => (
        <MobileStepper variant="dots" steps={totalQuestions + 1} position="static"
            activeStep={activeStep} sx={{ flexGrow: 1 }}
            style={{ width: "100%", background: "unset" }}
            nextButton={
                <section>
                    <Button size="small" onClick={handleNext} disabled={activeStep === totalQuestions}>
                        Continuar {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                </section>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} Voltar
                </Button>
            }
        />
    );

    const getQuestion = (index) => {
        let question = questions[index];
        if (!question)
            return getFeedbackOption();

        return (
            <section style={{ textAlign: "center", marginTop: "10px" }}>
                <Typography component="h4" style={{ fontWeight: 450, fontSize: "16px", width: "100%" }}>{question.description}</Typography>
                <Rating name={`rating-${question.id}`} max={5} style={{ marginTop: "10px" }}
                    value={(question.answer || 0)} onChange={(e) => { setQuestionAnswer(e, index) }} />
                <span style={{ display: "block", color: "rgb(0 0 0 / 44%)", fontWeight: "600" }}>* Informe a nota para continuar</span>
            </section>
        );
    }

    const getFeedbackOption = () => {
        return (
            <Grid item md={12} style={{ marginTop: "10px" }}>
                <FormControl fullWidth my={2} variant="outlined">
                    <TextField name="feedback" label={feedbackLabel} multiline={true}
                        placeholder="Deixe aqui seu feedback..." variant="outlined" value={feedback}
                        onChange={(e) => { setFeedback(e.target.value); }}
                    />
                    <span style={{ display: "block", color: "rgb(0 0 0 / 44%)", fontWeight: "600" }}>* Informe o feedback para concluir</span>
                </FormControl>
            </Grid>
        );
    }

    if (!showReviewForAllSituations && !solicitationService.canReviewSolicitation())
        return null;

    return (
        <React.Fragment>
            <Dialog open={isModalOpen} onClose={closeModal} maxWidth="xs" fullWidth disableBackdropClick disableEscapeKeyDown>
                <DialogTitle style={{ paddingBottom: "10px" }}>{name}</DialogTitle>
                {
                    questions.length ?
                        <DialogContent>
                            <section style={{ marginTop: 0, marginBottom: "10px", width: "100%" }}>
                                {members.length && !dontShowFaciliterName ? <span style={{ color: "rgb(0 0 0 / 60%)", fontWeight: 500 }}>Faciliter: {members.map(item => item.name).join(", ")}</span> : null}
                            </section>
                            {getQuestion(activeStep)}
                            {getStepperOptions(questions.length)}
                        </DialogContent> : null
                }
                <DialogActions>
                    <Button size="small" variant="contained" onClick={closeModal}>Cancelar</Button>
                    <Button size="small" disabled={activeStep != questions.length} variant="contained" color="primary" onClick={() => concluded(feedback, members, closeModal)}>Concluir</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default RatingForm;


