import { useState, useEffect } from "react";
import utilsProvider from "../../providers/utils.provider";
import { Close, WarningOutlined } from "@material-ui/icons";
import { obtainCustomerRepurchaseVoucher } from "../../../domain/services/marketing/voucher.service";
import loginService from "../../../domain/services/login.service";
import { useHistory } from "react-router-dom";
import LoadingSpin from "../../LoadingSpin";
import { voucherTrackerUserId } from "../../../domain/constant/customer.repurchase.voucher.constant";
import { IconButton } from "@material-ui/core";

export default function PendingModalContent({
    request,
    showClose = false,
    onClose = () => {},
    title = 'Uma solicitação está com pendência financeira!',
    subtitle = 'Renove o seu pacote e ganhe mais tempo e dinheiro.',
}) {
    
    const history = useHistory();

    const [voucher, setVoucher] = useState(null);

    const user = loginService.getUserAuthentication();
    const customerId = user?.customer?.id || request?.customerId;

    const texts = {
        absolute: {
            discountType: 'Utilize o seu saldo na compra do próximo pacote e ganhe mais 5% de desconto!',
        },
        percentage: {
            discountType: 'Turbine ainda mais sua economia com um desconto de 5%!',
        },
    };

    const handleSubmit = () => {
        history.push(`/marketplace?voucher=${voucher.code}&sellerUserId=${voucherTrackerUserId}`);
    }

    const handleClose = () => {
        history.push('/solicitations#pending');
    }

    const getRepurchaseVoucher = () => {
        if (request?.voucher) {
            setVoucher(request.voucher);
            return;
        }
        
        obtainCustomerRepurchaseVoucher(customerId, { ignoreMinimumCreditAmount: true })
            .then(({ data }) => {
                const repurchaseVoucher = data;

                if (!repurchaseVoucher || !repurchaseVoucher?.code) {
                    handleClose();
                    return;
                }

                setVoucher(repurchaseVoucher);
            })
            .catch(() => handleClose())
    }
    
    useEffect(() => {
        getRepurchaseVoucher();
    }, []);

    const text = texts[voucher?.discountType || 'absolute'];

    if (!voucher) return (
        <div className="p-12 flex flex-col items-center gap-2 text-base text-[#376fd0] font-semibold">
            <LoadingSpin size="large"/>
            Carregando...
        </div>
    );

    return (
        <div className="flex flex-col md:flex-row relative">
            {showClose && (
                <div className="absolute right-4 top-4">
                    <IconButton
                        aria-label="close"
                        size='small'
                        onClick={onClose}
                    >
                        <Close className="!text-[#aaa]" />
                    </IconButton>
                </div>
            )}
            <div className="text-xl bg-[#1e283a] text-white p-10 mr-0 rounded rounded-r-none flex flex-col justify-center items-center md:items-stretch gap-5">
                <div
                    className="text-[#faad14] font-semibold h-[1.25rem] text-justify min-w-max"
                >
                    E C O N O M I Z E
                    <span className="w-full inline-block"></span>
                </div>

                {voucher?.discountType !== 'percentage' && (
                    <div className="text-5xl md:text-6xl text-center">
                        <span className="text-2xl">R$</span>
                        {utilsProvider.formatNumberCurrencyWithoutSymbol(voucher?.discount || 0)}
                    </div>
                )}

                {voucher?.discountType === 'percentage' && (
                    <div className="text-6xl md:text-7xl text-center">
                        <span>{parseInt(voucher?.discount || 0)}</span>
                        <span
                            className="text-3xl"
                            style={{ lineHeight: '72px', verticalAlign: 'text-top' }}
                        >
                            %
                        </span>
                    </div>
                )}

                <div
                    className="text-[#fff9] text-center py-2 font-[350]"
                    style={{
                        borderTop: 'solid 1px #fff2',
                        borderBottom: 'solid 1px #fff2',
                    }}

                >
                    NA RENOVAÇÃO DO SEU PACOTE
                </div>
            </div>

            <div className="text-base text-center py-6 px-8 md:py-16 md:px-14">
                <div className="text-lg font-semibold">
                    <WarningOutlined style={{ color: '#faad14', transform: 'translateY(-2px)', marginRight: 8 }} />
                    {title}
                </div>

                <div className="mt-6">
                    {subtitle} {text.discountType}
                </div>
                <div
                    className="mt-7 mb-7  text-[#376fd0] font-semibold"
                >
                    Na hora de adquirir mais um pacote, ative a Recompra Inteligente e nunca mais tenha dores de cabeça com falta de saldo.
                </div>
                <div className="mt-7 w-full flex justify-center">
                    <button
                        className="w-full py-4 text-lg font-semibold rounded bg-[#faad14] text-white hover:brightness-105"
                        style={{ letterSpacing: 1 }}
                        onClick={handleSubmit}

                    >
                        RENOVAR COM DESCONTO
                    </button>
                </div>
                <div className="mt-6 text-sm text-[#444]">
                    Essa promoção irá expirar se você sair dessa mensagem.
                </div>
            </div>
        </div>
    );
}