import { useState } from "react";
import PremiumRequestIncentiveModal from "./premiumRequestIncentiveModal";
import { useTheme } from '@material-ui/core';

export default function PremiumRequestIncentiveButton() {

    const theme = useTheme();

    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                className='hover:brightness-110 text-sm mt-2 font-semibold'
                style={{ color: theme.palette.primary.main }}
                onClick={() => setOpen(true)}
            >
                Como funciona?
            </button>
            <PremiumRequestIncentiveModal open={open} onClose={() => setOpen(false)} />
        </>
    );
}