import { useEffect, useState } from "react";

export default function Input({
    id,
    value,
    onChange = () => {},
    placeholder,
    error,
}) {

    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);
    
    const handleChange = (e) => {
        onChange(e.target.value);
        setCurrentValue(e.target.value);
    };
    
    return (
        <div className="w-full relative text-sm">
            <input
                id={id}
                className={`
                    text-sm h-8 w-full rounded border hover:border-primary focus:border-primary focus:ring-0
                    ${!!error ? '!border-red-500' : 'border-[#ededed]'}
                `}
                style={{ transition: 'border-color .3s ease' }}
                value={currentValue || ''}
                onChange={handleChange}
            />

            {!currentValue && <div className="absolute left-3 top-1/2 -translate-y-1/2 text-[#0006] pointer-events-none">{placeholder}</div>}

            <div
                className={`h-0 text-sm text-red-500 ${!!error ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1'}`}
                style={{ transition: 'opacity .3s ease, transform .3s ease' }}
            >
                {error}
            </div>
        </div>
    );
}