export const toolbarItems = {
  development: [
    // 'New',
    'Open',
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'InsertFootnote',
    'InsertEndnote',
    'Separator',
    'Find',
    // 'Separator',
    // 'Comments',
    // 'TrackChanges',
    // 'Separator',
    // 'LocalClipboard',
    // 'RestrictEditing',
    // 'Separator',
    // 'FormFields',
    // 'UpdateFields'
  ],
  review: [
    // 'New',
    'Open',
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'InsertFootnote',
    'InsertEndnote',
    'Separator',
    'Find',
    'Separator',
    'Comments',
    'TrackChanges',
  ],
  correction: [
    'Open',
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'InsertFootnote',
    'InsertEndnote',
    'Separator',
    'Find'
  ]
}

export const fontStyles = {
  defaultStyleJson: {
    type: 'Paragraph',
    name: `Formatação Facilita`,
    basedOn: 'Normal',
    characterFormat: {
      fontSize: 12,
      fontFamily: 'Times New Roman',
      bold: false,
      italic: false,
    },
    paragraphFormat: {
      lineSpacing: 1.5,
      lineSpacingType: 'Multiple',
      textAlignment: 'Justify',
      leftIndent: 0,
      firstLineIndent: 3 * 36
    }
  },
  defaultCitationStyleJson: {
    type: 'Paragraph',
    name: `Formatação Facilita para Citação`,
    basedOn: 'Normal',
    characterFormat: {
      fontSize: 10,
      fontFamily: 'Times New Roman',
      bold: false,
      italic: false,
    },
    paragraphFormat: {
      lineSpacing: 1,
      lineSpacingType: 'Multiple',
      textAlignment: 'Justify',
      leftIndent: 5 * 36,
    }
  }
}

export const userSourceObj = {
  internal: {
    label: 'Interno',
    color: '#E36801',
  },
  faciliter: {
    label: 'Faciliter',
    color: '#00CED4'
  },
  'Sugestões Automáticas': {
    label: 'Sugestões Automáticas',
    color: '#D40000'
  },
  customer: {
    label: 'Cliente',
    color: '#E301B3'
  }
}

export const serviceUrl = 'https://facilita-document-editor-server.tk/api/documenteditor/'

export const syncfusionLicense = 'Mgo+DSMBaFt/QHFqVVhlXVpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9jTXxVd0dnXH5dc3FRQA==;Mgo+DSMBPh8sVXJ0S0d+XE9Bd1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTckdlWH1adXVWT2FYVQ==;ORg4AjUWIQA/Gnt2VVhhQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhX35cdHFUQmJcU0Y=;NjI1MzI5QDMyMzAyZTMxMmUzMEtHTlJTQStZRUNYeFhRNlpadnE4ZnI0Q1AyTFRpc25ML25sdE9Wa3FuSzQ9;NjI1MzMwQDMyMzAyZTMxMmUzMFFzanp5ZUFkYUYvM2FydC9OL1V2TnhFV2RzOEpQZkx3bEt4Mlk3MXorUkE9;NRAiBiAaIQQuGjN/V0Z+XU9EaFpDVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVnW3lfcnBQR2VZU0Rx;NjI1MzMyQDMyMzAyZTMxMmUzMGdQZ3llNkpBN2FwYWhRbkh4UjNhclhWRFZBVG1FaC9EZ3BUY3FmazRQMkE9;NjI1MzMzQDMyMzAyZTMxMmUzMEZoTHhsWDRrd1Fwa2QwbnZyaGt2bEtlUEp2NmtrdUNRQmFLcXZJQnFRQjQ9;Mgo+DSMBMAY9C3t2VVhhQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhX35cdHFUQ2ZeVUM=;NjI1MzM1QDMyMzAyZTMxMmUzMGwxNVFQbjkwMXhzTy9BdFhVZy9tR1pXc3RtcDVHMUd6Tm4zSEwraTZmVVU9;NjI1MzM2QDMyMzAyZTMxMmUzME5EMUFJY0JHLzRLY1JtalJ0cnorTkpYUVR5WFFyTDBOb2xlMEJRZU9sTDQ9'

export const fontFamilies = [
  'Arial',
  'Book Antiqua',
  'Bookman Old Style',
  'Calibri',
  'Californian FB',
  'Cambria Math',
  'Cambria',
  'Candara',
  'Century Gothic',
  'Century Schoolbook',
  'Comic Sans MS',
  'Courier New',
  'EB Garamond',
  'Ebrima',
  'Gadugi',
  'Garamond',
  'Georgia',
  'Impact',
  'Montserrat',
  'Nunito',
  'Open Sans',
  'Palatino Linotype',
  'Palatino',
  'Perpetua',
  'Roboto',
  'Segoe Print',
  'Segoe Script',
  'Segoe UI',
  'Source Sans Pro',
  'Symbol',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Tw Cen MT',
  'Ubuntu',
  'Verdana',
  'Wingdings',
  'Algerian'
]