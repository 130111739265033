import { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';

import ExtendDueDateModal from '../ExtendDueDateModal';

import loginService from '../../domain/services/login.service';
import requestDeadlineService from '../../domain/services/request/request.deadline.service';

export default function ButtonExtendDeadline({ request, onChange, setFieldValue, setAlert }) {

    const [isExtensionDeadlinePossible, setIsExtensionDeadlinePossible] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => { checkExtensionOperationalDeadlinePossibility() }, [])

    const checkExtensionOperationalDeadlinePossibility = async () => {

        const userId = loginService.getUserAuthentication().id;

        await requestDeadlineService
            .checkExtensionDeadlinePossibility(request.id, userId, null)
            .then(res => setIsExtensionDeadlinePossible(res.data));
    }

    if (!isExtensionDeadlinePossible)
        return null;

    return (
        <>
            <Button
                type="button" variant="outlined" color="primary"
                target="_blank" onClick={() => setIsModalOpen(true)}
            >
                Solicitar Mais Prazo
            </Button>

            <ExtendDueDateModal
                open={isModalOpen} onClose={() => setIsModalOpen(false)}
                requestID={request.id} request={request} setAlert={setAlert}
                onChange={onChange} setFieldValue={setFieldValue}
            />
        </>
    )
}