import { Button, Dialog, Typography, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import requestService from "../../domain/services/request/request.service";

export default function RequestPremiumConfirmModal({
    onClose,
    request,
    open,
    name,
    setValue,
    setOpen,
    setAlert,
    isEdit,
}) {
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        if (!isEdit) {
            setValue(name, true);
            return onClose();
        }

        try {
            setLoading(true);

            await requestService.changeServiceToPremium(request.id);
            setOpen(false);
            setAlert('Serviço premium adicionado com sucesso', 'success');
            setValue(name, true);
        } catch (err) {
            setAlert('Não foi possível adicionar o serviço premium', 'error');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            onClose={onClose}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 32,
                gap: 24,
                textAlign: 'left',
            }}>
                <div style={{ display: 'flex', gap: 24 }}>
                    <div>
                        <Typography variant="h3">
                            Faciliter Premium
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 8 }}>{`Ao solicitar um Faciliter Premium ${isEdit ? 'será debitado um crédito extra' : 'serão debitados 2 créditos'}. Tem certeza que deseja prosseguir?`}</Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', gap: 24 }}>
                    <Button size="large" variant="outlined" onClick={onClose}>Cancelar</Button>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleConfirm}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
