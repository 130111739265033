export const automationSellerUserIds = {
    upsell: 14617,
    upsellInDowngrade: 14618,
    expiredPackageAsDiscount: 14618,
    raffleDellLaptop: 14660,
    lawyerWeek: 14803,
    infinityIncentive: 14876,
    infinityMarketplace: 14915,
    customerAbandonedCheckout: 15409,
};
