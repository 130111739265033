import { Box, Button, Grid, Typography } from "@material-ui/core"
import { useTour } from "../../context/tourContext";

const RequestFormHighlightButton = ({ label, text, onClick }) => {

    const { registerRef } = useTour();

    const ratingRef = registerRef('concludedRequestTour', 'rating')

    return (
        <Box style={{ borderBottom: '1px solid #C8C8C8', paddingBottom: 10 }}>
            <div
                style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}
            >
                <Grid item md xs={6}>
                    <Typography style={{ fontSize: 15 }}>
                        <strong>{label}</strong>
                    </Typography>
                </Grid>

                <Grid item>
                    <Button variant='contained' onClick={onClick} color='primary' ref={ratingRef}>
                        {text}
                    </Button>
                </Grid>
            </div>
        </Box>
    );
}
export default RequestFormHighlightButton;