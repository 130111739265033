const updateData = [
    "id",
    "price",
    "status",
    "paid",
    "sellerUserId",
    "description"
]

const checkAttributeByInterface = (attr, operationInterface) =>
    operationInterface.includes(attr);

const mountUpdateObj = (request, operationInterface) =>
    Object.keys(request)
        .filter(key => checkAttributeByInterface(key, operationInterface))
        .reduce((requestObj, key) => ({ ...requestObj, [key]: request[key] }), {})

const checkIfCanBeUpdated = (request) => {

    if (Object.keys(request).length < 2)
        return null;

    return request;
}

const update = async (request) => await Promise
    .resolve(mountUpdateObj(request, updateData))
    .then(checkIfCanBeUpdated)

export default {
    update
}