import styled, { keyframes } from "styled-components";
import { useTheme } from '@material-ui/core';

/**
 * Simple Loading Spin
 * @property {('small' | 'default' | 'large')} size 
 * @returns 
 */
export default function LoadingSpin({
    size = 'default',
    color,
}) {

    const theme = useTheme();

    const sizes = {
        small: 14,
        default: 20,
        large: 32,
    };

    const chosenSize = isNaN(size) ? sizes[size] : size;

    const Rotate = keyframes`
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    `;

    const Spin = styled.div`
        animation: ${Rotate} 0.8s infinite linear;
        border: 2px solid transparent;
        border-left: 2px solid ${color || theme.palette.primary.main};
        border-radius: 50%;
        height: ${chosenSize}px;
        width: ${chosenSize}px;
        min-height: ${chosenSize}px;
        min-width: ${chosenSize}px;
    `;

    return (
        <Spin />
    );
}