import React, { useState, useEffect } from 'react';

import styled from 'styled-components/macro';

import {
    Button as MuiButton, Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Grid
} from '@material-ui/core';
import { Close, Assignment } from '@material-ui/icons';

import { useTour } from '../../../context/tourContext';
import { announcements } from '../../../domain/constant/announcements.constant';

const Button = styled(MuiButton)`
    span {
        justify-content: flex-start;
        svg {
            margin-right: 10px;
        }
    }
`;

export default function ModalDetailRequestPetitionIndication({ showModal, isStarted, petitionIndication, finishRegistration, closeModal }) {
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const { registerRef } = useTour()

    const petitionInformation = registerRef(announcements.tourPetitionIndication, 'petitionInformation')
    const startSolicitation = registerRef(announcements.tourPetitionIndication, 'startSolicitation')

    const [showAnalysis, setShowAnalysis] = useState(showModal);

    useEffect(() => {
        setShowAnalysis(showModal);
    }, [showModal]);

    return (
        <>
            <Dialog open={showAnalysis} maxWidth='md' fullWidth onClose={() => {
                setShowAnalysis(false);
                closeModal();
            }}>
                <DialogTitle style={{ display: 'inline-flex', backgroundColor: '#eee', width: '100%', minWidth: '100%' }}>
                    <Typography
                        variant="h4"
                        style={{
                            paddingTop: '10px',
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            minWidth: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Assignment style={{ color: '#376fd0', fontSize: '25px', marginRight: '7px' }} />
                        {petitionIndication?.serviceName} {petitionIndication?.areaName.toLowerCase()} {petitionIndication?.modalityName.toLowerCase()} - {petitionIndication?.id}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setShowAnalysis(false);
                            closeModal();
                        }}
                        style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent ref={petitionInformation}>
                    <Grid container direction="column" style={{ padding: '5px' }}>
                        {petitionIndication?.fields.map(field => (
                            field.values && field.values.length > 0 && (
                                field.values.map((val, index) => (
                                    (val.optionName || val.value) && (
                                        <Grid item key={`${field.key}-${index}`} style={{ borderBottom: '1px solid rgb(44 44 44 / 20%)', padding: '10px' }}>
                                            <Typography variant="h6" style={{ marginBottom: '5px' }}>{field.name}</Typography>
                                            {val.optionName && (
                                                <Typography variant="body1">{capitalizeFirstLetter(val.optionName)}</Typography>
                                            )}
                                            {val.value && field?.key !== "typeAction" && (
                                                <Typography variant="body2">{capitalizeFirstLetter(val.value)}</Typography>
                                            )}
                                        </Grid>
                                    )
                                ))
                            )
                        ))}
                    </Grid>
                </DialogContent>
                {!isStarted &&
                    <DialogActions style={{ background: "rgb(238, 238, 238)", display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                finishRegistration(petitionIndication, true);
                                setShowAnalysis(false);
                                closeModal();
                            }}
                            ref={startSolicitation}
                        >
                            Solicitar o serviço agora!
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}