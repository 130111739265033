import { useEffect, useState } from "react";

import {
    Card, CardContent, Accordion, AccordionSummary, AccordionDetails, Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ExpandMore, Star } from "@material-ui/icons";

import CustomerRatingList from "./customer.rating.list.component";
import LoadingProgress from "../../LoadingProgress";

import customerRatingService from "../../../domain/services/solicitation/customer.rating.service";

import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

const CustomerRating = ({ mainRequestId, startClosed, small }) => {

    const { promiseInProgress } = usePromiseTracker();

    const [isAccordionExpanded, setIsAccordionExpanded] = useState(!startClosed);
    const [requestRatings, setRequestRatings] = useState([]);

    useEffect(() => { getData(mainRequestId) }, []);

    const getData = async (mainRequestId) =>
        trackPromise(
            customerRatingService
                .getRequestRatings(mainRequestId)
                .then(data => setRequestRatings(data))
        );

    const toggleAccordion = () =>
        setIsAccordionExpanded((isAccordionExpanded) => !isAccordionExpanded);

    const getRequestRatingList = (requestRatings) =>
        requestRatings.length > 0 && (
            <CustomerRatingList ratings={requestRatings} small={small} />
        )

    const getEmptyCard = (requestRatings) => {

        const showEmptyCard = !promiseInProgress && !requestRatings.length;

        return showEmptyCard && (
            <Card style={{ marginTop: 20, borderRadius: 0, backgroundColor: 'unset' }}>
                <Alert mt={2} mb={1} severity='info'>
                    O cliente ainda não realizou nenhuma avaliação desta solicitação!
                </Alert>
            </Card>
        )

    }

    const loading = () => promiseInProgress && <LoadingProgress />

    return (
        <Card mb={6} style={{ marginBottom: 10 }}>
            <CardContent style={{ padding: 3 }}>
                <Accordion expanded={isAccordionExpanded} onChange={toggleAccordion}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Star style={{ fontSize: 18, marginRight: 10 }} />
                        <Typography variant="h6">Avaliação de satisfação do cliente</Typography>
                    </AccordionSummary>

                    <AccordionDetails style={{ borderTop: "1px solid #d8d8d8", display: "block" }}>

                        {getRequestRatingList(requestRatings)}

                        {getEmptyCard(requestRatings)}

                        {loading()}

                    </AccordionDetails>
                </Accordion>
            </CardContent>
        </Card >
    )
}

export default CustomerRating;