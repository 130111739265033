
import React from "react";

import Async from "../../components/Async";

import { OndemandVideo } from "@material-ui/icons";

const AuthGuard = Async(() => import("../auth.guard"));

const Videos = Async(() => import('../../pages/marketing/videos'));

const routes = {
    videos: {
        id: 'videos',
        path: '/videos',
        name: 'Vídeos',
        icon: <OndemandVideo />,
        component: Videos,
        guard: AuthGuard,
        roles: ['customer', 'faciliter']
    }
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}