
import { Card, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";

function LoadingProgress(props = {}) {

    return (
        <Card
            xs={12}
            elevation={0}
            style={{
                width: '100%',
                borderBottom: 'none',
                background: props.transparentBackground ? 'transparent' : 'default',
                boxShadow: 'none', 
            }}
        >
            <CardContent
                style={{
                    display: "-webkit-inline-box",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    background: props.transparentBackground ? 'transparent' : 'default'
                }}
            >
                <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                    <Typography variant="h6" gutterBottom> {props.title || 'Aguarde'} </Typography>
                    <Typography variant="body2" gutterBottom> {props.message || 'Carregando...'} </Typography>
                </Grid>
            </CardContent>
        </Card>
    );

}

export default LoadingProgress;
