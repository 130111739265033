import { useState, useEffect } from 'react';
import moment from 'moment';

import { Collapse, Grid } from '@material-ui/core'

import RadioButtonField from './radio.button.field';
import FormField from '../../FormField';
import { CalendarToday } from '@material-ui/icons';

export default function DatePickerField(
    { name, label, value, extraInfo, setFieldValue, fieldProps, onError, needConfirmation }
) {
    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {
        if (value || !needConfirmation) {
            setIsConfirmed(true);
        }
    }, [value, needConfirmation]);

    const onChange = (name, newValue) => {
        if (newValue) {
            const formattedDate = moment(newValue).format("YYYY-MM-DD");
            setFieldValue(name, formattedDate);
        }
    };

    const changeConfirmation = (n, value) => {
        setIsConfirmed(!!value);
        if (!value) {
            setFieldValue(name, null);
        }
    };

    const renderConfirmation = () => (
        <Grid item xs={12}>
            <RadioButtonField
                label="Houve citação do réu?"
                name={`has${name}`}
                value={isConfirmed}
                setFieldValue={changeConfirmation}
                fieldProps={fieldProps}
                onError={onError}
            />
        </Grid>
    );

    return (
        <>
            {needConfirmation && renderConfirmation()}
            <Grid item xs={12}>
                <Collapse in={isConfirmed}>
                    <FormField
                        {...fieldProps}
                        type='date'
                        name={name}
                        field={label}
                        currentValue={value ? moment(value) : null}
                        onChange={onChange}
                        helperText={extraInfo}
                        onError={onError}
                        placeholder="Selecione a data"
                        InputProps={{
                            endAdornment: <CalendarToday style={{ color: '#0007', fontSize: 20 }} />
                        }}
                    />
                </Collapse>
            </Grid>
        </>
    );
}
