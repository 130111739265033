import api from '../api';

const findByMainId = async (mainId) =>
    api.get({ type: 'user', service: `requests/${mainId}/members/external/tip` });

const sendTip = async (result) =>
    api.post({ type: 'user', service: `requests/${result.mainId}/members/external/tip`, data: result });

const checkToGiveTip = async (ratingScore, ratedRequest, action) => {

    const minRatingToGiveTip = 4;
    if (ratingScore < minRatingToGiveTip)
        return { success: false }

    const requestTips = await findByMainId(ratedRequest.mainId || ratedRequest.id).catch(err => ([]));
    if (requestTips.data.length > 0)
        return { success: false }

    action();
    return { success: true }
}

export default {
    findByMainId,
    sendTip,
    checkToGiveTip
}