import swal from "sweetalert2";

import { Chip } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import solicitationService from "../../domain/services/solicitation/solicitation.service";
import faciliterAssureIncomeService from "../../domain/services/correspondent/faciliter.assure.income.service";
import loginService from "../../domain/services/login.service";

import { faciliterCorrectionDropoutHtmlMessage } from '../../providers/swel.html.messages';

function FaciliterCorrectionDropout({ request, setAlert, setIsLoading }) {

    const handleConfirm = async () => {

        const faciliterWithAssureIncome = loginService.getUserAuthentication()?.faciliter?.isAssureIncome;

        if (!faciliterWithAssureIncome)
            return onConfirm();

        return confirmFaciliterAssureIncome();
    }

    const onConfirm = async () => {

        setIsLoading(true)

        return solicitationService
            .faciliterCorrectionDropout(request.id, loginService.getUserAuthentication()?.faciliter?.id)
            .then(() => window.location.href = "/solicitations")
            .catch(err => setAlert((err.response.data.message || "Erro ao solicitar a desistência"), "error"))
            .finally(() => setIsLoading(false));
    }


    const confirmFaciliterAssureIncome = async () => {

        const { faciliter } = loginService.getUserAuthentication();
        const programRequirements = await faciliterAssureIncomeService.findInfoById(faciliter.id)

        swal.fire({
            title: "Tem certeza?",
            text: `Se você desistir de ${programRequirements.maxDropout} solicitações em um período de ${programRequirements.dropoutRange} dias será desligado do programa Renda Garantida!`,
            icon: "warning",
            buttons: { cancel: 'Cancelar', confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' } }
        })
            .then(result => result.isConfirmed && onConfirm())
            .catch(err => setAlert((err?.response?.data?.message || 'Não foi possível desistir da correção!'), "error"));
    }

    const toggleConfirmationModal = () => {
        swal.fire({
            html: faciliterCorrectionDropoutHtmlMessage(),
            icon: 'warning',
            confirmButtonText: 'Confirmar Desistência',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            customClass: {
                container: 'swal-container'
            }
        }).then((result) => result.isConfirmed && handleConfirm())
    }

    return (
        <Chip
            label="Desistir da Correção"
            icon={<Cancel />}
            onClick={toggleConfirmationModal}
            variant="outlined"
            style={{ height: '100%' }}
        />
    )
}

export default FaciliterCorrectionDropout;