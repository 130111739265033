
import React from "react";

import Async from "../../components/Async";

import { Assessment, Assignment, ListAlt, PlaylistAddCheck, SmsFailed } from "@material-ui/icons";

const AuthGuard = Async(() => import("../auth.guard"));

const Solicitation = Async(() => import('../../pages/operations/solicitations/solicitation'));
const RequestPage = Async(() => import('../../pages/operations/solicitations/request.page'));
const RequestPresenceConfirmation = Async(() => import('../../pages/operations/solicitations/request.presence.confirmation'));
const RequestHearingCheckin = Async(() => import('../../pages/operations/solicitations/request.hearing.checkin'));
const NewRequest = Async(() => import('../../components/requests/new.request.component'));
const LegacyRequestDetails = Async(() => import('../../components/solicitations/legacy.request.details.component'));
const RequestDetails = Async(() => import('../../components/requests/request.details.component'));
const RequestCenter = Async(() => import("../../pages/operations/solicitations/request.center"));
const Analysis = Async(() => import("../../pages/operations/analysis/analysis"));
const PendencyCenter = Async(() => import("../../pages/operations/solicitations/pendency.center"));
const RequestBank = Async(() => import("../../pages/operations/request.bank"));
const RequestNotionForm = Async(() => import("../../components/requests/notion/request.notion.form"));
const RequestPetitionIndicationForm = Async(() => import("../../components/requests/notion/request.petition.indication.form"));
const ExternalLink = Async(() => import("../../pages/operations/solicitations/external.link"));

const routes = {
    requests: {
        id: 'requests',
        name: 'Solicitações',
        path: '/solicitations',
        component: Solicitation,
        guard: AuthGuard,
        icon: <ListAlt />,
    },
    requestPage: {
        id: 'requestPage',
        name: 'Solicitação',
        path: '/solicitations/:id',
        component: RequestPage,
        guard: AuthGuard,
        roles: ['admin', 'operator', 'customer', 'customer-user-operator', 'customer-user-admin', 'faciliter']
    },
    requestPresenceConfirmation: {
        id: 'requestPresenceConfirmation',
        name: 'Solicitação',
        path: '/solicitations/:id/confirm-presence',
        component: RequestPresenceConfirmation,
        guard: AuthGuard,
        roles: ['faciliter']
    },
    requestHearingCheckin: {
        id: 'requestHearingCheckin',
        name: 'Solicitação',
        path: '/solicitations/:id/hearing-checkin',
        component: RequestHearingCheckin,
        guard: AuthGuard,
        roles: ['faciliter']
    },
    requestForm: {
        id: 'requestForm',
        name: 'Solicitação',
        path: '/solicitations/:id/legacy',
        component: LegacyRequestDetails,
        guard: AuthGuard,
    },
    requestDetailsForm: {
        id: 'requestDetailsForm',
        name: 'Solicitação',
        path: '/solicitations/:id/request',
        component: RequestDetails,
        guard: AuthGuard,
    },
    requestNotionForm: {
        id: 'requestNotionForm',
        name: 'Parecer',
        path: '/solicitations/:requestId/notion',
        component: RequestNotionForm,
        guard: AuthGuard
    },
    newRequestPetitionIndicationForm: {
        id: 'newRequestPetitionIndicationForm',
        name: 'Indicação de serviço do Parecer',
        path: '/solicitations/new-request-indication/:notionRequestId',
        component: RequestPetitionIndicationForm,
        guard: AuthGuard
    },
    requestPetitionIndicationForm: {
        id: 'requestPetitionIndicationForm',
        name: 'Indicação de serviço do Parecer',
        path: '/solicitations/notion/:notionRequestId/request-indication/:id',
        component: RequestPetitionIndicationForm,
        guard: AuthGuard
    },
    requestCenter: {
        id: 'requestCenter',
        name: 'Central de solicitações',
        path: '/external-link/solicitations',
        component: RequestCenter,
        icon: <Assignment />,
        guard: AuthGuard,
        roles: ['faciliter'],
    },
    analyses: {
        id: 'analyses',
        name: 'Indicações de Petição',
        path: '/analyses',
        component: Analysis,
        guard: AuthGuard,
        icon: <PlaylistAddCheck />,
        roles: ['admin', 'operator'],
    },
    pendencyCenter: {
        id: 'pendencyCenter',
        path: '/pendency-center',
        name: 'Central de pendências',
        icon: <SmsFailed />,
        component: PendencyCenter,
        guard: AuthGuard,
        roles: ['admin', 'operator'],
    },
    requestBank: {
        id: 'requestBank',
        name: 'Avaliações',
        path: '/request-bank',
        component: RequestBank,
        icon: <Assessment />,
        guard: AuthGuard,
        roles: ['admin', 'operator'],
    },
}

const formRoutes = {
    newRequestForm: {
        id: 'newRequestForm',
        name: 'Nova Solicitação',
        path: '/solicitations/new/:id',
        component: NewRequest,
        guard: AuthGuard,
    }
}

const externalRoutes = {
    externalRequestLink: {
        id: 'externalRequestLink',
        name: 'Vinculo Solicitação',
        path: '/external-link/solicitations/:requestId/faciliter/:correspondentId/action/:action',
        component: ExternalLink,
    }
}

const pages = Object.values(routes);

const formPages = Object.values(formRoutes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    formRoutes,
    externalRoutes,
    pages,
    formPages,
    externalPages
}