import { createContext, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";

const FormContext = createContext();

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context)
        return {};
    return context;
};

export default function Form({
    form,
    children,
    onFinish = () => { },
    onFieldChange = (changedFields, formValues) => { },
    initialValues = {},
    ...props
}) {
    const typingDelayRef = useRef();
    const typingDefaultDelay = 500;

    const [formValues, setFormValues] = useState(initialValues || {});
    const [formFields, setFormFields] = useState({});
    const [invalidFields, setInvalidFields] = useState({});

    const registerFieldValue = (name, value) => {
        const maskedValue = formFields[name]?.mask ? formFields[name].mask(value) : value;

        setFormValues(prevValues => ({ ...prevValues, [name]: maskedValue }));

        setInvalidFields(prev => {
            const { [name]: removedValue, ...fields } = prev;
            return fields;
        });

        if (formValues[name] === maskedValue || !(name in formValues)) return;

        onFieldChange({ [name]: maskedValue }, { ...formValues, [name]: maskedValue });

        validateOnChange(name, maskedValue)
    };

    const validateOnChange = (name, value) => {
        const field = formFields[name];

        if (!field)
            return;

        let typingValidationDelay = 0;

        if (field.delayTypingValidation)
            typingValidationDelay = field.validationTypingDelay || typingDefaultDelay;

        clearTimeout(typingDelayRef.current)

        typingDelayRef.current = setTimeout(() => {
            validateField(value, field);
        }, typingValidationDelay);
    }

    const registerField = (name, props) => {
        setFormFields(prevFields => ({ ...prevFields, [name]: props }));
    };

    const normalizeFields = (values) =>
        new Promise((resolve) => {
            const normalizedFields = Object.entries(values).reduce(
                (obj, [key, value]) => {
                    const formItem = Object.values(formFields).find(
                        (formItem) => formItem.name === key
                    );
                    if (!formItem.normalizer) return obj;

                    const normalizedValue = formItem.normalizer(value);

                    return {
                        ...obj,
                        [key]: normalizedValue,
                    };
                },
                values
            );

            resolve(normalizedFields);
        });

    const validateField = async (value, field) => {

        const { validators, required, name } = field;

        for (const validator of validators) {

            const isPatternValid = validator?.pattern?.test(value) ?? true;

            const isCustomValid = (validator?.validate && value) ? await validator.validate(value, formValues) : true;

            if (!isPatternValid || !isCustomValid) {
                setInvalidFields(prev => ({
                    ...prev,
                    [name]: field.validator.message,
                }));
                return false;
            }

            if (required && !value) {
                setInvalidFields(prev => ({
                    ...prev,
                    [name]: "Campo obrigatório!",
                }));
                return false;
            }
        }

        return true;
    }

    const validateFields = (values) =>
        new Promise(async (resolve, reject) => {

            setInvalidFields({});

            const invalidFieldsFound = await Object.values(formFields).reduce(async (arr, field) => {

                const invalidFields = await arr;

                const value = values[field.name];

                const isValid = await validateField(value, field);

                if (!isValid) {
                    return [
                        ...invalidFields,
                        field.name,
                    ];
                }

                return invalidFields;
            }, Promise.resolve([]));

            if (Object.entries(invalidFieldsFound).length) {
                reject();
            }

            resolve(values);
        });

    const handleSubmit = (event) => {
        event?.preventDefault();

        normalizeFields(formValues)
            .then((normalizedValues) => validateFields(normalizedValues))
            .then((fields) => onFinish(fields))
            .catch(() => { })
    };

    useImperativeHandle(form.form, () => ({
        resetFields: () => {
            setFormValues({});
        },
        getFieldsValue: () => {
            return formValues;
        },
        getFieldValue: (fieldName) => {
            return formValues[fieldName];
        },
        setFieldValue: (name, value) => {
            setFormValues(prevValues => ({ ...prevValues, [name]: value }));
        },
        submit: () => {
            handleSubmit();
        },
        validateFields: () => {
            return validateFields(formValues);
        }
    }));

    return (
        <form onSubmit={handleSubmit} {...props}>
            <FormContext.Provider value={{ registerField, registerFieldValue, invalidFields, formValues }}>
                {children}
            </FormContext.Provider>
        </form>
    );
};
