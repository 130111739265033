import FormField from '../../FormField';

export default function RadioButtonField(
    { value = false, isDisabled, extraInfo, name, label, fieldProps,
        setFieldValue, confirmationButtonLabel, declineButtonLabel, tooltip, onError }
) {

    const handleChange = (name, value) => {
        setFieldValue(name, value == "true");
    }

    const fieldOptions = [
        { name: confirmationButtonLabel || "Sim", value: true },
        { name: declineButtonLabel || "Não", value: false },
    ]

    return (
        <FormField
            {...fieldProps}
            type='radioButton'
            name={name}
            currentValue={value}
            disabled={isDisabled || fieldProps?.disabled}
            helperText={extraInfo}
            field={label}
            tooltip={tooltip}
            onChange={handleChange}
            options={fieldOptions}
            onError={onError}
            titleSize={15}
            titleWeight={600}
            spacing={10}
        />
    );
}
