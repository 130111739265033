function EventEmmiter() {

    var listeners = {};
    var self = this;

    this.on = function (eventName, callback) {
        if (!Array.isArray(eventName)) {
            setListener(eventName, callback);
            return;
        }
        eventName.forEach(
            function (name) { setListener(name, callback) }
        )
    }

    this.emmit = function (eventName, obj) {
        if (!listeners[eventName])
            return;
        listeners[eventName].forEach(
            function (event) { event(obj) }
        )
    }

    var setListener = function (eventName, callback) {
        listeners[eventName] = listeners[eventName] || [];
        listeners[eventName].push(callback);
    }

    this.removeListener = function (eventName, callback) {
        listeners[eventName] = listeners[eventName]
            .filter(function (c) { return c !== callback })
    }

    this.removeListeners = function (eventsNames, callback) {
        eventsNames.forEach(function (eventName) {
            self.removeListener(eventName, callback);
        })
    }

}

export default EventEmmiter