import { Helmet } from "react-helmet";
import { Breadcrumbs, Divider, Link as MaterialLink, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function PageHeader({
    pageTitle,
    title,
    extra,
    navs = [],
    className = '',
}) {

    return (
        <div>
            <div className={`flex justify-between items-center ${className}`}>
                <div>
                    <Helmet title={pageTitle || title} />
                    <Typography variant="h4">{title}</Typography>

                    {!!navs.length && (
                        <div className="mt-2">
                            <Breadcrumbs>
                                {navs.map((nav, index) => (
                                    <MaterialLink key={index} component={NavLink} exact to={nav.href}>
                                        {nav.label}
                                    </MaterialLink>
                                ))}
                            </Breadcrumbs>
                        </div>
                    )}
                </div>

                {extra}
            </div>
            <div className="my-4">
                <Divider />
            </div>
        </div>
    )
}