import React, { useState, useEffect } from "react";

import { Grid, Card, CardContent, TextField, Chip, InputAdornment } from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { Search } from "@material-ui/icons";

import CorrespondentService from '../../../domain/services/correspondent.service';
import { events as eventsLoad } from '../../../domain/services/api';

import LoadingList from "../../LoadingList";
import RatingTable from "./rating.table.component";
import RatingFilter from "./rating.filter.component";
import RatingListMobile from "./ratingListMobile";

const FaciliterRatingList = ({ userId }) => {

    const [feedbacks, setFeedbacks] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [showList, setShowList] = useState(false);

    useEffect(() => list(), []);

    const list = async () => {

        eventsLoad.emmit('onLoadingList', true);

        const res = await CorrespondentService.findFeedbacksFaciliter(userId);
        setFeedbacks([...res.data]);
        setShowList(true);

        eventsLoad.emmit('onLoadingList', false);
    }

    const onChangeList = async (list) => {

        if (!list)
            return;

        setFeedbacks([...list]);
    }

    const getSearch = (search) => {

        let filterList = [...feedbacks];

        if (search)
            filterList = filterList.filter((item) => item.requestId.toString().indexOf(search.toLowerCase()) != -1);

        return filterList;
    }

    const changeSearchText = (value) => setSearchText(value);

    const getScoreChip = () =>
        feedbacks.length > 0 && (
            <Chip label={`Score: ${feedbacks[0].ratingsInformation.score}`}
                style={{
                    marginLeft: '10px', marginTop: '30px', color: 'white', fontWeight: '600',
                    fontSize: '10px', height: '16px', backgroundColor: '#00800082'
                }}
            />
        )

    const getContent = () =>
        showList && feedbacks.length > 0 ? (
            <>
                <div className="md:hidden">
                    <RatingListMobile ratings={getSearch(searchText) || []} />
                </div>
                <div className="hidden md:block">
                    <Card mb={4}>
                        <CardContent style={{ padding: "0", overflow: 'auto' }}>
                            <RatingTable feedbacks={(getSearch(searchText) || [])} />
                        </CardContent>
                    </Card>
                </div>
            </>
        ) : (
            <Card style={{ marginTop: "10px", borderRadius: "0", backgroundColor: "unset" }}>
                <Alert mt={2} mb={1} severity="info">
                    Não há feedbacks nesse período
                </Alert>
            </Card>
        )

    return (
        <React.Fragment>
            <section style={{ display: 'inline-flex', width: "100%", justifyContent: 'flex-end' }}>
                <TextField name="searchOptions" label="Buscar ID" placeholder="Buscar..." fullWidth
                    value={searchText} onChange={(e) => { changeSearchText(e.target.value) }}
                    style={{ marginBottom: "20px" }} my={2}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize="small" style={{ color: "#444444ab" }} />
                            </InputAdornment>
                        ),
                    }}
                />

                <div style={{ marginLeft: "20px", marginTop: "14px" }}>
                    <RatingFilter userId={userId} onChangeList={onChangeList} />
                </div>

                <Chip label={`Total: ${feedbacks.length}`}
                    style={{
                        marginLeft: '10px', marginTop: '30px', color: 'white', fontWeight: '600',
                        fontSize: '10px', height: '16px', backgroundColor: "rgb(55, 111, 208)"
                    }}
                />

                {getScoreChip()}
            </section>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <LoadingList />

                    {getContent()}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default FaciliterRatingList;
