import { useState, useEffect } from "react";

import { Collapse, Grid } from "@material-ui/core";

import FormField from "../../FormField";

import solicitationService from '../../../domain/services/solicitation/solicitation.service';

function SelectArea({
    areaId, selectedServices = [], services = [],
    setValue, isEdit, openEditModal, label = "Área", ...props
}) {

    const [area, setArea] = useState('');
    const [subarea, setSubarea] = useState('');

    const [allAreas, setAllAreas] = useState([]);
    const [availableAreas, setAvailableAreas] = useState([]);
    const [availableSubareas, setAvailableSubareas] = useState([]);

    useEffect(() => findAllAreas(), []);

    useEffect(() => getAreasByServices(), [selectedServices, services, allAreas]);

    const findAllAreas = async () =>
        solicitationService
            .findAllAreas()
            .then(setAllAreas);

    const getAreasByServices = () => {

        if (!selectedServices.length || !allAreas.length || !services.length)
            return;

        const availableAreas = allAreas
            .filter(a => !a.parentId && !selectedServices.find(serviceId => {
                const service = services.find(s => s.id == serviceId);
                return !service?.areaIds?.length ? false : !service.areaIds.includes(a.id);
            }));

        setAvailableAreas(availableAreas);

        findCurrentArea();

        return availableAreas;
    }

    const findCurrentArea = async () => {

        const currentArea = allAreas.find(a => a.id == areaId);

        if (!currentArea) {
            setArea('');
            setSubarea('');
            return;
        }

        getSubareasByMainArea(currentArea.parentId || currentArea.id);

        if (!currentArea.parentId) {
            setArea(currentArea.id);
            return;
        }

        setArea(currentArea.parentId);
        setSubarea(currentArea.id);
    }

    const getSubareasByMainArea = async (newAreaId) => {

        const newAvailableSubAreas = allAreas.filter(a => a.parentId == newAreaId);

        setAvailableSubareas(newAvailableSubAreas);
        return newAvailableSubAreas
    }

    const handleSelectArea = async (f, newAreaId) => {

        if (isEdit) {
            openEditModal({ areaId: newAreaId });
            return;
        }

        setArea(newAreaId);
        setValue("areaId", newAreaId);
        setSubarea('');
        getSubareasByMainArea(newAreaId);
    }

    const handleSelectSubArea = (f, newAreaId) => {
        setSubarea(newAreaId);
        setValue("areaId", newAreaId);
    }

    const showSubareaField = () => !!areaId && !!area && !!availableSubareas.length

    return (
        <Grid item container direction="column">

            <FormField
                field={label}
                type='select'
                {...props.fieldProps}
                required
                currentValue={!!areaId && area}
                onError={props.onError}
                onChange={handleSelectArea}
                name="areaId"
                options={availableAreas.map(a => ({ ...a, value: a.id }))}
                placeholder='Selecione a área da solicitação'
                disabled={props.fieldProps?.disabled || !selectedServices.length || !availableAreas.length}
            />

            <Collapse in={showSubareaField()}>
                <Grid style={{ marginTop: 20 }}>
                    <FormField
                        id='sub-area'
                        field="Sub Área" onChange={handleSelectSubArea}
                        name="areaId" currentValue={subarea}
                        placeholder='Selecione uma sub área' type='select'
                        options={availableSubareas.map(a => ({ ...a, value: a.id }))}
                        {...props.fieldProps}
                    />
                </Grid>
            </Collapse>

        </Grid>
    );
}

export default SelectArea;
