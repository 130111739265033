import api from '../api';
import loginService from '../login.service';

const findQuestions = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/rating/questions` });

const findAnalysisQuestions = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/rating/analysis/questions` });

const findResults = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/rating/results` });

const findAnalysisResults = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/rating/analysis/results` });

const saveResultQuestion = async (result, requestId, members) => {
    await Promise.all(members.map(async (member) =>
        await save(newResultQuestion(result, requestId, member.userId))
    ));
}

const saveAnalysisResultQuestion = async (result, requestId, members) => {
    await Promise.all(members.map(async (member) =>
        await saveAnalysis(newResultQuestion(result, requestId, member.userId))
    ));
}

const save = async (result) =>
    api.post({ type: 'user', service: `requests/${result.requestId}/rating`, data: result });

const saveAnalysis = async (result) =>
    api.post({ type: 'user', service: `requests/${result.requestId}/rating/analysis`, data: result });

const mapQuestionResults = (questions, results) => {
    return questions.map((item) => {
        let questionResult = results.find((result) => result.questionId == item.id);
        if (questionResult)
            item.answer = questionResult.answer;

        return item;
    });
}

const newResultQuestion = (resultQuestion, requestId, userId) => ({
    questionId: resultQuestion.id,
    answer: resultQuestion.answer,
    requestId,
    userId,
    senderUserId: loginService.getUserAuthentication().id,
    justification: resultQuestion.justification
})

export default {
    findQuestions,
    findAnalysisQuestions,
    findResults,
    findAnalysisResults,
    saveResultQuestion,
    saveAnalysisResultQuestion,
    mapQuestionResults
}