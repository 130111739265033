import { createMuiTheme } from "@material-ui/core/styles";
import merge from "deepmerge";
import variants from "./variants";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";
import { THEMES } from "../constants";
import utilsProvider from "../../../components/providers/utils.provider";

const createTheme = (name) => {
    let themeConfig = variants.find((variant) => variant.name === name);


    const createCustomTheme = () => {
        const { r, g, b } = utilsProvider.hexToRgb(name);

        const max = Math.max(r, g, b, 1);
        const step = 255 / (max * 10);

        const shades = Array(...new Array(10)).map((_, index) => {
            const shade = [
                parseInt((index + 1) * step * r),
                parseInt((index + 1) * step * g),
                parseInt((index + 1) * step * b),
            ];

            const hex = utilsProvider.rgbToHex(shade[0], shade[1], shade[2]);

            return hex;
        });

        const customTheme = {
            name,
            palette: {
                primary: {
                    main: shades[8],
                },
                secondary: {
                    main: shades[5],
                },
            },
            header: {
                indicator: {
                    background: shades[6],
                },
            },
            sidebar: {
                header: {
                    background: shades[8],
                },
            },
        };

        return customTheme;

    }

    if (!themeConfig) {
        const customTheme = createCustomTheme();
        themeConfig = merge(
            variants.find(variant => variant.name === THEMES.LIGHT),
            customTheme
        );
    }

    return createMuiTheme(
        {
            spacing: 4,
            breakpoints: breakpoints,
            overrides: overrides,
            props: props,
            typography: typography,
            shadows: shadows,
            palette: themeConfig.palette,
        },
        {
            name: themeConfig.name,
            header: themeConfig.header,
            footer: themeConfig.footer,
            sidebar: themeConfig.sidebar,
        }
    );
};

export default createTheme;
