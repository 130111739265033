import FormField from "../FormField";

import loginService from "../../domain/services/login.service";

function CheckManualLink({ request, onChange, fieldProps }) {

    const handleChange = (name, value) => {
        const newAdditionalInformation = { ...request.additionalInformation, [name]: value }
        onChange('additionalInformation', newAdditionalInformation);
    }

    return (
        <FormField
            {...fieldProps}
            type='checkbox'
            name="isManualLink"
            field="Vinculação Manual"
            currentValue={request.additionalInformation?.isManualLink}
            disabled={!loginService.isInternal()}
            onChange={handleChange}
        />
    );
}

export default CheckManualLink;


