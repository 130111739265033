import loginService from "./login.service";
import userService from "./user.service";

const vapidPublicKey = 'BPYkL5t25uneHJ11jHjKmA4nXnnut7JcEvjaOysEbGnhETZ-u5S_DX-gMsUkuzRz-zYuhI62Ng7xDytXLG7jW8s';

const failedSubscriptionsStorageKey = "@facilita-app/failed-push-subscription-users";

function register() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('Service Worker registered with scope:', registration.scope);
                setAuthenticatedUser()
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
        
    }
}

const setAuthenticatedUser = () => {
    navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage(
            {userId: loginService.getUserAuthentication()?.id},
        );
    });
}

const userLogout = () => {
    navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage(
            {userId: -1},
        );
    });
}

const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;

    const platforms = {
        macos: ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windows: ["Win32", "Win64", "Windows", "WinCE"],
        ios: ["iPhone", "iPad", "iPod"],
    };

    const platformValidators = {
        macos: () => platforms.macos.includes(platform),
        ios: () => platforms.ios.includes(platform),
        windows: () => platforms.windows.includes(platform),
        android: () => /Android/.test(userAgent),
        linux: () => /Linux/.test(platform),
    };

    const os = Object.keys(platformValidators).find(key => platformValidators[key]());

    return os || 'unknown';
}

const getDeviceInfo = () => {
    const userAgent = window.navigator.userAgent;

    const standalone = window.matchMedia('(display-mode: standalone)').matches;

    const platform = getOS();

    const isInstalled = !!(standalone || (platform === 'ios' && !userAgent.match(/Safari/)));

    return { platform, isInstalled };
}


function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const createSubscription = async (subscription) => {
    const user = loginService.getUserAuthentication();
    if (!user) return;

    const userAgent = navigator?.userAgent || null;

    const deviceInfo = getDeviceInfo();

    const data = {
        ...subscription.toJSON(),
        userAgent,
        ...deviceInfo,
    };

    await userService.addWebPushSubscription(user.id, data)

    return { success: true };
}

const storeFailedSubscription = () => {
    const user = loginService.getUserAuthentication();
    const failedSubscriptionUsers = JSON.parse(localStorage.getItem(failedSubscriptionsStorageKey) || '[]');
    localStorage.setItem(failedSubscriptionsStorageKey, JSON.stringify([...failedSubscriptionUsers, user.id]));
}

const removeFailedSubscription = () => {
    const user = loginService.getUserAuthentication();
    const failedSubscriptionUsers = JSON.parse(localStorage.getItem(failedSubscriptionsStorageKey) || '[]');

    const index = failedSubscriptionUsers.findIndex(id => id === user.id);

    const newFailedSubscriptionUsers = [
        ...failedSubscriptionUsers.slice(0, index),
        ...failedSubscriptionUsers.slice(index + 1, failedSubscriptionUsers.length),
    ];

    localStorage.setItem(failedSubscriptionsStorageKey, JSON.stringify(newFailedSubscriptionUsers));
}

const subscribeUser = () => new Promise((resolve, reject) => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        return navigator.serviceWorker.ready
            .then(async (registration) => {
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(vapidPublicKey)
                });

                const { error } = await createSubscription(subscription).catch(err => ({ error: err }));

                if (error) {
                    storeFailedSubscription();
                    throw error;
                }

                removeFailedSubscription();

                resolve({ success: true });
            })
            .catch(error => {
                console.error('Failed to subscribe the user: ', error);
                reject(error);
            });
    }

    resolve({ success: false });
});

const addPwaInfoToSubscription = () => {
    navigator.serviceWorker.ready.then(async (registration) => {
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(vapidPublicKey)
        });

        if (!subscription) return;
        
        const deviceInfo = getDeviceInfo();

        const user = loginService.getUserAuthentication();

        await userService.editWebPushSubscription(user.id, subscription.toJSON().endpoint, deviceInfo);
    })
}

const findSubscriptionEndpoint = () => new Promise((resolve, reject) => {
    return navigator.serviceWorker.ready.then(async (registration) => {
        await registration.pushManager.getSubscription().then(async(subscription)=>{
            
            const user = loginService.getUserAuthentication();

            const {data} = await userService.findSubscriptionEndpoint(user.id, subscription?.toJSON().endpoint)
            
            resolve(data.success)
        })
        .catch(error => {
            reject(error);
        })
    })
})

const serviceWorker = {
    register,
    subscribeUser,
    addPwaInfoToSubscription,
    findSubscriptionEndpoint,
    setAuthenticatedUser,
    userLogout,
};

export default serviceWorker;