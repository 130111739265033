import { Grid } from '@material-ui/core';

import AnalysisFactsFundamentalsForm from './analysis/analysis.facts.fundamentals.component';
import SkeletonLoading from '../SkeletonLoading';

import stepFormFields from './fields/request.fields.config';

import requestFieldsService from '../../domain/services/request/request.fields.service';

const FactsFundamentalsForm = ({ request = {}, stepFields, setValue, isEdit, fieldProps, fieldsWithError, setAlert }) => {

    const dependencyFieldValidation = (field) =>
        (!field.dependencyField || request[field.dependencyField] == 'true' || (((request?.fields || {})[field.dependencyField]?.values || [])[0]?.value == 'true'));

    if (request.referenceAnalysisId)
        return (
            <AnalysisFactsFundamentalsForm values={request} isEdit={isEdit} fieldProps={fieldProps} setAlert={setAlert} />
        )

    return (
        <SkeletonLoading
            isDataLoading={!request.id || !stepFields.length}
            variant='rect' size={{ height: 110 }}
        >
            <Grid
                container spacing={8} direction='column'
                style={requestFieldsService.checkDisableFieldsStyle(request, isEdit)}
            >

                {stepFields
                    .filter(field => dependencyFieldValidation(field))
                    .map(item => ({ ...stepFormFields.getComponentField({ field: item, request, setValue, isEdit, fieldProps, fieldsWithError }) }))
                }

            </Grid>
        </SkeletonLoading>
    );
}

export default FactsFundamentalsForm;