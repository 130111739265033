import { useEffect, useState } from 'react';
import moment from 'moment';

import { Typography, Grid, Divider, Tooltip, Chip } from '@material-ui/core';
import { AccessAlarm, AccessTime, Check, Edit, LaptopMac, ArrowForward } from '@material-ui/icons';

import SkeletonLoading from '../../SkeletonLoading';

import requestServicesService from '../../../domain/services/solicitation/request.services.service';
import loginService from '../../../domain/services/login.service';
import modalities from '../../../domain/constant/modalities.constant';

const serviceTypes = {
    'FROZEN': {
        id: 'frozen',
        label: 'Serviço em espera',
        labelDate: "Criado em: ",
        background: 'black',
        fontColor: 'black',
        icon: <AccessAlarm />
    },
    'IN_PROGRESS': {
        id: 'in_progess',
        label: 'Serviço em andamento',
        labelDate: "Iniciado em: ",
        datePropertyName: "startedServiceAt",
        iconColor: 'primary',
        variant: 'outlined',
        background: 'rgb(55, 111, 208)',
        fontColor: 'white',
        icon: <LaptopMac />
    },
    'CONCLUDED': {
        id: 'concluded',
        label: 'Serviço concluído',
        labelDate: "Concluído em: ",
        datePropertyName: "concludedAt",
        iconColor: 'primary',
        variant: 'default',
        background: '#0FAE2C',
        fontColor: 'white',
        icon: <Check />
    },
    'CORRECTION': {
        id: 'correction',
        label: 'Serviço em correção',
        labelDate: "Correção solicitada em: ",
        datePropertyName: "correctionAt",
        iconColor: 'primary',
        variant: 'outlined',
        background: 'orange',
        fontColor: 'white',
        icon: <Edit />
    },
    'PENDING': {
        id: 'pending',
        label: 'Serviço em pendência',
        labelDate: "Enviado para pendência em: ",
        datePropertyName: "pendingAt",
        iconColor: 'primary',
        variant: 'outlined',
        background: '#fcdc00',
        fontColor: 'black',
        icon: <AccessTime />
    }
}

const RequestServicesTimeline = ({ request, mainRequestId, isLoading, setAlert }) => {

    const [loading, setLoading] = useState(true);

    const [services, setServices] = useState([]);

    useEffect(() => {

        if (!!isLoading)
            return;

        getServices();

    }, [isLoading]);

    const getServices = async () => {

        setLoading(true);

        await requestServicesService.findProductionServices(mainRequestId)
            .then((res) => mapProductionServices(res.data))
            .then(setServices)
            .catch((error) =>
                setAlert(error.response.data.message || "Erro ao obter os serviços")
            )
            .finally(() => setLoading(false));
    }

    const mapProductionServices = (allServices) =>
        allServices.map(item => ({
            ...item,
            serviceName: modalities.notion.id == item.modalityId ? modalities.notion.label : item.serviceName
        }));

    const getDate = (service) => {

        const dateInfo = service[serviceTypes[service.status].datePropertyName];

        if (!dateInfo)
            return null;

        return `${serviceTypes[service.status].labelDate} ${moment(service[serviceTypes[service.status].datePropertyName] || service.created).format("DD/MM/YYYY [às] HH:mm")}`
    }

    return loginService.isInternal() && (
        <>
            <Typography
                color='textSecondary'
                style={{ marginBottom: 20 }}
            >
                <strong>Serviços da solicitação</strong>
            </Typography>

            <SkeletonLoading
                isDataLoading={loading}
                variant='rect' size={{ height: 50 }}
            >
                <Grid container style={{ gap: 15 }}>
                    {services.map((service, index) => (
                        <div style={{ display: 'flex', cursor: 'default' }}>

                            <div style={{
                                display: 'flex', flexDirection: 'column'
                            }}>
                                <div style={{ display: 'flex', gap: 15 }}>
                                    <div
                                        style={{ alignSelf: 'center' }}
                                    >
                                        {serviceTypes[service.status].icon}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>
                                            <Typography variant="h6">
                                                {service.serviceName}
                                            </Typography>

                                            {service.referenceNotionRequestId &&
                                                <Typography variant='caption' color='textSecondary' style={{ fontWeight: '500', fontSize: 12 }}>
                                                    Criado a partir do Parecer ID:
                                                    <a
                                                        href={`/solicitations/${service.referenceNotionRequestId}`}
                                                        style={{ color: 'rgb(55, 111, 208)', textDecoration: 'unset', cursor: 'pointer', marginLeft: 5 }}>
                                                        {service.referenceNotionRequestId}
                                                    </a>
                                                </Typography>
                                            }

                                            <Typography style={{
                                                color: serviceTypes[service.status].background,
                                                fontWeight: '500'
                                            }}>
                                                {serviceTypes[service.status].label}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 37 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {getDate(service)}
                                    </Typography>

                                    {(service.members || []).map((item, index) => (
                                        <Tooltip title={item.name} key={index} style={{ zIndex: "999999", cursor: "pointer" }}>
                                            <Chip size="small" label={item.percentageInteracted ? `Analisou ${item.percentageInteracted}% dos documentos` : item.name} variant="outlined"
                                                avatar={
                                                    <div style={{ padding: '5px', borderRadius: '100%', color: '#fff', background: '#5584AC', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {loginService.getNameInitials(item.name)}
                                                    </div>
                                                }
                                                style={{ fontWeight: "500", background: "none", color: '#5584AC', border: '0', marginBottom: "8px" }}
                                            />
                                        </Tooltip>
                                    ))}

                                    {(service.faciliterReviewers || []).map((item, index) => (
                                        <>
                                            <br />
                                            <Tooltip title={item.name} key={index} style={{ zIndex: "999999", cursor: "pointer" }}>
                                                <Chip size="small" label={item.percentageInteracted ? `Analisou ${item.percentageInteracted}% dos documentos` : item.name} variant="outlined"
                                                    avatar={
                                                        <div style={{ padding: '5px', borderRadius: '100%', color: '#fff', background: '#8479E1', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {loginService.getNameInitials(item.name)}
                                                        </div>
                                                    }
                                                    style={{ fontWeight: "500", background: "none", color: '#8479E1', border: '0', marginBottom: "8px" }}
                                                />
                                            </Tooltip>
                                        </>
                                    ))}
                                </div>
                            </div>

                            {index < services.length - 1 && (
                                <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                                    <ArrowForward style={{ width: 50 }} />
                                </div>
                            )}

                        </div>
                    ))}
                </Grid>
            </SkeletonLoading>

            <Divider style={{ marginTop: 20 }} />
        </>
    );
}

export default RequestServicesTimeline;
