import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import PetitionModelDocuments from '../clients/petition.model.documents.component';
import SkeletonDocuments from '../solicitations/skeleton.documents.component';
import TextFormatRequirement from '../solicitations/text.format.requirement.component';

const EmptyComponent = ({ description }) => (
    <Alert severity='info'>
        {description}
    </Alert>
)

const steps = [
    {
        title: 'Esqueletos de petição',
        component: (request) => (
            <SkeletonDocuments
                area={request.area}
                modality={request.modality}
                emptyComponent={
                    <EmptyComponent
                        description='Ainda não há esqueletos de petição cadastrados.'
                    />
                }
            />
        )
    },
    {
        title: 'Timbrados',
        component: (request) => (
            <PetitionModelDocuments
                customerId={request.customerId}
                hideHeader
                emptyComponent={
                    <EmptyComponent
                        description='Ainda não há timbrados cadastrados.'
                    />
                }
            />
        )
    },
    {
        title: 'Critérios de formatação',
        component: (request) => (
            <TextFormatRequirement
                customerId={request.customerId}
                isOpen
                emptyComponent={
                    <EmptyComponent
                        description='Ainda não há critérios de formatação cadastrados.'
                    />
                }
            />
        )
    },
]

export default function ReferencesPetition({ request, isSmall }) {

    return (
        <Grid container direction='column'>
            {steps.map((item, index) => (
                <Grid
                    key={index} item spacing={isSmall ? 3 : 10}
                    direction={isSmall ? 'column' : 'row'}
                    justifyContent='space-between'
                    style={{ marginBottom: 30 }} container
                >

                    <Grid item xs={isSmall ? 12 : 4}>

                        <Typography variant='h4' gutterBottom>
                            {item.title}
                        </Typography>

                        <Typography style={{ color: 'gray' }}>
                            {item.description && item.description(request)}
                        </Typography>

                    </Grid>

                    <Grid item xs={isSmall ? 12 : 7} container>
                        <Card style={{ width: '100%' }}>
                            <CardContent>
                                <Grid style={{ padding: 10 }}>
                                    {item.component(request)}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            ))}
        </Grid>
    );
}
