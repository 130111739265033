import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const FacilitaSnackbar = ({ alert, onClose }) => (
    <Snackbar
        onClose={onClose} open={alert.isOpen}
        anchorOrigin={alert.anchorOrigin || { vertical: 'top', horizontal: 'center' }}
        key={alert.key} autoHideDuration={alert.duration || 2500}
    >
        <Alert severity={(alert.severity || "success")}>
            {alert.text}
        </Alert>
    </Snackbar>
);

export default FacilitaSnackbar;
