import { get, post } from '../../services/api';

const baseUrl = 'requests/fields';

const createFieldOption = async (fieldId, data) =>
    post({ type: 'user', service: `requests/registration/field/${fieldId}/option`, data });

const transcribeAudioField = async (requestId, fieldId) =>
    post({ type: 'user', service: `${baseUrl}/${requestId}/audio-transcription/${fieldId}` });

const findMissingByRequestId = async (requestId) =>
    get({ type: 'user', service: `${baseUrl}/${requestId}/missing-fields` });

export default {
    createFieldOption,
    transcribeAudioField,
    findMissingByRequestId
}