import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Tooltip, Menu, IconButton as MuiIconButton, Badge } from "@material-ui/core";

import { Notifications } from "@material-ui/icons";
import NotificationList from "./notifications/notification.list.component"
import notificationService from '../domain/services/notification/internal.notification.service';
import LoginService from '../domain/services/login.service';


const IconButton = styled(MuiIconButton)`
  svg {
    width: 32px;
    height: 32px;
  }
`;

function Notification() {

    const [notifications, setNotifications] = useState([]);
    const [anchorMenu, setAnchorMenu] = useState(null);

    const trackRealTimeChangesByUser = (collection, userId, updateFunction) =>
        notificationService.trackRealTimeChangesByUser(collection, userId, updateFunction)

    const list = async () => {

        const res = await notificationService.getNewsByUser(LoginService.getUserAuthentication().id);

        res.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? -1 : ((parseInt(b.id) > parseInt(a.id)) ? 1 : 0))

        setNotifications(res)
    };


    useEffect(() => {

        list()
        trackRealTimeChangesByUser('notifications', LoginService.getUserAuthentication().id, list)

    }, []);

    const toggleMenu = (event) =>
        setAnchorMenu(event.currentTarget);

    const closeMenu = () =>
        setAnchorMenu(null);

    return (
        <React.Fragment>
            <Tooltip title='Notificações' placement="left">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                    style={{ marginRight: 5 }}
                >
                    <Badge badgeContent={notifications.length} overlap="circular" color="error">
                        <Notifications
                            fontSize="small" alt='Notifications' src="/static/img/avatars/avatar-1.jpg"
                            style={{ width: 28, height: 28 }}
                        />
                    </Badge>
                </IconButton>
            </Tooltip >
            <Menu
                style={{ marginTop: '40px', border: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={(notifications.length > 0) ? Boolean(anchorMenu) : false}
                onClose={closeMenu}
            >
                <NotificationList notifications={notifications} />
            </Menu>
        </React.Fragment>
    );
}

export default Notification;
