import api from '../api';
import axios from 'axios';

import { events } from '../api';

import stepsSolicitation from '../../constant/steps.solicitation.constant';
import { areas } from '../../constant/area.constant';
import modalities from '../../constant/modalities.constant';

import loginService from '../login.service';

const fileDownload = require('js-file-download');

const find = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/documents` }).catch((err) => {
        console.error('document:service', err);
        return [];
    });

const findServiceDocuments = async (requestId) =>
    api.get({ type: 'user', service: `requests/${requestId}/documents/service` });

const findServiceDocumentBySenderUserId = async (requestId, serviceId, userId) =>
    api.get({ type: 'user', service: `requests/${requestId}/documents/service/${serviceId}/user/${userId}` });

const checkUpload = async (requestId, payload, notifyOnError) =>
    api.post({
        type: 'user',
        service: `requests/${requestId}/documents/check-upload?notifyOnError=${notifyOnError}`,
        data: { payload },
    });

const saveAll = async (requestId, files, showProgress = true) => {

    if (!files || files.length == 0) return;

    const documents = await getSignedRequest(requestId, files);

    return await requestFiles(files, documents || [], showProgress);
};

const getSignedRequest = async (requestId, files) => {

    const data = files.map(item => getDocument(item));

    return await api
        .post({ type: 'user', service: `requests/${requestId}/documents`, data })
        .then(({ data }) => data);
}

const update = async (document) =>
    api.put({
        type: 'user',
        service: `requests/${document.requestId}/documents/${document.id}`,
        data: document,
    });

const remove = async (document) =>
    api.remove({
        type: 'user',
        service: `requests/${document.requestId}/documents/${document.id}`,
    });

const getDocument = (item) => ({
    name: item.name,
    fileSize: item.size,
    fileType: item.type,
    nameType: item.nameType,
    forCorrection: item.forCorrection,
    serviceId: item.serviceId,
    isVisibleCustomer: item.isVisibleCustomer,
    isServiceMainDocument: item.isServiceMainDocument,
    fieldId: item?.fieldId,
    isProcessMainDocument: item?.isProcessMainDocument,
    source: item?.source
});

const uploadFile = (file, signedRequest, item, showProgress = true) =>
    new Promise((resolve, reject) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', signedRequest);

            xhr.upload.onprogress = (e) => {
                const progressData = {
                    total: e.total,
                    loaded: e.loaded,
                    filename: item.document.name,
                    type: 'Upload',
                };

                setProgress(progressData, showProgress);
            }

            xhr.onloadend = () => resolve({ ...item, success: true });

            xhr.send(file);
        } catch (error) {
            reject({ ...item, success: false });
        }
    });

const downloadFile = async (url, filename) => {
    await axios({
        method: 'get',
        url,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) =>
            setProgress({
                total: progressEvent.total,
                loaded: progressEvent.loaded,
                filename,
                type: 'Download',
            }),
    })
        .then((res) => fileDownload(res.data, filename))
        .finally(() =>
            events.emmit('onProgressDeterminate', {
                info: 'Download concluído',
                progress: 100,
                show: false,
            }),
        );
};

const setProgress = (progressData, showProgress = true) => {
    if (!progressData.loaded) return;

    let progress = Math.round((progressData.loaded / progressData.total) * 100);
    events.emmit('onProgressDeterminate', {
        info: `Fazendo ${progressData.type} do arquivo: ${progressData.filename}`,
        progress,
        show: showProgress,
    });
};

const requestFiles = async (files, data, showProgress = true) => {

    events.emmit('onProgressDeterminate', {
        info: 'Verificando os arquivos',
        progress: 0,
        show: showProgress,
    });

    const reponseFiles = await Promise.all(
        data.map((item) => {
            if (!item.document) return;

            let file = files.find((x) => x.name == item.document.name);

            return uploadFile(file, item.signedRequest, item, showProgress).catch((error) => fileError(error));
        }),
    );

    events.emmit('onProgressDeterminate', {
        info: 'Upload concluído com sucesso',
        progress: 100,
        show: false,
    });

    return reponseFiles;
};

const fileError = async (error) => {
    await remove(error.document);
    events.emmit('onProgressDeterminate', { info: 'Falha ao carregar o arquivo', progress: 0, show: false });
    throw error;
}

const uploadListByAreaId = (areaId) => {
    const list = {
        [areas.previdenciario.id]: [
            {
                nameType: 'cnis',
                title: 'Extrato do CNIS',
                required: true,
                modalityIds: [modalities.initial.id]
            },
            {
                nameType: 'ctps',
                title: 'Cópia da CTPS',
                required: true,
                modalityIds: [modalities.initial.id]
            },
            {
                nameType: 'administrativeProcess',
                title: 'Processo Administrativo',
                required: true,
                modalityIds: [modalities.initial.id]
            },
            {
                nameType: 'medicalDocuments',
                title: 'Documentos Médicos',
                required: false,
                modalityIds: [modalities.initial.id]
            },
            {
                nameType: 'witnessStatement',
                title: 'Declaração de Testemunhas',
                required: false,
                modalityIds: [modalities.initial.id]
            },
            {
                nameType: 'stableUnion',
                title: 'Documentos que comprovem união estável ou condição de dependente econômico',
                required: false,
                modalityIds: [modalities.initial.id]
            },
            {
                nameType: 'activitySpecialties',
                title: 'Cópia de PPP ou laudos que comprovem especialidade das atividades desempenhadas',
                required: false,
                modalityIds: [modalities.initial.id]
            }
        ]
    }

    return list[areaId] || []
}

const hasPending = (request, documents = []) => {

    if (request.modality != 'Inicial')
        return false;

    const requiredNameTypes = (uploadListByAreaId(request.areaId) || []).filter(item => item.required).map(item => item.nameType);
    return !requiredNameTypes.every(nameType => documents.some(doc => doc.nameType === nameType));
}

const getMissingDocs = (request, documents = []) => {
    const requiredDocs = (uploadListByAreaId(request.areaId) || []).filter(item => item.required)

    const missing = requiredDocs.filter(requiredDoc => !documents.some(doc => doc.nameType === requiredDoc.nameType))

    return missing.map(item => item.title)
}

const isDocumentRequired = (request, document) => {
    const requiredDocs = uploadListByAreaId(request.areaId).filter(item => item.required)

    return requiredDocs.map(item => item.nameType).includes(document.nameType)
}

const isAllowEnableToCustomer = (req) => {

    const user = loginService.getUserAuthentication();
    const isFaciliterReviewer = (req?.faciliterReviewers || [])[0]?.userId == user.id;

    return loginService.isInternal() || isFaciliterReviewer
}

const showRequiredDocumentConfirmation = (request, doc) =>
    request.status != stepsSolicitation.PENDING
    && request.status != stepsSolicitation.CONCLUDED
    && isDocumentRequired(request, doc)

const showServiceMainDocumentConfirmation = (request, doc) =>
    request.status == stepsSolicitation.CONCLUDED && doc.isServiceMainDocument

const isInCorrection = (request) =>
    request.status == stepsSolicitation.CONCLUDED && (request.isInCorrection || request.status == stepsSolicitation.CORRECTION)

const findByRequestIdAndFieldId = async (requestId, fieldId) =>
    api.get({ type: 'user', service: `requests/${requestId}/documents/${fieldId}` })
        .then(res => res.data || {});

const tagInfoByKey = (key, item, request) => ({
    correction: {
        title: 'Correção',
        color: 'orange',
        message: 'Documento de correção'
    },
    serviceMainDocument: {
        title: item.serviceName,
        color: 'blue',
        message: `Documento principal do serviço de ${item.serviceName}`
    },
    finalVersion: {
        title: 'Versão final',
        color: 'green',
        message: request.status == 'CONCLUDED' ?
            'Esse documento está sendo mostrado para o cliente'
            : 'Esse documento será mostrado ao cliente quando a solicitação for concluída'
    },
    wasDevelopedWithIa: {
        title: 'Revisar e Incluir Jurisprudências',
        color: 'purple',
        message: 'Petição desenvolvida sem a inclusão de jurisprudências'
    },
    internalUpload: {
        title: 'Interno',
        color: 'blue',
        message: 'Esse documento foi anexado por um membro da Facilita Jurídico'
    },
    isVisibleCustomer: {
        title: 'Visível para o cliente',
        color: 'green',
        message: 'Esse arquivo está visível para o cliente na aba de anexos'
    },
    isProcessMainDocument: {
        title: 'Processo',
        color: 'blue',
        message: 'Esse é o documento principal onde contém todos os dados do processo'
    },
    isIAPetitionReview: {
        title: 'Revisada com IA',
        color: 'gray',
        message: 'Essa petição foi revisada com Inteligência Artificial após confecção'
    }
})[key]

const createDocumentInteractionRecord = async (requestId, payload) =>
    api.post({
        type: 'user',
        service: `requests/${requestId}/members/external/document/interaction-record`,
        data:  payload,
    });

export default {
    find,
    checkUpload,
    saveAll,
    update,
    remove,
    downloadFile,
    uploadFile,
    uploadListByAreaId,
    hasPending,
    getMissingDocs,
    isDocumentRequired,
    isAllowEnableToCustomer,
    findServiceDocuments,
    findServiceDocumentBySenderUserId,
    showRequiredDocumentConfirmation,
    showServiceMainDocumentConfirmation,
    isInCorrection,
    findByRequestIdAndFieldId,
    tagInfoByKey,
    createDocumentInteractionRecord
};
