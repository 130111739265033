import { packages } from "../../constant/marketing/product.constant";
import { requestServices } from "../../constant/request.services.constant";

const postProcessCalculations = group => {
    const { services } = group;

    const pricesToGroup = [350, 300];

    const groupedByPrice = services.reduce((servicesObj, service) => {

        if (pricesToGroup.includes(service.price)) {
            if (!servicesObj[service.price]) {
                servicesObj[service.price] = [];
            }

            servicesObj[service.price].push(service);

        } else {
            if (!servicesObj.other) {
                servicesObj.other = [];
            }

            servicesObj.other.push(service);
        }

        return servicesObj;
    }, {});

    const reorganizedServices = Object.entries(groupedByPrice).reduce((servicesArray, [price, services]) => {
        if (price === 'other') {
            servicesArray = servicesArray.concat(services);
        } else if (services.length > 1) {
            servicesArray.push({
                name: "Outros Cálculos",
                price: parseFloat(price),
                services: services,
                serviceKey: 'otherCalculation',
            });
        } else {
            servicesArray.push(services[0]);
        }
        return servicesArray;
    }, []);

    const sortedServices = reorganizedServices.sort((a, b) => {
        if (a.serviceKey === "otherCalculation" && b.serviceKey !== "otherCalculation") {
            return 1;
        }

        if (a.serviceKey !== "otherCalculation" && b.serviceKey === "otherCalculation") {
            return -1;
        }

        return a.price - b.price;
    });

    const newGroup = {
        ...group,
        services: sortedServices
    };

    return newGroup;
};

const groupedServices = [
    {
        name: 'Documentos Jurídicos',
        key: 'legalDocument',
        services: [
            requestServices.PETITION,
            requestServices.NOTION,
            requestServices.CONTRACT,
        ],
    },
    {
        name: 'Análises',
        key: 'analysis-group',
        services: [
            requestServices.ANALYSIS,
            requestServices.HEARINGANALYSIS,
        ],
    },
    {
        name: 'Cálculos',
        key: 'calculations',
        groupCondition: service => !!service.calculationServiceId,
        postProcessing: postProcessCalculations,
    },
    {
        name: 'Entrega no mesmo dia',
        key: 'advances',
        groupCondition: service => !!service.advanceId,
    },
];

const checkServiceInGroup = (service, group) => {
    if (!group.groupCondition) {
        return group.services.includes(service.serviceKey);
    }

    return group.groupCondition(service);
}

const groupPackpageServices = options => {
    const groupedOptions = options.reduce((groupedOpts, option) => {
        const serviceGroup = groupedServices.find(group => checkServiceInGroup(option, group));

        if (!serviceGroup) {
            return [...groupedOpts, option];
        }

        const existingGroupIndex = groupedOpts.findIndex(group => group.serviceKey === serviceGroup.key);

        if (existingGroupIndex === -1) {
            return [
                ...groupedOpts,
                {
                    ...option,
                    price: option.price,
                    name: serviceGroup.name,
                    serviceKey: serviceGroup.key,
                    services: [option],
                }
            ];
        }

        groupedOpts[existingGroupIndex].services.push(option);
        return groupedOpts;

    }, []);

    return groupedOptions;
}

const postProcessServices = options => {
    const postProcessedOptions = options.reduce((groupedOpts, option) => {
        const group = groupedServices.find(serviceGroup => serviceGroup.key === option.serviceKey);

        if (!group?.postProcessing) {
            return [...groupedOpts, option];
        }

        return [
            ...groupedOpts,
            group.postProcessing(option),
        ];
    }, []);

    return postProcessedOptions;
}

const getAdvanceLabel = (opt) => opt.hoursToDueDate ? `Entrega em ${opt.hoursToDueDate} horas` : opt.name;

const formatPackageServiceLabels = options => options.map(opt => {
    if (opt.advanceId) {
        return ({
            ...opt,
            name: getAdvanceLabel(opt)
        })
    }

    if (opt.calculationServiceId) {
        return ({
            ...opt,
            name: `Cálculo ${opt.name}`,
        });
    }

    return opt;
})

const formatPackageServices = options => {
    const optionsWithLabels = formatPackageServiceLabels(options);
    const groupedOptions = groupPackpageServices(optionsWithLabels);
    const postProcessedGroups = postProcessServices(groupedOptions);
    return postProcessedGroups;
}

const getPackagesPercentages = (packs, packagesWithPercentages = [packages.infinity.id]) => {
    const newPackages = packs.map(pack => {
        if (!packagesWithPercentages.includes(pack.id)) {
            return pack;
        }

        const packageServices = (pack.packageServices || []).map(ps => {
            const packagesByPrice =
                packs.filter(p => p.id !== pack.id && parseFloat(p.packagePrice) < parseFloat(pack.packagePrice))
                    .sort((a, b) => parseFloat(b.packagePrice) - parseFloat(a.packagePrice));

            const servicesToCompare = packagesByPrice[0].packageServices;
            const serviceToCompare = servicesToCompare.find(s => s.serviceKey === ps.serviceKey);

            if (!serviceToCompare)
                return ps;

            const difference = 100 - (100 / serviceToCompare.price * ps.price);

            if (difference <= 0)
                return ps;

            return {
                ...ps,
                percentageDiscount: parseInt(difference)
            };
        });

        return {
            ...pack,
            packageServices,
        };
    })

    return newPackages;
}

export const mapPackageOptions = (packs, options = {}) => {
    const packagesWithGroupedServices = packs.map(packageOption => ({
        ...packageOption,
        packageServices: formatPackageServices(packageOption.packageServices),
    }))
    const packagesWithPercentages = getPackagesPercentages(packagesWithGroupedServices, options.packagesWithPercentages);
    return packagesWithPercentages;
}

const PackageCardService = {
    mapPackageOptions,
}

export default PackageCardService;