
import React from "react";

import Async from "../../components/Async";

import { AccountBalance, AttachMoney, Bookmark, Dashboard, Group, HomeOutlined, Person } from "@material-ui/icons";

import PurchaseThank from "../../pages/customer/purchaseThank/purchaseThank";
import subscriptionService from "../../domain/services/financial/subscription.service";

const AuthGuard = Async(() => import("../auth.guard"));

const Client = Async(() => import('../../pages/admin/clients/client'));
const CustomerStatistics = Async(() => import('../../pages/customer/statistics'));
const CustomerBalance = Async(() => import('../../pages/customer/balance'));
const CustomerSubscription = Async(() => import('../../pages/customer/customer.subscription'));
const CustomerProfileView = Async(() => import("../../pages/profile/customer.profile"));
const CustomerUser = Async(() => import("../../pages/customer/customer.user"));
const Home = Async(() => import("../../pages/customer/home"));

//external register
const ExternalClient = Async(() => import('../../pages/admin/clients/external-client'));
const ExternalCustomerUser = Async(() => import('../../pages/admin/clients/external.customer.user'));

const routes = {
    customers: {
        id: 'customers',
        name: 'Clientes',
        path: '/customers',
        component: Client,
        guard: AuthGuard,
        icon: <AccountBalance />,
        roles: ['admin', 'operator', 'external-seller']
    },
    home: {
        id: 'home',
        name: 'Início',
        path: '/home',
        component: Home,
        icon: <HomeOutlined />,
        guard: AuthGuard,
        roles: ['customer', 'customer-user-operator', 'customer-user-admin'],
    },
    customerStatistics: {
        id: 'customerStatistics',
        name: 'Estatísticas',
        path: '/statistics',
        component: CustomerStatistics,
        icon: <Dashboard />,
        guard: AuthGuard,
        roles: ['customer', 'customer-user-operator', 'customer-user-admin'],
    },
    customersFinance: {
        id: 'customersFinance',
        name: 'Meus Saldos',
        path: '/customer/balance',
        component: CustomerBalance,
        guard: AuthGuard,
        icon: <AttachMoney />,
        roles: ['customer', 'customer-user-admin']
    },
    customerSubscription: {
        id: 'customerSubscription',
        name: 'Assinatura',
        path: '/customer/subscription',
        component: CustomerSubscription,
        guard: AuthGuard,
        icon: <Bookmark />,
        roles: ['customer', 'customer-user-admin'],
        validator: () => subscriptionService.isSubscribed(),
    },
    customerUser: {
        id: 'customerUser',
        name: 'Equipe',
        path: '/customerTeam',
        component: CustomerUser,
        icon: <Group />,
        roles: ['customer', 'customer-user-admin']
    },
    customerProfile: {
        id: 'customerProfile',
        name: 'Minha conta',
        path: '/profile',
        component: CustomerProfileView,
        disabledMenu: false,
        icon: <Person />,
        roles: ['customer'],
    }
}

const externalRoutes = {
    externalCustomers: {
        id: 'externalCustomers',
        name: 'Clientes',
        path: '/customers/external-new/:uuid?',
        component: ExternalClient,
    },
    externalCustomerUser: {
        id: 'externalCustomerUser',
        name: 'Usuário',
        path: '/customer/user/:uuid?',
        component: ExternalCustomerUser,
    },
    externalCustomersPurchaseThank: {
        id: 'externalCustomersPurchaseThank',
        name: 'Clientes',
        path: '/customers/external-new/thanks-purchase',
        props: { isRegister: true },
        component: PurchaseThank,
        guard: AuthGuard,
    },
    customersPurchaseThank: {
        id: 'customersPurchaseThank',
        name: 'Clientes',
        path: '/customers/thanks-purchase',
        component: PurchaseThank,
        guard: AuthGuard,
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}