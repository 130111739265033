import React from "react";
import moment from "moment";

import { List, ListItem, Avatar, ListItemIcon, ListItemText, Chip, Grid, Tooltip } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';

const CustomerRatingList = ({ ratings = [], small }) => {

    const getCorrectionTag = (isCorrectionRating) =>
        isCorrectionRating && (
            <Tooltip title='Avaliação feita após correção'>
                <Chip
                    size="small" label='CORREÇÃO' color="primary"
                    style={{ height: 17, fontSize: 10, fontWeight: 600 }}
                />
            </Tooltip>
        )

    const getLegacyAnalysisTag = (isLegacyAnalysis) =>
        isLegacyAnalysis && (
            <Tooltip title='Essa avaliação foi feita para Indicação de Petição no fluxo antigo'>
                <Chip
                    size="small" label='Indicação de Petição' color="secundary"
                    style={{ fontWeight: 600, height: 17, fontSize: 10 }}
                />
            </Tooltip>
        )

    const getRemark = (remark) =>
        remark && (
            <Grid item xs={small ? '12' : true}>
                <span style={{ color: "rgb(0 0 0 / 80%)", wordBreak: "normal" }}>
                    <strong>Observação: </strong>{remark}
                </span>
            </Grid>
        )

    return (
        <List
            component="nav" dense={true}
            style={{
                width: "100%", height: "auto", overflow: "auto",
                borderBottom: "1px solid #e0e0e0", display: "block"
            }}
        >
            {
                ratings.map((item, index) => (
                    <ListItem key={index} button style={{ paddingBottom: 10 }}>
                        <Grid
                            spacing={3} alignItems="center"
                            justifyContent="center" container
                        >
                            <Grid item container sm={small ? true : '4'} xs={6} alignItems='center'>
                                <Tooltip title={item.customerName}>
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                        <Avatar
                                            alt={item.customerName}
                                            src="/static/img/avatars/avatar-1.jpg"
                                            style={{
                                                width: 24, height: 24,
                                                fontSize: 10, fontWeight: 600
                                            }}
                                        />
                                    </ListItemIcon>
                                </Tooltip>

                                <Grid>
                                    <ListItemText>
                                        {moment(item.createdAt).format("DD/MM/YYYY - HH:mm")}
                                    </ListItemText>

                                    {getCorrectionTag(item.isAfterCorrection)}

                                    {getLegacyAnalysisTag(item.isLegacyAnalysis)}
                                </Grid>
                            </Grid>

                            <Grid item md={3} sm={3} xs={6} container>
                                <Grid container direction="column" alignItems="center">
                                    <Rating value={parseFloat(item.value || 0)}
                                        precision={0.5} readOnly size="small" />

                                    <Chip
                                        size="small" label={item.value}
                                        style={{
                                            color: 'white', fontWeight: 600,
                                            marginTop: 2, fontSize: 10,
                                            backgroundColor: '#00800082', height: 17
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {getRemark(item.remark)}
                        </Grid>
                    </ListItem>
                ))
            }
        </List >
    )
}

export default CustomerRatingList;