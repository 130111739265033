import { useState } from "react";

export default function BlogEditorMobileSidebar({
    options = [],
    onSelect,
    selectedOption,
}) {

    const [open, setOpen] = useState(false);

    const selectedOptionData = options.find(opt => opt.id === selectedOption);

    const handleSelect = (e, id) => {
        e.stopPropagation();
        onSelect(id);
        setOpen(false);
    }

    const renderSidebarOption = (option) => {

        const isSelected = selectedOption === option.id;

        return (
            <div
                key={option.id}
                className={`px-3 py-3 rounded ${isSelected ? 'bg-[#0001] text-[#131313]' : 'bg-transparent text-[#434343]'} hover:bg-[#0001] hover:cursor-pointer flex justify-center items-center gap-3`}
                style={{ transition: 'background-color .3s ease' }}
                onClick={e => handleSelect(e, option.id)}
            >
                <div className="text-[#555] translate-y-[0px]">
                    {option.icon}
                </div>
                <span className="text-sm">{option.label}</span>
            </div>
        )
    }

    return (
        <>
            <button
                className="z-20 relative w-full bg-[#555] border border-[#ededed] text-white rounded-lg py-4 flex justify-center items-center gap-3 hover:bg-[#666]"
                style={{ boxShadow: 'inset 0 4px 4px -4px #fff7, inset 0px -6px 4px -4px #0007' }}
                onClick={() => setOpen(prev => !prev)}
            >
                <div className="text-white translate-y-[0px]">
                    {selectedOptionData.icon}
                </div>
                <span className="text-sm">{selectedOptionData.label}</span>

                <div
                    className="absolute z-50 top-full mt-4 w-full p-4 bg-white border border-[#dedede] rounded shadow flex flex-col gap-1"
                    style={{
                        pointerEvents: open ? 'all' : 'none',
                        opacity: open ? 1 : 0,
                        transformOrigin: 'top',
                        transform: open ? 'scaleY(1)' : 'scaleY(0.85)',
                        transition: 'opacity .2s ease, transform .2s ease',
                    }}
                >
                    {options.map(renderSidebarOption)}
                </div>
            </button>
            <div className="z-10 fixed left-0 top-0 w-full h-screen pointer-events-none"
                style={{
                    backdropFilter: open ? 'blur(4px)' : 'blur(0px)',
                    transition: 'backdrop-filter .3s ease'
                }}
            />
        </>
    )
}