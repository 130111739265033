import { useState, useEffect } from "react";

import swal from 'sweetalert';
import moment from "moment";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Settings } from "@material-ui/icons";

import FormField from "../FormField";
import LoadingProgress from "../LoadingProgress";
import CheckManualLink from "./check.manual.link.component";

import requestValidator from "../../domain/validators/request.validator";
import { stepsSolicitation } from "../../domain/constant/steps.solicitation.constant";

import loginService from "../../domain/services/login.service";
import solicitationService from "../../domain/services/solicitation/solicitation.service";

const LinkSettingsForm = ({ request, onSave, onCancel, onRefreshRequest, setAlert }) => {

    const [open, setOpen] = useState(true);

    const [requestValue, setRequestValue] = useState({ ...request });

    const [operationalDueDateSuggested, setOperationalDueDateSuggested] = useState(null);
    const [enabledSuggestionDate, setEnabledSuggestionDate] = useState(true);
    const [errorSuggestionDate, setErrorSuggestionDate] = useState("");

    const { promiseInProgress } = usePromiseTracker();

    const fieldStyles = { cleanTitle: true, titleColor: 'black', fontSize: 16 }

    useEffect(() => {

        const operationalDueDate = !requestValidator.isAnalysisModality(request) ?
            request.operationalDueDate : null;

        setRequestValue({ ...request, operationalDueDate });
        getSuggestionDate();

    }, []);

    const getSuggestionDate = async () => {
        await solicitationService
            .getAvailableOperationalDueDate(request.id)
            .then(res => {
                onChangeSettings('operationalDueDate', res.data);
                setOperationalDueDateSuggested(res.data || null);
            }).catch(error => setErrorSuggestionDate(error.response.data.message))
    }

    const verifyToSuggest = () =>
        swal({
            title: "Sugestão Prazo Operacional",
            text: "A sugestão poderá sobreescrever a data, deseja continuar?",
            icon: "warning",
            buttons: {
                confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' },
                cancel: 'Cancelar',
            }
        }).then(res => {
            setEnabledSuggestionDate(!!res);
            getSuggestionDate();
        });

    const suggestDeadline = async (isSuggest) => {

        setEnabledSuggestionDate(isSuggest);

        if (!isSuggest)
            return;

        if (!requestValue.operationalDueDate) {
            getSuggestionDate();
            return;
        }

        verifyToSuggest();
    }

    const onChangeSettings = async (field, value) => {
        const item = { ...requestValue };
        item[field] = value;
        setRequestValue(item);
    }

    const closeModal = () => {
        setOpen(false);
        onCancel();
    }

    const setAlertSaved = () =>
        swal({
            title: "Salvo com sucesso.",
            text: `
                Prazo de entrega do Faciliter: ${moment(requestValue.operationalDueDate).format('DD/MM/YYYY HH:mm')}
            `,
            icon: "success",
            buttons: { cancel: 'Continuar' }
        });

    const conclude = async () => {

        if (!requestValue.operationalDueDate || !isValidDate(requestValue)) {
            setAlert("Informe uma data válida para vincular");
            return;
        }

        trackPromise(
            solicitationService
                .editValue(requestValue.id, mapSettings(requestValue))
                .then(() => onSuccessEdit(requestValue.id))
                .catch((error) => {
                    setAlert(error.response.data.message || "Erro ao mover o serviço para vinculação");
                    onRefreshRequest();
                })
        );
    }

    const onSuccessEdit = async (requestId) =>
        Promise
            .resolve(onSave({ stepName: stepsSolicitation.LINK, requestId }))
            .then(onRefreshRequest())
            .then((res) => {
                setAlertSaved(res);
                closeModal();
            })

    const mapSettings = ({ operationalDueDate, additionalInformation }) => ({
        operationalDueDate: moment(operationalDueDate).format(),
        operationalDueDateSuggested: operationalDueDateSuggested && moment(operationalDueDateSuggested).format(),
        additionalInformation
    });

    const isValidDate = ({ dueDate, operationalDueDate }) =>
        !moment(operationalDueDate).isAfter(moment(dueDate));

    const loading = () =>
        promiseInProgress && (
            <LoadingProgress />
        );

    if (!loginService.isInternal())
        return null;

    return (
        <Dialog
            open={open} onClose={closeModal} maxWidth="xs"
            disableBackdropClick disableEscapeKeyDown fullWidth
        >

            <DialogTitle style={{ paddingBottom: "10px" }}>
                <Settings style={{
                    marginBottom: -4, marginRight: 10,
                    fontSize: 20, color: "#376fd0"
                }} />
                A Vincular - Configurações
            </DialogTitle>

            <DialogContent>

                {loading()}

                {!promiseInProgress && (
                    <section>

                        <section style={{ marginLeft: "-5px", marginBottom: "5px", display: "grid" }}>

                            <FormField
                                type="checkbox" field="Sugerir Prazo"
                                name="enabledSuggestion" {...fieldStyles}
                                currentValue={enabledSuggestionDate} titleSize={17}
                                onChange={(name, value) => suggestDeadline(value)}
                            />

                            <CheckManualLink
                                request={requestValue}
                                onChange={onChangeSettings}
                                fieldProps={{ ...fieldStyles, titleSize: 17 }}
                            />

                            <section style={{ margin: "20px 0 10px 5px" }}>
                                <FormField
                                    {...fieldStyles} fontSize={15}
                                    readOnly spacing={6}
                                    titleColor='gray' titleSize={13}
                                    name="faciliterServiceTypeId"
                                    field="Tipo do Serviço do Faciliter"
                                    currentValue={request.faciliterServiceName}

                                />
                            </section>

                        </section>

                        <FormField
                            type="datetime" name="operationalDueDate"
                            field="Prazo de Entrega do Faciliter" required
                            {...fieldStyles} titleSize={13} titleColor='gray'
                            currentValue={requestValue.operationalDueDate}
                            onChange={(name, field) => {
                                onChangeSettings(name, field);
                                setEnabledSuggestionDate(false);
                            }}
                        />

                        {errorSuggestionDate && (
                            <Alert
                                variant="outlined" severity="error"
                                style={{
                                    fontSize: "11px", marginTop: "10px", padding: "0 10px 0px 10px"
                                }}
                            >
                                {(errorSuggestionDate || 'Não foi possível sugerir um prazo para o operacional. Verifique o tempo restante')}
                            </Alert>
                        )}

                    </section>
                )}

            </DialogContent>

            <DialogActions>

                <Button
                    onClick={closeModal} variant="text"
                    color="primary" disabled={promiseInProgress}
                >
                    Cancelar
                </Button>

                <Button
                    onClick={conclude} variant="text"
                    color="primary" disabled={promiseInProgress}
                >
                    Confirmar
                </Button>

            </DialogActions>

        </Dialog>
    );
}

export default LinkSettingsForm;