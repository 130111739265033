import { useRef, useState } from "react";
import Button from "./Button";
import { CloudUploadOutlined, EditOutlined } from "@material-ui/icons";
import LoadingSpin from "../LoadingSpin";

export default function DragAndDropFile({
    handleFile,
    multiple = false,
    loading = false,
    value,
    isImage = false,
    size = 'medium',
    formId = 'form-file-upload',
    inputId = 'input-file-upload',
}) {

    const inputRef = useRef(null);

    const [dragActive, setDragActive] = useState(false);

    const onFiles = (files) => {
        if (multiple) {
            handleFile(files)
            return;
        }

        handleFile(files[0]);
    }

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onFiles(e.dataTransfer.files);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onFiles(e.target.files);
        }
    };

    const sizesPadding = {
        small: 'py-4',
        medium: 'py-8',
        large: 'py-12',
    }
    
    const iconSize = {
        small: '!w-12 !h-12',
        medium: '!w-16 !h-16',
        large: '!w-20 !h-20',
    }

    const renderUploadField = () => (
        <form
            id={formId}
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
            className="w-full h-full relative"
            style={{ display:(!!value && !loading) ? 'none' : undefined }}
        >
            <input
                ref={inputRef}
                type="file"
                id={inputId}
                multiple={multiple}
                onChange={handleChange}
                className="hidden"
            />
            <label
                htmlFor={inputId}
                // className={dragActive ? "drag-active" : ""}
                className={`w-full h-full flex justify-center items-center rounded ${sizesPadding[size]} text-[#131313] bg-[#00000007] border border-dashed border-[#dedede] hover:border-primary cursor-pointer
                    ${dragActive ? 'border-primary' : ''}
                `}
                style={{ transition: 'border-color .3s ease' }}
            >
                <div className="relative">

                    {loading && (
                        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                            <LoadingSpin size="large" />
                        </div>
                    )}

                    <div
                        className="flex flex-col items-center gap-3"
                        style={{ opacity: loading ? 0 : 1 }}
                    >
                        <CloudUploadOutlined className={`${iconSize[size]} text-[#0003]`} />
                        <p>Arraste e solte os arquivos</p>
                        <Button
                            type="primary"
                            className="upload-button"
                            onClick={() => inputRef.current.click()}
                        >
                            Selecionar Arquivos
                        </Button>
                    </div>
                </div>
            </label>
            {dragActive && (
                <div
                    className="absolute top-0 left-0 w-full h-full"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                />
            )}
        </form>
    )

    const renderUploadedImage = () => (
        <div className="relative w-full h-full border border-[#dedede] bg-[#00000007] rounded p-2 group">
            <img src={value} alt="imagem" className="w-full" />
            <div className="hidden absolute left-2 top-2 bottom-2 right-2 bg-[#0007] cursor-pointer group-hover:flex items-center justify-center gap-6 text-white">
                <label htmlFor={inputId} className="rounded-full hover:bg-[#fff1] p-4 cursor-pointer">
                    <EditOutlined className="!w-12 !h-12" />
                </label>
            </div>
        </div>
    );

    const renderUploadedFiles = () => (
        <div className="relative w-full h-full border border-[#dedede] bg-[#00000007] rounded p-2 group">
            <div className="w-full h-full flex justify-center items-center">
                {value}
            </div>
            <div className="hidden absolute left-2 top-2 bottom-2 right-2 bg-[#0007] cursor-pointer group-hover:flex items-center justify-center gap-6 text-white">
                <label htmlFor={inputId} className="rounded-full hover:bg-[#fff1] p-4 cursor-pointer">
                    <EditOutlined className="!w-12 !h-12" />
                </label>
            </div>
        </div>
    )

    return (
        <div className="w-full h-full">
            {renderUploadField()}
            {(!!value && isImage && !loading) && renderUploadedImage()}
            {(!!value && !isImage && !loading) && renderUploadedFiles()}
        </div>
    )
}