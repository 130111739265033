export default function TableOfContentsMobile({
    tableOfContents = []
}) {
    return (
        <>
            <div className="text-base font-semibold text-[#131313cc]">
                Sumário
            </div>
            <div className="mt-4 text-sm flex flex-col gap-3">
                {tableOfContents.map((item, index) => (
                    <div
                        key={index}
                        className=""
                        style={{
                            paddingLeft: (item.level - 1) * 12,
                        }}
                    >
                        {item.text}
                    </div>
                ))}
            </div>
            <div className="mt-8 w-full h-[1px] bg-[#dedede]" />
        </>
    )
}