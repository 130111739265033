export const stepsTransactions = {
    NOT_CREATED: 'not_created',
    AUTHORIZED: 'authorized',
    CHARGED_BACK: 'charged_back',
    PROCESSING: 'processing',
    WAITING_PAYMENT: 'waiting_payment',
    PENDING_REFUND: 'pending_refund',
    ANALYZING: 'analyzing',
    PENDING_REVIEW: 'pending_review',
    EXPIRED: 'expired',
    PAID: 'paid',
    REFUNDED: 'refunded',
    CHARGEDBACK: 'chargedback',
    REFUSED: 'refused',

    stepTypes: {
        not_created: {
            id: 1, key: 'not_created', value: 'Não Criada', name: 'Não Iniciada', background: "#830B23", color: "#FCFAF9"
        },
        waiting_payment: {
            id: 2, key: 'waiting_payment', value: 'Aguardando', name: 'Aguardando', background: "#FFAE03", color: "#FCFAF9"
        },
        refused: {
            id: 4, key: 'refused', value: 'Recusada', name: 'Recusada', background: "#ed254e", color: "#FCFAF9"
        },
        refunded: {
            id: 6, key: 'refunded', value: 'Estorno', name: 'Estorno', background: "#F5A700", color: "#FCFAF9"
        },
        chargedback: {
            id: 7, key: 'chargedback', value: 'Estornado pelo banco', name: 'Estornado pelo banco', background: "#F5A700", color: "#FCFAF9"
        },
        paid: {
            id: 5, key: 'paid', value: 'Paga', name: 'Paga', background: "#88C035", color: "#FCFAF9"
        },
        expired: {
            id: 3, key: 'expired', value: 'Expirada', name: 'Expirada', background: "#4B0614", color: "#FCFAF9"
        }
    },

    subscriptionStatus: {
        active: {
            id: 1, key: 'active', value: 'Ativa', name: 'Ativa', background: "#88C035", color: "#FCFAF9"
        },
        ended: {
            id: 2, key: 'ended', value: 'Finalizada', name: 'Finalizada', background: "#4B0614", color: "#FCFAF9"
        }
    }
};

export const steps = [
    {
        id: 1,
        label: 'Não Iniciada',
        key: stepsTransactions.NOT_CREATED,
        value: stepsTransactions.NOT_CREATED
    },
    {
        id: 2,
        label: 'Aguardando Pagamento',
        key: stepsTransactions.WAITING_PAYMENT,
        value: stepsTransactions.WAITING_PAYMENT
    },
    {
        id: 3,
        label: 'Expirada',
        key: stepsTransactions.EXPIRED,
        value: stepsTransactions.EXPIRED
    },
    {
        id: 4,
        label: 'Recusada',
        key: stepsTransactions.REFUSED,
        value: stepsTransactions.REFUSED
    },
    {
        id: 5,
        label: 'Paga',
        key: stepsTransactions.PAID,
        value: stepsTransactions.PAID
    },
    {
        id: 6,
        label: 'Estorno',
        key: stepsTransactions.REFUNDED,
        value: stepsTransactions.REFUNDED
    },
    {
        id: 6,
        label: 'Estornado pelo banco',
        key: stepsTransactions.CHARGEDBACK,
        value: stepsTransactions.CHARGEDBACK
    }
];



export default {
    stepsTransactions,
    steps,
}