import { createContext, useContext, useEffect, useState } from 'react';
import { THEMES } from '../material-app-pro/src/constants';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as SCThemeProvider } from 'styled-components/macro';
import createTheme from '../material-app-pro/src/theme';
import loginService from '../domain/services/login.service';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {

    const user = loginService.getUserAuthentication();

    const [theme, setTheme] = useState(user?.customer?.theme?.name || THEMES.LIGHT);

    const generatedTheme = createTheme(theme);

    const updateTheme = (newTheme) => {
        if (!newTheme) {
            setTheme(THEMES.LIGHT);
            return;
        }
        
        setTheme(newTheme);
    };

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--color-primary', generatedTheme.palette.primary.main);
        root.style.setProperty('--color-primary-light', generatedTheme.palette.primary.light);
        root.style.setProperty('--color-primary-dark', generatedTheme.palette.primary.dark);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, updateTheme }}>
            <MuiThemeProvider theme={generatedTheme}>
                <SCThemeProvider theme={generatedTheme}>
                    {children}
                </SCThemeProvider>
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
