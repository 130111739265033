import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import ExternalRegisterHeader from "../components/ExternalRegisterHeader";
import ExternalRegisterFooter from "../components/ExternalRegisterFooter";

import { spacing } from "@material-ui/system";
import {
    CssBaseline,
    Paper as MuiPaper,
    withWidth,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: #F7F9FC !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const ExternalRegisterLayout = ({ children, routes, width }) => {
    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <AppContent>
                <ExternalRegisterHeader />
                <MainContent p={isWidthUp("lg", width) ? 12 : 6}>
                    {children}
                </MainContent>
                <ExternalRegisterFooter />
            </AppContent>
        </Root>
    );
};

export default withWidth()(ExternalRegisterLayout);
