import Form from "../../../shared/form/Form";
import FormItem from "../../../shared/form/FormItem";
import Input from "../../../shared/Input";
import SaveChangesFooter from "./saveChangesFooter";
import Card from "../../../shared/Card";
import Row from "../../../shared/grid/Row";
import Col from "../../../shared/grid/Col";
import { useBlog } from "../../../../context/marketing/blogContext";
import useForm from "../../../../hooks/useForm";
import useRequest from "../../../../hooks/useRequest";
import BlogsService from "../../../../domain/services/marketing/blog.service";
import LoadingSpin from "../../../LoadingSpin";
import SnackbarAlert from "../../../shared/SnackbarAlert";
import Formatter from "../../../../domain/utils/Formatter";
import { Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";

export default function SeoConfig() {

    const form = useForm();

    const { blogData, updateChanges, saveChanges, changes, loading } = useBlog();

    const [findBySlug, findingBySlug] = useRequest(BlogsService.findBySlug);

    const initialValues = {
        slug: blogData?.slug ? blogData.slug.replace(`-${blogData.id}`, '') : null,
        metaTitle: blogData?.seoInformation?.metaTitle || null,
        metaDescription: (blogData?.seoInformation?.metaDescription) || null,
    };

    const updateSeoInfo = (formValues) => {
        updateChanges('seoInformation', {
            metaTitle: formValues.metaTitle,
            metaDescription: formValues.metaDescription,
        });
    }

    const onChange = {
        metaTitle: (_, formValues) => updateSeoInfo(formValues),
        metaDescription: (_, formValues) => updateSeoInfo(formValues),
        slug: (value) => updateChanges('slug', `${value.trim()}-${blogData.id}`),
    }

    const handleChange = (changedFields, formValues) => {
        const [key, value] = Object.entries(changedFields)[0];
        onChange[key](value, formValues);
    }

    const handleSave = () => {
        form.submit();
    }

    const handleSubmit = () => {
        saveChanges()
            .then(() => SnackbarAlert.success('Alterações salvas com sucesso!'));
    }

    const renderFormItemLabel = (label, tooltip) => (
        <div className="flex gap-2 items-center">
            <span>
                {label}
            </span>

            <Tooltip title={<span className="text-xs">{tooltip}</span>} placement="top">
                <HelpOutline style={{ color: '#0007', fontSize: 16, transform: 'translateY(1px)' }} />
            </Tooltip>
        </div>
    )

    return (
        <div className="w-full">
            <Card title="Configurações Principais">
                <div className="w-full">
                    <Form
                        form={form}
                        initialValues={initialValues}
                        onFieldChange={handleChange}
                        onFinish={handleSubmit}
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24} lg={12} xl={12}>
                                <FormItem
                                    name="metaTitle"
                                    label={renderFormItemLabel("Meta Título", 'O meta título é utilizado pelos motores de busca para entender o conteúdo da página. Deve ser atraente e não muito longo para melhorar a otimização SEO')}
                                >
                                    <Input placeholder="Insira o meta título" />
                                </FormItem>
                            </Col>
                            <Col span={24} lg={12} xl={12}>
                                <FormItem
                                    name="slug"
                                    label={renderFormItemLabel("Slug", 'O slug é a parte final da URL que identifica a página. Deve ser curto, descritivo e não conter espaços ou caracteres especiais')}
                                    delayTypingValidation
                                    mask={Formatter.formatToSlug}
                                    validators={[{
                                        validate: async value => {
                                            if (value === initialValues.slug)
                                                return true;

                                            const slug = await findBySlug(`${value}-${blogData.id}`).catch(() => null);
                                            return !slug;
                                        },
                                        message: 'Slug já utilizado, por favor escolha outro!'
                                    }]}
                                >
                                    <Input placeholder="Insira o slug" />
                                </FormItem>

                                {findingBySlug && <div className="h-0 translate-y-1"><LoadingSpin size="small" /></div>}

                            </Col>
                            <Col span={24}>
                                <FormItem
                                    name="metaDescription"
                                    label={renderFormItemLabel("Meta Descrição", 'A meta descrição aparece nos resultados de busca como um resumo da página. Ela deve ser clara e persuasiva.')}
                                >
                                    <Input placeholder="Insira a meta descrição" />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card >

            <SaveChangesFooter
                onSave={handleSave}
                showCancel={false}
                hasChanges={!!Object.keys(changes).length}
                loading={loading.editingBlog}
            />
        </div >
    );
}