import { useState, useEffect } from "react";

import { Grid, Typography } from "@material-ui/core";
import FormField from "../../FormField";

import addressService from "../../../domain/services/address.service";
import requestService from "../../../domain/services/request/request.service";

function SelectStateCity({ request: values, setValue, fieldProps, onError }) {

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const isInPersonHearing = [values?.serviceId, values?.mainServiceId].includes(8);

    useEffect(() => {
        load();
        if (isInPersonHearing)
            return findAvailableHearingStates();
    }, []);


    const findAvailableHearingStates = async () => addressService.findAllAvailableStatesByArea(values.areaId).then(({ data }) => {
        const stateSiglas = data.map(districtData => { return { sigla: districtData.state } });
        setStates(stateSiglas || []);
    });

    const load = async () => {

        const stateValue = values?.state;

        if (stateValue) {

            setStates([{ sigla: stateValue }]);

            const cityValue = values.city;

            if (cityValue)
                setCities([{ nome: cityValue }]);
        }

        loadState();
    }

    const loadState = async () => {

        return addressService
            .findStates()
            .then(({ data }) => {

                const states = (data || []);
                setStates(states);

                if (!values?.state)
                    return;

                loadCities(values.state);
            });
    }

    const loadCities = async (value) => {
        if (!isInPersonHearing)
            return addressService
                .findCities(value)
                .then(({ data }) => setCities(data));

        return requestService
            .findAllAvailableCitiesByState(values.id, value)
            .then(({ data }) => {
                const availableCities = data.map(cityData => { return { nome: cityData.city } });
                setCities(availableCities)
            });
    }

    const selectState = (field, value) => {
        setValue(field, value);
        loadCities(value);
    }

    return (
        <Grid item xs={12}>

            <Typography
                variant="subtitle1" gutterBottom
                style={{
                    color: '#131313',
                    fontSize: 15,
                    fontWeight: 600
                }}
            >
                {isInPersonHearing ? 'Qual a comarca que será realizada a Audiência Presencial?' : 'Qual a comarca que será distribuída a ação?'}
            </Typography>
            {isInPersonHearing && (
                <Typography
                    variant="subtitle2" gutterBottom
                    style={{
                        color: '#131313',
                        fontSize: 12,
                        fontWeight: 500
                    }}
                >
                    *São listadas apenas comarcas com faciliters ativos para atender audiências presenciais.
                </Typography>
            )}

            <Grid container spacing={6} style={{ padding: 0 }}>

                <Grid item xs={12} md={6}>
                    <FormField
                        {...fieldProps}
                        type="autocomplete"
                        name="state" field="UF"
                        currentValue={values?.state}
                        placeholder="Selecione a UF"
                        onChange={selectState}
                        options={states.map(st => ({ value: st.sigla, name: st.sigla }))}
                        onError={onError}
                        disableClearable
                        titleColor='#131313'
                        titleSize={15}
                        titleWeight={400}
                        spacing={10}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormField
                        {...fieldProps}
                        type="autocomplete"
                        name="city" field="Cidade"
                        currentValue={values?.city}
                        placeholder="Selecione a cidade"
                        onChange={setValue}
                        options={cities.map(ct => ({ value: ct.nome, name: ct.nome }))}
                        disableClearable
                        disabled={!cities.length}
                        onError={onError}
                        titleColor='#131313'
                        titleSize={15}
                        titleWeight={400}
                        spacing={10}
                    />
                </Grid>

            </Grid>

        </Grid>
    );
}

export default SelectStateCity;
