import { Button, Card, Grid, Typography } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";

const ForbiddenPageCard = ({
    showRedirectButton,
    redirectButtonText,
    redirectTo,
    errorMessage = 'Desculpe, mas parece que você não possui permissão para acessar esta página.'
}) => {

    const defaultRedirectConfig = {
        redirectTo: '/',
        buttonText: 'Voltar para página principal'
    }

    return (
        <Grid>
            <Card style={{ padding: 25 }}>

                <Typography
                    variant="h4" color="secondary"
                    style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}
                >
                    <WarningRounded fontSize="20" style={{ marginRight: 10 }} />
                    {errorMessage}
                </Typography>

                <Typography color="textSecondary">
                    Se considerar isso um erro, aguarde brevemente e tente novamente.
                    Para assistência imediata, contate nosso suporte. Estamos aqui para ajudar!
                </Typography>

                <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
                    {showRedirectButton && (
                        <Button
                            variant='contained' color="primary"
                            href={redirectTo || defaultRedirectConfig.redirectTo}
                        >
                            {redirectButtonText || defaultRedirectConfig.buttonText}
                        </Button>
                    )}
                </Grid>

            </Card>
        </Grid>
    )
}

export default ForbiddenPageCard;