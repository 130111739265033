import api from '../../services/api';

const baseUrl = 'requests/registration';

const initRegistry = async (data) =>
    api.post({ type: 'user', service: `${baseUrl}/`, data });

const update = async (requestId, data) =>
    api.put({ type: 'user', service: `requests/registration/${requestId}`, data });

const findRequestToContinueRegistration = async (customerId) =>
    api.get({ type: 'user', service: `${baseUrl}/registration-continuation/${customerId}` });

const loadFieldsByProcessNumber = async (requestId) =>
    api.post({ type: 'user', service: `${baseUrl}/${requestId}/load-fields-by-process-number` });

export default {
    findRequestToContinueRegistration,
    initRegistry,
    update,
    loadFieldsByProcessNumber
}