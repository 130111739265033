import React, { createContext, useState } from 'react';

const DEFAULT_VALUE = {};

export const DocsContext = createContext(DEFAULT_VALUE);

const CM_TO_POINTS = 28.350001017

export function DocsContextProvider({ children }) {
    const [docEditorContainer, setDocEditorContainer] = useState(null);
    const [currentDocument, setCurrentDocument] = useState(null);
    const [snackbarOptions, setSnackbarOptions] = useState({ open: false, message: '', severity: 'success' });
    const [selectedLetterheadToLoad, setSelectedLetterheadToLoad] = useState(null);
    const [loadLetterheadModalOpen, setLoadLetterheadModalOpen] = useState(false);
    const [customerFormattings, setCustomerFormattings] = useState({});
    const [firstLineIndentation, setFirstLineIndentation] = useState(3 * CM_TO_POINTS);
    const [buttons, setButtons] = useState([]);

    const [showFormattings, setShowFormattings] = useState(false);
    const [showFinalDocuments, setShowFinalDocuments] = useState(false);
    const [showSkeletonDocumentsList, setShowSkeletonDocumentsList] = useState(false);
    const [indentation, setIndentation] = useState(3 * CM_TO_POINTS)

    return (
        <DocsContext.Provider
            value={{
                docEditorContainer,
                setDocEditorContainer,
                currentDocument,
                setCurrentDocument,
                snackbarOptions,
                setSnackbarOptions,
                buttons,
                setButtons,
                selectedLetterheadToLoad,
                setSelectedLetterheadToLoad,
                loadLetterheadModalOpen,
                setLoadLetterheadModalOpen,
                customerFormattings,
                setCustomerFormattings,
                firstLineIndentation,
                setFirstLineIndentation,
                showFormattings,
                setShowFormattings,
                showFinalDocuments,
                setShowFinalDocuments,
                showSkeletonDocumentsList,
                setShowSkeletonDocumentsList,
                indentation,
                setIndentation,
                CM_TO_POINTS
            }}
        >
            {children}
        </DocsContext.Provider>
    );
}
