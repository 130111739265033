import React, { useState } from 'react';

import { makeStyles, Chip, Grid, Typography } from '@material-ui/core';

import requestStatusService from '../../domain/services/solicitation/request.status.service';

import RequestAllServicesDueDate from './request.all.services.due.date.component';
import RequestDetailsActionButtons from './request.details.action.buttons.component';
import CustomerRequestProtocoledButton, { CustomerRequestProtocoledModal } from './customer/customer.request.protocoled.button';
import CustomerRequestFavoriteButton from './customer/customer.request.favorite.button';
import RequestCustomerTipButton from './rating/request.customer.tip.button';
import RequestServiceInformationHearingDateInfo from './request.service.information.hearingDate.info';
import { useTour } from '../../context/tourContext';
import { tours } from '../../domain/constant/tour.constant';

const useStyles = makeStyles((theme) => ({
    actionsContainer: {
        display: 'flex',
        gap: 10,
        '@media (max-width: 599px)': {
            flexDirection: 'column'
        },
        height: 'fit-content',
    }
}));

export default function CustomerRequestServiceInformation({
    mainRequestId, setAlert, setValue, request, refresh, isSmall, setAllowNext, tutorial
}) {

    const { hearingDateTime } = request;

    const { registerRef } = useTour();

    const tourRefs = {
        statusRef: registerRef(tours.requestDetailsTour, 'status'),
        dueDateRef: registerRef(tours.requestDetailsTour, 'dueDate'),
    };

    const [protocoledModal, setProtocoledModal] = useState({ open: false, request: {} });

    const classes = useStyles();

    const fieldProps = {
        titleSize: 12, titleColor: '#87b1ff', fontSize: 14, cleanTitle: true, spacing: 8
    };

    const getCustomerStatusFields = () => {

        const stepRequest = requestStatusService.getStepByKeyAndUserSource(request.status);

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }} ref={tourRefs.statusRef}>
                <Typography style={{ color: '#000000DE' }}>A solicitação se encontra na seguinte etapa</Typography>
                <Typography style={{ color: stepRequest.background, fontWeight: 500, fontSize: 20 }}>{stepRequest.value}</Typography>
            </div>
        )
    }

    const getReferenceNotion = () => {

        if (!request.referenceNotionRequestId)
            return null;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ color: '#000000DE' }}>Solicitação criada a partir do Parecer</Typography>
                <Typography variant='h6' color='primary' style={{ fontWeight: 500, fontSize: 18 }}>
                    <a href={`/solicitations/${request.referenceNotionRequestId}`}
                        style={{ cursor: 'pointer', textDecoration: 'unset', color: 'rgb(55, 111, 208)' }}>
                        Referência do Parecer ID: {request.referenceNotionRequestId}
                    </a>
                </Typography>
            </div>
        )
    }

    const renderHearingDate = (dateTime, isCheckin) => (
        <RequestServiceInformationHearingDateInfo dateTimeInfo={dateTime} isCheckin={isCheckin} />
    )


    return (
        <React.Fragment>
            <div className='flex justify-between flex-col md:flex-row'>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

                    {getCustomerStatusFields()}

                    <div ref={tourRefs.dueDateRef}>
                        <RequestAllServicesDueDate
                            mainRequestId={mainRequestId} request={request}
                            setAlert={setAlert} fieldProps={fieldProps}
                            refresh={refresh} setValue={setValue}
                        />
                    </div>

                    <Grid container alignItems='center'>
                        <Typography style={{ marginRight: 10 }}>
                            Serviço protocolado em:
                        </Typography>

                        <CustomerRequestProtocoledButton
                            protocoledAt={request.protocoledAt}
                            onClick={() => setProtocoledModal({ open: true, request })}
                        />
                    </Grid>

                    {getReferenceNotion()}

                    {!!request.checkIn && renderHearingDate(request.checkIn, true)}

                    {!!hearingDateTime && renderHearingDate(hearingDateTime)}
                </div>



                <div className={classes.actionsContainer}>

                    {request.isInCorrection && (
                        <Chip
                            label='Está em correção' size='small'
                            style={{
                                marginTop: 6,
                                borderRadius: 2, fontWeight: 'bold',
                                backgroundColor: 'orange', color: 'white'
                            }}
                        />
                    )}
                    <Grid>
                        <Grid container spacing={3} direction='column'>
                            {request.correspondentId && (
                                <Grid item xs={12}>
                                    <CustomerRequestFavoriteButton
                                        requestData={request}
                                        setAlert={setAlert}
                                    />
                                </Grid>
                            )}
                            {request.concludedAt &&
                                <Grid item xs={12}>
                                    <RequestCustomerTipButton
                                        requestData={request}
                                        setAlert={setAlert}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid style={{ width: '100%' }}>
                        <RequestDetailsActionButtons
                            mainRequestId={mainRequestId}
                            isSmall={isSmall}
                            request={request}
                            refresh={refresh}
                            setValue={setValue}
                            setAlert={setAlert}
                            setAllowNext={setAllowNext}
                            tutorial={tutorial}
                        />
                    </Grid>
                </div>

            </div>

            <CustomerRequestProtocoledModal
                open={protocoledModal.open}
                handleClose={() => setProtocoledModal(false)}
                request={protocoledModal.request}
                setAlert={setAlert}
                onRefresh={refresh}
            />

        </React.Fragment >
    );
}