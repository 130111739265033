import React, { useEffect, useState } from 'react';
import styled, { withTheme } from "styled-components/macro";

import swal from 'sweetalert';

import {
    Button, Collapse, Dialog, Grid, IconButton, Table, TableBody,
    TableCell, DialogContent, TableHead, TableRow, Typography, TextField,
    FormControl, TableContainer, FormControlLabel, Radio, RadioGroup
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Add, Delete } from '@material-ui/icons';

import FormField from '../../FormField';

import { copyWriter } from './step.legend.config';

import utilsProvider from '../../providers/utils.provider';

import solicitationService from '../../../domain/services/solicitation/solicitation.service';
import loginService from '../../../domain/services/login.service';
import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';
import requestPartService from '../../../domain/services/solicitation/request.part.service';
import stepsSolicitation from '../../../domain/constant/steps.solicitation.constant';
import SimpleModal from '../../shared/SimpleModal';

const TableWrapper = styled.div`
    overflow-y: auto;
    max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

function PartsForm({ request = {}, setRequest, isEdit, setAlert, fieldProps = {} }) {

    const { parts = [] } = request;

    const [partTypes, setPartTypes] = useState([]);

    const [newPartForm, setNewPartForm] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [fieldsWithError, setFieldsWithError] = useState([]);

    useEffect(() => {

        let isSubscribed = true;

        if (isSubscribed)
            getPartTypes();

        return () => (isSubscribed = false);

    }, [request.modalityId]);

    const getPartTypes = async () => {

        setIsLoading(true);

        await solicitationService.getPartTypes(request.modalityId)
            .then(res => {
                if (!isEdit) {
                    (request.parts || []).filter(p => !(res.data || []).some(pt => pt.id == p.partTypeId)).map(part => remove(part, false));
                    setRequest({ ...request, parts: (request.parts || []).filter(p => (res.data || []).some(pt => pt.id == p.partTypeId)) })
                }
                setPartTypes(res.data)
            })
            .catch(() => null)
            .finally(() => setIsLoading(false));
    }

    const changeRequestParts = (newParts) =>
        setRequest({ ...request, parts: newParts });

    const remove = async (partValue, notify = true) => {

        if (!partValue.id) {
            handleClose();
            return;
        }

        await requestRegistryService
            .removePart(partValue)
            .then(() => {
                changeRequestParts([...utilsProvider.removeItemInList(parts, partValue)]);
                notify && setAlert("Parte removida com sucesso!", "success")
            });
    }

    const handleChange = (key, value, part) => {

        if (!part.id) {
            setNewPartForm({ ...newPartForm, data: { ...newPartForm.data, [key]: value } });
            return;
        }

        changeRequestParts([...utilsProvider.changeItemInList(parts, { ...part, [key]: value })]);
    }

    const handleRemove = (part) => {

        swal({
            title: "Excluir Parte",
            text: "Realmente deseja excluir a parte selecionada?",
            icon: "warning",
            buttons: {
                cancel: 'Cancelar',
                confirm: { text: 'Sim', className: 'MuiButton-containedPrimary' }
            }
        }).then(confirm => confirm && remove(part));
    }

    const save = async () => {

        const missingFields = requestPartService.getMissingFieldsToSavePart(newPartForm.data);

        if (missingFields.length > 0) {
            setAlert('Preencha todos os campos para continuar!', 'error');
            setFieldsWithError(missingFields);
            return;
        }

        setIsLoading(true);

        await requestRegistryService
            .createPart(request.id, { ...newPartForm.data, isRepresented: newPartForm.data.isRepresented == 'true' })
            .then((res) => {
                changeRequestParts([...parts, res.data]);
                setAlert("Parte adicionada com sucesso", "success");
                handleClose();
            })
            .catch(error => {
                setAlert(error.response?.data?.message || 'Um erro ocorreu ao tentar salvar a parte! Contate o suporte para que seu problema seja resolvido!', "error")
            })
            .finally(() => setIsLoading(false));
    }

    const handleClose = () => {
        setNewPartForm({});
        setFieldsWithError([]);
    }

    const cantEdit = () => (
        loginService.isFaciliter() ||
        (isEdit && !solicitationService.canAddContent(request))
    );

    const isNotionIndication = (request) =>
        (request.status == stepsSolicitation.NEW && !!request.referenceNotionRequestId)

    const disableStyle = () => {

        if (!cantEdit() || isNotionIndication(request))
            return;

        return { pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' };
    }

    const headerStyles = {
        color: 'gray', fontSize: 10, lineHeight: 1
    }

    const getPartRow = (part = {}, index) => (
        <TableRow key={index}>

            <TableCell
                component="th" scope="row" padding='none'
                style={{ minWidth: 160 }}
            >
                <FormField
                    name='value'
                    readOnly={true}
                    currentValue={part.value}
                    placeholder='Digite o nome da parte'
                    onChange={(name, value) => handleChange(name, value, part)}
                    disabled={fieldProps.disabled} fontSize={12}
                />
            </TableCell>

            <TableCell
                padding='checkbox'
                style={{ minWidth: 90 }}
            >
                <FormField
                    type='select' name='partTypeId'
                    currentValue={part.partTypeId} readOnly={true}
                    options={partTypes.map(pt => ({ name: pt.name, value: pt.id }))}
                    onChange={(name, value) => handleChange(name, value, part)}
                    disabled={fieldProps.disabled} fontSize={12}
                />
            </TableCell>

            <TableCell align="center" padding='checkbox'>
                <Grid container justifyContent='center'>
                    <Typography>{part.isRepresented ? 'Sim' : 'Não'}</Typography>
                </Grid>
            </TableCell>

            <TableCell padding='none'>

                <IconButton
                    variant="text"
                    onClick={() => handleRemove(part, index)}
                    disabled={fieldProps.disabled}
                >
                    <Delete color='error' style={{ fontSize: 15 }} />
                </IconButton>

            </TableCell>

        </TableRow>
    );

    return (
        <React.Fragment>
            <SimpleModal
                showClose={false}
                open={newPartForm.show}
                onClose={handleClose}
                maxWidth="xs"
            >
                <div style={{ marginBottom: 24 }}>
                    <Typography
                        style={{
                            fontSize: 15,
                            fontWeight: 600,
                            color: fieldsWithError.includes('partTypeId') ? '#E01919' : '#000000DE',
                        }}>
                        Selecione o tipo da parte que deseja adicionar
                    </Typography>
                    <FormControl size='small' style={{ width: '100%' }}>
                        <FormControl>
                            <RadioGroup
                                value={newPartForm.data?.partTypeId || ''}
                                onChange={(e) => setNewPartForm({ ...newPartForm, data: { ...newPartForm.data, partTypeId: e.target.value } })}
                            >
                                <div style={{ display: 'flex', gap: 10 }}>
                                    {partTypes.map((partType, index) => (
                                        <div key={partType.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormControlLabel value={partType.id} control={<Radio checked={newPartForm?.data?.partTypeId == partType.id} />} label={partType.name} />
                                            {index != partTypes.length - 1 && <p style={{ marginRight: 5, paddingTop: 2 }}>ou</p>}
                                        </div>
                                    ))}
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </FormControl>
                </div>

                <Collapse in={newPartForm?.data?.partTypeId}>
                    <div style={{ marginBottom: 24 }}>
                        <Typography
                            style={{
                                fontSize: 15,
                                fontWeight: 600,
                                color: fieldsWithError.includes('value') ? '#E01919' : '#000000DE',
                            }}>
                            Qual o nome completo do(a) {(partTypes.find(pt => pt.id == newPartForm?.data?.partTypeId)?.name || '').toLowerCase()} que deseja adicionar?
                        </Typography>
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: 12,
                                fontWeight: 500,
                                marginBottom: 10
                            }}>
                            *Pode qualificar a parte se desejar
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                size="small"
                                fullWidth
                                id="new-part-name"
                                variant="outlined"
                                name='new-part-name'
                                currentValue={newPartForm.data?.value || ''}
                                onChange={(e) => setNewPartForm({ ...newPartForm, data: { ...newPartForm.data, value: e.target.value } })}
                            />
                        </div>
                    </div>
                </Collapse>

                <Collapse in={newPartForm?.data?.partTypeId}>
                    <div className='mb-6'>
                        <FormControl>
                            <div>
                                <Typography
                                    style={{
                                        fontSize: 15,
                                        fontWeight: 600,
                                        color: fieldsWithError.includes('isRepresented') ? '#E01919' : '#000000DE',
                                    }}>
                                    Você representa essa parte?
                                </Typography>
                                <FormControl >
                                    <RadioGroup
                                        value={newPartForm.data?.partTypeId || ''}
                                        onChange={(e) => setNewPartForm({ ...newPartForm, data: { ...newPartForm.data, isRepresented: e.target.value } })}
                                    >
                                        <div style={{ display: 'flex', gap: 10 }}>
                                            <FormControlLabel value={'true'} control={<Radio checked={newPartForm?.data?.isRepresented == 'true'} />} label='Sim' />
                                            <FormControlLabel value={'false'} control={<Radio checked={newPartForm?.data?.isRepresented == 'false'} />} label='Não' />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </FormControl>
                    </div>
                </Collapse>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        gap: 10
                    }}
                >
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color='primary'
                        onClick={save}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Carregando...' : 'Adicionar Parte'}
                    </Button>
                </div>
            </SimpleModal>

            <Grid container direction='column' style={disableStyle()} id='parts'>

                <Grid container direction='column' spacing={2}>

                    <Grid
                        item container
                        justifyContent='space-between'
                    >
                        <div
                            style={{
                                fontSize: 15,
                                fontWeight: 600,
                                alignSelf: 'center',
                            }}
                        >
                            Partes
                        </div>
                        <Button
                            variant="outlined" color='primary'
                            size='small'
                            onClick={() => setNewPartForm({ show: true })}
                        >
                            <Add style={{ marginRight: 5, fontSize: 15 }} />
                            Adicionar
                        </Button>
                    </Grid>

                    <Grid item style={{ width: '100%' }}>

                        <Collapse in={!parts?.length}>
                            <Alert severity='info' style={{ marginTop: 15 }}>
                                {copyWriter.parts[request.modalityKey]?.addButtonLabel || copyWriter.parts.default.addButtonLabel}
                            </Alert>
                        </Collapse>

                    </Grid>

                </Grid>

                <Collapse
                    in={!!parts?.length}
                    style={{ width: '100%' }}
                >
                    <TableContainer style={{ width: '100%' }}>
                        <TableWrapper>
                            <Table size="small" style={{ marginTop: 15 }}>

                                <TableHead>
                                    <TableRow>

                                        <TableCell padding='none' style={headerStyles}>
                                            Nome
                                        </TableCell>

                                        <TableCell style={{ ...headerStyles, padding: '10px 0', minWidth: 75 }}>
                                            Tipo de Parte
                                        </TableCell>

                                        <TableCell
                                            padding='none'
                                            style={{ ...headerStyles, minWidth: 75 }}
                                        >
                                            É representada
                                        </TableCell>

                                        <TableCell style={headerStyles} padding='none'></TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {(parts || []).map((part, index) =>
                                        getPartRow(part, index)
                                    )}

                                </TableBody>

                            </Table>
                        </TableWrapper>
                    </TableContainer>
                </Collapse>

            </Grid>
        </React.Fragment>
    );
}

export default withTheme(PartsForm);