import { get, post, put } from '../../api';

import loginService from '../../login.service';
import modalities from '../../../constant/modalities.constant';

const findByRequestId = async (requestId) =>
    Promise
        .resolve(get({ type: 'user', service: `/requests/${requestId}/notion` }))
        .then(response => response.data);

const findPetitionIndications = async (requestId) =>
    Promise
        .resolve(get({ type: 'user', service: `/requests/${requestId}/notion/services` }))
        .then(response => response.data);

const save = async (data) => {

    if (data.id)
        return await put({ type: 'user', service: `/requests/${data.requestId}/notion/${data.id}`, data: mapNotion(data) });

    return await post({ type: 'user', service: `/requests/${data.requestId}/notion/registration`, data: mapNotion(data) });
}


const cancelServiceReferral = async (requestId, id) =>
    put({ type: 'user', service: `/requests/${requestId}/notion/cancel-service-referral/${id}`, data: [] });


const mapNotion = (values) => {

    const fields = [
        'id',
        'userId',
        'requestId',
        'summary',
        'caseStudy'
    ]

    Object.keys(values).forEach(key => (fields.indexOf(key) < 0) && delete values[key]);

    return { ...values };
}

const canViewForm = (request) =>
    (loginService.isInternal() || loginService.isFaciliter()) && request.modalityId == modalities.notion.id;

export default {
    findByRequestId,
    findPetitionIndications,
    save,
    cancelServiceReferral,
    canViewForm
}