
import { Card, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";

const SimpleLoading = (props = {}) => {

    return (
        <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 18}}>
                <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                    <Typography variant="h6" gutterBottom> {props.title || 'Aguarde'} </Typography>
                    <Typography variant="body2" gutterBottom> {props.message || 'Carregando...'} </Typography>
                </Grid>
        </div>
    );

}

export default SimpleLoading;
