
import { useState, useEffect } from "react";
import {
    Grid, FormControlLabel, Checkbox,
    FormControl, Typography, Select,
    TextField, MenuItem, InputLabel, Button
} from "@material-ui/core";
import { Formik } from 'formik';
import * as Yup from "yup";

const CorrectionForm = ({ initialValues, onSubmit, onClose, isReadOnly, fields }) => {
    
    const validations = fields.reduce((acc, field) => {

        if (field.required) {
            acc[field.key] = Yup.string().required('Campo obrigatório');
        }
        return acc;
    }, {});
    

    const validationSchema = Yup.object().shape(validations);

            const fieldRenderers = {
                checkbox: (field, values, handleChange) => (
                    <Grid item xs={12} key={field.key}>
                        <FormControlLabel
                            label={field.name}
                            control={
                                <Checkbox
                                    name={field.key}
                                    color="primary"
                                    checked={values[field.key] || false}
                                    onChange={handleChange}
                                    disabled={isReadOnly}
                                />
                            }
                        />
                    </Grid>
                ),
                input: (field, values, handleChange, errors, touched, handleBlur) => (
                    <Grid item xs={12} key={field.key}>
                        <Typography variant="body1" style={{ width: "100%", fontWeight: '500', color: 'GrayText', marginBottom: '5px' }}>
                            {field.name}
                        </Typography>
                        <TextField
                            type="text"
                            fullWidth
                            multiline={true}
                            name={field.key}
                            variant="outlined"
                            placeholder={field.placeholder}
                            value={values[field.key] || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched[field.key] && errors[field.key])}
                            helperText={touched[field.key] && errors[field.key]}
                            disabled={isReadOnly}
                        />
                    </Grid>
                ),
                typography: (field) => (
                    <Typography variant="h6" style={{ marginLeft: 10}}>
                        {field.name}
                    </Typography>
                )
            
            };
            
            const renderField = (field, values, handleChange, errors, touched, handleBlur) => {
                const fieldRenderer = fieldRenderers[field.type];
                return fieldRenderer && fieldRenderer(field, values, handleChange, errors, touched, handleBlur);
            };
            
            

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({
                values,
                handleChange,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={6} style={{ marginTop: -8, marginBottom: -8 }}>
                        {fields.map((field) => renderField(field, values, handleChange, errors, touched, handleBlur))}
                        <Grid item xs={12}>
                            <Grid container style={{ gap: 8 }} justify="flex-end">
                                <Grid item>
                                    <Button variant="contained" disabled={isSubmitting} onClick={onClose}>
                                        Fechar
                                    </Button>
                                </Grid>
                                {!isReadOnly && (
                                    <Grid item>
                                        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                                            Enviar
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

export default CorrectionForm;
