import { getFormatCellphone } from "../components/providers/utils.provider";

const getErrorMessage = (error = {}, alertFunction, defaultMessage = '') => {

    const errorMessage = error.response?.data?.message || defaultMessage || 'Ocorreu um erro ao executar a ação!'

    alertFunction(errorMessage, 'error');
}

const sendWhatsAppMessage = (phone) => {
    window.open(`https://api.whatsapp.com/send?phone=55${getFormatCellphone(phone)}&utm_source=whatsapp`, '_blank')
}

export default {
    getErrorMessage,
    sendWhatsAppMessage
};