import { useState, useEffect } from "react";
import swal from "sweetalert";

import { Grid, Button, Fab, Tooltip, Typography, Collapse, Box } from "@material-ui/core";
import { CloseOutlined, GetApp, CloudUploadOutlined } from "@material-ui/icons";

import ProgressDeterminate from '../../ProgressDeterminate';
import SimpleLoading from '../../SimpleLoading';
import CorruptedDocumentsModal from "../../solicitations/corrupted.documents.modal";
import DocumentPreview from "../../solicitations/documents/document.preview.component";
import RequestDocumentList from "./request.document.list.component";
import RequestDocumentsServiceConfigModal from "./request.documents.service.config.modal.component";
import RequestDocumentTypeConfirmationModal from "./request.documents.type.confirmation.modal.component";

import documentsService from "../../../domain/services/solicitation/documents.service";
import loginService from "../../../domain/services/login.service";
import solicitationService from "../../../domain/services/solicitation/solicitation.service";

import communicationProvider from "../../../providers/communication.provider";
import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";
import modalities from "../../../domain/constant/modalities.constant";
import { processDocumentAttachmentOptions } from "../../../domain/constant/request.documents.constant";


const RequestDocumentForm = ({ request, setRequest, resolvePending, setAlert, isEdit, isMainDocumentForm, refreshDocuments }) => {

    const [documents, setDocuments] = useState([]);
    const [uploadList, setUploadList] = useState([]);

    const [preview, setPreview] = useState({ show: false });
    const [isFilesErrorModalOpen, setIsFilesErrorModalOpen] = useState(false);
    const [serviceConfigModal, setServiceConfigModal] = useState({});

    const [isDocumentTypeModalOpen, setIsDocumentTypeModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const canUpload = solicitationService.canUpload(request) || !isEdit;

    const hasSelectedDocs = !!documents.filter(item => item.checked).length;

    const acceptTypeFiles = [
        ".txt", ".doc", ".docx", ".csv", ".pjc", ".xls", ".xlsx", ".ppt", ".pptx",
        ".jpg", ".jpeg", ".png", ".eml", ".msg", ".html", ".pdf", ".mp3", ".mp4",
        ".avi", ".mpeg", ".mpga", ".m4a", ".wav", ".webm", ".ogg", ".opus", ".asf"
    ];

    const acceptTypeFilesToProcess = ["doc", "docx", "pdf"]

    useEffect(() => getAll(request.id), [request.id, refreshDocuments]);

    useEffect(() => {

        let isSubscribed = true

        if (isSubscribed)
            loadUploadList(documentsService.uploadListByAreaId(request.areaId));

        return () => { isSubscribed = false }

    }, [request.areaId, request.modalityId]);

    const getAll = async (requestId) => {

        if (!requestId)
            return;

        setLoading(true);

        const { data } = await documentsService.find(requestId);

        const filteredDocs = data.filter((doc) => !doc.fieldId)
        const docs = (filteredDocs || []);

        setDocuments(docs);

        loadUploadList(
            documentsService
                .uploadListByAreaId(request.areaId)
                .filter(item => docs.length === 0 || !docs.some(doc => doc.nameType === item.nameType))
        );

        setLoading(false);
    }

    const loadUploadList = (value) => {

        if (request.areaId && request.modalityId == 4) {
            setUploadList(value);
            return;
        }

        setUploadList([]);
    }

    const setProcessMainDocument = (selectedFile) => Object.assign(selectedFile, { isProcessMainDocument: true, isVisibleCustomer: true })

    const saveActionByKey = {
        'serviceDocument': (selectedFiles) => {
            handleOpenConfig(selectedFiles[0]);
            return { selectedFiles, isToReturn: true };
        },
        'processMainDocument': (selectedFiles) => {
            selectedFiles = selectedFiles.map(setProcessMainDocument);
            setRequest({ ...request, hasProcessDocument: true, processDocumentAttachment: processDocumentAttachmentOptions.ATTACHED });
            return { selectedFiles, isToReturn: false };
        }
    }

    const modalityRequiresProcessDocument = () => modalities[request.modalityKey]?.processDocumentRequired;

    const showDocumentTypesSelection = (docs) => !!(!splitDocuments.mainProcessDocument(docs).length && (modalityRequiresProcessDocument() || request.processNumber) && (loginService.isFaciliter() || showDocumentsOptionsToCustomer()));

    const shouldChargeBackExtraCredits = (key) => !!(key === 'processMainDocument' && !loginService.isFaciliter() && isEdit);

    const handleSave = (files, key = loginService.isFaciliter() && 'serviceDocument') => {

        let selectedFiles = [...Array.from(files)].reduce(validateFile, []);

        if (!selectedFiles.length) {
            return swal({
                title: "Selecione documentos válidos para o anexo",
                text: `Apenas arquivos com as extensões ${acceptTypeFiles.join(", ")} são permitidos.`,
                icon: "info"
            })
        }

        if (documentsService.isInCorrection(request))
            selectedFiles.map(file => ({ ...file, forCorrection: true }));

        if (key) {
            
            const results = saveActionByKey[key](selectedFiles);

            if (results.isToReturn) 
                return;

            selectedFiles = results.selectedFiles
        }

        if (!loginService.isCustomer())
            selectedFiles.map(file => ({ ...file, isVisibleCustomer: true }));

        setLoading(true);
        saveDocuments(request.id, selectedFiles, null, shouldChargeBackExtraCredits(key));
    }

    const saveDocuments = async (requestId, files, standardName, showProcessDocumentMessage = false) =>
        documentsService
            .saveAll(requestId, files)
            .then(res => checkUpload(requestId, res, files, standardName, showProcessDocumentMessage))
            .then(() => getAll(requestId))
            .catch(err => communicationProvider.getErrorMessage(err, setAlert, "Erro ao salvar! Contate o atendimento."))
            .finally(() => setLoading(false));

    const checkUpload = async (requestId, response, files, standardName, showProcessDocumentMessage = false) => {

        const documentIds = (response || []).map(item => item.document.id);

        if (!documentIds.length)
            return;

        await documentsService
            .checkUpload(requestId, documentIds, false)
            .then(() => {
                setAlert(`${showProcessDocumentMessage ? 'Processo anexado com sucesso!' : 'Upload concluído com sucesso!'}`, "success");
                checkIsPending(files);
            })
            .catch(() => setIsFilesErrorModalOpen(true));

        if (standardName)
            await update(documentIds[0], { name: standardName }, true);

    }

    const validateFile = (files, input) => {

        var fileExtension = input.name.split('.').pop().toLowerCase();

        if (acceptTypeFiles.includes(`.${fileExtension}`)) {
            files.push(input);
            return files;
        }

        return files;
    }

    const onConfirmServiceConfig = async (document, serviceConfig, standardName) => {

        setLoading(true);

        if (document.id)
            return update(document.id, { ...serviceConfig, name: standardName || serviceConfig.name });

        for (const element of Object.keys(serviceConfig))
            document[element] = serviceConfig[element]

        handleCloseConfig();

        return saveDocuments(request.id, [document], standardName);
    }

    const update = async (documentId, serviceConfig, noFeedback) =>
        documentsService
            .update({ ...serviceConfig, id: documentId, requestId: request.id })
            .then(() => {
                !noFeedback && setAlert('Documento editado com sucesso!', 'success');
                getAll(request.id);
                handleCloseConfig();
            })
            .catch(err => communicationProvider.getErrorMessage(err, setAlert))
            .finally(() => setLoading(false));

    const checkIsPending = (newFiles = []) => {

        if (request.status != stepsSolicitation.PENDING || !loginService.isCustomer() || request.isFinancialPending)
            return;

        if (!documentsService.hasPending(request, [...documents, ...newFiles]))
            swal({
                title: 'Resolver Pendências',
                text: `
                    Precisamos da sua colaboração para resolver pendências nesta solicitação. 
                    
                    Por favor, verifique se você já anexou os comentários necessários e/ou inseriu as informações solicitadas pelo analista. Sua pronta resposta é fundamental para prosseguirmos. 
                    
                    Você já tratou todas as pendências existentes? 
                    
                    Agradecemos sua ajuda!
                `,
                icon: 'info',
                buttons: { confirm: { text: 'Sim', className: 'MuiButton-containedPrimary' }, cancel: 'Não' },
                closeOnClickOutside: false,
            }).then(res => (res) && resolvePending(request.id));
    }

    const selectAll = (checked) => {
        let item = [...documents].map(item => ({ ...item, checked }));
        setDocuments(item);
    }

    const selectFile = (index, checked) => {
        let item = [...documents];
        item[index].checked = checked;
        setDocuments(item);
    }

    const downloadAll = async () =>
        Promise.all([...documents].filter(item => item.checked).map(async (item) =>
            documentsService.downloadFile(item.url, item.name)
        ));

    const visibility = async (file, show) => setPreview({ ...file, show });

    const showRemoveConfimation = ({ title, message, doc, index, needExtraCharge = false }) =>
        swal({
            title,
            text: `Realmente deseja remover o documento? ${message}`,
            icon: 'warning',
            buttons: { confirm: { text: 'Remover', className: 'MuiButton-containedPrimary' }, cancel: 'Cancelar' }
        }).then((res) => (res) && removeDocument(doc, index, false))

    const splitDocuments = {
        'mainProcessDocument': (docs) => docs.filter(doc => !!doc.isProcessMainDocument),
        'defaultDocuments': (docs) => isEdit ? docs : docs.filter(doc => !doc.isProcessMainDocument)
    }

    const removeDocument = async (doc, index, checkDocRequired = true) => {


        if (checkDocRequired && documentsService.showRequiredDocumentConfirmation(request, doc)) {

            showRemoveConfimation({
                title: 'Remoção de Documento Obrigatório',
                message: 'A solicitação será retornada para pendência!',
                doc, index
            })

            return;
        }

        if (checkDocRequired && documentsService.showServiceMainDocumentConfirmation(request, doc)) {

            showRemoveConfimation({
                title: 'Remoção de Documento Principal',
                message: 'Esse documento está sendo mostrado para o cliente.',
                doc, index
            })

            return;
        }

        if (checkDocRequired && doc.isProcessMainDocument && isEdit) {

            showRemoveConfimation({
                title: 'Remoção do processo',
                message: `Esse documento é obrigatório, caso seja removido, ${loginService.isCustomer() ? 'vamos buscar por você por um valor de R$20' : 'vamos cobrar R$20 do pacote do cliente'}`,
                doc, index, needExtraCharge: true
            })

            return;
        }

        if (doc.isProcessMainDocument)
            setRequest({ ...request, hasProcessDocument: false, processDocumentAttachment: processDocumentAttachmentOptions.NOT_ATTACHED })

        if (doc.id)
            await documentsService.remove(doc);

        removeInList(index);

        if (documentsService.isDocumentRequired(request, doc))
            getAll(request.id);
    }

    const removeInList = (docIndex) => {
        let item = [...documents];
        item.splice(docIndex, 1);
        setDocuments([...item]);
    }

    const handleOpenConfig = (document, isEdit) =>
        setServiceConfigModal({ open: true, document, isEdit });

    const handleCloseConfig = () =>
        setServiceConfigModal({ open: false });

    const optionsSelectAll = () => {

        const iconsStyle = { marginRight: 10, minHeight: 30, height: 30, minWidth: 30, width: 30 }

        return (
            <Collapse orientation="horizontal" in={hasSelectedDocs}>
                <Grid item>

                    <Tooltip title="Download">
                        <Fab
                            type="button" color="primary"
                            style={iconsStyle} onClick={downloadAll}
                        >
                            <GetApp fontSize="small" style={{ paddingTop: 3 }} />
                        </Fab>
                    </Tooltip>

                    <Tooltip title="Cancelar">
                        <Fab
                            type="button" color="default" style={iconsStyle}
                            onClick={() => selectAll(false)}
                        >
                            <CloseOutlined fontSize="small" style={{ paddingTop: 3 }} />
                        </Fab>
                    </Tooltip>

                </Grid>
            </Collapse>
        );
    }

    const addAttribute = (files, attribute, value) => {

        if (files && files.length > 0)
            files[0][attribute] = value

        return files
    }

    const UploadList = () =>
        uploadList.map(item => (
            isDocumentIncluded(item.nameType) ? null : (
                <div
                    key={item.nameType}
                    style={{
                        padding: '10px', marginTop: '10px', display: 'flex',
                        justifyContent: 'space-between', alignItems: 'center',
                        border: `1px dotted #aaa`, borderRadius: '5px', width: '100%'
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CloudUploadOutlined style={{ marginRight: '10px' }} />
                        <div>
                            <span style={{ marginRight: '10px', fontWeight: '600' }}>
                                {item.title}
                            </span>
                            <span style={item.required ? ({ background: '#F55353', color: '#FFF', padding: '3px', fontSize: '10px', borderRadius: '5px', fontWeight: '600' }) : ({ background: '#4D96FF', color: '#FFF', padding: '3px', fontSize: '10px', borderRadius: '5px', fontWeight: '600' })}>{item.required ? 'obrigatório' : 'opcional'}</span> </div>
                    </div>
                    <span>
                        <Button variant="contained" style={{ padding: 0 }} disabled={!canUpload}>
                            <label style={{ cursor: 'pointer', padding: '6px 12px 6px 12px' }} title={`Anexar ${item.title}`} htmlFor={item.nameType}>Anexar</label>
                        </Button>
                        <input
                            type="file"
                            id={item.nameType}
                            accept={acceptTypeFiles.join(",")}
                            style={{ display: 'none' }}
                            onChange={(e) =>
                                handleSave(addAttribute(e.target.files, 'nameType', item.nameType))
                            }
                        />
                    </span>
                </div>
            )
        ));

    const getInput = ({ id, multiple, disabled, key }) => {

        let documentTypesAvailable = key == "processMainDocument" ? acceptTypeFilesToProcess : acceptTypeFiles

        return (
            <input
                type="file" disabled={disabled}
                style={{ display: 'none' }} id={id}
                multiple={!isEdit || multiple}
                accept={documentTypesAvailable.join(",")}
                onChange={(e) => {
                    isDocumentTypeModalOpen && setIsDocumentTypeModalOpen(false);
                    handleSave(e.target.files, key);
                }}
            />
        )
    }

    const isDocumentIncluded = (nameType) => !!documents.find(doc => doc.nameType === nameType);

    const allowedStatusToCustomerUploadProcessMainDocument = [stepsSolicitation.ANALYSE, stepsSolicitation.LINK, stepsSolicitation.FROZEN, stepsSolicitation.PENDING];

    const showDocumentsOptionsToCustomer = () => loginService.isCustomer() && allowedStatusToCustomerUploadProcessMainDocument.includes(request.status);

    const shouldDisplayProcessMainDocumentType = (docs) => isEdit &&
        (!docs.find(doc => doc.isProcessMainDocument) && request.processDocumentRequired) &&
        (loginService.isFaciliter() || loginService.isInternal() || showDocumentsOptionsToCustomer());

    const getInternalDocumentTypeModal = () => {

        if ((!loginService.isInternal() && !showDocumentTypesSelection(documents)) || isMainDocumentForm || !isDocumentTypeModalOpen)
            return null;

        return (
            <RequestDocumentTypeConfirmationModal
                getInput={getInput}
                handleClose={() => setIsDocumentTypeModalOpen(false)}
                loginService={loginService}
                documents={documents}
                shouldDisplayProcessMainDocumentType={shouldDisplayProcessMainDocumentType}
                showDocumentsOptionsToCustomer={showDocumentsOptionsToCustomer}
            />
        )
    }

    const getServiceConfigModal = () => {

        if (!serviceConfigModal.open)
            return null;

        return (
            <RequestDocumentsServiceConfigModal
                request={request}
                document={serviceConfigModal.document}
                onConfirm={onConfirmServiceConfig}
                handleClose={handleCloseConfig}
                isEdit={serviceConfigModal.isEdit}
            />
        )
    }

    const allowOnlyOneDocument = () => !!(documents.filter(doc => doc.isProcessMainDocument).length >= 1);

    const disabledButton = () => isMainDocumentForm ? allowOnlyOneDocument() : !canUpload

    const hideButton = () => !!((isMainDocumentForm && allowOnlyOneDocument()) || (isMainDocumentForm && isEdit));

    return (
        <>

            <Grid item xs={12} container style={{ padding: -15 }}>

                {loading && <SimpleLoading message="Carregando lista..." />}

                <ProgressDeterminate style={{ display: "block" }} />

                {!loading && (
                    <>

                        <Grid
                            container justifyContent="space-between" alignItems="end"
                            direction={!isMainDocumentForm && "row-reverse"} style={{ marginBottom: 15 }}
                        >
                            <div>

                                {getInput({
                                    id: isMainDocumentForm ? 'file' : 'files',
                                    disabled: !canUpload,
                                    multiple: true,
                                    key: isMainDocumentForm && 'processMainDocument'
                                })}

                                {!!documents.length && !isMainDocumentForm &&
                                    <Button
                                        className="hover:border-[#376fd0] hover:text-[#376fd0] hover:!bg-[transparent]"
                                        color="default"
                                        variant="outlined"
                                        style={{ marginRight: 10 }}
                                        onClick={() => selectAll(true)}
                                    >
                                        Selecionar todos
                                    </Button>
                                }

                                {!hideButton() && (
                                    <label htmlFor={((!loginService.isInternal() && !showDocumentTypesSelection(documents)) || isMainDocumentForm) && (isMainDocumentForm ? 'file' : 'files')}>
                                        <Button
                                            color="primary" component="span" startIcon={<CloudUploadOutlined />}
                                            disabled={disabledButton()} variant="contained"
                                            onClick={() => setIsDocumentTypeModalOpen(true)}
                                        >
                                            Anexar {isMainDocumentForm ? 'processo' : 'documentos'}
                                        </Button>
                                    </label>
                                )}


                            </div>

                            {!!documents.length && hasSelectedDocs && (
                                <Grid>
                                    {optionsSelectAll()}
                                </Grid>
                            )}

                        </Grid>

                        <RequestDocumentList
                            request={request}
                            documents={!isMainDocumentForm ? splitDocuments.defaultDocuments(documents) : splitDocuments.mainProcessDocument(documents)}
                            selectFile={selectFile}
                            visibility={visibility}
                            handleOpenConfig={handleOpenConfig}
                            removeDocument={removeDocument}
                            update={update}
                            isEdit={isEdit}
                        />

                        <Grid item style={{ width: "100%" }}>
                            <UploadList />
                        </Grid>

                    </>
                )}

            </Grid>

            {preview.show && (
                <DocumentPreview file={preview} onSelect={visibility} />
            )}

            {isFilesErrorModalOpen && (
                <CorruptedDocumentsModal
                    isOpen={isFilesErrorModalOpen}
                    request={request}
                    alert={setAlert}
                    onClose={() => {
                        setIsFilesErrorModalOpen(false);
                        getAll(request.id);
                    }}
                    editCheckPending={checkIsPending}
                />
            )}

            {getServiceConfigModal()}

            {getInternalDocumentTypeModal()}

        </>
    );
}

export default RequestDocumentForm;