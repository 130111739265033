
import Async from "../../components/Async";

import { EventNote } from "@material-ui/icons";

const AuthGuard = Async(() => import("../auth.guard"));

const Agenda = Async(() => import('../../pages/operations/agenda'));

const routes = {
    operationalAgenda: {
        id: 'operationalAgenda',
        name: 'Pautas',
        path: '/agenda',
        component: Agenda,
        icon: <EventNote />,
        guard: AuthGuard,
        roles: ['admin', 'operator']
    }
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}