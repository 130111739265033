import { useEffect, useState } from "react";

import {
    Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography,
    useTheme
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import datetimeProvider from "../../../providers/datetime.provider";

import solicitationService from "../../../domain/services/solicitation/solicitation.service";
import communicationProvider from "../../../providers/communication.provider";

export const CustomerRequestProtocoledModal = ({ open, handleClose, request = {}, setAlert, onRefresh }) => {

    const [protocoledAt, setProtocoledAt] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (!open)
            return;

        setProtocoledAt(request.protocoledAt || null);
    }, [open]);

    const handleSave = async () => {

        const mainId = request.mainId || request.id;

        setIsLoading(true);

        await solicitationService
            .editValue(mainId, { protocoledAt })
            .then(() => {
                onRefresh();
                handleClose();
            })
            .catch(err => communicationProvider.getErrorMessage(err, setAlert))
            .finally(() => setIsLoading(false));
    }

    return (
        <Dialog
            open={open} onClose={handleClose}
            maxWidth='xs' fullWidth
        >
            <DialogTitle >
                Data de protocolação <br />
                <Typography style={{ marginTop: 5 }}>
                    Adicione a data de protocolação do seu serviço.
                </Typography>
            </DialogTitle>

            <DialogContent style={{ marginBottom: 15 }}>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <DatePicker
                        name="initialDateFilter" fullWidth format="dd/MM/yyyy"
                        placeholder="Data de protocolação"
                        clearLabel="Remover" clearable
                        okLabel="Confirmar" cancelLabel="Cancelar"
                        value={protocoledAt} onChange={setProtocoledAt}
                    />
                </MuiPickersUtilsProvider>
            </DialogContent>

            <DialogActions>

                <Button onClick={handleClose} disabled={isLoading}>
                    Cancelar
                </Button>

                <Button
                    variant="contained" color="primary"
                    onClick={handleSave} disabled={isLoading}
                >
                    {!isLoading ? 'Salvar' : (
                        <>
                            Salvando
                            <CircularProgress size={14} style={{ marginLeft: 8 }} />
                        </>
                    )}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

const CustomerRequestProtocoledButton = ({ protocoledAt, onClick }) => {
    
    const theme = useTheme();

    const getProtocoledValue = () => (
        <Grid container alignItems="center">
            {datetimeProvider.formatDateTime(protocoledAt, 'DD/MM/YYYY')}
            <Edit style={{ marginLeft: 5, fontSize: 14, color: 'white' }} />
        </Grid>
    )

    return (
        <Tooltip
            title={(
                <div style={{ fontSize: 14, padding: 8, lineHeight: 1.4, color: '#fff' }}>
                    {!protocoledAt
                        ? "Adicionar data que serviço foi protocolado"
                        : `Esse serviço foi protocolado em ${datetimeProvider.formatDateTime(protocoledAt, 'DD/MM/YYYY')}
                    `}
                </div>
            )}
        >
            {
                !protocoledAt ? (
                    <button
                        style={{
                            color: theme.palette.primary.main, fontWeight: 600,
                            fontSize: 14, textAlign: 'center'
                        }}
                        onClick={onClick}
                    >
                        Adicionar
                    </button>
                ) : (
                    <Chip
                        label={getProtocoledValue()} color='primary'
                        onClick={onClick}
                    />
                )
            }


        </Tooltip>
    )
}

export default CustomerRequestProtocoledButton;