import api from '../../services/api';

const baseUrl = 'requests'

const revokeRequest = async (mainId) =>
    api.put({ type: 'user', service: `${baseUrl}/${mainId}/revoke-request` });

const findAllRevokedServices = async (requestId) =>
    api.get({ type: 'user', service: `${baseUrl}/${requestId}/unrevoke-list` });

export default {
    revokeRequest,
    findAllRevokedServices
}