import { useState, useEffect } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import moment from 'moment';

export default function LabelViewField(props) {

    const [labelProps, setLabelProps] = useState({});

    const { request = {}, label, name, value, type } = props;

    useEffect(() => loadValues(), []);

    const loadValues = () => {

        const setValues = () => ({
            text: () =>
                ({ title: label, value }),
            multilineText: () =>
                ({ title: label, value }),
            select: (optionName) =>
                ({ title: label, value: optionName }),
            multiselect: (optionName) =>
                ({ title: label, value: optionName }),
            default: (optionName) =>
                ({ title: label, value: optionName }),
            boolean: () =>
                ({ title: label, value: (value == true) ? "Sim" : "Não" }),
            date: () =>
                ({ title: label, value: moment(value).format("DD [de] MMMM [de] YYYY") }),
            datetime: () =>
                ({ title: label, value: moment(value).format("DD [de] MMMM [de] YYYY [às] HH:mm") }),
            selectStateCity: () =>
                ({ title: label, value: `${value} - ${request.city}` })
        })


        const requestField = (request?.fields || {})[name] || {};
        const selectedOption = (requestField.values || [])[0] || requestField.value;
        const optionValue = (selectedOption?.optionName || requestField?.optionName || value);

        setLabelProps(setValues()[type || 'default'](optionValue));
    }

    return (
        <Grid item xs={12}>

            <Typography variant="h5" ant="h6" gutterBottom>
                {labelProps.title}
            </Typography>
            <Typography variant="p" gutterBottom style={{ whiteSpace: "pre-line", fontSize: 15 }}>
                {labelProps.value || "Não informado"}
            </Typography>

            <Divider style={{ marginTop: 25 }} />

        </Grid>
    );
}
