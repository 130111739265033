
import { useState, useEffect } from 'react';

const useDebounce = (value, delay) => {

    const [debouncedValue, setDebouncedValue] = useState(value);

    let timer = null;

    useEffect(() => {

        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => { clearTimeout(timer) };
    }, [value]);

    return debouncedValue;
}

export default useDebounce;

