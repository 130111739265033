export const solicitationSuccessHtmlMessage = (dueDate) => `
  <div style="text-align: justify">
    Prazo de entrega:
    <strong>
      ${new Intl.DateTimeFormat('pt-br', { dateStyle: 'short', timeStyle: 'short' }).format(
    new Date(dueDate),
)}
      .
    </strong>
    <br />
    <br />
    <strong>Importante</strong>: Sendo necessário a
    <strong>antecipação da entrega da petição, entre em contato imediatamente</strong>
    pelo
    <a href="https://bit.ly/3Qr8DBr" target="_blank" style="text-decoration: none;">
      <span>suporte da Facilita Jurídico</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        fill="currentColor"
        class="bi bi-arrow-up-right"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
        />
      </svg>
    </a>
    .
    <div style="margin: 15px 0 15px 0; font-size: 14px; letter-spacing: normal; word-spacing: normal; padding: 15px; background: #F1F0C0; border-radius: 10px">
      A Facilita Jurídico não se responsabiliza pela tempestividade da solicitação e somente
      anteciparemos a entrega caso haja contato pelo atendimento para averiguar a disponibilidade em
      pauta.
    </div>
    A cada solicitação cadastrada, seu escritório dá um passo rumo ao sucesso!
    <br></br>
    Conte sempre com a Facilita Jurídico.
  </div>
`;

export const onDownloadPetitionHtmlMessage = () => `
  <div style="text-align: justify">
    <strong>Obrigada por utilizar os serviços da Facilita Jurídico!</strong>
    <br></br>
    Se precisar de algum ajuste, poderá solicitar em 5 dias úteis através da opção <strong>CORREÇÃO</strong>.
    <br></br>
    Não deixe de avaliar a petição entregue, através da opção <strong>FEEDBACKS</strong>, para que possamos lhe entregar petições de qualidade!
    <br></br>
    Conte sempre com a Facilita Jurídico.
  </div>
`

export const faciliterCorrectionDropoutHtmlMessage = () => `
  <div style="text-align: justify">
    <strong>Confirmação de Desistência</strong>
    <br><br>
    Confirme abaixo se deseja desistir da correção. Lembre-se de que, ao desistir:
    <br>
    <ol>
        <li>Não receberá pagamento pelo serviço.</li>
        <li>Pode afetar sua continuidade na plataforma.</li>
    </ol>
    Para confirmar sua desistência, clique em "Confirmar Desistência". Se desejar reconsiderar, clique em "Cancelar".
  </div>
`

export const servicesReceiptHtmlMessage = (services) => `
    Abaixo se encontram os serviços selecionados para a solicitação. Confirme-os antes de continuar o cadastro:<br/>
    <div style="text-align: left">
        <ul>
            ${services.map(s => `<li>${s.name}</li>`).join('')}
        </ul>
    </div>
`