import React, { useState } from "react";

import {
    Card,
    CardContent,
    Typography,
    Grid,
    Avatar,
    Hidden,
    Checkbox,
    FormControlLabel,
    CardActionArea,
    Divider,
    Snackbar,
} from "@material-ui/core";

import 'moment/locale/pt-br'

import notificationService from '../../domain/services/notification/internal.notification.service';
import { LibraryBooks } from "@material-ui/icons";
import moment from "moment";
import { Alert } from "@material-ui/lab";

const NotificationList = (props) => {

    const [statusMsg, setStatusMsg] = useState({});

    const read = async (id, link) => {
        await notificationService
            .read(id)
            .catch(err =>
                setAlertStatus('Notificação não encontrada.', 'warning')
            )

        link && window.open(link, '_blank')
    }

    const onCheck = async (id) =>
        await notificationService
            .read(id)
            .catch(err =>
                setAlertStatus('Notificação não encontrada.', 'warning')
            )

    const setAlertStatus = (msg, severity) =>
        setStatusMsg({ text: msg, date: new Date(), open: true, severity });

    const handleCloseAlert = () =>
        setStatusMsg({ text: "", date: new Date(), open: false });


    return (
        <React.Fragment>
            <div style={{ borderBottom: '1px solid #CECFC7', textAlign: 'center', marginTop: '-10px', marginBottom: '0px' }}>
                <p style={{ color: '#131B23', fontSize: '18px', fontWeight: '600px' }}>Notificações</p>
            </div>
            {
                props.notifications.map((notification, i) => {
                    return <>
                        <CardActionArea key={i}>
                            <Card mb={4} style={{ marginBottom: "12px", marginLeft: '10px', boxShadow: 'none' }}>
                                <CardContent style={{ cursor: 'pointer', padding: "10px 10px 5px" }}>
                                    <Grid container direction="row" onClick={() => read(notification.id, notification.additionalData.link || false)}>
                                        <Hidden only={['xs', 'sm']}>
                                            <Grid item>
                                                <Avatar color="primary" alt={notification.title}
                                                    style={{
                                                        width: "32px", height: "32px", minHeight: "24px", marginRight: "10px",
                                                        fontSize: "10px", fontWeight: 600, borderColor: "rgb(55, 111, 208)", backgroundColor: "rgb(55, 111, 208)",
                                                        marginBottom: '10px'
                                                    }}>
                                                    <LibraryBooks style={{ fontSize: '16px' }}></LibraryBooks>
                                                </Avatar>
                                            </Grid>
                                        </Hidden>
                                        <Typography variant="body1" style={{ whiteSpace: "pre-wrap", fontWeight: "600", color: "rgba(68, 68, 68, 0.8)", marginTop: '5px', fontSize: '16px' }} >
                                            {`${notification.title}`}
                                        </Typography>
                                    </Grid>
                                    <div style={{ display: 'flex' }} onClick={() => read(notification.id, notification.additionalData.link || false)}>
                                        <p style={{
                                            color: "#444444ab", fontSize: "11px", marginTop: "10px", fontWeight: "600",
                                            lineHeight: '1.4',
                                            whiteSpace: 'pre-wrap', width: "22em", overflow: "hidden", textOverflow: "ellipsis"
                                        }}>
                                            <span style={{ marginBottom: "-3px", paddingTop: "3px", marginRight: "2px", fontSize: '12px', color: "rgba(68, 68, 68, 0.7)" }}>{notification.description}</span>
                                            <br></br>
                                            <div style={{ marginTop: '30px', display: 'flex' }}>
                                                <span style={{ fontSize: '12px' }}>{moment(notification.createdAt).locale('pt-BR').fromNow()}</span>
                                            </div>

                                        </p>
                                    </div>
                                    <Hidden only={['xs', 'sm']}>
                                        <FormControlLabel style={{ marginLeft: '250px', maxWidth: '65px', maxHeight: '10px', display: 'flex', marginTop: '-25px', marginBottom: '10px' }} control={<Checkbox name={`checkbox-${i}`} checked={false} onChange={async () => await onCheck(notification.id)} />} label="Lida" />
                                    </Hidden >
                                    <Hidden mdUp={true}>
                                        <FormControlLabel style={{ marginLeft: '150px', maxWidth: '65px', maxHeight: '10px', display: 'flex', marginTop: '-25px', marginBottom: '10px' }} control={<Checkbox name={`checkbox-${i}`} checked={false} onChange={async () => await onCheck(notification.id)} />} label="Lida" />
                                    </Hidden >
                                </CardContent>
                            </Card>
                        </CardActionArea>
                        <Divider />
                        <Snackbar onClose={handleCloseAlert} open={statusMsg.open}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            autoHideDuration={2000} key={statusMsg.date}>
                            <Alert severity={statusMsg.severity}>
                                {statusMsg.text}
                            </Alert>
                        </Snackbar>
                    </>
                })
            }
        </React.Fragment>
    )
}

export default NotificationList;