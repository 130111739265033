import React, { useState, useEffect } from "react";

import { CircularProgress, Card, CardContent, Typography, Grid } from "@material-ui/core";

import { events } from '../domain/services/api';

function LoadingList() {

    let [load, setLoadState] = useState(false);

    useEffect(() => {
        function handleLoadChange(load) {
            setLoadState(load)
        }
        events.on('onLoadingList', handleLoadChange)
        return () => {
            events.removeListener('onLoadingList', handleLoadChange)
        }
    }, [])

    return (
        load ?
            <Card mb={12} xs={12} style={{ marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                <CardContent style={{ display: "-webkit-inline-box", paddingTop: "20px", paddingBottom: "20px" }}>
                    <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                    <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                        <Typography variant="h6" gutterBottom> Aguarde </Typography>
                        <Typography variant="body2" gutterBottom> carregando lista... </Typography>
                    </Grid>
                </CardContent>
            </Card> : null
    );
}

export default LoadingList;
