import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { Button, Dialog, DialogContent, Divider, Grid, Typography } from "@material-ui/core";

import PendingSolveModal from "./pending.solve.modal.component";
import RequestFormHighlightButton from "../request.form.highlight.button";
import { useCustomerRequestList } from "../../../context/customerRequestListContext";
import { voucherTrackerUserId } from "../../../domain/constant/customer.repurchase.voucher.constant";

const PendingSolveButton = ({ request, setAlert, onFinish, variant = 'link' }) => {

    const {
        setIsPendingResolveModalOpen = () => {},
        showingPendingResolveTour,
    } = useCustomerRequestList() || {};

    let { search } = useLocation();
    const history = useHistory();

    const buttonText = request.isFinancialPending ? 'Adquirir Saldo' : 'Resolver pendência';

    const [isModalOpen, setIsmodalOpen] = useState(false);

    useEffect(() => { checkRedirectionInUrl(search) }, []);

    const checkRedirectionInUrl = (search) => {

        const { solvePending } = queryString.parse(search);

        if (!solvePending)
            return;

        setIsmodalOpen(true);
    }

    const openModal = () => {
        setIsmodalOpen(true);
        setIsPendingResolveModalOpen(true);
    }

    const handleClick = () => {
        if (request.isFinancialPending) {
            history.push(`/marketplace?sellerUserId=${voucherTrackerUserId}`);
            return;
        }

        openModal();
    }

    const getButton = () => ({
        link: (
            <Button
                variant='text'
                color='primary'
                className="w-full"
                onClick={handleClick}
            >
                <span className='text-sm'>{buttonText}</span>
            </Button>
        ),
        card: (
            <RequestFormHighlightButton
                text={buttonText} color='rgb(252, 220, 0)'
                onClick={handleClick}
                label='Resolva a pendência para que possamos avançar com a solicitação!'
            />
        ),
        contained: (
            <Button
                variant="contained" color="primary"
                style={{ fontWeight: 'bold' }}
                onClick={handleClick}
            >
                {buttonText}
            </Button>
        )
    })[variant];

    return (
        <>
            {getButton()}

            <PendingSolveModal
                request={request}
                isOpen={isModalOpen && !showingPendingResolveTour}
                closeModal={() => setIsmodalOpen(false)}
                onFinish={onFinish}
                setAlert={setAlert}
            />
        </>
    )
}

export default PendingSolveButton;