import requestValidator from '../../validators/request.validator';
import { get, put } from '../api';

const findByAreaId = async (areaId) =>
    get({ type: 'user', service: `requests/calculation-services/area/${areaId}` });

const update = async (requestId, data) =>
    put({ type: 'user', service: `requests/calculation-services/${requestId}`, data });

const hasServices = (request) =>
    request.id && request.needCalculation;

export default {
    findByAreaId,
    update,
    hasServices
}