import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { AttachMoney } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import loginService from '../../domain/services/login.service';

const ResponsibleChip = ({ id, name, currentPrice, item, label, color, onDelete }) => {
  const [reason, setReason] = useState('');
  const [price, setPrice] = useState(currentPrice);
  const [reasonError, setReasonError] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setReason('');
    setPrice(currentPrice);
  }, [open]);

  const handleRemove = () => {
    if (!reason) {
      setReasonError(true);
      return;
    }

    onDelete({ reason, price });
  };

  return (
    <>
      <Chip
        size="small"
        label={label}
        variant="outlined"
        avatar={
          <Tooltip title={name} key={id} style={{ zIndex: '999999', cursor: 'pointer' }}>
            <Avatar
              alt={name}
              style={{
                fontSize: '10px',
                marginLeft: '2px',
                fontWeight: 600,
                background: color,
                color: '#FFF',
                width: '20px',
                height: '20px',
              }}
            >
              {loginService.getNameInitials(name)}
            </Avatar>
          </Tooltip>
        }
        style={{
          fontWeight: '500',
          color,
          marginRight: '5px',
          marginBottom: '8px',
          border: `1px solid ${color}`,
        }}
        onDelete={() => setOpen(true)}
      />

      <Dialog open={open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>Remover {label}</DialogTitle>

        <DialogContent>
          <TextField
            label="Informe o motivo da remoção"
            type="text"
            name={name}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            style={{ margin: '10px 0 10px' }}
            error={reasonError}
          />
          <TextField
            InputProps={{
              startAdornment: <AttachMoney style={{ color: '#999', fontSize: '16px' }} />,
            }}
            label={`Valor a receber`}
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
            style={{ margin: '10px 0 10px' }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Fechar
          </Button>

          <Button onClick={() => handleRemove()} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResponsibleChip;
