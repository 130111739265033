import api from '../services/api';

const baseUrl = 'requests/hearing'

const save = async (requestId, data) =>
    api.post({ type: 'user', service: `${baseUrl}/${requestId}`, data });

const findByRequestId = async (requestId) =>
    api.get({ type: 'user', service: `${baseUrl}/${requestId}` });

export default {
    save,
    findByRequestId
}