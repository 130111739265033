import { useEffect, useState } from "react";

const applyResponsiveStyles = (sizes, gutter) => {
    const halfGutter = (Array.isArray(gutter) ? gutter[0] : gutter) / 2;
    const style = {
        paddingLeft: `${halfGutter}px`,
        paddingRight: `${halfGutter}px`,
    };

    const hasDefinedSize = Object.values(sizes).some(val => !!val);

    if (!hasDefinedSize) {
        return {
            ...style,
            flex: '0 0 auto',
            position: 'relative',
            visible: true,
        };
    }

    const baseStyles = {
        flex: `0 0 ${(sizes.span / 24) * 100}%`,
        maxWidth: `${(sizes.span / 24) * 100}%`,
        position: 'relative',
        visible: ![undefined, null].includes(sizes.span) && sizes.span > 0,
    };

    Object.assign(style, baseStyles);

    const mediaQueries = [
        { query: '(max-width: 575.98px)', size: sizes.xs },
        { query: '(min-width: 576px) and (max-width: 767.98px)', size: sizes.sm },
        { query: '(min-width: 768px) and (max-width: 991.98px)', size: sizes.md },
        { query: '(min-width: 992px) and (max-width: 1199.98px)', size: sizes.lg },
        { query: '(min-width: 1200px)', size: sizes.xl },
    ];

    mediaQueries.forEach(({ query, size }) => {
        if (![undefined, null].includes(size) && window.matchMedia(query).matches) {
            const responsiveStyle = {
                flex: `0 0 ${(size / 24) * 100}%`,
                maxWidth: `${(size / 24) * 100}%`,
                visible: size > 0
            };
            Object.assign(style, responsiveStyle);
        }
    });

    return style;
};

export default function Col({ span, xs, sm, md, lg, xl, gutter = 0, style: customStyle, className, children }) {
    const sizes = { span, xs, sm, md, lg, xl };

    const [style, setStyle] = useState({});

    const handleResize = () => setStyle(applyResponsiveStyles(sizes, gutter));

    useEffect(() => {
        setStyle(applyResponsiveStyles(sizes, gutter));
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!style.visible) return <></>;

    return <div className={className} style={{ ...style, ...customStyle }}>{children}</div>;
};