import { useEffect, useState } from "react"

import ratingService from "../../../domain/services/solicitation/rating.service";
import CorrespondentService from "../../../domain/services/correspondent.service";
import RatingForm from "./rating.form.component";

import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";

const RequestRatingForm = (props) => {
    const [questions, setQuestions] = useState([]);

    const getQuestions = async () => {
        const res = await ratingService.findQuestions(props.requestId);
        let listQuestions = (res.data || []);
        setQuestions(listQuestions);
        setResultQuestions(listQuestions);
    }

    useEffect(() => {
        getQuestions();
    }, [])

    const setResultQuestions = async (listQuestions) => {
        const res = await ratingService.findResults(props.requestId);
        let resultQuestions = ratingService.mapQuestionResults(listQuestions, (res.data || []));
        setQuestions(resultQuestions);
    }

    const saveResultQuestion = async (resultQuestion, members) => {
        await ratingService.saveResultQuestion(resultQuestion, props.requestId, members);
    }

    const concluded = async (feedback, members, closeModal) => {
        await CorrespondentService.concludedRating({
            feedback, requestId: props.requestId, questions: [...questions]
        }, members);

        ratingConcluded(closeModal);
    }

    const ratingConcluded = async (closeModal) => {
        await props.onSave({ stepName: stepsSolicitation.CONCLUDED, requestId: props.requestId });
        props.onRefreshRequest();
        closeModal();
    }

    const isConcludedRating = (member) =>
        member && (member.score && member.feedback);

    return <RatingForm questions={questions} setQuestions={setQuestions}
        saveResultQuestion={saveResultQuestion} ratingConcluded={ratingConcluded}
        concluded={concluded} isConcludedRating={isConcludedRating}
        name="Ficha de avaliação"
        {...props}
    />
}

export default RequestRatingForm;
