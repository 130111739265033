import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';

import {
    Button as MuiButton, Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Grid, FormControl, TextField
} from '@material-ui/core';
import { Close, Assignment, InsertDriveFile } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import requestHearingAnalysisService from '../../../domain/services/request/analysis/request.hearing.analysis.service';

const useStyles = makeStyles((theme) => ({
    loadingIcon: {
        color: 'greenyellow',
        width: 15,
        marginLeft: 10
    },
    dialogHeader: {
        background: "rgb(238 238 238 / 50%)",
        borderBottom: "3px solid rgb(25, 118, 210)"
    },
    dialogContent: {
        padding: '30px 10px'
    },
    dialogActions: {
        padding: 15,
        background: "rgb(238 238 238 / 50%)",
        borderTop: "3px solid rgb(25, 118, 210)"
    }
}));

const Button = styled(MuiButton)`
    span {
        justify-content: flex-start;
        svg {
            margin-right: 10px;
        }
    }
`;

const RequestHearingAnalysisCustomerView = ({ requestId, buttonWidth, buttonVariant, buttonTitle }) => {

    const classes = useStyles();

    const [showAnalysis, setShowAnalysis] = useState(false);
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState([]);

    useEffect(() => {

        if (!requestId)
            return

        setLoading(true)
        requestHearingAnalysisService.findValuesByRequestId(requestId)
            .then(setValues)
            .then(() => setLoading(false))

    }, [requestId])

    return (
        <>
            <Button
                size="small" color="primary" variant="outlined"
                onClick={() => setShowAnalysis(true)}
            >
                {buttonTitle}
            </Button>

            <Dialog open={showAnalysis} maxWidth='md' fullWidth onClose={() => setShowAnalysis(false)}>
                <DialogTitle style={{ display: 'inline-flex', backgroundColor: '#eee', width: '100%', minWidth: '100%' }}>
                    <Typography
                        variant="h4"
                        style={{
                            paddingTop: '12px',
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            minWidth: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Assignment style={{ color: '#376fd0', fontSize: '25px', marginRight: '7px' }} />
                        Análise da audiência
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowAnalysis(false)}
                        style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container direction='column' spacing={10} className={classes.dialogContent} style={{ pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' }}>
                        {values.map(field => (
                            <Grid item key={field.key}>
                                <FormControl fullWidth>

                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        label={field.name}
                                        multiline
                                        variant='outlined'
                                        value={field.value}
                                    />

                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>

                <DialogActions style={{ background: "rgb(238, 238, 238)" }}>
                    <Button variant='contained' onClick={() => setShowAnalysis(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RequestHearingAnalysisCustomerView;
