
import React from "react";

import Async from "../../components/Async";

import { More } from "@material-ui/icons";

const VoucherView = Async(() => import("../../pages/marketing/voucher.table"));
const VoucherEditView = Async(() => import("../../pages/marketing/voucher.edit"));
const VoucherCreateView = Async(() => import("../../pages/marketing/voucher.create"));

const routes = {
    vouchers: {
        id: 'vouchers',
        name: 'Vouchers',
        path: '/marketing/vouchers',
        component: VoucherView,
        icon: <More />,
        roles: ['admin', 'financial', 'marketing'],
    },
    voucherCreate: {
        id: "voucherCreate",
        name: "voucherCreate",
        path: "/marketing/vouchers/create",
        component: VoucherCreateView,
        roles: ["admin", "financial", "marketing"],
    },
    voucherEdit: {
        id: "voucherEdit",
        name: "voucherEdit",
        path: "/marketing/vouchers/:voucherId",
        component: VoucherEditView,
        roles: ["admin", "financial", "marketing"],
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}