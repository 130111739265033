import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import loginService from '../../domain/services/login.service';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Preencha um email válido').max(255).required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
    newPassword: Yup.string().required('Campo obrigatório'),
});

const EditPasswordForm = ({ handleSubmit, closeDialog }) => {
    const { email } = loginService.getUserAuthentication();

    return (
        <Formik
            initialValues={{
                email,
                password: '',
                newPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent style={{ paddingTop: 0 }}>
                        <Grid container justifyContent="flex-end" spacing={2}>
                            {errors.submit && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">{errors.submit}</Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextField
                                    type="email"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    type="password"
                                    id="password"
                                    name="password"
                                    label="Informe sua senha"
                                    value={values.password}
                                    onChange={handleChange}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    autoComplete="current-password"
                                    autoFocus
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    label="Informe uma nova senha"
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    error={touched.newPassword && Boolean(errors.newPassword)}
                                    helperText={touched.newPassword && errors.newPassword}
                                    autoComplete="new-password"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={closeDialog} disabled={isSubmitting}>
                            Fechar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            Salvar
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Formik>
    );
};

const EditPasswordDialog = ({ isOpen, onClose }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            await loginService.updatePassword({
                email: values.email,
                password: values.password,
                newPassword: values.newPassword,
            });

            enqueueSnackbar('Senha alterada com sucesso!', { variant: 'success' });
            onClose();
        } catch {
            setErrors({ submit: 'Por favor, verifique sua senha.' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Editar senha</DialogTitle>
            <EditPasswordForm handleSubmit={handleSubmit} closeDialog={onClose} />
        </Dialog>
    );
};

export default EditPasswordDialog;
