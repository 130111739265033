import { useEffect, useState } from 'react';

import { FormControl, Grid, TextField } from '@material-ui/core';

import useDebounce from '../../../providers/debounce';
import stepFormFieldsConfig from '../fields/request.fields.config';
import { stepsSolicitation } from "../../../domain/constant/steps.solicitation.constant";

import requestAnalysisService from '../../../domain/services/request.analysis.service';
import requestRegistryService from '../../../domain/services/solicitation/request.registry.service';
import loginService from '../../../domain/services/login.service';
import FormField from '../../FormField';

export default function AnalysisFactsFundamentalsForm({ values, isEdit, fieldProps, setAlert }) {

    const [analysis, setAnalysis] = useState({});

    const [fields, setFields] = useState([]);

    const [analysisChange, setAnalysisChange] = useState({});
    const debounced = useDebounce(analysisChange, 2500);

    const [fieldChange, setFieldChange] = useState({});
    const debouncedField = useDebounce(fieldChange, 1000);

    const request = values;

    useEffect(() => getAnalysis(request.referenceRequestId), []);

    useEffect(() => {

        if (debounced.value)
            saveAnalysis(debounced.value, request.referenceRequestId);

    }, [debounced]);

    useEffect(() => {

        if (debouncedField.value)
            editField(debouncedField.value, request.referenceRequestId);

    }, [debouncedField]);

    const getAnalysis = async (requestId) => {

        await Promise
            .all([
                requestAnalysisService.getAnalysis(requestId).catch(() => ({})),
                requestRegistryService.getDynamicFieldsAnalysis(requestId).catch(() => ({}))
            ])
            .then((response) => setAnalysisValue(response))
            .catch((error) => setAlert(error.response.data.message || "Erro ao obter a Indicação de Petição", 'error'))

    }

    const setAnalysisValue = (values) => {

        const analysis = (values[0] || {});
        const fieldValues = (values[1] || []);

        getAnalysisFields(request.areaId, analysis.modalityId);

        setAnalysis({
            ...analysis,
            fields: requestRegistryService.integrateFields(analysis, fieldValues),
            duration: requestAnalysisService.getTimeByTotalMin(analysis.duration)
        });

    }

    const getAnalysisFields = async (areaId, modalityId) =>
        requestRegistryService
            .findFieldsToAnalysis(areaId, modalityId)
            .then((res) => setFields(res.data || []));

    const changeValues = async (field, newValue) => {

        setAnalysis({ ...analysis, [field]: newValue });

        setAnalysisChange({
            value: {
                id: analysis.id,
                requestId: analysis.requestId,
                duration: analysis.duration,
                [field]: newValue
            }
        });
    }

    const setFieldValues = (name, field) =>
        setAnalysis((item) => ({ ...item, [name]: field.value }));

    const setUpdateField = async (field) => {

        if (field.type.toLowerCase().indexOf('text') != -1) {
            setFieldChange({ value: field });
            return;
        }

        await editField(field, request.referenceRequestId);
    }

    const editField = async (field, requestId) =>
        requestAnalysisService
            .editField(requestId, field)()
            .then((res) => setFieldValues(field.name, (res.data?.value || [])))
            .catch((error) => setAlert(error.response.data.message, 'error'));

    const saveAnalysis = async (values, requestId) =>
        requestAnalysisService
            .editRequestAnalysis({ ...values, requestId })
            .then((response) => setAnalysis({ ...analysis, id: analysis.id || response.data.id }))
            .catch((error) => setAlert(error.response.data.message, 'error'));

    const setUpFields = (fields, values, setUpdateField) => {

        if (!fields.length)
            return null;

        return fields.map((field) =>
            ({ ...stepFormFieldsConfig.getComponentField({ field, request: values, setValue: setUpdateField, isEdit, fieldProps }) })
        );
    }

    const disableStyle = () => {

        if (loginService.isInternal()) return;

        if (isEdit && values.status != stepsSolicitation.ANALYSE)
            return { pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' };
    }

    return (
        <Grid container spacing={6} style={disableStyle()}>

            {setUpFields(fields, analysis, setUpdateField)}

            <Grid item xs={12}>
                <FormField
                    type='multiline' name='facts'
                    field='Breve relato do caso'
                    currentValue={analysis.facts}
                    onChange={changeValues} required
                    {...fieldProps}
                />
            </Grid>

            <Grid item xs={12}>
                <FormField
                    type='multiline' name='petitionTopics'
                    field='Tópicos obrigatórios na petição'
                    currentValue={analysis.petitionTopics}
                    onChange={changeValues} required
                    {...fieldProps}
                />
            </Grid>

            <Grid item xs={12}>
                <FormField
                    type='multiline' name='attorney'
                    field='Dados do advogado subscritor'
                    currentValue={analysis.attorney}
                    onChange={changeValues} required
                    {...fieldProps}
                />
            </Grid>

        </Grid>
    );
}

