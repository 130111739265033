import { useEffect, useState } from "react";

import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";

import masks from "../../providers/masks";
import addressProvider from "../../providers/address.provider";
import utilsProvider from "../providers/utils.provider";

import addressService from "../../domain/services/address.service";

const CustomerAddressForm = ({ customer, setCustomer, fieldsWithError }) => {

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => { getStates(customer.address) }, []);

    const getStates = async (address = {}) => {

        const data = await addressService.findStates().then(res => res.data || []);
        const states = data.map((item) => ({ name: item.nome, value: item.sigla }));
        setStates([...states]);

        if (address?.state)
            getCitiesByUF(address.state);
    }

    const getCitiesByUF = async (state) => {
        const data = await addressService.findCities(state).then(res => res.data || []);
        const cities = data.map((item) => ({ name: item.nome, value: item.nome }));
        setCities([...cities]);
    }

    const checkSearchAddress = async (value) => {

        const rawValue = value.replace(/[^\d]/g, "");
        const isFinished = rawValue.length == 8;

        if (!isFinished)
            return;

        await getAddressByZipCode(rawValue);
    }

    const getAddressByZipCode = async (zipCode) => {

        const data = await addressProvider.getAddressByZipCode(zipCode).catch(() => null);

        if (!data?.uf) return;

        await getCitiesByUF(data.uf);

        const address = {
            district: data.bairro,
            complement: data.complement,
            street: data.logradouro,
            city: data.localidade,
            state: data.uf,
            zipCode: data.cep
        }

        setCustomer({ address });
    }

    const handleChange = (field, value) => {

        const newValue = field.mask ? field.mask(value) : value;

        let changes = {
            [field.key]: newValue
        }

        if (field.jsonFieldKey)
            changes = {
                [field.jsonFieldKey]: {
                    ...customer[field.jsonFieldKey],
                    [field.key]: newValue
                }
            }

        for (const fieldToClear of field.clearOnChangeFields || [])
            changes[fieldToClear] = null;

        setCustomer(changes);
    }

    const getFieldInputComponent = (field) => {
        const inputByType = {
            text: () => (
                <TextField
                    variant='standard'
                    value={utilsProvider.accessObjValueByCompositeKey(customer, field.accessor) || ''}
                    label={field.label}
                    error={fieldsWithError.includes(field.accessor)}
                    fullWidth
                    style={{ }}
                    onChange={(e) => {
                        handleChange(field, e.target.value);
                        field.onChange && field.onChange(e.target.value);
                    }}
                    multiline={!!field.multiline}
                />
            ),
            select: () => (
                <FormControl variant="standard" style={{ display: 'flex' }}>
                    <InputLabel id={field.label}>{field.label}</InputLabel>
                    <Select
                        labelId={field.label}
                        id={field.key}
                        value={utilsProvider.accessObjValueByCompositeKey(customer, field.accessor) || ''}
                        error={fieldsWithError.includes(field.accessor)}
                        onChange={(e) => {
                            handleChange(field, e.target.value);
                            field.onChange && field.onChange(e.target.value);
                        }}
                        label={field.label}
                    >
                        {field.options.map(option => (
                            <MenuItem value={option.value}>{option.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ),
            checkbox: () => (
                <FormControlLabel
                    control={
                        <Checkbox
                            value={!!utilsProvider.accessObjValueByCompositeKey(customer, field.accessor)}
                            onChange={(e) => handleChange(field, e.target.checked)}
                            name={field.key}
                            color="primary"
                        />
                    }
                    label={field.label}
                />
            )
        }

        const inputComponent = inputByType[field.type];

        const fieldIsValid = !field.validator || field.validator();
        const hasPrerequisitesFieldsFilleds = !field.prerequisitesFields || field.prerequisitesFields.every(f => customer[f.key] == f.value);

        if (!fieldIsValid || !hasPrerequisitesFieldsFilleds || !inputComponent)
            return null

        return (
            <Grid key={field.label} item md={6} xs={12}>
                {inputComponent()}
            </Grid>
        )
    }

    const fields = [
        {
            label: 'CEP',
            placeholder: 'Digite o CEP',
            key: 'zipCode',
            accessor: 'address.zipCode',
            jsonFieldKey: 'address',
            type: 'text',
            mask: masks.zipCode,
            onChange: (value) => checkSearchAddress(value)
        },
        {
            label: 'Rua',
            placeholder: 'Digite a rua',
            key: 'street',
            accessor: 'address.street',
            jsonFieldKey: 'address',
            type: 'text',
        },
        {
            label: 'Bairro',
            placeholder: 'Digite o bairro',
            key: 'district',
            accessor: 'address.district',
            jsonFieldKey: 'address',
            type: 'text'
        },
        {
            label: 'Complemento',
            placeholder: 'Digite o complemento',
            key: 'complement',
            accessor: 'address.complement',
            jsonFieldKey: 'address',
            type: 'text'
        },
        {
            label: 'Número',
            placeholder: 'Digite o número',
            key: 'number',
            accessor: 'address.number',
            jsonFieldKey: 'address',
            type: 'text'
        },
        {
            label: 'UF',
            key: 'state',
            accessor: 'address.state',
            jsonFieldKey: 'address',
            type: 'select',
            options: states,
            onChange: (value) => getCitiesByUF(value)
        },
        {
            label: 'Cidade',
            key: 'city',
            accessor: 'address.city',
            jsonFieldKey: 'address',
            type: 'select',
            options: cities
        },
    ]

    return (
        <Grid container spacing={6}>
            {fields.map(field => getFieldInputComponent(field))}
        </Grid>
    )
}

export default CustomerAddressForm;